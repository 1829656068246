import type { ReducerFragment } from '../../types';
import type { TranslationConfigsState } from '../types';
import update from 'immutability-helper';

const reduce: ReducerFragment<TranslationConfigsState> = {
  'TRANSLATION_CONFIGS.PUT_PENDING': (state, action) => {
    const { meta } = action;

    return update(state, {
      map: {
        [meta.market]: (market) =>
          update(market || {}, {
            statusPut: {
              $set: 'pending',
            },
          }),
      },
    });
  },
  'TRANSLATION_CONFIGS.PUT_FULFILLED': (state, action) => {
    const { meta } = action;

    return update(state, {
      map: {
        [meta.market]: {
          statusPut: { $set: 'fulfilled' },
        },
      },
    });
  },

  'TRANSLATION_CONFIGS.PUT_REJECTED': (state, action) => {
    const { meta } = action;
    return update(state, {
      map: {
        [meta.market]: {
          statusPut: { $set: 'rejected' },
        },
      },
    });
  },
};

export default reduce;
