import type { TranslationConfigsState } from '../types';
import createReducer from '../../utility/createReducer';
import reduceGet from './get';
import reducePut from './put';

const initialState: TranslationConfigsState = {
  map: { ...null },
};

export default createReducer(initialState, {
  'GLOBAL.RESET': () => initialState,
  ...reduceGet,
  ...reducePut,
});
