export default {
  id: 'mvp',
  firebaseConfig: {
    apiKey: 'AIzaSyCVrL_XRb4faE9CM6OJIX_lb8vsz8TESJY',
    authDomain: 'mvp-euw-1k-mcdonalds.firebaseapp.com',
    projectId: 'mvp-euw-1k-mcdonalds',
    storageBucket: 'mvp-euw-1k-mcdonalds.appspot.com',
  },
  apiConfig: {
    baseURL: 'https://mvp-euw-1k-mcdonalds.appspot.com/_ah/api',
    params: {
      key: 'AIzaSyDGHcUWA0-8omORshEACA-PBwVhQXAUSjw',
    },
  },
};
