import type { ReducerFragment } from '../../types';
import type { RestaurantsState } from '../model/types';
import * as types from '../action/types';
import reduceListFulfilled from '../../utility/general/reducers/reduceListFulfilled';

const reduceMap: ReducerFragment<RestaurantsState> = {
  [types.RESET_MAP]: (state: RestaurantsState) => {
    return { ...state, map: {}, mapInvalidatorId: 'PRISTINE' };
  },

  [types.LIST_CANCEL_ALL]: (state: RestaurantsState) => {
    return {
      ...state,
      pendingListRequests: [],
    };
  },
  [types.LIST_PENDING]: (
    state: RestaurantsState,
    action: types.LIST_PENDING_ACTION
  ) => {
    return {
      ...state,
      statusList: 'pending',
      pendingListRequests: [
        ...state.pendingListRequests,
        action.meta.requestId,
      ],
    };
  },

  [types.LIST_FULFILLED]: reduceListFulfilled(),

  [types.LIST_REJECTED]: (state: RestaurantsState) => {
    return { ...state, statusList: 'rejected' };
  },

  [types.SET_MAP_INVALIDATE_ID]: (
    state: RestaurantsState,
    action: types.SET_MAP_INVALIDATE_ID_ACTION
  ) => ({
    ...state,
    mapInvalidatorId: action.payload,
  }),

  [types.UNSET_MAP_INVALIDATE_ID]: (
    state: RestaurantsState,
    action: types.UNSET_MAP_INVALIDATE_ID_ACTION
  ) => {
    if (state.mapInvalidatorId === action.payload) {
      return {
        ...state,
        mapInvalidatorId: null,
      };
    }
    return state;
  },
};

export default reduceMap;
