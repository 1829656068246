import type { ContextRouter } from 'react-router-dom';
import type { Node } from 'react';

import { withRouter } from 'react-router-dom';
import { useEffect } from 'react';

type Props = {
  ...ContextRouter,
  children?: Node,
};

// Taken from: https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
const ScrollToTop = (props: Props) => {
  const { location, children } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return children || null;
};

export default withRouter(ScrollToTop);
