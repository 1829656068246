import * as types from './types';
import initialState from './initialState';
import createReducer from '../utility/createReducer';
import reduceSetItemEditedData from '../utility/item/reducers/reduceSetItemEditedData';
import reduceSetIsSavingItemEditedData from '../utility/item/reducers/reduceSetIsSavingItemEditedData';
import reducePutItemPending from '../utility/item/reducers/reducePutItemPending';
import reducePutItemFulfilled from '../utility/item/reducers/reducePutItemFulfilled';
import reducePutItemRejected from '../utility/item/reducers/reducePutItemRejected';
import reduceGetItemPending from '../utility/item/reducers/reduceGetItemPending';
import reduceGetItemFulfilled from '../utility/item/reducers/reduceGetItemFulfilled';
import reduceGetItemRejected from '../utility/item/reducers/reduceGetItemRejected';
import {
  getPersistedState,
  persistState as _persistState,
} from './persistance';
import { createLsmConfig } from './creators';

export const preloadedState = {
  ...initialState,
  ...getPersistedState(),
};
export const persistState = _persistState;

export type State = types.State;
export type Action = types.Action;

export const reducer = createReducer(initialState, {
  'GLOBAL.RESET': () => initialState,
  [types.SET_EDITED]: reduceSetItemEditedData,
  [types.SET_IS_SAVING]: reduceSetIsSavingItemEditedData,
  [types.PUT_PENDING]: reducePutItemPending,
  [types.PUT_FULFILLED]: reducePutItemFulfilled,
  [types.PUT_REJECTED]: reducePutItemRejected,
  [types.GET_PENDING]: reduceGetItemPending,
  [types.GET_FULFILLED]: reduceGetItemFulfilled,
  [types.GET_REJECTED]: reduceGetItemRejected(createLsmConfig),
});
