import { Link, useLocation } from 'react-router-dom';
import useStyles from './styles';

const NavItem = ({
  Icon,
  title,
  linkTo,

  subnav,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const isActiveRoute = location.pathname.indexOf(linkTo) !== -1;
  const isActiveClass = isActiveRoute ? classes.isActive : '';

  const isSubNav = subnav ? classes.subNavListItem : '';

  return (
    <li className={classes.listItem}>
      <Link to={linkTo} className={classes.link}>
        <div className={`${classes.content} ${isSubNav}`}>
          {Icon && <Icon className={`${classes.icon} ${isActiveClass}`} />}
          <span className={`${classes.text} ${isActiveClass}`}>{title}</span>
        </div>
      </Link>
    </li>
  );
};

export default NavItem;
