import * as types from './types';
import * as api from './api';
import { type ThunkAction } from '../types';
import * as itemAction from '../utility/item/actionCreators';
import * as generalAction from '../utility/general/actionCreators';
import { selectEditedConfig } from './selectors';
import { selectItemsById } from '../lsmConfigs/selectors';

export const get =
  (marketCode: string): ThunkAction =>
  (dispatch, getState) => {
    return dispatch({
      type: types.GET,
      meta: {
        subjectId: marketCode,
        deactivateErrorMiddlewareMsg: true,
      },
      payload: api.get(marketCode),
    });
  };

export const put = generalAction.put(types.PUT, api.put);

export const setEdited = itemAction.setEdited(types.SET_EDITED);

export const setIsEditing = itemAction.setIsEditing(setEdited, selectItemsById);

export const setIsSaving = itemAction.setIsSaving(types.SET_IS_SAVING);

export const saveEdited = itemAction.saveEdited(
  setIsSaving,
  setIsEditing,
  put,
  selectEditedConfig
);
