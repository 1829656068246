import type { RootState } from '../';
import type { State } from './types';

const KEY = '1k:state:lsmconfigs';

type PersistedState = State | {||};

export const getPersistedState = (): PersistedState => {
  const persisted = localStorage.getItem(KEY);
  if (persisted) {
    return JSON.parse(persisted);
  }
  return Object.freeze({});
};

export const persistState = (state: RootState): void => {
  const itemsWithOnlyData = Object.entries(state.lsmConfigs.items).reduce(
    (accu, [key, { data }]) => {
      accu[key] = { data };
      return accu;
    },
    {}
  );
  const toPersist: PersistedState = {
    items: itemsWithOnlyData,
  };
  if (toPersist) {
    localStorage.setItem(KEY, JSON.stringify(toPersist));
  }
};
