export default {
  id: 'stg',
  firebaseConfig: {
    apiKey: 'AIzaSyDaUipl9K58r3AbyoeMO5jvnNQbYgH2qZU',
    authDomain: 'stg-marketengine-mcdonalds.firebaseapp.com',
    projectId: 'stg-marketengine-mcdonalds',
    storageBucket: 'stg-marketengine-mcdonalds.appspot.com',
  },
  apiConfig: {
    baseURL: 'https://stg-marketengine-mcdonalds.appspot.com/_ah/api',
    params: {
      key: 'AIzaSyCSekldwNTFZwmMD-GjZmAHskWgtjQAdjk',
    },
  },
  me3Url: 'https://stg-next.marketengine.app',
};
