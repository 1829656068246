import update from 'immutability-helper';

const reducePutItemFulfilled = (state: any, action: any) => {
  const data = action.payload.data;
  const itemId = action.meta.subjectId;

  return update(state, {
    items: {
      [itemId]: {
        data: { $set: data },
        statusPut: { $set: 'fulfilled' },
      },
    },
  });
};

export default reducePutItemFulfilled;
