// import { COLOR_DRAWER_BG } from '../../colors';
// import { BORDER_TABLE } from '../../borders';

const Button = {
  MuiDrawer: {
    // paper: {
    //   backgroundColor: COLOR_DRAWER_BG,
    // },
    // paperAnchorDockedLeft: {
    //   borderRight: BORDER_TABLE,
    // },
  },
};

export default Button;
