import type { ThunkAction } from '../../../types';

const debounced = (
  key: string,
  action: ThunkAction,
  durationMs: number = 0
): ThunkAction => {
  const thunk: ThunkAction = (dispatch) => {
    return dispatch(action);
  };

  thunk.meta = {
    debounce: {
      time: durationMs,
      key,
    },
  };

  return thunk;
};

export default debounced;
