import type { MUITheme } from '../../../../styles/types';

const styles = (theme: MUITheme) => ({
  root: {
    padding: theme.spacing(3),
  },
});

export type Classes = $Call<typeof styles, MUITheme>;
export default styles;
