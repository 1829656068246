import type { AxiosPromise } from 'axios';
import type { Market } from './model/types';
import API from '../../utility/API';
import getListParams, {
  type ListRequestOptions,
} from '../../utility/API/getListParams';

export const get = (marketCode: string): Promise<any> => {
  const axios = API.axios();
  return axios.get(`/market/v1/markets/${marketCode}`);
};

export const put = (market: Market): AxiosPromise<Market> => {
  const axios = API.axios();
  return axios.put(`/market/v1/markets/${market.marketCode}`, market);
};

export const post = (market: Market): AxiosPromise<Market> => {
  const axios = API.axios();
  return axios.post(`/market/v1/markets`, market);
};

export const remove = (marketCode: string): AxiosPromise<mixed> => {
  const axios = API.axios();
  return axios.delete(`/market/v1/markets/${marketCode}`);
};

export const list = (options?: ListRequestOptions): Promise<any> => {
  const axios = API.axios();
  return axios.get(`/market/v1/markets`, {
    params: getListParams(options),
  });
};
