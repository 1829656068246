import type { Classes } from './styles';
import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import RouteWithSubRoutes from 'components/common/routeWithSubRoutes/RouteWithSubRoutes';
import ErrorBoundary from 'components/common/errorBoundary/ErrorBoundary';
import AppNavigation from 'components/app/appNavigation/AppNavigation';
import SpacerAppBar from 'components/common/spacerAppBar/SpacerAppBar';
import PageInset from 'components/app/pageInset/PageInset';
import SuspendedPageView from 'components/common/suspendedPageView/SuspendedPageView';
import styles from './styles';
import useFetchLsmConfig from '../../hooks/useFetchLsmConfig/UseFetchLsmConfig';
import useFetchAssetsConfig from '../../hooks/useFetchAssetsConfig/useFetchAssetsConfig';
import { getSelectedMarketId } from '../../state/markets/model/selectors';

type Props = {|
  classes: Classes,
  routes: Object[],
|};

const SelectedMarketRoot = (props: Props) => {
  const { classes, routes } = props;
  const selectedMarketId = useSelector(getSelectedMarketId);

  useFetchLsmConfig(selectedMarketId);
  useFetchAssetsConfig(selectedMarketId);

  return (
    <div className={classes.root}>
      <AppNavigation />
      <PageInset>
        <SpacerAppBar hasToolbar />
        <ErrorBoundary>
          <Suspense fallback={<SuspendedPageView />}>
            <Switch>
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={`${route.path}_${i}`} {...route} />
              ))}
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </PageInset>
    </div>
  );
};

export default withStyles(styles)(SelectedMarketRoot);
