export type LSMBundle = {|
  id: string,
  name: string,
  publishAt: string,
  beginsAt: string,
  endsAt: string,
  createdAt: string,
  updatedAt: string,
  restaurantIds: string[],
  offerIds: string[],
  status: 'Upcoming' | 'Published' | 'Live' | 'Expired',
  scheduleState?: {|
    type?: string,
    scheduleError?: {|
      scheduleErrorCode: string,
      errorMessage?: string,
    |},
  |},
|};

export type State = {|
  map: {|
    [string]: LSMBundle,
  |},
  mapInvalidatorId: ?string,
|};

// POST

export const POST = 'LSM_BUNDLES.POST';
export type POST_ACTION = {
  type: 'LSM_BUNDLES.POST',
  payload: Promise<any>,
};

export const POST_PENDING = 'LSM_BUNDLES.POST_PENDING';
export type POST_PENDING_ACTION = {
  type: 'LSM_BUNDLES.POST_PENDING',
};

export const POST_FULFILLED = 'LSM_BUNDLES.POST_FULFILLED';
export type POST_FULFILLED_ACTION = {
  type: 'LSM_BUNDLES.POST_FULFILLED',
  payload: any,
};

export const POST_REJECTED = 'LSM_BUNDLES.POST_REJECTED';
export type POST_REJECTED_ACTION = {
  type: 'LSM_BUNDLES.POST_REJECTED',
};

type POST_ACTIONS =
  | POST_ACTION
  | POST_PENDING_ACTION
  | POST_FULFILLED_ACTION
  | POST_REJECTED_ACTION;
// PUT

export const PUT = 'LSM_BUNDLES.PUT';
export type PUT_ACTION = {
  type: 'LSM_BUNDLES.PUT',
  payload: Promise<any>,
};

export const PUT_PENDING = 'LSM_BUNDLES.PUT_PENDING';
export type PUT_PENDING_ACTION = {
  type: 'LSM_BUNDLES.PUT_PENDING',
};

export const PUT_FULFILLED = 'LSM_BUNDLES.PUT_FULFILLED';
export type PUT_FULFILLED_ACTION = {
  type: 'LSM_BUNDLES.PUT_FULFILLED',
  payload: any,
};

export const PUT_REJECTED = 'LSM_BUNDLES.PUT_REJECTED';
export type PUT_REJECTED_ACTION = {
  type: 'LSM_BUNDLES.PUT_REJECTED',
};

type PUT_ACTIONS =
  | PUT_ACTION
  | PUT_PENDING_ACTION
  | PUT_FULFILLED_ACTION
  | PUT_REJECTED_ACTION;

// REMOVE

export const REMOVE = 'LSM_BUNDLES.REMOVE';
export type REMOVE_ACTION = {
  type: 'LSM_BUNDLES.REMOVE',
  payload: Promise<any>,
};

export const REMOVE_PENDING = 'LSM_BUNDLES.REMOVE_PENDING';
export type REMOVE_PENDING_ACTION = {
  type: 'LSM_BUNDLES.REMOVE_PENDING',
};

export const REMOVE_FULFILLED = 'LSM_BUNDLES.REMOVE_FULFILLED';
export type REMOVE_FULFILLED_ACTION = {
  type: 'LSM_BUNDLES.REMOVE_FULFILLED',
  payload: any,
};

export const REMOVE_REJECTED = 'LSM_BUNDLES.REMOVE_REJECTED';
export type REMOVE_REJECTED_ACTION = {
  type: 'LSM_BUNDLES.REMOVE_REJECTED',
};

type REMOVE_ACTIONS =
  | REMOVE_ACTION
  | REMOVE_PENDING_ACTION
  | REMOVE_FULFILLED_ACTION
  | REMOVE_REJECTED_ACTION;

// GET

export const GET = 'LSM_BUNDLES.GET';
export type GET_ACTION = {
  type: 'LSM_BUNDLES.GET',
  payload: Promise<any>,
};

export const GET_PENDING = 'LSM_BUNDLES.GET_PENDING';
export type GET_PENDING_ACTION = {
  type: 'LSM_BUNDLES.GET_PENDING',
};

export const GET_FULFILLED = 'LSM_BUNDLES.GET_FULFILLED';
export type GET_FULFILLED_ACTION = {
  type: 'LSM_BUNDLES.GET_FULFILLED',
  payload: any,
};

export const GET_REJECTED = 'LSM_BUNDLES.GET_REJECTED';
export type GET_REJECTED_ACTION = {
  type: 'LSM_BUNDLES.GET_REJECTED',
};

type GET_ACTIONS =
  | GET_ACTION
  | GET_PENDING_ACTION
  | GET_FULFILLED_ACTION
  | GET_REJECTED_ACTION;

// LIST

export const LIST = 'LSM_BUNDLES.LIST';
export type LIST_ACTION = {
  type: 'LSM_BUNDLES.LIST',
  payload: Promise<any>,
};

export const LIST_PENDING = 'LSM_BUNDLES.LIST_PENDING';
export type LIST_PENDING_ACTION = {
  type: 'LSM_BUNDLES.LIST_PENDING',
};

export const LIST_FULFILLED = 'LSM_BUNDLES.LIST_FULFILLED';
export type LIST_FULFILLED_ACTION = {
  type: 'LSM_BUNDLES.LIST_FULFILLED',
  payload: any,
};

export const LIST_REJECTED = 'LSM_BUNDLES.LIST_REJECTED';
export type LIST_REJECTED_ACTION = {
  type: 'LSM_BUNDLES.LIST_REJECTED',
};

// SET_MAP_INVALIDATOR_ID

export const SET_MAP_INVALIDATOR_ID = 'LSM_BUNDLES.SET_MAP_INVALIDATOR_ID';
export type SET_MAP_INVALIDATOR_ID_ACTION = {
  type: 'LSM_BUNDLES.SET_MAP_INVALIDATOR_ID',
  payload: string,
};

// UNSET_MAP_INVALIDATOR_ID

export const UNSET_MAP_INVALIDATOR_ID = 'LSM_BUNDLES.UNSET_MAP_INVALIDATOR_ID';
export type UNSET_MAP_INVALIDATOR_ID_ACTION = {
  type: 'LSM_BUNDLES.UNSET_MAP_INVALIDATOR_ID',
  payload: string,
};

export const RESET = 'LSM_BUNDLES.RESET';
export type RESET_ACTION = {
  type: 'LSM_BUNDLES.RESET',
};

type LIST_ACTIONS =
  | LIST_ACTION
  | LIST_PENDING_ACTION
  | LIST_FULFILLED_ACTION
  | LIST_REJECTED_ACTION
  | SET_MAP_INVALIDATOR_ID_ACTION
  | UNSET_MAP_INVALIDATOR_ID_ACTION;

//------------------------------------

export type Action =
  | POST_ACTIONS
  | PUT_ACTIONS
  | REMOVE_ACTIONS
  | GET_ACTIONS
  | LIST_ACTIONS;
