const Pickers = {
  MuiPickersToolbarText: {
    toolbarTxt: {
      'h2&': {
        //Same as h4, would be better to extend h4, but how?
        fontSize: '2.125rem',
        fontWeight: 400,
        lineHeight: '1.2345',
        letterSpacing: '0.00735em',
      },
    },
  },
};

export default Pickers;
