import CssBaseline from './overrides/CssBaseline';
import Backdrop from './overrides/Backdrop';
import Button from './overrides/Button';
import Accordion from './overrides/Accordion';
import Input from './overrides/Input';
import Table from './overrides/Table';
import Typography from './overrides/Typography';
import Link from './overrides/Link';
import List from './overrides/List';
import Dialog from './overrides/Dialog';
import Drawer from './overrides/Drawer';
import Paper from './overrides/Paper';
import Tooltip from './overrides/Tooltip';
import Checkbox from './overrides/Checkbox';
import Pickers from './overrides/Pickers';
import Switch from './overrides/Switch';

const Overrides = (theme: string) => ({
  ...CssBaseline,
  ...Backdrop,
  ...Button,
  ...Accordion,
  ...Input(theme),
  ...Table,
  ...Typography,
  ...Link,
  ...List,
  ...Dialog,
  ...Drawer,
  ...Paper,
  ...Tooltip,
  ...Checkbox(theme),
  ...Switch(theme),
  ...Pickers,
});

export default Overrides;
