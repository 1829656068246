// This must be the first line in src/index.js
import 'react-app-polyfill/ie11';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { userMe3Session } from "state/user/utils/me3Session";
import store, { history } from './state';
import Root from './root/Root';
import StyleProvider from './styles/StyleProvider';
import SnackbarProvider from './components/common/snackbarProvider/SnackbarProvider';
import AppAlertDialog from './components/app/appAlertDialog/AppAlertDialog';
import LoadingOverlay from './components/common/__loaders/loadingOverlay/LoadingOverlay';

void userMe3Session();

const root = ReactDOM.createRoot(document.getElementById('root') as Element);

root.render(
  <StyleProvider>
    <ReduxProvider store={store}>
      <ConnectedRouter history={history}>
        <SnackbarProvider>
          <Suspense
            fallback={
              <LoadingOverlay
                variants={['centered', 'expand']}
                style={{ minHeight: '100vh' }}
              />
            }
          >
            <div id="AppDialogRoot">
              <AppAlertDialog />
              <Root />
            </div>
          </Suspense>
        </SnackbarProvider>
      </ConnectedRouter>
    </ReduxProvider>
  </StyleProvider>
);

if (process.env.REACT_APP_VERSION) {
  console.log(`%cCurrent version is: ${process.env.REACT_APP_VERSION}`, "font-size: 20px");
}
