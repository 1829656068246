import type { ReducerFragment } from '../../types';
import type { AssetsState } from '../model/types';
import * as types from '../action/types';
import reduceListFulfilled from '../../utility/general/reducers/reduceListFulfilled';

const reduceList: ReducerFragment<AssetsState> = {
  [types.LIST_PENDING]: (state, action) => {
    return { ...state, statusList: 'pending' };
  },

  [types.LIST_FULFILLED]: reduceListFulfilled(),

  [types.LIST_REJECTED]: (state, action) => {
    return { ...state, statusList: 'rejected' };
  },
};

export default reduceList;
