import type { MUITheme } from '../../../../styles/types';
import { TEXT_BODY } from '../../../../styles/textStyles';

const styles = (theme: MUITheme) => ({
  variantAccount_buttonLabel: {
    color: '#DEDEDE',
    textTransform: 'capitalize',
    ...TEXT_BODY,
  },
  variantAccount_iconRoot: {
    color: '#A3A3A3',
  },
});

export type Classes = $Call<typeof styles, MUITheme>;
export default styles;
