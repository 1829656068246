import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _get from 'lodash/get';
// import { setDisplayedLocale as setDisplayedLsmLocale } from '../state/lsm/action/creators';
import * as lsmConfigsAction from '../../state/lsmConfigs/actionCreators';
import { canView as _canView } from '../../state/app/selectors';

const useFetchLsmConfig = (marketId: ?string) => {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => _get(state, 'user.firebaseIdToken'));
  const canView = useSelector(_canView);
  const canViewLocalMarketing = canView('localMarketing');

  useEffect(() => {
    if (canViewLocalMarketing && authToken && marketId && marketId !== '_') {
      dispatch(lsmConfigsAction.get(marketId));
    }
  }, [authToken, marketId, dispatch, canViewLocalMarketing]);
};

export default useFetchLsmConfig;
