import type { AppState } from './app/types';
import type { UserState } from './user/types';
import type { AccountsState } from './accounts/model/types';
import type { MarketsState } from './markets/model/types';
import type { RestaurantsState } from './restaurants/model/types';
import type { RestaurantsConfigsState } from './restaurantsConfigs/model/types';
import type { RestaurantsImportsState } from './restaurantsImports/reducer/types';
import type { RestaurantFacilityOverrideState } from './restaurantsFacilityOverrides/model/types';
import type { AssetsState } from './assets/model/types';
import type { AssetsConfigState } from './assetsConfig/model/types';
import type { RestaurantOrderSettingsState } from './restaurantOrderSettings/model/types';

import type { RolesState } from './roles/model/types';
import type { RoleMembersState } from './roleMembers/model/types';
import type { TranslationsState } from './translations/types';
import type { TranslationConfigsState } from './translationConfigs/types';
import {
  combineReducers,
  legacy_createStore as createStore,
  applyMiddleware,
} from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import getDebounceMiddleware from 'redux-debounced';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import errorMiddleware from './errorMiddleware';
import { ENV } from '../config/env';

import {
  type State as RestaurantsPermissionsState,
  reducer as restaurantsPermissionsReducer,
} from './restaurantsPermissions';
import {
  type State as LsmConfigsState,
  reducer as lsmConfigsReducer,
  preloadedState as preloadedLsmConfigsState,
  persistState as persistLsmConfigsState,
} from './lsmConfigs';
import {
  type State as LsmBundlesState,
  reducer as lsmBundlesReducer,
} from './lsmBundles';
import {
  type State as LsmOfferTemplatesState,
  reducer as lsmOfferTemplatesReducer,
} from './lsmOfferTemplates';
import {
  type State as NutritionConfigsState,
  reducer as nutritionConfigsReducer,
} from './nutritionConfigs';
import {
  type State as NutritionImportsState,
  reducer as nutritionImportsReducer,
} from './nutritionImports';
import {
  type State as NutritionItemsState,
  reducer as nutritionItemsReducer,
} from './nutritionItems';
import {
  type State as NutritionCategoriesState,
  reducer as nutritionCategoriesReducer,
} from './nutritionCategories';
import {
  type State as NutritionAllergensState,
  reducer as nutritionAllergensReducer,
} from './nutritionAllergens';
import {
  type State as NutritionNutrientsState,
  reducer as nutritionNutrientsReducer,
} from './nutritionNutrients';

// app
import appReducer, { preloadedState as preloadedAppState } from './app';
// user
import { USER_PRELOADED_STATE } from "state/user";
import { userReducer } from 'state/user/reducer';
import { userStorageValueSet } from 'state/user/utils/storage';
// accounts
import accountsReducer from './accounts/reducer';
// invitations
import invitationsReducer from './invitations/reducer';
// roles
import rolesReducer from './roles/reducer';
// roleMembers
import roleMembersReducer, {
  preloadedState as preloadedRoleMembersState,
} from './roleMembers/reducer';
import { persistState as persistRoleMembersState } from './roleMembers/persistance';
// restaurants
import restaurantsReducer, {
  preloadedState as preloadedRestaurantsState,
} from './restaurants/reducer';
// restaurantsConfigs
import restaurantsConfigsReducer from './restaurantsConfigs/reducer';
// restaurantsImports
import restaurantsImportsReducer from './restaurantsImports/reducer';
// restaurantFacilityOverrides
import restaurantFacilityOverrides from './restaurantsFacilityOverrides/reducer';
// assets
import assetsReducer from './assets/reducer';
// assetsConfig
import assetsConfigReducer from './assetsConfig/reducer';
// restaurantOrderSettings
import OrderSettingsReducer from './restaurantOrderSettings/reducer';
// translations
import translationsReducer from './translations/reducer';
// translationConfigs
import translationConfigsReducer from './translationConfigs/reducer';
// markets
import marketsReducer, {
  preloadedState as preloadedMarketsState,
} from './markets/reducer';
import { persistState as persistMarketsState } from './markets/persistance';

export const history = createBrowserHistory();

export type RootState = {
  router: Object,
  app: AppState,
  user: UserState,
  accounts: AccountsState,
  markets: MarketsState,
  lsmConfigs: LsmConfigsState,
  lsmBundles: LsmBundlesState,
  lsmOfferTemplates: LsmOfferTemplatesState,
  nutritionImports: NutritionImportsState,
  nutritionConfigs: NutritionConfigsState,
  nutritionItems: NutritionItemsState,
  nutritionCategories: NutritionCategoriesState,
  nutritionAllergens: NutritionAllergensState,
  nutritionNutrients: NutritionNutrientsState,
  assets: AssetsState,
  assetsConfig: AssetsConfigState,
  restaurants: RestaurantsState,
  restaurantsConfigs: RestaurantsConfigsState,
  restaurantsImports: RestaurantsImportsState,
  restaurantsPermissions: RestaurantsPermissionsState,
  restaurantsFacilityOverrides: RestaurantFacilityOverrideState,
  restaurantOrderSettings: RestaurantOrderSettingsState,
  roles: RolesState,
  roleMembers: RoleMembersState,
  translations: TranslationsState,
  translationConfigs: TranslationConfigsState,
};

const preloadedRootState: $Shape<RootState> = {
  app: preloadedAppState,
  user: USER_PRELOADED_STATE,
  roleMembers: preloadedRoleMembersState,
  markets: preloadedMarketsState,
  lsmConfigs: preloadedLsmConfigsState,
  restaurants: preloadedRestaurantsState,
};

const rootReducer = combineReducers({
  router: connectRouter(history),
  app: appReducer,
  user: userReducer,
  accounts: accountsReducer,
  invitations: invitationsReducer,
  roles: rolesReducer,
  markets: marketsReducer,
  lsmConfigs: lsmConfigsReducer,
  lsmBundles: lsmBundlesReducer,
  lsmOfferTemplates: lsmOfferTemplatesReducer,
  nutritionImports: nutritionImportsReducer,
  nutritionConfigs: nutritionConfigsReducer,
  nutritionItems: nutritionItemsReducer,
  nutritionCategories: nutritionCategoriesReducer,
  nutritionAllergens: nutritionAllergensReducer,
  nutritionNutrients: nutritionNutrientsReducer,
  assets: assetsReducer,
  assetsConfig: assetsConfigReducer,
  restaurants: restaurantsReducer,
  restaurantsConfigs: restaurantsConfigsReducer,
  restaurantsImports: restaurantsImportsReducer,
  restaurantsFacilityOverrides: restaurantFacilityOverrides,
  restaurantOrderSettings: OrderSettingsReducer,
  restaurantsPermissions: restaurantsPermissionsReducer,
  roleMembers: roleMembersReducer,
  translations: translationsReducer,
  translationConfigs: translationConfigsReducer,
});

// Reference: https://github.com/zalmoxisus/redux-devtools-extension#13-use-redux-devtools-extension-package-from-npm
const composeEnhancers = composeWithDevTools({
  trace: 'prd' !== ENV,
  traceLimit: 25,
});

const store = createStore(
  rootReducer,
  preloadedRootState,
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      errorMiddleware,
      getDebounceMiddleware(),
      thunk,
      promise
    )
  )
);

window.onbeforeunload = () => {
  persistMarketsState(store.getState());
  userStorageValueSet(store.getState());
  persistRoleMembersState(store.getState());
  persistLsmConfigsState(store.getState());
};

export default store;
