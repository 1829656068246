import { withStyles, AppBar, Toolbar, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';

import MarketPickerMenu from './marketPickerMenu/MarketPickerMenu';
import AppBottomAppBar from './appBottomAppBar/AppBottomAppBar';
import McdIcon from '../../../common/__icons/mcdIcon/McdIcon';
import EnvironmentTag from './environmentTag/EnvironmentTag';
import AccountMenu from './accountMenu/AccountMenu';
import styles from './styles';

type Props = {
  classes: Object,
  onToggleDrawer: () => void,
};

const AppAppBar = (props: Props) => {
  const { classes, onToggleDrawer, ...restProps } = props;

  return (
    <div>
      <AppBar className={classes.topAppBar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarStart}>
            <IconButton onClick={onToggleDrawer} className={classes.logo}>
              <McdIcon />
            </IconButton>
            <IconButton
              aria-label="toggle drawer"
              onClick={onToggleDrawer}
              className={classes.buttonToggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <MarketPickerMenu />
            <EnvironmentTag classes={classes} />
          </div>
          <AccountMenu />
        </Toolbar>
      </AppBar>
      <AppBottomAppBar {...restProps} />
    </div>
  );
};

export default withStyles(styles)(AppAppBar);
