export default async function retry<T>(
  fn: () => Promise<T>,
  retriesLeft: number = 4,
  interval: number = 1000,
  exponential: boolean = true
): Promise<T> {
  try {
    const val = await fn();

    return val;
  }
  catch (error) {
    if (retriesLeft) {
      await new Promise((r) => setTimeout(r, interval));

      return retry(
        fn,
        retriesLeft - 1,
        exponential ? interval * 2 : interval,
        exponential
      );
    }
    else {
      throw new Error(`Max retries reached for function ${fn.name}`);
    }
  }
}
