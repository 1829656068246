import type { AccountsState } from '../model/types';
import * as types from '../action/types';
import createReducer from '../../utility/createReducer';
import reduceGet from './get';
import reducePut from './put';
import reduceMap from './map';
import { orderDirection } from '../../../utility/utility';

const initialState: AccountsState = {
  getStatus: undefined,
  putStatus: undefined,
  listStatus: undefined,
  map: {},
  mapInvalidatorId: 'PRISTINE',
  userAccount: undefined,
  nextPageToken: undefined,
  sortBy: undefined,
  sortOrder: orderDirection.ASCENDING,
};

export default createReducer(initialState, {
  'GLOBAL.RESET': () => initialState,
  [types.RESET_MAP]: (state) => ({
    ...state,
    map: { ...initialState.map, mapInvalidatorId: 'PRISTINE' },
  }),
  ...reduceGet,
  ...reducePut,
  ...reduceMap,
  [types.SET_SORT_BY]: (state, action) => {
    return {
      ...state,
      sortBy: action.payload,
    };
  },
  [types.SET_SEARCH_QUERY]: (state, action) => {
    return {
      ...state,
      searchQuery: action.payload,
    };
  },
});
