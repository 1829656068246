import * as React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme) => ({
  page: {
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.dimensions.drawer.width}px)`,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
});

type Props = {
  classes: Object,
  children: React.Node,
  className: string,
};

const PageInset = (props: Props) => {
  const { children, classes, className } = props;
  return <div className={clsx(className, classes.page)}>{children}</div>;
};

export default withStyles(styles)(PageInset);
