import type { Axios } from 'axios';
import AxiosExport from 'axios';
import _get from 'lodash/get';
import store from '../../state';

type RequestConfig = {
  url?: string;
  method?: string;
  baseURL?: string;
  headers?: string;
  params?: any;
  data?: any;
  timeout?: number;
};

type Variant = 'STANDARD_AUTH' | 'CUSTOM';

class API {
  static _config: RequestConfig;
  static _accessToken: string;

  static initialize = (conf: RequestConfig) => {
    API._config = conf;

    store.subscribe(() => {
      API._accessToken = store.getState().user.firebaseIdToken;
    });
  };

  static axios = (
    variant: Variant = 'STANDARD_AUTH',
    config?: Object
  ): Axios => {
    let conf = {};

    if (variant === 'STANDARD_AUTH') {
      if (API._config) {
        conf = { ...conf, ...API._config };
        if (API._accessToken) {
          conf = {
            ...conf,
            headers: {
              ...(_get(conf, 'headers') || {}),
              Authorization: `Bearer ${API._accessToken}`,
            },
          };
        }
      } else {
        throw new Error(
          'Could not get API instance - initialize first, and make sure an accessToken exists in state'
        );
      }
    }
    if (variant === 'CUSTOM') {
      conf = { ...config };
    }
    return AxiosExport.create(conf);
  };
}

export default API;
