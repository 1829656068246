type Name = 'email' | 'password' | 'activationCode';

type Validator = (value: string) => boolean;

// https://stackoverflow.com/a/13975255
const validateEmail = (email: string) => {
  const input = document.createElement('input');

  input.type = 'email';
  input.required = true;
  input.value = email;

  return typeof input.checkValidity === 'function'
    ? input.checkValidity()
    : /\S+@\S+\.\S+/.test(email);
};

const VALIDATORS: Record<Name, Validator> = {
  email: (value) => value.length > 5 && validateEmail(value),
  password: (value) => value.length > 4,
  activationCode: (value) => value.length > 3,
};

export default (name: Name, value: string): boolean => {
  if (name in VALIDATORS) {
    return VALIDATORS[name](value);
  }
  throw new Error(`Could not validate ${name}. Validation is not configured.`);
};
