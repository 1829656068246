import { COLOR_DANGER } from '../../colors';
export const LINK_STYLES = {
  color: COLOR_DANGER,
  fontWeight: 500,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
};

const List = {
  MuiLink: {
    root: LINK_STYLES,
  },
};

export default List;
