import React, { useState } from 'react';

import AppDrawer from './appDrawer/AppDrawer';
import AppAppBar from './appAppBar/AppAppBar';

const AppNavigation = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleToggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  return (
    <div className="AppNavigation">
      <AppDrawer mobileIsOpen={isDrawerOpen} onClose={handleToggleDrawer} />
      <AppAppBar onToggleDrawer={handleToggleDrawer} />
    </div>
  );
};

export default AppNavigation;
