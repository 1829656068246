import { COLOR_GRAY_DARK, COLOR_BUTTON_TEXT } from '../../../../styles/colors';
import type { MUITheme } from '../../../../styles/types';

const styles = (theme: MUITheme) => {
  return {
    topAppBar: {
      [theme.breakpoints.up('md')]: {
        zIndex: theme.zIndex.appBar,
      },
      backgroundColor:
        theme.palette.type === 'dark' ? '#1b1b1b' : theme.palette.primary.main,
    },

    logo: {
      // To make the text in the market picker line up with the text of the menu below
      marginRight: 7,

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },

    buttonToggleDrawer: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      color: COLOR_BUTTON_TEXT,
    },

    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    toolbarStart: {
      display: 'flex',
      alignItems: 'center',
      // To make the logo line up with icons in the menu below
      marginLeft: -1 * theme.spacing(1) - 2,
    },

    envTag: {
      color: COLOR_GRAY_DARK,
    },
  };
};

export default styles;
