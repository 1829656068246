import type { ThunkAction } from '../../types';
import type { Market } from '../model/types';
import { replace } from 'connected-react-router';
import qs from 'qs';
import _get from 'lodash/get';
import * as types from './types';
import * as api from '../api';
import { userKickOut } from "state/user/action/kickOut";
import HandledError from '../../../errors/HandledError';
import listHelper from '../../utility/general/actionCreators/listHelper';
import { setDisplayedLocale as setDisplayedRestaurantsLocale } from '../../restaurants/action/creators';

export const get =
  (marketCode: string): ThunkAction =>
  (dispatch) => {
    return dispatch({
      type: types.GET,
      payload: api.get(marketCode).then((response) => response.data),
    });
  };

export const put =
  (market: Market): ThunkAction =>
  (dispatch, getState) => {
    return dispatch({
      type: types.PUT,
      payload: api.put(market).then((response) => response.data),
    });
  };

export const post =
  (market: $Shape<Market>): ThunkAction =>
  (dispatch, getState) => {
    return dispatch({
      type: types.POST,
      payload: api.post(market).then((response) => response.data),
    });
  };

export const remove =
  (marketCode: string): ThunkAction =>
  (dispatch, getState) => {
    return dispatch({
      type: types.REMOVE,
      payload: api.remove(marketCode).then(() => ({
        id: marketCode,
      })),
    });
  };

export const initSelectedMarket = (): ThunkAction => (dispatch, getState) => {
  const {
    markets: { selectedMarketId, map },
    router: {
      location: { pathname, search },
    },
  } = getState();

  const marketInPath = getMarketInPath(pathname);

  const marketIds = Object.keys(map);

  let newSelectedMarketId =
    (marketInPath && marketIds.find((market) => market === marketInPath)) ||
    selectedMarketId;

  if (!selectedMarketId || !marketIds.includes(selectedMarketId)) {
    // This happens if the user is new, because then no selectedMarketId has been initialized from localStorage
    // (see the persistance file).
    // It also happens if the previously signed in user had a market selected that this user doesn't have access to.
    newSelectedMarketId = marketIds[0];
    if (search) {
      newSelectedMarketId =
        marketIds.find(
          (market) => market === getMarketInRedirectPath(search)
        ) || marketIds[0];
    }

    dispatch({
      type: types.SET_SELECTED_MARKET_ID,
      payload: newSelectedMarketId,
    });
  }

  if (!newSelectedMarketId) {
    return Promise.resolve();
  }

  if (marketInPath && marketInPath !== newSelectedMarketId) {
    const newPathname = `/${newSelectedMarketId}/`; // + pathname.split('/').slice(2).join('/');

    dispatch(replace({ ...getState().router.location, pathname: newPathname }));
  }

  return dispatch(selectMarket(newSelectedMarketId));
};

// Also update markets action creators file
const UN_AUTHENTICATED_ROUTES = [
  '/',
  '/auth',
  '/auth/sign-in',
  '/auth/reset',
  '/auth/activate',
];

const ALWAYS_RENDERED_ROUTES = [
  '/auth/activate/start',
  '/auth/activate/set-password',
  '/auth/activate/expired-invite',
];

const getMarketInPath = (path: string) => {
  const redirectPath = path.split('?')[0];
  if (
    !UN_AUTHENTICATED_ROUTES.includes(redirectPath) &&
    !ALWAYS_RENDERED_ROUTES.includes(redirectPath)
  ) {
    const splitPath = redirectPath.split('/');
    return splitPath[1];
  }
  return undefined;
};

const getMarketInRedirectPath = (search: string) => {
  const searchParams = qs.parse(search.slice(1));
  if (searchParams.redirect) {
    return getMarketInPath(searchParams.redirect);
  }
  return undefined;
};

export const selectMarket =
  (nextMarketId: string): ThunkAction =>
  (dispatch, getState): Promise<void> => {
    const { selectedMarketId } = getState().markets;
    if (nextMarketId !== selectedMarketId) {
      dispatch({
        type: types.SET_SELECTED_MARKET_ID,
        payload: nextMarketId,
      });
      dispatch(setDisplayedRestaurantsLocale(undefined));
      dispatch(get(nextMarketId));
    }
    return Promise.resolve();
  };

export const list =
  (options?: {
    getNextPage?: boolean,
    append?: boolean,
    invalidate?: boolean,
    debounce?: {
      key?: string,
      duration: number,
    },
    fetchAll?: boolean,
    pageSize?: number,
  }): ThunkAction =>
  (dispatch, getState) => {
    const { markets } = getState();
    const { sortBy: orderBy, sortOrder, searchQuery } = markets;
    const { invalidate, pageSize, ...restOptions } = options || {};

    let cfg = {
      ...restOptions,
      fetch: {
        action: types.LIST,
        bindFetch: (market, nextPageToken) =>
          api.list({
            nextPageToken,
            orderBy,
            sortOrder,
            searchQuery,
            pageSize,
          }),
      },
      statePath: 'markets',
    };

    if (options) {
      if (options.invalidate) {
        cfg = {
          ...cfg,
          invalidate: {
            actionSet: types.SET_MAP_INVALIDATOR_ID,
            actionClear: types.UNSET_MAP_INVALIDATOR_ID,
          },
        };
      }
    }
    return dispatch(
      listHelper(cfg, (error) => {
        if (403 === _get(error, 'response.status')) {
          dispatch(userKickOut());
          return Promise.reject(
            new HandledError('Unauthorized: User kicked out')
          );
        }
        return Promise.reject(error);
      })
    );
  };
