import type { ReducerFragment } from '../../types';
import type { RestaurantOrderSettingsState } from '../model/types';
import * as types from '../action/types';

const reduceList: ReducerFragment<RestaurantOrderSettingsState> = {
  [types.LIST_PENDING]: (state, action) => {
    return { ...state, statusList: 'pending' };
  },

  [types.LIST_FULFILLED]: (state, action) => {
    const orderSettings = action.payload.items;

    orderSettings?.sort((a, b) => new Date(a.startAt) - new Date(b.startAt));

    return {
      ...state,
      orderSettings,
      statusList: 'fulfilled',
    };
  },

  [types.LIST_REJECTED]: (state, action) => {
    return { ...state, statusList: 'rejected' };
  },
};

export default reduceList;
