import { withStyles, AppBar, Toolbar, Typography } from '@material-ui/core';
import React from 'react';

import McdIcon from '../../common/__icons/mcdIcon/McdIcon';
import styles from './styles';

type Props = {
  classes: Object,
};

/**
 * A simple version of the app bar, shown on pages where the user is not signed in.
 */
const AppBarSimple = (props: Props) => {
  const { classes } = props;

  return (
    <AppBar>
      <Toolbar className={classes.toolbar}>
        <div className={classes.toolbarStart}>
          <McdIcon className="mr-3" />
          <Typography variant="button" className="color-white">
            Market Engine
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(AppBarSimple);
