import type { MUITheme } from '../../../../../styles/types';
import { BORDER_TABLE } from '../../../../../styles/borders';

const styles = (theme: MUITheme) => ({
  root: {
    top: theme.dimensions.appBar.height,
    display: 'flex',
    borderBottom: BORDER_TABLE,
    transition: 'box-shadow 90ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    [theme.breakpoints.up('md')]: {
      marginLeft: theme.dimensions.drawer.width,
      width: `calc(100% - ${theme.dimensions.drawer.width}px)`,
      zIndex: theme.zIndex.appBar - 1,
    },
  },

  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: 77,
    paddingBottom: 16,
  },

  toolbarStart: {
    display: 'flex',
    alignItems: 'flex-end',
  },

  contextControlsEnd: {
    display: 'flex',
    alignItems: 'center',
  },
});

export type Classes = $Call<typeof styles, MUITheme>;
export default styles;
