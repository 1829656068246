import type { Classes } from './styles';
import * as React from 'react';
import clsx from 'clsx';
import { Typography, withStyles } from '@material-ui/core';
import { COLOR_GRAY_DARK } from '../../../styles/colors';
import styles from './styles';
import Centerer from './centerer/Centerer';
import PaperPadded from './paperPadded/PaperPadded';

type Props = {
  heading: string,
  preamble: string,
  children: React.Node,
  className?: string,
  classes: Classes,
};

const FocusPageView = (props: Props) => (
  <Centerer color="muted" className={clsx(props.className, props.classes.root)}>
    <PaperPadded className={props.classes.center}>
      <Typography variant="h5" className=" my-3">
        {props.heading}
      </Typography>
      <Typography
        variant="body1"
        className="mb-3"
        style={{ color: COLOR_GRAY_DARK }}
      >
        {props.preamble}
      </Typography>
      {props.children}
    </PaperPadded>
  </Centerer>
);
export default withStyles(styles)(FocusPageView);
