import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _get from 'lodash/get';
import * as assetsConfigActions from '../../state/assetsConfig/action/creators';

import { canView as _canView } from '../../state/app/selectors';

const useFetchAssetsConfig = (marketId: ?string) => {
  const dispatch = useDispatch();
  const authToken = useSelector((state) =>
    Boolean(_get(state, 'user.firebaseIdToken'))
  );
  const canView = useSelector(_canView);
  const canViewAssets = canView('mop');

  useEffect(() => {
    if (canViewAssets && authToken && marketId && marketId !== '_') {
      dispatch(assetsConfigActions.get(marketId));
    }
  }, [authToken, marketId, dispatch, canViewAssets]);
};

export default useFetchAssetsConfig;
