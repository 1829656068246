const Paper = {
  MuiPaper: {
    elevation1: {
      boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
    },
    elevation2: {
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    },
    elevation3: {
      boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2)',
    },
  },
};

export default Paper;
