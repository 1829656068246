import { type LSMConfig } from './types';
import API from '../../utility/API';

export const get = (marketCode: string): Promise<{ data: any }> => {
  const axios = API.axios();
  return axios.get(`/lsm/v1/config/${marketCode}`);
};

export const put = (marketCode: string, data: LSMConfig): Promise<Object> => {
  const axios = API.axios();
  return axios.put(`/lsm/v1/config/${marketCode}`, data);
};
