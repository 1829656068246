import type { ThunkDispatch } from "redux-thunk";
import { useDispatch as useReduxDispatch } from 'react-redux';
import type { RootState } from "state/index";
import type { Action } from "state/types";

type Dispatch = ThunkDispatch<RootState, unknown, Action>;

export function useDispatch(): Dispatch {
  return useReduxDispatch() as Dispatch;
}
