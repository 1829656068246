import type { Classes } from './styles';
import * as React from 'react';
import clsx from 'clsx';
import { Paper, withStyles } from '@material-ui/core';
import styles from './styles';

type Props = {|
  className?: string,
  classes: Classes,
  children: React.Node,
|};

const PaperPadded = ({ classes, className, ...rest }: Props) => (
  <Paper className={clsx(className, classes.root)} {...rest} />
);

export default withStyles(styles)(PaperPadded);
