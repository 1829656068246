import Overrides from './Overrides';
import {
  COLOR_DANGER,
  COLOR_WARNING,
  COLOR_SUCCESS,
  COLOR_TEXT_DEFAULT,
  COLOR_TEXT_DISABLED,
  COLOR_BUTTON_TEXT,
} from '../colors';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { darken } from '@material-ui/core/styles';

const hoverOpacity = 0.2;

const defaultTheme = {
  palette: {
    type: 'light',
    typography: {
      primary: COLOR_TEXT_DEFAULT,
      disabled: COLOR_TEXT_DISABLED,
    },
    primary: {
      light: alpha(COLOR_TEXT_DEFAULT, hoverOpacity),
      main: COLOR_TEXT_DEFAULT,
      dark: darken(COLOR_TEXT_DEFAULT, hoverOpacity),
      text: COLOR_BUTTON_TEXT,
    },
    secondary: {
      light: alpha(COLOR_TEXT_DISABLED, hoverOpacity),
      main: COLOR_TEXT_DISABLED,
      dark: darken(COLOR_TEXT_DISABLED, hoverOpacity),
    },
    warning: {
      light: alpha(COLOR_WARNING, hoverOpacity),
      main: COLOR_WARNING,
      dark: darken(COLOR_WARNING, hoverOpacity),
      text: COLOR_BUTTON_TEXT,
    },
    error: {
      light: alpha(COLOR_DANGER, hoverOpacity),
      main: COLOR_DANGER,
      dark: darken(COLOR_DANGER, hoverOpacity),
      text: COLOR_BUTTON_TEXT,
    },
    success: {
      light: alpha(COLOR_SUCCESS, hoverOpacity),
      main: COLOR_SUCCESS,
      dark: darken(COLOR_SUCCESS, hoverOpacity),
      text: COLOR_BUTTON_TEXT,
    },
    paper: {
      main: '#fff',
      hover: '#f5f5f5',
      transparent: alpha('#fff', 0.75),
    },
    skeleton: {
      main: '#d7d7d7',
    },
  },
  /* Custom below */
  dimensions: {
    drawer: {
      width: 230,
    },
    appBar: {
      height: 64,
      heightSm: 56,
    },
    tabBar: {
      height: 48,
    },
  },
  link: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  overrides: { ...Overrides('light') },
};

const darkTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    type: 'dark',

    typography: {
      primary: '#fff',
      disabled: '#868686',
    },
    secondary: {
      main: COLOR_TEXT_DISABLED,
    },
    primary: {
      main: '#fff',
    },
    paper: {
      main: '#424242',
      hover: '#5f5f5f',
      transparent: alpha('#787878', 0.5),
    },
    skeleton: {
      main: '#606060',
    },
  },
  overrides: { ...Overrides('dark') },
};

const muiTheme = (themeName: string) =>
  themeName === 'dark' ? darkTheme : defaultTheme;

export default muiTheme;
