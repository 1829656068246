import React from 'react';
import { Grid } from '@material-ui/core';
import GridRootContainer from '../gridRootContainer/GridRootContainer';
import Progress from '../__loaders/progress/Progress';

const SuspendedPageView = () => (
  <GridRootContainer>
    <Grid item xs={12}>
      <Progress delay={1000} />
    </Grid>
  </GridRootContainer>
);

export default SuspendedPageView;
