import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
  toolbarFatHeight: {
    height: 77,
  },
  tabBarHeight: {
    height: theme.dimensions.tabBar.height,
  },
});

const SpacerAppBar = (props) => {
  const { classes, className, hasToolbar } = props;

  const app = useSelector((state) => state.app);

  return (
    <div className={clsx(className, classes.page)}>
      <div className={classes.toolbar} />
      {hasToolbar && <div className={classes.toolbarFatHeight} />}
      {app.tabs.length > 0 && <div className={classes.tabBarHeight} />}
    </div>
  );
};

export default withStyles(styles)(SpacerAppBar);
