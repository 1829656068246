import {
  TEXT_HEADLINE1,
  TEXT_HEADLINE2,
  TEXT_SUB_HEAD,
  TEXT_BODY,
  TEXT_BUTTON,
  TEXT_DESCRIPTION,
} from '../../textStyles';

const Typography = {
  MuiTypography: {
    root: {
      ...TEXT_BODY,
    },
    body1: {
      ...TEXT_BODY,
    },
    body2: {
      ...TEXT_DESCRIPTION,
    },
    h1: {
      ...TEXT_HEADLINE1,
    },
    h2: {
      ...TEXT_HEADLINE2,
    },
    subtitle1: {
      ...TEXT_SUB_HEAD,
    },
    button: {
      ...TEXT_BUTTON,
    },

    gutterBottom: {
      marginBottom: 16,
    },
  },
};

export default Typography;
