/* eslint-disable import/no-anonymous-default-export */
import { type ThunkAction, type Action } from '../../../types';
import { getUUID } from '../../../../utility/utility';
import debounced from './debounced';
import _get from 'lodash/get';

type Options = {
  fetch: {
    action: string,
    bindFetch: (subject: any, nextPageToken?: string) => Promise<any>,
  },
  statePath: string,
  subject?: string,
  invalidate?: {
    actionSet: string,
    actionClear: string,
  },
  getNextPage?: ?boolean,
  debounce?: {
    key?: string,
    duration: number,
  },
  append?: boolean,
  fetchAll?: boolean,
  requestId?: string,
  meta?: Object,
};

export default (options?: Options, onError?: () => Promise<any>): ThunkAction =>
  (dispatch, getState) => {
    const {
      fetch,
      subject,
      statePath,
      getNextPage,
      invalidate,
      debounce,
      append,
      fetchAll,
      requestId,
      meta,
    } = options || {};

    let invalidatorId;

    if (invalidate) {
      invalidatorId = getUUID();

      dispatch({
        type: invalidate.actionSet,
        payload: invalidatorId,
        meta: { subject, ...meta },
      });
    }

    const _requestId = requestId ?? getUUID();
    const _meta = {
      ...meta,
      subject,
      append,
      clearInvalidatorId: invalidatorId,
      requestId: _requestId,
    };

    let action: ThunkAction;

    if (getNextPage) {
      let nextPageToken = _get(getState(), `${statePath}.nextPageToken`);
      if (!nextPageToken) {
        console.warn(
          "Can't get next page, because there's no next page token in state."
        );
      }
      action = () => (dispatch) =>
        dispatch({
          type: fetch.action,
          payload: fetch.bindFetch(subject, nextPageToken),
          meta: _meta,
        }).finally((result) => {
          if (invalidate) {
            dispatch({
              type: invalidate.actionClear,
              payload: invalidatorId,
              meta: { subject, ...meta },
            });
          }
          return result;
        });
    } else {
      action = () => (dispatch) =>
        dispatch({
          type: fetch.action,
          payload: fetch.bindFetch(subject),
          meta: _meta,
        }).finally((result) => {
          if (invalidate) {
            dispatch({
              type: invalidate.actionClear,
              payload: invalidatorId,
              meta: { subject, ...meta },
            });
          }
          return result;
        });
    }
    return dispatch(
      _doDispatch(
        action,
        statePath,
        subject,
        debounce,
        fetchAll,
        fetch,
        _requestId
      )
    )
      .catch(onError)
      .then(() => _get(getState(), `${statePath}.map`));
    // .finally((map) => {
    //   if (invalidate) {

    //     dispatch({

    //       type: invalidate.actionClear,
    //       payload: invalidatorId,
    //       meta: { subject },
    //     });
    //   }
    //   return map;
    // });
  };

const _doDispatch =
  (
    action,
    statePath,
    subject,
    debounce,
    fetchAll,
    fetch,
    requestId
  ): ThunkAction =>
  (dispatch) => {
    if (debounce) {
      if (fetchAll) {
        return dispatch(
          debounced(
            debounce.key || fetch.action,
            _listPages(action(), fetch, subject, 100, 1, statePath, requestId),
            debounce.duration || 1000
          )
        );
      } else {
        return dispatch(
          debounced(
            debounce.key || fetch.action,
            action(),
            debounce.duration || 1000
          )
        );
      }
    } else {
      if (fetchAll) {
        return dispatch(
          _listPages(action(), fetch, subject, 50, 1, statePath, requestId)
        );
      } else {
        return dispatch(action());
      }
    }
  };

const _listPages =
  (
    action: Action,
    fetch: any,
    subject?: string,
    maxNumPages?: number = 1,
    counter?: number = 1,
    statePath: string,
    requestId: string
  ): ThunkAction =>
  (dispatch, getState) => {
    const pendingRequests = _get(
      getState(),
      `${statePath}.pendingListRequests`
    );
    if (
      pendingRequests &&
      counter > 1 &&
      !pendingRequests.includes(requestId)
    ) {
      return Promise.resolve(`Request cancelled: ${requestId}`);
    }
    return dispatch(action).then((result) => {
      if (
        counter < maxNumPages &&
        result &&
        result.value.data.items &&
        result.value.data.items.length > 0 &&
        result.value.data.nextPageToken
      ) {
        let nextPageToken = _get(result, `value.data.nextPageToken`);
        action = {
          type: fetch.action,
          payload: fetch.bindFetch(subject, nextPageToken),
          meta: { ...result.action.meta, append: true },
        };
        return dispatch(
          _listPages(
            action,
            fetch,
            subject,
            maxNumPages,
            counter + 1,
            statePath,
            requestId
          )
        );
      }
      return action;
    });
  };
