import type { RootState } from '../';
import type { AsyncStatus } from '../types';
import type { RoleMember } from './model/types';

const KEY = '1k:state:role-members';

type PersistedRoleMembersState =
  | {|
      statusList: ?AsyncStatus,
      map: {| [string]: RoleMember |},
      mapIsValid: boolean,
      nextPageToken: ?string,
    |}
  | {||};

export const getPersistedState = (): PersistedRoleMembersState => {
  const persisted = localStorage.getItem(KEY);
  if (persisted) {
    return JSON.parse(persisted);
  }
  return Object.freeze({});
};

export const persistState = (state: RootState): void => {
  const toPersist: PersistedRoleMembersState = {
    statusList: state.roleMembers.statusList,
    map: state.roleMembers.map,
    mapIsValid: state.roleMembers.mapIsValid,
    nextPageToken: state.roleMembers.nextPageToken,
  };
  if (toPersist) {
    localStorage.setItem(KEY, JSON.stringify(toPersist));
  }
};
