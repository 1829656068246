export type LSMOfferTemplateLocalised = {|
  title: ?string,
  promoImagePath: ?string,
  promoImageDescription: ?string,
  termsAndConditions: ?string,
  description: ?string,
  languageCode: string,
  languageName: ?string,
|};

export type LSMOfferTemplate = {|
  id: string,
  externalId: string,
  merchantId: string,
  startDate: string,
  endDate: string,
  market: string,
  catalogueId: string,
  localisedContent: LSMOfferTemplateLocalised[],
  _meta?: {
    localisedContentLang: string,
  },
  // "Requirements"
  dailyStartTime?: number,
  dailyEndTime?: number,
  daysOfWeek?: number[],
  repeating?: boolean,
  repeatsInDays?: number,
  numberOfRepeats?: number,
  contentTags?: {
    tagName: string,
    code: string,
  }[],
|};

export type State = {|
  map: {|
    [string]: LSMOfferTemplate,
  |},
  mapInvalidatorId: ?string,
|};

// LIST

export const LIST = 'LSM_OFFER_TEMPLATES.LIST';
export type LIST_ACTION = {
  type: 'LSM_OFFER_TEMPLATES.LIST',
  payload: Promise<any>,
};

export const LIST_PENDING = 'LSM_OFFER_TEMPLATES.LIST_PENDING';
export type LIST_PENDING_ACTION = {
  type: 'LSM_OFFER_TEMPLATES.LIST_PENDING',
};

export const LIST_FULFILLED = 'LSM_OFFER_TEMPLATES.LIST_FULFILLED';
export type LIST_FULFILLED_ACTION = {
  type: 'LSM_OFFER_TEMPLATES.LIST_FULFILLED',
  payload: any,
};

export const LIST_REJECTED = 'LSM_OFFER_TEMPLATES.LIST_REJECTED';
export type LIST_REJECTED_ACTION = {
  type: 'LSM_OFFER_TEMPLATES.LIST_REJECTED',
};

// SET_MAP_INVALIDATOR_ID

export const SET_MAP_INVALIDATOR_ID =
  'LSM_OFFER_TEMPLATES.SET_MAP_INVALIDATOR_ID';
export type SET_MAP_INVALIDATOR_ID_ACTION = {
  type: 'LSM_OFFER_TEMPLATES.SET_MAP_INVALIDATOR_ID',
  payload: string,
};

// UNSET_MAP_INVALIDATOR_ID

export const UNSET_MAP_INVALIDATOR_ID =
  'LSM_OFFER_TEMPLATES.UNSET_MAP_INVALIDATOR_ID';
export type UNSET_MAP_INVALIDATOR_ID_ACTION = {
  type: 'LSM_OFFER_TEMPLATES.UNSET_MAP_INVALIDATOR_ID',
  payload: string,
};

type LIST_ACTIONS =
  | LIST_ACTION
  | LIST_PENDING_ACTION
  | LIST_FULFILLED_ACTION
  | LIST_REJECTED_ACTION
  | SET_MAP_INVALIDATOR_ID_ACTION
  | UNSET_MAP_INVALIDATOR_ID_ACTION;

// SET_OFFER_DISPLAYED_LC_LANG

export const SET_OFFER_DISPLAYED_LC_LANG =
  'LSM_OFFER_TEMPLATES.SET_OFFER_DISPLAYED_LC_LANG';
export type SET_OFFER_DISPLAYED_LC_LANG_ACTION = {
  type: 'LSM_OFFER_TEMPLATES.SET_OFFER_DISPLAYED_LC_LANG',
  payload: {
    offerId: string,
    displayedLCLang: string,
  },
};

//------------------------------------

export type Action = LIST_ACTIONS | SET_OFFER_DISPLAYED_LC_LANG_ACTION;
