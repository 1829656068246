import React, { useState, useEffect } from 'react';
import { CircularProgress, makeStyles, Fade } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

type Props = {|
  style?: Object,
  delay?: number,
  fadeProps?: Object,
  progressProps?: Object,
|};

const Progress = ({
  delay: _delay,
  fadeProps,
  progressProps,
  style,
}: Props) => {
  const delay = _delay || 0;
  const classes = useStyles();
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay]);

  return (
    <div className={classes.root} style={style}>
      <Fade in={show} {...fadeProps}>
        <CircularProgress size={35} {...progressProps} />
      </Fade>
    </div>
  );
};

export default Progress;
