import type { AxiosPromise } from 'axios';
import API from '../../../utility/API';

export const get = (marketCode: string): Promise<any> => {
  const axios = API.axios();
  return axios.get(`/restaurant/v1/config/${marketCode}`);
};

export const put = (
  marketCode: string,
  restaurantsConfig: Object
): AxiosPromise<Object> => {
  const axios = API.axios();
  return axios.put(`/restaurant/v1/config/${marketCode}`, restaurantsConfig);
};
