import type { MUITheme } from '../types';
import { withStyles } from '@material-ui/core';
import generateSpacingHelpers from './generateSpacingHelpers';
import flexboxHelpers from './flexboxHelpers';
import displayHelpers from './displayHelpers';
import colorHelpers from './colorHelpers';
import miscHelpers from './miscHelpers';
import textHelpers from './textHelpers';
import animations from './animations';

const styles = (theme) => ({
  // Global JSS, see: https://cssinjs.org/jss-global/
  '@global': {
    ...generateSpacingHelpers(theme.spacing(1)),
    ...flexboxHelpers,
    ...displayHelpers,
    ...colorHelpers,
    ...miscHelpers,
    ...textHelpers,
    ...animations,
  },
});

type Props = { classes: $Call<typeof styles, MUITheme> };
export default withStyles(styles)((props: Props) => null);
