import React, { useState } from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import MuiThemeOptions from './muiTheme/MuiTheme';
import CssGlobals from './globals/Globals';
import {
  THEME_NAME,
  THEME_LOCAL_STORAGE_KEY,
} from '../hooks/useDarkMode/UseDarkMode';

export const ThemeContext = React.createContext();

const StyleProvider = ({ children }: any) => {
  const [theme, setTheme] = useState(
    localStorage.getItem(THEME_LOCAL_STORAGE_KEY) || THEME_NAME.LIGHT
  );
  const muiTheme = createTheme(MuiThemeOptions(theme));

  return (
    <ThemeContext.Provider value={{ setTheme, theme }}>
      <MuiThemeProvider theme={muiTheme}>
        <CssGlobals />
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default StyleProvider;
