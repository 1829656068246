import type { ReducerFragment } from '../../types';
import type { RestaurantsConfigsState } from '../model/types';
import * as types from '../action/types';

const fragment: ReducerFragment<RestaurantsConfigsState> = {
  [types.PUT_PENDING]: (state, action) => {
    return {
      ...state,
      statusPut: 'pending',
    };
  },

  [types.PUT_FULFILLED]: (state, action) => {
    const restaurantsConfig = action.payload.data;
    const marketCode = action.meta.subjectId;

    return {
      ...state,
      map: {
        ...state.map,
        [marketCode]: restaurantsConfig,
      },
      statusPut: 'fulfilled',
    };
  },

  [types.PUT_REJECTED]: (state, action) => ({
    ...state,
    statusPut: 'rejected',
  }),
};

export default fragment;
