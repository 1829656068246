import type { Page, Restaurant, RpmConfigData } from "../model/types";
import type { OrderDirection } from '../../../utility/types';

// RESET

export const RESET = 'RESTAURANTS.RESET';
export interface RESET_ACTION {
  type: 'RESTAURANTS.RESET';
}

// RESET_MAP

export const RESET_MAP = 'RESTAURANTS.RESET_MAP';
export interface RESET_MAP_ACTION {
  type: 'RESTAURANTS.RESET_MAP';
}

// LIST

export const LIST = 'RESTAURANTS.LIST';
export interface LIST_ACTION {
  type: 'RESTAURANTS.LIST';
  payload: Promise<any>;
}

export const LIST_PENDING = 'RESTAURANTS.LIST_PENDING';
export interface LIST_PENDING_ACTION {
  type: 'RESTAURANTS.LIST_PENDING';
  payload: Page;
  meta: { requestId?: string };
}

export const LIST_FULFILLED = 'RESTAURANTS.LIST_FULFILLED';
export interface LIST_FULFILLED_ACTION {
  type: 'RESTAURANTS.LIST_FULFILLED';
  payload: Page;
}

export const LIST_REJECTED = 'RESTAURANTS.LIST_REJECTED';
export interface LIST_REJECTED_ACTION {
  type: 'RESTAURANTS.LIST_REJECTED';
}

export const LIST_CANCEL_ALL = 'RESTAURANTS.LIST_CANCEL_ALL';
export interface LIST_CANCEL_ALL_ACTION {
  type: 'RESTAURANTS.LIST_CANCEL_ALL';
}

// GET

export const GET = 'RESTAURANTS.GET';
export interface GET_ACTION {
  type: 'RESTAURANTS.GET';
  payload: Promise<any>;
}

export const GET_PENDING = 'RESTAURANTS.GET_PENDING';
export interface GET_PENDING_ACTION {
  type: 'RESTAURANTS.GET_PENDING';
}

export const GET_FULFILLED = 'RESTAURANTS.GET_FULFILLED';
export interface GET_FULFILLED_ACTION {
  type: 'RESTAURANTS.GET_FULFILLED';
  payload: Restaurant;
}

export const GET_REJECTED = 'RESTAURANTS.GET_REJECTED';
export interface GET_REJECTED_ACTION {
  type: 'RESTAURANTS.GET_REJECTED';
}

// PUT

export const PUT = 'RESTAURANTS.PUT';
export interface PUT_ACTION {
  type: 'RESTAURANTS.PUT';
  payload: Promise<any>;
}

export const PUT_PENDING = 'RESTAURANTS.PUT_PENDING';
export interface PUT_PENDING_ACTION {
  type: 'RESTAURANTS.PUT_PENDING';
}

export const PUT_FULFILLED = 'RESTAURANTS.PUT_FULFILLED';
export interface PUT_FULFILLED_ACTION {
  type: 'RESTAURANTS.PUT_FULFILLED';
  payload: Restaurant;
}

export const PUT_REJECTED = 'RESTAURANTS.PUT_REJECTED';
export interface PUT_REJECTED_ACTION {
  type: 'RESTAURANTS.PUT_REJECTED';
}

// POST

export const POST = 'RESTAURANTS.POST';
export interface POST_ACTION {
  type: 'RESTAURANTS.POST';
  payload: Promise<any>;
}

export const POST_PENDING = 'RESTAURANTS.POST_PENDING';
export interface POST_PENDING_ACTION {
  type: 'RESTAURANTS.POST_PENDING';
}

export const POST_FULFILLED = 'RESTAURANTS.POST_FULFILLED';
export interface POST_FULFILLED_ACTION {
  type: 'RESTAURANTS.POST_FULFILLED';
  payload: Restaurant;
}

export const POST_REJECTED = 'RESTAURANTS.POST_REJECTED';
export interface POST_REJECTED_ACTION {
  type: 'RESTAURANTS.POST_REJECTED';
}

// REMOVE

export const REMOVE: 'RESTAURANTS.REMOVE' = 'RESTAURANTS.REMOVE';
export interface REMOVE_ACTION {
  type: typeof REMOVE;
  payload: Promise<{ id: string }>;
}

export const REMOVE_PENDING: 'RESTAURANTS.REMOVE_PENDING' =
  'RESTAURANTS.REMOVE_PENDING';
export interface REMOVE_PENDING_ACTION {
  type: typeof REMOVE_PENDING;
}

export const REMOVE_FULFILLED: 'RESTAURANTS.REMOVE_FULFILLED' =
  'RESTAURANTS.REMOVE_FULFILLED';
export interface REMOVE_FULFILLED_ACTION {
  type: typeof REMOVE_FULFILLED;
  payload: { id: string };
}

export const REMOVE_REJECTED: 'RESTAURANTS.REMOVE_REJECTED' =
  'RESTAURANTS.REMOVE_REJECTED';
export interface REMOVE_REJECTED_ACTION {
  type: typeof REMOVE_REJECTED;
}

type REMOVE_ACTIONS =
  | REMOVE_ACTION
  | REMOVE_PENDING_ACTION
  | REMOVE_FULFILLED_ACTION
  | REMOVE_REJECTED_ACTION;

// SET_SORT_BY

export const SET_SORT_BY = 'RESTAURANTS.SET_SORT_BY';
export interface SET_SORT_BY_ACTION {
  type: 'RESTAURANTS.SET_SORT_BY';
  payload: string;
}

// SET_SORT_ORDER

export const SET_SORT_ORDER = 'RESTAURANTS.SET_SORT_ORDER';
export interface SET_SORT_ORDER_ACTION {
  type: 'RESTAURANTS.SET_SORT_ORDER';
  payload: OrderDirection;
}

// SET_SEARCH_QUERY

export const SET_SEARCH_QUERY = 'RESTAURANTS.SET_SEARCH_QUERY';
export interface SET_SEARCH_QUERY_ACTION {
  type: 'RESTAURANTS.SET_SEARCH_QUERY';
  payload: string;
}

// SET_MAP_INVALIDATE_ID

export const SET_MAP_INVALIDATE_ID = 'RESTAURANTS.SET_MAP_INVALIDATE_ID';
export interface SET_MAP_INVALIDATE_ID_ACTION {
  type: 'RESTAURANTS.SET_MAP_INVALIDATE_ID';
  payload: string;
}

// UNSET_MAP_INVALIDATE_ID

export const UNSET_MAP_INVALIDATE_ID = 'RESTAURANTS.UNSET_MAP_INVALIDATE_ID';
export interface UNSET_MAP_INVALIDATE_ID_ACTION {
  type: 'RESTAURANTS.UNSET_MAP_INVALIDATE_ID';
  payload: string;
}

// SET_DISPLAYED_LOCALE

export const SET_DISPLAYED_LOCALE: 'RESTAURANTS.SET_DISPLAYED_LOCALE' =
  'RESTAURANTS.SET_DISPLAYED_LOCALE';
export interface SET_DISPLAYED_LOCALE_ACTION {
  type: typeof SET_DISPLAYED_LOCALE;
  payload: string;
}

// IMPORT

export const IMPORT = 'RESTAURANTS.IMPORT';
export interface IMPORT_ACTION {
  type: 'RESTAURANTS.IMPORT';
  payload: Promise<any>;
}

export const IMPORT_PENDING = 'RESTAURANTS.IMPORT_PENDING';
export interface IMPORT_PENDING_ACTION {
  type: 'RESTAURANTS.IMPORT_PENDING';
}

export const IMPORT_FULFILLED = 'RESTAURANTS.IMPORT_FULFILLED';
export interface IMPORT_FULFILLED_ACTION {
  type: 'RESTAURANTS.IMPORT_FULFILLED';
  payload: { data: Restaurant };
}

export const IMPORT_REJECTED = 'RESTAURANTS.IMPORT_REJECTED';
export interface IMPORT_REJECTED_ACTION {
  type: 'RESTAURANTS.IMPORT_REJECTED';
}

export const SET_RPM_MODE = 'RESTAURANTS.SET_RPM_MODE';
export interface SET_RPM_MODE_ACTION {
  type: 'RESTAURANTS.SET_RPM_MODE';
  payload: RpmConfigData;
}

type IMPORT_ACTIONS =
  | IMPORT_ACTION
  | IMPORT_PENDING_ACTION
  | IMPORT_FULFILLED_ACTION
  | IMPORT_REJECTED_ACTION;

//------------------------------------

export type Action =
  | RESET_ACTION
  | RESET_MAP_ACTION
  | LIST_ACTION
  | LIST_PENDING_ACTION
  | LIST_FULFILLED_ACTION
  | LIST_REJECTED_ACTION
  | LIST_CANCEL_ALL_ACTION
  | GET_ACTION
  | GET_PENDING_ACTION
  | GET_FULFILLED_ACTION
  | GET_REJECTED_ACTION
  | PUT_ACTION
  | PUT_PENDING_ACTION
  | PUT_FULFILLED_ACTION
  | PUT_REJECTED_ACTION
  | POST_ACTION
  | POST_PENDING_ACTION
  | POST_FULFILLED_ACTION
  | POST_REJECTED_ACTION
  | REMOVE_ACTIONS
  | SET_SORT_ORDER_ACTION
  | SET_SORT_BY_ACTION
  | SET_MAP_INVALIDATE_ID_ACTION
  | UNSET_MAP_INVALIDATE_ID_ACTION
  | SET_SEARCH_QUERY_ACTION
  | SET_DISPLAYED_LOCALE_ACTION
  | IMPORT_ACTIONS
  | SET_RPM_MODE_ACTION;
