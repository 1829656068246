import type { AsyncStatus } from '../types';
import { type NutritionNutrient } from '../nutritionNutrients/types';
import {
  type NutritionCategory,
  type NutritionCategoryLanguage,
} from '../nutritionCategories/types';

export type Item = {|
  id: string,
  market: string,
  status: 'enabled' | 'disabled',
  order: number,
  imageId?: string,
  thumbnailImageId?: string,
  image?: string,
  thumbnailImage?: string,
  imagePreview?: string,
  thumbnailImagePreview?: string,
  languages: {|
    [locale: string]: {|
      name: string,
      description: string,
      ingredients?: ?string,
    |},
  |},
  allergens: Object[],
  categories: {|
    ...NutritionCategory,
    languages: {
      [string]: {|
        ...NutritionCategoryLanguage,
        value?: string,
      |},
    },
  |}[],
  nutrients: {|
    ...NutritionNutrient,
    value?: string,
    perHundred?: string,
    ri?: string,
  |}[],
|};

export type Items = {|
  [itemId: string]: Item,
|};

export type State = {|
  mapInvalidatorId: ?string,
  searchQuery?: ?string,
  statusList: ?AsyncStatus,
  map: Items,
|};

// SET_ORDER

export const SET_ORDER = 'NUTRITION.SET_ORDER';
export type SET_ORDER_ACTION = {
  type: 'NUTRITION.SET_ORDER',
  payload: Promise<any>,
};

export const SET_ORDER_PENDING = 'NUTRITION.SET_ORDER_PENDING';
export type SET_ORDER_PENDING_ACTION = {
  type: 'NUTRITION.SET_ORDER_PENDING',
};

export const SET_ORDER_FULFILLED = 'NUTRITION.SET_ORDER_FULFILLED';
export type SET_ORDER_FULFILLED_ACTION = {
  type: 'NUTRITION.SET_ORDER_FULFILLED',
  payload: any,
};

export const SET_ORDER_REJECTED = 'NUTRITION.SET_ORDER_REJECTED';
export type SET_ORDER_REJECTED_ACTION = {
  type: 'NUTRITION.SET_ORDER_REJECTED',
};

// RESET_MAP

export const RESET_MAP = 'NUTRITION.RESET_MAP';
export type RESET_MAP_ACTION = {
  type: 'NUTRITION.RESET_MAP',
};

// INVALIDATE

export const SET_MAP_INVALIDATE_ID = 'NUTRITION.SET_MAP_INVALIDATE_ID';
export type SET_MAP_INVALIDATE_ID_ACTION = {
  type: 'NUTRITION.SET_MAP_INVALIDATE_ID',
  payload: string,
};

export const UNSET_MAP_INVALIDATE_ID = 'NUTRITION.UNSET_MAP_INVALIDATE_ID';
export type UNSET_MAP_INVALIDATE_ID_ACTION = {
  type: 'NUTRITION.UNSET_MAP_INVALIDATE_ID',
  payload: string,
};

// SET_SEARCH_QUERY

export const SET_SEARCH_QUERY = 'NUTRITION.SET_SEARCH_QUERY';
export type SET_SEARCH_QUERY_ACTION = {
  type: 'NUTRITION.SET_SEARCH_QUERY',
};

// LIST

export const LIST = 'NUTRITION.LIST';
export type LIST_ACTION = {
  type: 'NUTRITION.LIST',
  payload: Promise<any>,
};

export const LIST_PENDING = 'NUTRITION.LIST_PENDING';
export type LIST_PENDING_ACTION = {
  type: 'NUTRITION.LIST_PENDING',
};

export const LIST_FULFILLED = 'NUTRITION.LIST_FULFILLED';
export type LIST_FULFILLED_ACTION = {
  type: 'NUTRITION.LIST_FULFILLED',
  payload: any,
};

export const LIST_REJECTED = 'NUTRITION.LIST_REJECTED';
export type LIST_REJECTED_ACTION = {
  type: 'NUTRITION.LIST_REJECTED',
};

// GET

export const GET = 'NUTRITION.GET';
export type GET_ACTION = {
  type: 'NUTRITION.GET',
  payload: Promise<any>,
};

export const GET_PENDING = 'NUTRITION.GET_PENDING';
export type GET_PENDING_ACTION = {
  type: 'NUTRITION.GET_PENDING',
};

export const GET_FULFILLED = 'NUTRITION.GET_FULFILLED';
export type GET_FULFILLED_ACTION = {
  type: 'NUTRITION.GET_FULFILLED',
  payload: { data: any },
};

export const GET_REJECTED = 'NUTRITION.GET_REJECTED';
export type GET_REJECTED_ACTION = {
  type: 'NUTRITION.GET_REJECTED',
};

// PUT

export const PUT = 'NUTRITION.PUT';
export type PUT_ACTION = {
  type: 'NUTRITION.PUT',
  payload: Promise<any>,
};

export const PUT_PENDING = 'NUTRITION.PUT_PENDING';
export type PUT_PENDING_ACTION = {
  type: 'NUTRITION.PUT_PENDING',
};

export const PUT_FULFILLED = 'NUTRITION.PUT_FULFILLED';
export type PUT_FULFILLED_ACTION = {
  type: 'NUTRITION.PUT_FULFILLED',
  payload: { data: any },
};

export const PUT_REJECTED = 'NUTRITION.PUT_REJECTED';
export type PUT_REJECTED_ACTION = {
  type: 'NUTRITION.PUT_REJECTED',
};

// POST

export const POST = 'NUTRITION_ITEMS.POST';
export type POST_ACTION = {
  type: 'NUTRITION_ITEMS.POST',
  payload: Promise<any>,
};

export const POST_PENDING = 'NUTRITION_ITEMS.POST_PENDING';
export type POST_PENDING_ACTION = {
  type: 'NUTRITION_ITEMS.POST_PENDING',
};

export const POST_FULFILLED = 'NUTRITION_ITEMS.POST_FULFILLED';
export type POST_FULFILLED_ACTION = {
  type: 'NUTRITION_ITEMS.POST_FULFILLED',
  payload: any,
};

export const POST_REJECTED = 'NUTRITION_ITEMS.POST_REJECTED';
export type POST_REJECTED_ACTION = {
  type: 'NUTRITION_ITEMS.POST_REJECTED',
};

type POST_ACTIONS =
  | POST_ACTION
  | POST_PENDING_ACTION
  | POST_FULFILLED_ACTION
  | POST_REJECTED_ACTION;

// REMOVE

export const REMOVE = 'NUTRITION.REMOVE';
export type REMOVE_ACTION = {
  type: 'NUTRITION.REMOVE',
  payload: Promise<any>,
};

export const REMOVE_PENDING = 'NUTRITION.REMOVE_PENDING';
export type REMOVE_PENDING_ACTION = {
  type: 'NUTRITION.REMOVE_PENDING',
};

export const REMOVE_FULFILLED = 'NUTRITION.REMOVE_FULFILLED';
export type REMOVE_FULFILLED_ACTION = {
  type: 'NUTRITION.REMOVE_FULFILLED',
  payload: any,
};

export const REMOVE_REJECTED = 'NUTRITION.REMOVE_REJECTED';
export type REMOVE_REJECTED_ACTION = {
  type: 'NUTRITION.REMOVE_REJECTED',
};

type REMOVE_ACTIONS =
  | REMOVE_ACTION
  | REMOVE_PENDING_ACTION
  | REMOVE_FULFILLED_ACTION
  | REMOVE_REJECTED_ACTION;

// CREATE_SIGNED_URL

export const CREATE_SIGNED_URL = 'NUTRITION_ITEMS.CREATE_SIGNED_URL';
export type CREATE_SIGNED_URL_ACTION = {
  type: 'NUTRITION_ITEMS.CREATE_SIGNED_URL',
  payload: any,
};

// UPLOAD_IMAGE

export const UPLOAD_IMAGE = 'NUTRITION_ITEMS.UPLOAD_IMAGE';
export type UPLOAD_IMAGE_ACTION = {
  type: 'NUTRITION_ITEMS.UPLOAD_IMAGE',
  payload: any,
};

type NUTRITION_ITEMS_ACTIONS =
  | RESET_MAP_ACTION
  | SET_ORDER_ACTION
  | SET_ORDER_PENDING_ACTION
  | SET_ORDER_FULFILLED_ACTION
  | SET_ORDER_REJECTED_ACTION
  | SET_SEARCH_QUERY_ACTION
  | SET_MAP_INVALIDATE_ID_ACTION
  | UNSET_MAP_INVALIDATE_ID_ACTION
  | LIST_ACTION
  | LIST_PENDING_ACTION
  | LIST_FULFILLED_ACTION
  | LIST_REJECTED_ACTION
  | GET_ACTION
  | GET_PENDING_ACTION
  | GET_FULFILLED_ACTION
  | GET_REJECTED_ACTION
  | PUT_ACTION
  | PUT_PENDING_ACTION
  | PUT_FULFILLED_ACTION
  | PUT_REJECTED_ACTION
  | POST_ACTIONS
  | REMOVE_ACTIONS
  | CREATE_SIGNED_URL_ACTION
  | UPLOAD_IMAGE_ACTION;

//------------------------------------

export type Action = NUTRITION_ITEMS_ACTIONS;
