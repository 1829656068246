import type { RootState } from '../';
import _get from 'lodash/get';
import {
  listUserAccountRoleMembers,
  listAccountRoleMembers,
} from '../roleMembers/model/selectors';
import { getSelectedMarketId } from '../markets/model/selectors';
import convertPathToPermission from '../../utility/convertPathToPermission';
import { ACCOUNTROLES } from "state/roles/helpers";

export const getAppAlert = (state: RootState) => state.app.alert;

export const getConsumerTagWarning = (state: RootState) =>
  state.app.consumerTagWarning;

export const selectUserAccountId = (state: RootState) =>
  _get(state, 'user.firebaseUser.uid');

const isForOwnMarket = (state, subj) => {
  const userRoleMembers = listUserAccountRoleMembers(state);
  const isOwnMarket = userRoleMembers.find(
    (m) =>
      m.market === subj[3] && (m.role === subj[4] || m.role === ACCOUNTROLES.MARKET_MANAGER)
  );
  return isOwnMarket;
};

const isForOwnAccount = (state, subj) => {
  const userId = _get(state, 'user.firebaseUser.uid');
  if (!userId) return false;
  const isOwnAccount = userId === subj[2];
  return isOwnAccount;
};

const getRelevantRoleStringForSubjectAccount = (state, subj) => {
  const accountId = subj[2];
  const roleMembers = listAccountRoleMembers(state, accountId);

  if (roleMembers.find((m) => m.role === ACCOUNTROLES.ADMIN || m.role === ACCOUNTROLES.PRODUCT_TEAM)) {
    return [ACCOUNTROLES.PRODUCT_TEAM];
  }
  if (roleMembers.find((m) => m.role === ACCOUNTROLES.SUPPORT_TEAM)) {
    return [ACCOUNTROLES.SUPPORT_TEAM];
  }
  if (roleMembers.find((m) => m.role === ACCOUNTROLES.MARKET_MANAGER)) {
    return ['market', ACCOUNTROLES.MARKET_MANAGER];
  }
  if (roleMembers.find((m) => m.role === ACCOUNTROLES.MARKET_LOCATION_MANAGER)) {
    return ['market', ACCOUNTROLES.MARKET_LOCATION_MANAGER];
  }
  if (roleMembers.find((m) => m.role === ACCOUNTROLES.DEFAULT)) {
    return [ACCOUNTROLES.DEFAULT];
  }
  return [];
};

const getHasPermissionToEither = (state) => (permissions, subject) => {
  if (!subject) return true;
  const perm = state.app.enabledFunctionality;
  let subj = subject.split('.');

  if (permissions.includes('VIEW') && perm.canViewEverything) return true;
  if (permissions.includes('EDIT') && perm.canEditEverything) return true;
  if (permissions.includes('DELETE') && perm.canDeleteEverything) return true;

  if (subject.startsWith('accountRoles.role.market')) {
    if (isForOwnMarket(state, subj)) {
      subj[2] = 'ownMarket';
    }
    subj.splice(3, 1);
  } else if (subject.startsWith('accounts.account')) {
    if (isForOwnAccount(state, subj)) {
      subj[1] = 'ownAccount';
      subj.splice(2, 1);
    } else {
      const roleString = getRelevantRoleStringForSubjectAccount(state, subj);
      if (roleString.length > 0) {
        subj.splice(2, roleString.length, 'withRole', ...roleString);
      } else {
        subj.splice(2, 1);
      }
    }
  }

  let permizione = _get(perm, `${subject}.perm`);
  while (!permizione) {
    let efSubject = subj.join('.');
    permizione = _get(perm, `${efSubject}.perm`);
    subj = subj.slice(0, subj.length - 1);
    if (!permizione && subj.length <= 0) {
      permizione = _get(perm, `${subject}.perm`);
      break;
    }
  }

  let hasPermission = permissions.includes(permizione);

  if (hasPermission) {
    const roleMembers = listUserAccountRoleMembers(state);

    roleMembers.find((r) => {
      if (
        subj.includes('products') ||
        subj.includes('restaurants') ||
        subj.includes('localMarketing')
      ) {
        return (hasPermission = !!hasRoleInMarket(state, subj, roleMembers));
      }

      return false;
    });
  }
  return hasPermission;
};

const hasRoleInMarket = (state, subj, roleMembers) => {
  const selectedMarket = getSelectedMarketId(state);
  const rolesMarket = [];

  const hasPermissionsFor = [
    { products: ACCOUNTROLES.MARKET_NUTRITION_MANAGER },
    { restaurants: ACCOUNTROLES.MARKET_LOCATION_MANAGER },
    { localMarketing: ACCOUNTROLES.MARKET_FRANCHISEE_MANAGER },
    { restaurants: ACCOUNTROLES.MARKET_FRANCHISEE_MANAGER },
  ];

  roleMembers.find((role) => {
    if (role.role === ACCOUNTROLES.SUPPORT_TEAM) rolesMarket.push(role.role);
    if (role.market === selectedMarket) rolesMarket.push(role.role);

    return false;
  });

  return rolesMarket.find((role) => {
    if (role === ACCOUNTROLES.SUPPORT_TEAM) return true;
    if (role === ACCOUNTROLES.MARKET_MANAGER) return true;

    return hasPermissionsFor.find((section) => {
      return section[subj[0].toString()] === role;
    });
  });
};

export const canDelete = (state: RootState) => (subject: string) => {
  const hasPermissionTo = getHasPermissionToEither(state);
  return hasPermissionTo(['DELETE'], subject);
};

export const canEdit = (state: RootState) => (subject: string) => {
  const hasPermissionToEither = getHasPermissionToEither(state);
  return hasPermissionToEither(['EDIT', 'DELETE'], subject);
};

export const canView = (state: RootState) => (subject: string) => {
  const hasPermissionToEither = getHasPermissionToEither(state);
  return hasPermissionToEither(['VIEW', 'EDIT', 'DELETE'], subject);
};

export const getCanViewPath = (state: RootState) => (pathname: string) => {
  const pathPermission = convertPathToPermission(pathname);
  const can = canView(state)(pathPermission);
  return can;
};
