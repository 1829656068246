import type { ThunkAction } from '../../types';
import type { RoleMember } from '../model/types';
import * as types from './types';
import _get from 'lodash/get';
import api from '../api';
import { listAccountRoleMembers } from '../model/selectors';
import { createChangeRecord } from '../model/creators';
import { userKickOut } from "state/user/action/kickOut";
import { getUserAccountId } from '../../user/model/selectors';
import HandledError from '../../../errors/HandledError';
import listHelper from '../../utility/general/actionCreators/listHelper';

export const saveChangesOfAccount =
  (nextRoleMembers: RoleMember[], accountId: string): ThunkAction =>
  (dispatch, getState) => {
    const roleMembers = listAccountRoleMembers(getState(), accountId);
    const changeRecord = createChangeRecord(nextRoleMembers, roleMembers);
    if (changeRecord.operations.length === 0) {
      return Promise.resolve();
    } else {
      return dispatch({
        type: types.APPLY,
        payload: api.apply(changeRecord),
      });
    }
  };

export const list =
  (
    accountId: string,
    options?: {
      getNextPage?: boolean,
      append?: boolean,
      debounce?: {
        key?: string,
        duration: number,
      },
      fetchAll?: boolean,
    }
  ): ThunkAction =>
  (dispatch, getState) => {
    let cfg = {
      ...(options || {}),
      fetch: {
        action: types.LIST_OF_ACCOUNT,
        bindFetch: (accountId, nextPageToken) =>
          api.listForAccount(accountId, nextPageToken),
      },
      subject: accountId,
      statePath: 'roleMembers',
    };

    return dispatch(
      listHelper(cfg, (error) => {
        const userAccountId = getUserAccountId(getState());
        const didFailListingRolesOfUser =
          403 === _get(error, 'response.status') &&
          (!userAccountId || accountId === userAccountId);

        if (didFailListingRolesOfUser) {
          dispatch(userKickOut());
          return Promise.reject(
            new HandledError('Unauthorized: User kicked out')
          );
        }

        return Promise.reject(error);
      })
    );
  };
