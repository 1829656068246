import isEqualWith from 'lodash/isEqualWith';
import type OrderDirection from '../utility/types';

export const hasValueWithSubstring = (obj: Object, substr: string) => {
  return Object.keys(obj)
    .map((key) => {
      const rawValue = obj[key];
      if (typeof rawValue === 'string') {
        return obj[key].toLowerCase();
      }
      if (typeof rawValue === 'number') {
        return `${obj[key]}`;
      }
      return '';
    })
    .reduce((containsSearchedValue, currentValue) => {
      return (
        containsSearchedValue || currentValue.includes(substr.toLowerCase())
      );
    }, false);
};

/*
 * Returns a Universally Unique ID
 * Source: https://stackoverflow.com/a/2117523
 */
export function getUUID() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

/* Named after Lodash _.over */
export const over =
  (...funs: Array<(*) => *>) =>
  (...args: Array<*>) =>
    funs.forEach((fun) => fun(...args));

export const areArraysEqual = (a: ?(any[]), b: ?(any[])) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  // eslint-disable-next-line
  if (a.length != b.length) return false;

  a.sort();
  b.sort();

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const getChanges = <T: { id: string }>(
  nextList: T[],
  prevList: T[],
  isEqual: <T>(a: T, b: T) => boolean
): { added: T[], removed: T[] } => {
  // TODO the time complexity of this function is probably O(n^3+n),
  // if you're smart you might want to try and optimize it.

  const added = [...nextList];
  const removed = [...prevList];

  nextList.forEach((next, idxNext) => {
    prevList.forEach((prev, idx) => {
      if (next.id && prev.id) {
        if (next.id === prev.id) {
          const addIdx = added.indexOf(next);
          added.splice(addIdx, 1);
          const removeIdx = removed.indexOf(prev);
          removed.splice(removeIdx, 1);
        }
      } else {
        if (
          isEqualWith(next, prev, (a: T, b: T) => {
            if (a.id === undefined || b.id === undefined) {
              return isEqual(a, b);
            }
          })
        ) {
          const addIdx = added.indexOf(next);
          added.splice(addIdx, 1);
          const removeIdx = removed.indexOf(prev);
          removed.splice(removeIdx, 1);
        }
      }
    });
  });

  return { added, removed };
};

/** Source: https://stackoverflow.com/a/9133690 */
export const searchTree = (
  root: any,
  found: (node: any) => boolean,
  keyChildren?: string = 'children'
) => {
  let stack = [...root];
  let node;
  let ii;

  while (stack.length > 0) {
    node = stack.pop();
    if (found(node)) {
      // Found it!
      return node;
    } else if (node[keyChildren] && node[keyChildren].length) {
      for (ii = 0; ii < node[keyChildren].length; ii += 1) {
        stack.push(node[keyChildren][ii]);
      }
    }
  }

  // Didn't find it. Return null.
  return null;
};

export const compareProperty =
  (sortDirection: OrderDirection, getProp: (Object) => any) =>
  (a: Object, b: Object) => {
    if (sortDirection === orderDirection.ASCENDING) {
      if (getProp(a) > getProp(b)) return 1;
      if (getProp(a) < getProp(b)) return -1;
      return 0;
    } else if (sortDirection === orderDirection.DESCENDING) {
      if (getProp(a) < getProp(b)) return 1;
      if (getProp(a) > getProp(b)) return -1;
      return 0;
    } else {
      throw new Error('Invalid sort direction ' + sortDirection);
    }
  };

export const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  (document.body: any).appendChild(el);
  el.select();
  document.execCommand('copy');
  (document.body: any).removeChild(el);
};

export const snakeCaseToCamelCase = (str: string) =>
  str
    .split('-')
    .map((s, idx) => (idx === 0 ? s : s.slice(0, 1).toUpperCase() + s.slice(1)))
    .join('');

export const arrayToObject = <T: Object>(
  array: T[],
  key: string,
  itemDecorator?: (T) => T
) => {
  const initialValue = {};

  if (array) {
    if (itemDecorator) {
      return array.reduce((obj, item) => {
        return {
          ...obj,
          [item[key]]: itemDecorator(item),
        };
      }, initialValue);
    }

    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  }

  return;
};

export const getImportStatsString = (
  stats: {
    added?: string[],
    updated?: string[],
    deleted?: string[],
  },
  name?: ?string
) => {
  const _name = name ? ' ' + name : '';
  let str = `${
    stats && stats.added ? stats.added.length + _name + ' added, ' : ''
  }${
    stats && stats.updated ? stats.updated.length + _name + ' updated, ' : ''
  }${
    stats && stats.deleted ? stats.deleted.length + _name + ' deleted, ' : ''
  }`;

  return str;
};

export const orderDirection = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
