import { useEffect, useContext } from 'react';
import { ThemeContext } from '../../styles/StyleProvider';

export const THEME_NAME = {
  LIGHT: 'light',
  DARK: 'dark',
};

export const THEME_LOCAL_STORAGE_KEY = 'theme';

const UseToggleDarkMode = () => {
  const { setTheme, theme } = useContext(ThemeContext);

  const applyTheme = (targetTheme) => {
    localStorage.setItem(THEME_LOCAL_STORAGE_KEY, targetTheme);
    setTheme(targetTheme);
  };

  const toggleTheme = () => {
    const targetTheme =
      theme === THEME_NAME.LIGHT ? THEME_NAME.DARK : THEME_NAME.LIGHT;
    applyTheme(targetTheme);
  };

  useEffect(() => {
    const targetTheme =
      localStorage.getItem(THEME_LOCAL_STORAGE_KEY) || THEME_NAME.LIGHT;
    applyTheme(targetTheme);
  });

  return {
    theme,
    toggleTheme,
  };
};

export default UseToggleDarkMode;
