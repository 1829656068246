import type { ReducerFragment } from '../../types';
import type { RestaurantOrderSettingsState } from '../model/types';
import * as types from '../action/types';

const reduceGet: ReducerFragment<RestaurantOrderSettingsState> = {
  [types.GET_PENDING]: (state, action) => {
    return { ...state, statusGet: 'pending' };
  },

  [types.GET_FULFILLED]: (state, action) => {
    return { ...state, statusGet: 'fulfilled' };
  },

  [types.GET_REJECTED]: (state, action) => {
    return { ...state, statusGet: 'rejected' };
  },
};

export default reduceGet;
