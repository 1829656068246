import { BORDER_TABLE } from '../../borders';

const Table = {
  MuiTableRow: {
    root: {
      height: 57,
    },
  },
  MuiTableCell: {
    root: {
      borderBottom: BORDER_TABLE,
    },
    head: {
      fontWeight: 400,
    },
  },
};

export default Table;
