import { TEXT_BODY } from '../../textStyles';
import { COLOR_GRAY_DARK } from '../../colors';

const INPUT_PADDING_LEFT = 10;
const INPUT_PADDING_RIGHT = 24;

const Input = (theme: string) => ({
  MuiInput: {
    root: {
      backgroundColor: theme === 'dark' ? '#595959' : '#EFEFEF',
      borderRadius: 2,
      '&$disabled': {
        backgroundColor: 'inherit',
      },
    },
  },
  MuiInputAdornment: {
    positionStart: {
      marginLeft: 8,
      marginRight: 0,
      color: COLOR_GRAY_DARK,
    },
  },
  MuiInputBase: {
    root: {
      height: '100%',
      ...TEXT_BODY,
    },
    input: {
      padding: `9px 0 9px ${INPUT_PADDING_LEFT}px`,
      '&[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button':
        {
          '-webkit-appearance': 'none',
          margin: 0,
        },
    },
    multiline: {
      padding: 9,
    },
  },
  MuiSelect: {
    select: {
      height: '1.3em', // Fix text getting cut at bottom
      width: `calc(100% - ${INPUT_PADDING_LEFT + INPUT_PADDING_RIGHT}px)`,
      paddingRight: INPUT_PADDING_RIGHT,

      '&$disabled': {
        '& ~ $icon': {
          opacity: 0,
        },
      },
    },
  },
});

export default Input;
