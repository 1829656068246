import type { ReducerFragment } from '../../types';
import { normalizeRoleMemberList } from '../model/normalizers';
import type { RoleMembersState } from '../model/types';
import * as types from '../action/types';
import { removeRoleMembersOfAccount } from '../model/selectors';
import { arrayToObject } from '../../../utility/utility';

const reduce: ReducerFragment<RoleMembersState> = {
  [types.LIST_OF_ACCOUNT_PENDING]: (state, action) => {
    return {
      ...state,
      statusList: 'pending',
    };
  },

  [types.LIST_OF_ACCOUNT_FULFILLED]: (state, action) => {
    const {
      payload: {
        data: { items, nextPageToken },
      },
      meta: { subject, append },
    } = action;

    const roleMembersList = items ? normalizeRoleMemberList(items) : [];
    const map = arrayToObject(roleMembersList, 'id');

    let prevMap = append
      ? state.map
      : removeRoleMembersOfAccount(state.map, subject);

    return {
      ...state,
      map: {
        ...prevMap,
        ...map,
      },
      nextPageToken,
      statusList: 'fulfilled',
    };
  },

  [types.LIST_OF_ACCOUNT_REJECTED]: (state, action) => ({
    ...state,
    statusList: 'rejected',
  }),

  [types.SET_IS_MAP_VALID]: (state, action) => ({
    ...state,
    mapIsValid: action.payload,
  }),
};

export default reduce;
