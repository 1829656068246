import { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import type { ConfigRoutesSub } from "config/types";
import { AppReCaptcha } from "components/app/reCaptcha";
import RouteWithSubRoutes from 'components/common/routeWithSubRoutes/RouteWithSubRoutes';
import AppBarSimple from 'components/app/appBarSimple/AppBarSimple';
import SpacerAppBar from 'components/common/spacerAppBar/SpacerAppBar';
import LoadingOverlay from 'components/common/__loaders/loadingOverlay/LoadingOverlay';

interface Props {
  routes: ConfigRoutesSub[];
}

export default function RootAuth(props: Props): JSX.Element {
  const { routes } = props;

  return (
    <>
      <AppReCaptcha />
      <AppBarSimple />
      <SpacerAppBar />
      <Suspense
        fallback={
          <LoadingOverlay
            variants={['centered', 'expand']}
            style={{ minHeight: '100vh' }}
          />
        }
      >
        <Switch>
          {routes.map((route, index) => (
            <RouteWithSubRoutes key={'path' in route ? route.path : index} {...route} />
          ))}
        </Switch>
      </Suspense>
    </>
  );
}
