import type { Market } from '../model/types';
import type OrderDirection from '../../../utility/types';

// RESET

export const RESET: 'MARKETS.RESET' = 'MARKETS.RESET';
export type RESET_ACTION = {
  type: typeof RESET,
};

// RESET_MAP

export const RESET_MAP = 'ACCOUNTS.RESET_MAP';
export type RESET_MAP_ACTION = {
  type: 'ACCOUNTS.RESET_MAP',
};

// SET_SELECTED_MARKET_ID

export const SET_SELECTED_MARKET_ID: 'MARKETS.SET_SELECTED_MARKET_ID' =
  'MARKETS.SET_SELECTED_MARKET_ID';
export type SET_SELECTED_MARKET_ID_ACTION = {
  type: typeof SET_SELECTED_MARKET_ID,
  payload: string,
};

// GET

export const GET: 'MARKETS.GET' = 'MARKETS.GET';
export type GET_ACTION = {
  type: typeof GET,
  payload: Promise<Market>,
};

export const GET_PENDING: 'MARKETS.GET_PENDING' = 'MARKETS.GET_PENDING';
export type GET_PENDING_ACTION = {
  type: typeof GET_PENDING,
};

export const GET_FULFILLED: 'MARKETS.GET_FULFILLED' = 'MARKETS.GET_FULFILLED';
export type GET_FULFILLED_ACTION = {
  type: typeof GET_FULFILLED,
  payload: Market,
};

export const GET_REJECTED: 'MARKETS.GET_REJECTED' = 'MARKETS.GET_REJECTED';
export type GET_REJECTED_ACTION = {
  type: typeof GET_REJECTED,
};

type GET_ACTIONS =
  | GET_ACTION
  | GET_PENDING_ACTION
  | GET_FULFILLED_ACTION
  | GET_REJECTED_ACTION;

// PUT

export const PUT: 'MARKETS.PUT' = 'MARKETS.PUT';
export type PUT_ACTION = {
  type: typeof PUT,
  payload: Promise<Market>,
};

export const PUT_PENDING: 'MARKETS.PUT_PENDING' = 'MARKETS.PUT_PENDING';
export type PUT_PENDING_ACTION = {
  type: typeof PUT_PENDING,
};

export const PUT_FULFILLED: 'MARKETS.PUT_FULFILLED' = 'MARKETS.PUT_FULFILLED';
export type PUT_FULFILLED_ACTION = {
  type: typeof PUT_FULFILLED,
  payload: Market,
};

export const PUT_REJECTED: 'MARKETS.PUT_REJECTED' = 'MARKETS.PUT_REJECTED';
export type PUT_REJECTED_ACTION = {
  type: typeof PUT_REJECTED,
};

type PUT_ACTIONS =
  | PUT_ACTION
  | PUT_PENDING_ACTION
  | PUT_FULFILLED_ACTION
  | PUT_REJECTED_ACTION;

// POST

export const POST: 'MARKETS.POST' = 'MARKETS.POST';
export type POST_ACTION = {
  type: typeof POST,
  payload: Promise<Market>,
};

export const POST_PENDING: 'MARKETS.POST_PENDING' = 'MARKETS.POST_PENDING';
export type POST_PENDING_ACTION = {
  type: typeof POST_PENDING,
};

export const POST_FULFILLED: 'MARKETS.POST_FULFILLED' =
  'MARKETS.POST_FULFILLED';
export type POST_FULFILLED_ACTION = {
  type: typeof POST_FULFILLED,
  payload: Market,
};

export const POST_REJECTED: 'MARKETS.POST_REJECTED' = 'MARKETS.POST_REJECTED';
export type POST_REJECTED_ACTION = {
  type: typeof POST_REJECTED,
};

type POST_ACTIONS =
  | POST_ACTION
  | POST_PENDING_ACTION
  | POST_FULFILLED_ACTION
  | POST_REJECTED_ACTION;

// REMOVE

export const REMOVE: 'MARKETS.REMOVE' = 'MARKETS.REMOVE';
export type REMOVE_ACTION = {
  type: typeof REMOVE,
  payload: Promise<{ id: string }>,
};

export const REMOVE_PENDING: 'MARKETS.REMOVE_PENDING' =
  'MARKETS.REMOVE_PENDING';
export type REMOVE_PENDING_ACTION = {
  type: typeof REMOVE_PENDING,
};

export const REMOVE_FULFILLED: 'MARKETS.REMOVE_FULFILLED' =
  'MARKETS.REMOVE_FULFILLED';
export type REMOVE_FULFILLED_ACTION = {
  type: typeof REMOVE_FULFILLED,
  payload: { id: string },
};

export const REMOVE_REJECTED: 'MARKETS.REMOVE_REJECTED' =
  'MARKETS.REMOVE_REJECTED';
export type REMOVE_REJECTED_ACTION = {
  type: typeof REMOVE_REJECTED,
};

type REMOVE_ACTIONS =
  | REMOVE_ACTION
  | REMOVE_PENDING_ACTION
  | REMOVE_FULFILLED_ACTION
  | REMOVE_REJECTED_ACTION;

// LIST

export const LIST: 'MARKETS.LIST' = 'MARKETS.LIST';
export type LIST_ACTION = {
  type: typeof LIST,
  payload: Promise<any>,
};

export const LIST_PENDING: 'MARKETS.LIST_PENDING' = 'MARKETS.LIST_PENDING';
export type LIST_PENDING_ACTION = {
  type: typeof LIST_PENDING,
};

export const LIST_FULFILLED: 'MARKETS.LIST_FULFILLED' =
  'MARKETS.LIST_FULFILLED';
export type LIST_FULFILLED_ACTION = {
  type: typeof LIST_FULFILLED,
  payload: any,
};

export const LIST_REJECTED: 'MARKETS.LIST_REJECTED' = 'MARKETS.LIST_REJECTED';
export type LIST_REJECTED_ACTION = {
  type: typeof LIST_REJECTED,
};

// MAP_INVALIDATOR_ID

export const SET_MAP_INVALIDATOR_ID: 'MARKETS.SET_MAP_INVALIDATOR_ID' =
  'MARKETS.SET_MAP_INVALIDATOR_ID';
export type SET_MAP_INVALIDATOR_ID_ACTION = {
  type: typeof SET_MAP_INVALIDATOR_ID,
  payload: string,
};

export const UNSET_MAP_INVALIDATOR_ID: 'MARKETS.UNSET_MAP_INVALIDATOR_ID' =
  'MARKETS.UNSET_MAP_INVALIDATOR_ID';
export type UNSET_MAP_INVALIDATOR_ID_ACTION = {
  type: typeof UNSET_MAP_INVALIDATOR_ID,
  payload: string,
};

// SET_SORT_BY

export const SET_SORT_BY = 'MARKETS.SET_SORT_BY';
export type SET_SORT_BY_ACTION = {
  type: 'MARKETS.SET_SORT_BY',
  payload: ?string,
};

// SET_SORT_ORDER

export const SET_SORT_ORDER = 'MARKETS.SET_SORT_ORDER';
export type SET_SORT_ORDER_ACTION = {
  type: 'MARKETS.SET_SORT_ORDER',
  payload: OrderDirection,
};

// SET_SEARCH_QUERY

export const SET_SEARCH_QUERY = 'MARKETS.SET_SEARCH_QUERY';
export type SET_SEARCH_QUERY_ACTION = {
  type: 'MARKETS.SET_SEARCH_QUERY',
  payload: string,
};

type LIST_ACTIONS =
  | LIST_ACTION
  | LIST_PENDING_ACTION
  | LIST_FULFILLED_ACTION
  | LIST_REJECTED_ACTION
  | SET_MAP_INVALIDATOR_ID_ACTION
  | UNSET_MAP_INVALIDATOR_ID_ACTION
  | SET_SORT_BY_ACTION
  | SET_SORT_ORDER_ACTION
  | SET_SEARCH_QUERY_ACTION;

//------------------------------------

export type Action =
  | RESET_ACTION
  | RESET_MAP_ACTION
  | SET_SELECTED_MARKET_ID_ACTION
  | GET_ACTIONS
  | PUT_ACTIONS
  | POST_ACTIONS
  | REMOVE_ACTIONS
  | LIST_ACTIONS;
