/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import update from 'immutability-helper';

export default {
  'RESTAURANTS_CONFIG.SET_EDITED': (state, action) => {
    const { payload, meta } = action;
    return update(state, {
      info: {
        [meta.id]: (infoMarket) =>
          update(infoMarket || { id: meta.id }, {
            edited: { $set: payload },
          }),
      },
    });
  },
  'RESTAURANTS_CONFIG.SET_IS_SAVING': (state, action) => {
    const { payload, meta } = action;
    return update(state, {
      info: {
        [meta.id]: (infoMarket) =>
          update(infoMarket || { id: meta.id }, {
            isSaving: { $set: payload },
          }),
      },
    });
  },
};
