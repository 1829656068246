import type { RoleMember, OperationAdd, OperationDelete } from './types';

import isEqualWith from 'lodash/isEqualWith';
import { denormalizeRoleMemberId } from './normalizers';

export const createFromChangeRecordOperationAdd = (
  op: OperationAdd,
  id: string
): RoleMember => {
  if (op.market) {
    return {
      id,
      market: op.market,
      member: op.member.id,
      role: op.roleId,
    };
  } else {
    return {
      id,
      member: op.member.id,
      role: op.roleId,
    };
  }
};

/**
 * Returns an array containing a record of the changes that were made
 * in nextRoleMembers compared to prevRoleMembers.
 */
export const createChangeRecord = (
  nextRoleMembers: RoleMember[],
  prevRoleMembers: RoleMember[]
) => {
  // TODO the time complexity of this function is probably O(n^3+n),
  // if you're very smart you might want to try and optimize it.

  const changeRecord: Array<OperationAdd | OperationDelete> = [];

  const addedRoleMembers = [...nextRoleMembers];
  const removedRoleMembers = [...prevRoleMembers];
  nextRoleMembers.forEach((nextRoleMember, idxNext) => {
    prevRoleMembers.forEach((roleMember, idx) => {
      if (nextRoleMember.id) {
        if (nextRoleMember.id === roleMember.id) {
          const addIdx = addedRoleMembers.indexOf(nextRoleMember);
          addedRoleMembers.splice(addIdx, 1);
          const removeIdx = removedRoleMembers.indexOf(roleMember);
          removedRoleMembers.splice(removeIdx, 1);
        }
      } else {
        if (
          isEqualWith(nextRoleMember, roleMember, (a, b) => {
            if (a.id === undefined || b.id === undefined) {
              return (
                a.role === b.role &&
                a.member === b.member &&
                a.market === b.market
              );
            }
          })
        ) {
          const addIdx = addedRoleMembers.indexOf(nextRoleMember);
          addedRoleMembers.splice(addIdx, 1);
          const removeIdx = removedRoleMembers.indexOf(roleMember);
          removedRoleMembers.splice(removeIdx, 1);
        }
      }
    });
  });

  addedRoleMembers.forEach((roleMember) =>
    changeRecord.push({
      type: 'add',
      roleId: roleMember.role,
      ...(roleMember.market ? { market: roleMember.market } : {}),
      member: {
        type: 'User',
        id: roleMember.member,
      },
    })
  );
  removedRoleMembers.forEach((roleMember) =>
    changeRecord.push({
      type: 'delete',
      roleId: roleMember.role,
      ...(roleMember.market ? { market: roleMember.market } : {}),
      memberId: denormalizeRoleMemberId(roleMember),
    })
  );
  return { operations: changeRecord };
};
