import React from 'react';
import { SnackbarProvider } from 'notistack';

const SnackbarProvider_ = ({ children, ...rest }) => (
  <SnackbarProvider
    autoHideDuration={5000}
    maxSnack={3}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...rest}
  >
    {children}
  </SnackbarProvider>
);

export default SnackbarProvider_;
