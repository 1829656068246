import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CollapseFadeSlide from '../collapseFadeSlide/CollapseFadeSlide';
import NavItem from './NavItem';
import useStyles from './styles';

const ExpandableNavItem = ({ Icon, title, linkTo, subroutes }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const classes = useStyles();
  const location = useLocation();
  const isActiveRoute = location.pathname.indexOf(linkTo) !== -1;
  const isActiveClass = isActiveRoute ? classes.isActive : '';

  useEffect(() => {
    if (isActiveRoute) {
      setIsExpanded(true);
    }
  }, [isActiveRoute]);

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const ExpandIcon = () => {
    const isExpandedClass = isExpanded ? classes.isExpanded : '';
    return (
      <ArrowDropDownIcon
        onClick={handleToggleExpand}
        className={`${classes.expandIcon} ${isExpandedClass} ${isActiveClass}`}
      />
    );
  };

  const SubNavItems = subroutes?.map((item) => {
    return (
      <NavItem
        linkTo={item.linkTo}
        title={item.title}
        subnav
        key={item.linkTo}
      />
    );
  });

  return (
    <>
      <li className={`${classes.listItem}`}>
        <ExpandIcon />
        <Link to={linkTo} className={classes.link}>
          <div className={`${classes.content}`}>
            {Icon && <Icon className={`${classes.icon} ${isActiveClass}`} />}
            <span className={`${classes.text} ${isActiveClass}`}>{title}</span>
          </div>
        </Link>
      </li>
      {subroutes && (
        <CollapseFadeSlide in={isExpanded}>
          <ul className={classes.list}>{SubNavItems}</ul>
        </CollapseFadeSlide>
      )}
    </>
  );
};

export default ExpandableNavItem;
