import type { RootState } from '../..';
import type { Restaurant, RestaurantsState } from "./types";
import { getSelectedMarketId } from '../../markets/model/selectors';
import { createSelector } from 'reselect';

// Root

interface Language {
  name: string;
  openingHoursRemark?: string;
  address: Address;
}

interface Address {
  street: string;
  city: string;
  zip: string;
}

export const mapRestaurantsById = (state: RootState) => state.restaurants.map;

export const listRestaurantsOfSelectedMarket = createSelector(
  mapRestaurantsById,
  getSelectedMarketId,
  (restaurantsMap, selectedMarketId) => {
    const restaurants = Object.keys(restaurantsMap).reduce((accu, key) => {
      const restaurant = restaurantsMap[key];
      if (
        restaurant &&
        restaurant.market &&
        restaurant.market.toLowerCase() === selectedMarketId
      ) {
        accu.push(restaurant);
      }
      return accu;
    }, [] as Restaurant[]);

    return restaurants;
  }
);

export const getStatusList = (state: RootState) => state.restaurants.statusList;

export const getStatusGet = (state: RootState) => state.restaurants.statusGet;

export const isRestaurantsInvalidated = (state: RootState) =>
  !!state.restaurants.mapInvalidatorId;

export const getRestaurant = (state: RootState, rid: string) => {
  const restaurants = mapRestaurantsById(state);
  if (restaurants) {
    return restaurants[rid];
  }
  return undefined;
};

export const getRestaurantsSearchQuery = (state: RootState) => {
  return state.restaurants.searchQuery;
};

// Restaurant

export const getRestaurantFacilities = (restaurant: Restaurant) => {
  const facilities = restaurant.facilities
    ?.map((facility) => (facility.includes('facility') ? facility : null))
    .filter(Boolean);

  return facilities || [];
};

export const getRestaurantLanguages = (
  restaurant: Restaurant
): Language | {} => {
  return restaurant?.languages ?? {};
};

export const getRestaurantLanguage = (
  restaurant: Restaurant,
  locale?: string
): Language | undefined => {
  const languages = getRestaurantLanguages(restaurant);
  if (languages) {
    if (locale) {
      return languages[locale as keyof typeof languages];
    } else {
      const keys = Object.keys(languages);
      if (keys) {
        return languages[keys[0] as keyof typeof languages];
      }
    }
  }
  return undefined;
};

export const getRestaurantName = (restaurant: Restaurant, locale?: string) => {
  const language = getRestaurantLanguage(restaurant, locale);
  if (language) {
    return language.name;
  }
  return undefined;
};

export const getRestaurantRemark = (
  restaurant: Restaurant,
  locale?: string
) => {
  const language = getRestaurantLanguage(restaurant, locale);
  if (language) {
    return language.openingHoursRemark;
  }
  return undefined;
};

export const getRestaurantAddress = (
  restaurant: Restaurant,
  locale?: string
): Address | undefined => {
  const language = getRestaurantLanguage(restaurant, locale);
  if (language) {
    return language.address;
  }
  return undefined;
};

export const getRestaurantCity = (restaurant: Restaurant, locale?: string) => {
  const address = getRestaurantAddress(restaurant, locale);
  if (address) {
    return address.city;
  }
  return undefined;
};

export const rpmIsEnabled = createSelector(
  (state: RootState) => state.restaurants.rpmMode,
  getSelectedMarketId,
  (rpmMode: RestaurantsState["rpmMode"], market: string) => {
    return rpmMode[market] === "rpm";
  },
);
