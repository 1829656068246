import type { ReducerFragment } from '../../types';
import type { RestaurantFacilityOverrideState } from '../model/types';
import * as types from '../actions/types';

const reduceGet: ReducerFragment<RestaurantFacilityOverrideState> = {
  [types.GET_PENDING]: (state, action) => {
    return { ...state, statusGet: 'pending' };
  },

  [types.GET_FULFILLED]: (state, action) => {
    return {
      ...state,
      statusGet: 'fulfilled',
    };
  },

  [types.GET_REJECTED]: (state, action) => {
    return { ...state, statusGet: 'rejected' };
  },
};

export default reduceGet;
