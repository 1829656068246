import type { ReducerFragment } from '../../types';
import type { AssetsConfigState } from '../model/types';
import * as types from '../action/types';

const reduceGet: ReducerFragment<AssetsConfigState> = {
  [types.GET_PENDING]: (state, action) => {
    return { ...state, statusGet: 'pending' };
  },

  [types.GET_FULFILLED]: (state, action) => {
    const config = action.payload;
    return {
      ...state,
      statusGet: 'fulfilled',
      config: config,
    };
  },

  [types.GET_REJECTED]: (state, action) => {
    return { ...state, statusGet: 'rejected', config: {} };
  },
};

export default reduceGet;
