import type { Classes } from './styles';
import * as React from 'react';
import clsx from 'clsx';
import styles from './styles';
import { withStyles } from '@material-ui/core';

type Color = 'muted';

type Props = {
  className?: string,
  classes: Classes,
  color: Color,
  expandHeight: boolean,
  fillHeight: boolean,
  children: React.Node,
};

const Centerer = (props: Props) => (
  <div
    className={clsx(props.className, props.classes.root, {
      expandHeight: props.expandHeight,
      fillHeight: props.fillHeight,
    })}
  >
    {props.children}
  </div>
);
export default withStyles(styles)(Centerer);
