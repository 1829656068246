import type { FormEvent, ChangeEvent } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import { useSnackbar } from "notistack";
import { Button, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { USER_ACTION_TYPES } from "state/user/action/types";
import validate from 'utility/validator';
import { userAppVerificationDisable } from "state/user/utils/appVerification";
import { userErrorsGetMfaError, userErrorsIsFirebaseMfa } from "state/user/utils/errors";
import { userEmailGetFromMfaError } from "state/user/utils/email";
import { userStorageSave } from "state/user/utils/storage";
import { useSelector } from "hooks/selector";
import { useDispatch } from "hooks/dispatch";
import HorizontalGroup from 'components/common/__form/horizontalGroup/HorizontalGroup';
import LoadingOverlay from 'components/common/__loaders/loadingOverlay/LoadingOverlay';
import FocusPageView from 'components/common/focusPageView/FocusPageView';

export default function PagesAuthSignIn(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const ready = useSelector((state) => state.user.firebaseReady);
  const [failed, setFailed] = useState(false);
  const [pending, setPending] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const emailValid = validate('email', email);
  const passwordValid = validate('password', password);
  const valid = emailValid && passwordValid;

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFailed(false);
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFailed(false);
    setPassword(e.target.value);
  };

  const handleSignIn = async () => {
    try {
      setPending(true);
      userAppVerificationDisable(email);
      await signInWithEmailAndPassword(getAuth(), email, password);
      dispatch({
        type: USER_ACTION_TYPES.INVITATION_ACCEPTED,
        payload: true,
      });
      dispatch({
        type: USER_ACTION_TYPES.MFA_ENROLLED,
        payload: false,
      });
      dispatch({
        type: USER_ACTION_TYPES.EMAIL,
        payload: email,
      });
      userStorageSave();
      history.push({
        pathname: '/auth/enable-mfa',
        search,
      });
    }
    catch (err) {
      const authFlowMfaError = userErrorsGetMfaError(err);
      if (userErrorsIsFirebaseMfa(err) && authFlowMfaError) {
        dispatch({
          type: USER_ACTION_TYPES.MFA_ERROR,
          payload: err,
        });
        dispatch({
          type: USER_ACTION_TYPES.MFA_ENROLLED,
          payload: true,
        });
        dispatch({
          type: USER_ACTION_TYPES.EMAIL,
          payload: email ?? userEmailGetFromMfaError(err),
        });
        userStorageSave();
        history.push({
          pathname: '/auth/verify-code',
          search,
        });
      }
      else {
        console.error(err);
        setFailed(true);
        if (err instanceof Error) {
          enqueueSnackbar(err.message, { variant: 'error' });
        }
      }
    }
    finally {
      setPending(false);
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (ready && !pending && !failed && valid) {
      void handleSignIn();
    }
  };

  return (
    <LoadingOverlay
      isLoading={!ready || pending}
      variants={['centered', 'expand']}
    >
      <FocusPageView heading="Log in" preamble="">
        <form
          data-e2e="auth--sign-in--form"
          onSubmit={handleSubmit}
        >
          <HorizontalGroup className="mb-3">
            <Typography variant="body1">Email</Typography>
            <TextField
              type="email"
              name="email"
              inputProps={{
                'data-testid': 'email',
                'data-e2e': 'auth--sign-in--email',
              }}
              InputProps={{ disableUnderline: true }}
              placeholder="Required"
              autoComplete="username"
              value={email}
              onChange={handleEmailChange}
              autoFocus
              required
              className="flex-grow-1 ml-2"
              style={{ maxWidth: 192 }}
            />
          </HorizontalGroup>
          <HorizontalGroup className="mb-5">
            <Typography variant="body1">Password</Typography>
            <TextField
              type="password"
              name="password"
              inputProps={{
                'data-testid': 'password',
                'data-e2e': 'auth--sign-in--password',
              }}
              autoComplete="current-password"
              InputProps={{ disableUnderline: true }}
              placeholder="Required"
              value={password}
              onChange={handlePasswordChange}
              required
              className="flex-grow-1 ml-2"
              style={{ maxWidth: 192 }}
            />
          </HorizontalGroup>
          <div className="justify-content-end mb-3">
            <Button
              type="button"
              href="/auth/reset"
              className="mr-2"
              data-e2e="auth--sign-in--password-reset"
            >
              Forgot Password?
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={pending || failed || !valid}
              color="primary"
              data-e2e="auth--sign-in--submit"
            >
              Continue
            </Button>
          </div>
        </form>
      </FocusPageView>
    </LoadingOverlay>
  );
}
