import type { RouterHistory } from 'react-router-dom';

import { Menu, MenuItem, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { useState } from 'react';
import _get from 'lodash/get';

import * as marketsActions from '../../../../../state/markets/action/creators';
import * as restaurantsActions from '../../../../../state/restaurants/action/creators';
import * as assetsActions from '../../../../../state/assets/action/creators';
import * as accountsActions from '../../../../../state/accounts/action/creators';

import { listMarkets } from '../../../../../state/markets/model/selectors';
import MenuButton from '../../../../common/__button/menuButton/MenuButton';

type Props = {|
  history: RouterHistory,
|};

const MarketPickerMenu = (props: Props) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const marketsMap = useSelector((state) => state.markets.map);
  const markets = useSelector((state) => listMarkets(state, { sort: true }));
  const selectedMarketId = useSelector(
    (state) => state.markets.selectedMarketId
  );

  const renderButton = !!_get(marketsMap, selectedMarketId);
  const selectedMarketName = _get(marketsMap, [selectedMarketId, 'name']);

  const handleClickListItem = (event, marketId) => {
    if (marketId !== selectedMarketId) {
      props.history.push(`/${marketId}`);
      dispatch(marketsActions.selectMarket(marketId));
      dispatch(restaurantsActions.reset());
      dispatch(assetsActions.reset());
      dispatch(accountsActions.resetMap());
      setAnchorEl(null);
    }
  };

  return (
    <>
      {renderButton ? (
        selectedMarketName && (
          <MenuButton
            className="color-white"
            ariaOwns={anchorEl ? 'simple-menu' : null}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            {selectedMarketName}
          </MenuButton>
        )
      ) : (
        <Typography variant="button" className="color-white">
          Market Engine&nbsp;
        </Typography>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {markets &&
          markets.map((market, index) => (
            <MenuItem
              key={`${index} ${market.marketCode}`}
              onClick={(event) => handleClickListItem(event, market.marketCode)}
              selected={market.marketCode === selectedMarketId}
            >
              {market.name}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default withRouter(MarketPickerMenu);
