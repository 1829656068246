import type { RoleMember } from './types';

const ID_SEPARATOR = '---';

export const normalizeRoleMemberList = (list: any) =>
  list.map((roleMember) => normalizeRoleMember(roleMember));

const normalizeRoleMemberId = (roleMember: RoleMember): string => {
  return roleMember.role + ID_SEPARATOR + roleMember.id;
};

export const denormalizeRoleMemberId = (roleMember: RoleMember): string => {
  const denormalizedId = roleMember.id.split(ID_SEPARATOR)[1];
  return denormalizedId;
};

const normalizeRoleMember = (roleMember: RoleMember): RoleMember => {
  const normalizedRoleMember = {
    ...roleMember,
  };

  normalizedRoleMember.id = normalizeRoleMemberId(roleMember);

  if (
    typeof roleMember.member === 'object' &&
    typeof roleMember.member.id === 'string'
  ) {
    normalizedRoleMember.member = roleMember.member.id;
  } else if (typeof roleMember.member === 'string') {
    normalizeRoleMember.member = roleMember.member;
  } else {
    throw new Error('Invalid object passed as argument');
  }

  normalizedRoleMember.market = roleMember.market;

  return normalizedRoleMember;
};
