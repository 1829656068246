import type { RolesState } from '../model/types';
import createReducer from '../../utility/createReducer';
import reduceMap from './map';

const initialState: RolesState = {
  map: {},
  mapInvalidatorId: undefined,
  statusList: undefined,
  nextPageToken: undefined,
};

export default createReducer(initialState, {
  'GLOBAL.RESET': () => initialState,
  ...reduceMap,
});
