import type { ContextRouter } from 'react-router-dom';

import { Tabs, Tab, withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React from 'react';

type Props = {
  ...ContextRouter,
  classes: Object,
};

const AppBottomAppBarTabs = (props: Props) => {
  const { history, match, classes } = props;
  const market = match.params.selectedMarketId || 'willChange';

  const app = useSelector((state) => state.app);

  return (
    <Tabs
      className={classes.root}
      value={app.selectedTabIdx}
      scrollButtons="auto"
    >
      {app.tabs.map((tab, idx) => (
        <Tab
          key={tab.name}
          label={tab.name}
          onClick={() => history.push(`/${market}/${tab.href}`)}
        />
      ))}
    </Tabs>
  );
};

const styles = (theme) => ({
  root: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
});

export default withStyles(styles)(withRouter(AppBottomAppBarTabs));
