import { createSelector } from 'reselect';
import { type RootState } from '../';
import { getSelectedMarketId } from '../markets/model/selectors';
import { getIsLsmEnabled } from '../../utility/lsmBundles';
import * as utils from '../utility/item/selectors';

export const selectItemsById = (state: RootState) => state.lsmConfigs.items;

export const selectIsLsmEnabledForSelectedMarket = createSelector(
  selectItemsById,
  getSelectedMarketId,
  (itemsById, selectedMarketId) => {
    if (selectedMarketId && itemsById && itemsById[selectedMarketId]) {
      return getIsLsmEnabled(itemsById[selectedMarketId]);
    }
    return false;
  }
);

export const selectConfig = utils.selectItemData('lsmConfigs');

export const selectEditedConfig = utils.selectItemEditedData('lsmConfigs');

export const selectIsSaving = utils.selectItemIsSaving('lsmConfigs');

export const selectStatusGet = utils.selectItemStatusGet('lsmConfigs');
