import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { type Config } from '../../state/assetsConfig/model/types';
import { getAssetsConfig } from '../../state/assetsConfig/model/selectors';

const UseGetAssetsConfig = (marketId: ?string): ?Config => {
  const assetsConfigs = useSelector(getAssetsConfig);

  const [assetsConfig, setAssetsConfig] = useState(undefined);

  useEffect(() => {
    if (assetsConfigs?.market === marketId && assetsConfigs?.enabled === true)
      setAssetsConfig(assetsConfigs);
  }, [marketId, assetsConfigs]);

  return assetsConfig;
};

export default UseGetAssetsConfig;
