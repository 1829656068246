import type { State as _State, Action as _Action } from './types';
import * as types from './types';
import createReducer from '../utility/createReducer';
import reduceSetItemEditedData from '../utility/item/reducers/reduceSetItemEditedData';
import reduceSetIsSavingItemEditedData from '../utility/item/reducers/reduceSetIsSavingItemEditedData';
import reducePutItemPending from '../utility/item/reducers/reducePutItemPending';
import reducePutItemFulfilled from '../utility/item/reducers/reducePutItemFulfilled';
import reducePutItemRejected from '../utility/item/reducers/reducePutItemRejected';
import reduceGetItemPending from '../utility/item/reducers/reduceGetItemPending';
import reduceGetItemFulfilled from '../utility/item/reducers/reduceGetItemFulfilled';
import reduceGetItemRejected from '../utility/item/reducers/reduceGetItemRejected';
import { createNutritionConfig } from './creators';

export type State = _State;
export type Action = _Action;

const initialState: State = {
  items: {},
};

export const reducer = createReducer(initialState, {
  'GLOBAL.RESET': () => initialState,
  [types.RESET]: () => initialState,
  'PRODUCTS_CONFIG.SET_EDITED': reduceSetItemEditedData,
  'PRODUCTS_CONFIG.SET_IS_SAVING': reduceSetIsSavingItemEditedData,
  [types.PUT_PENDING]: reducePutItemPending,
  [types.PUT_FULFILLED]: reducePutItemFulfilled,
  [types.PUT_REJECTED]: reducePutItemRejected,
  [types.GET_PENDING]: reduceGetItemPending,
  [types.GET_FULFILLED]: reduceGetItemFulfilled,
  [types.GET_REJECTED]: reduceGetItemRejected(createNutritionConfig),
});
