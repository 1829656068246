import * as React from 'react';
import GridRootContainer from '../gridRootContainer/GridRootContainer';
import { Grid, Typography } from '@material-ui/core';

type Props = { children: React.Node | null };
type State = { hasError: boolean, error: any };

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: {} };
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true, error });
    console.error({ error, info });
  }

  render() {
    if (this.state.hasError) {
      return (
        <GridRootContainer>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="mb-1">
              Oops! An error occurred.
            </Typography>
            <Typography>Please reload the page.</Typography>
            <Typography>Contact support if this problem persists.</Typography>
          </Grid>
        </GridRootContainer>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
