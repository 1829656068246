import type { Account } from '../model/types';
import type OrderDirection from '../../../utility/types';
// RESET

export const RESET: 'ACCOUNTS.RESET' = 'ACCOUNTS.RESET';
export type RESET_ACTION = {
  type: typeof RESET,
};

// RESET_MAP

export const RESET_MAP = 'ACCOUNTS.RESET_MAP';
export type RESET_MAP_ACTION = {
  type: 'ACCOUNTS.RESET_MAP',
};

// GET

export const GET: 'ACCOUNTS.GET' = 'ACCOUNTS.GET';
export type GET_ACTION = {
  type: typeof GET,
  payload: Promise<any>,
};

export const GET_PENDING: 'ACCOUNTS.GET_PENDING' = 'ACCOUNTS.GET_PENDING';
export type GET_PENDING_ACTION = {
  type: typeof GET_PENDING,
};

export const GET_FULFILLED: 'ACCOUNTS.GET_FULFILLED' = 'ACCOUNTS.GET_FULFILLED';
export type GET_FULFILLED_ACTION = {
  type: typeof GET_FULFILLED,
  payload: Account,
};

export const GET_REJECTED: 'ACCOUNTS.GET_REJECTED' = 'ACCOUNTS.GET_REJECTED';
export type GET_REJECTED_ACTION = {
  type: typeof GET_REJECTED,
};

type GET_ACTIONS =
  | GET_ACTION
  | GET_PENDING_ACTION
  | GET_FULFILLED_ACTION
  | GET_REJECTED_ACTION;

// PUT

export const PUT: 'ACCOUNTS.PUT' = 'ACCOUNTS.PUT';
export type PUT_ACTION = {
  type: typeof PUT,
  payload: Promise<Account>,
};

export const PUT_PENDING: 'ACCOUNTS.PUT_PENDING' = 'ACCOUNTS.PUT_PENDING';
export type PUT_PENDING_ACTION = {
  type: typeof PUT_PENDING,
};

export const PUT_FULFILLED: 'ACCOUNTS.PUT_FULFILLED' = 'ACCOUNTS.PUT_FULFILLED';
export type PUT_FULFILLED_ACTION = {
  type: typeof PUT_FULFILLED,
  payload: Account,
};

export const PUT_REJECTED: 'ACCOUNTS.PUT_REJECTED' = 'ACCOUNTS.PUT_REJECTED';
export type PUT_REJECTED_ACTION = {
  type: typeof PUT_REJECTED,
};

type PUT_ACTIONS =
  | PUT_ACTION
  | PUT_PENDING_ACTION
  | PUT_FULFILLED_ACTION
  | PUT_REJECTED_ACTION;

// DELETE

export const DELETE: 'ACCOUNTS.DELETE' = 'ACCOUNTS.DELETE';
export type DELETE_ACTION = {
  type: typeof DELETE,
  payload: Promise<any>,
};

export const DELETE_PENDING: 'ACCOUNTS.DELETE_PENDING' =
  'ACCOUNTS.DELETE_PENDING';
export type DELETE_PENDING_ACTION = {
  type: typeof DELETE_PENDING,
};

export const DELETE_FULFILLED: 'ACCOUNTS.DELETE_FULFILLED' =
  'ACCOUNTS.DELETE_FULFILLED';
export type DELETE_FULFILLED_ACTION = {
  type: typeof DELETE_FULFILLED,
  payload: any,
};

export const DELETE_REJECTED: 'ACCOUNTS.DELETE_REJECTED' =
  'ACCOUNTS.DELETE_REJECTED';
export type DELETE_REJECTED_ACTION = {
  type: typeof DELETE_REJECTED,
};

type DELETE_ACTIONS =
  | DELETE_ACTION
  | DELETE_PENDING_ACTION
  | DELETE_FULFILLED_ACTION
  | DELETE_REJECTED_ACTION;

// LIST

export const LIST: 'ACCOUNTS.LIST' = 'ACCOUNTS.LIST';
export type LIST_ACTION = {
  type: typeof LIST,
  payload: Promise<any>,
};

export const LIST_PENDING: 'ACCOUNTS.LIST_PENDING' = 'ACCOUNTS.LIST_PENDING';
export type LIST_PENDING_ACTION = {
  type: typeof LIST_PENDING,
};

export const LIST_FULFILLED: 'ACCOUNTS.LIST_FULFILLED' =
  'ACCOUNTS.LIST_FULFILLED';
export type LIST_FULFILLED_ACTION = {
  type: typeof LIST_FULFILLED,
  payload: any,
};

export const LIST_REJECTED: 'ACCOUNTS.LIST_REJECTED' = 'ACCOUNTS.LIST_REJECTED';
export type LIST_REJECTED_ACTION = {
  type: typeof LIST_REJECTED,
};

export const SET_MAP_INVALIDATOR_ID: 'ACCOUNTS.SET_MAP_INVALIDATOR_ID' =
  'ACCOUNTS.SET_MAP_INVALIDATOR_ID';
export type SET_MAP_INVALIDATOR_ID_ACTION = {
  type: typeof SET_MAP_INVALIDATOR_ID,
  payload: string,
};

export const UNSET_MAP_INVALIDATOR_ID: 'ACCOUNTS.UNSET_MAP_INVALIDATOR_ID' =
  'ACCOUNTS.UNSET_MAP_INVALIDATOR_ID';
export type UNSET_MAP_INVALIDATOR_ID_ACTION = {
  type: typeof UNSET_MAP_INVALIDATOR_ID,
  payload: string,
};

type LIST_ACTIONS =
  | LIST_ACTION
  | LIST_PENDING_ACTION
  | LIST_FULFILLED_ACTION
  | LIST_REJECTED_ACTION
  | SET_MAP_INVALIDATOR_ID_ACTION
  | UNSET_MAP_INVALIDATOR_ID_ACTION;

// SET_SORT_BY

export const SET_SORT_BY = 'ACCOUNTS.SET_SORT_BY';
export type SET_SORT_BY_ACTION = {
  type: 'ACCOUNTS.SET_SORT_BY',
  payload: ?string,
};

// SET_SORT_ORDER

export const SET_SORT_ORDER = 'ACCOUNTS.SET_SORT_ORDER';
export type SET_SORT_ORDER_ACTION = {
  type: 'ACCOUNTS.SET_SORT_ORDER',
  payload: OrderDirection,
};

// SET_SEARCH_QUERY

export const SET_SEARCH_QUERY = 'ACCOUNTS.SET_SEARCH_QUERY';
export type SET_SEARCH_QUERY_ACTION = {
  type: 'ACCOUNTS.SET_SEARCH_QUERY',
  payload: ?string,
};

//------------------------------------

export type Action =
  | RESET_ACTION
  | RESET_MAP_ACTION
  | GET_ACTIONS
  | PUT_ACTIONS
  | DELETE_ACTIONS
  | LIST_ACTIONS
  | SET_SORT_BY_ACTION
  | SET_SORT_ORDER_ACTION
  | SET_SEARCH_QUERY_ACTION;
