import type { ThunkAction } from '../../types';
import * as types from './types';
import * as api from '../api';

export const get =
  (marketId: string): ThunkAction =>
  (dispatch, getState) => {
    return dispatch({
      type: types.GET,
      payload: api
        .get(marketId)
        .then((response) => (response.status === 200 ? response.data : null)),
      meta: {
        deactivateErrorMiddlewareMsg: true,
      },
    });
  };
