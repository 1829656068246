import { Typography } from '@material-ui/core';
import React from 'react';

import { ENV } from '../../../../../config/env';

type Props = {
  classes: Object,
};

const EnvironmentTag = ({ classes }: Props) => {
  const getEnv = () => {
    switch (ENV) {
      case 'dev':
        return 'DEVELOPMENT';
      case 'stg':
        return 'STAGING';
      case 'mvp':
        return 'MVP';
      default:
        return null;
    }
  };

  return (
    <Typography variant="button" className={classes.envTag}>
      {getEnv()}
    </Typography>
  );
};

export default EnvironmentTag;
