import { getAuth } from 'firebase/auth';

const IS_NOT_PROD = process.env.REACT_APP_ENV !== 'prd';

const EMAILS = process.env.REACT_APP_TEST_AUTOMATION_USERS ?? '';

export function userIsTA(email: string): boolean {
  return Boolean(EMAILS.trim() && email.trim()) && EMAILS.includes(email);
}

export function userAppVerificationDisable(email: string): void {
  if (email && IS_NOT_PROD && EMAILS.includes(email)) {
    getAuth().settings.appVerificationDisabledForTesting = true;
  }
}

export function userAppVerificationEnable(): void {
  getAuth().settings.appVerificationDisabledForTesting = false;
}
