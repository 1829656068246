/* eslint-disable no-use-before-define */
import { type ThunkAction } from '../../types';
// eslint-disable-next-line no-unused-vars
import { put } from '../general/actionCreators';

export const setEdited =
  (type: any) =>
  <T>(subjectId: string, model: ?T) => ({
    type,
    payload: model,
    meta: { subjectId: subjectId },
  });

export const setIsEditing =
  <T>(
    setEdited: $Call<typeof setEdited, any>,
    selectItems: (any) => $Shape<{ [string]: { data: ?T } }>
  ) =>
  (subjectId: string, isEditing: boolean): ThunkAction =>
  (dispatch, getState) => {
    const items = selectItems(getState());
    if (isEditing) {
      dispatch(setEdited(subjectId, items[subjectId].data));
    } else {
      dispatch(setEdited(subjectId, undefined));
    }
  };

export const setIsSaving =
  (type: any) => (subjectId: string, isSaving: boolean) => ({
    type,
    payload: isSaving,
    meta: { subjectId },
  });

export const saveEdited =
  (
    setIsSaving: $Call<typeof setIsSaving, any>,
    setIsEditing: $Call<typeof setIsEditing, $Call<typeof setEdited, any>, any>,
    put: $Call<typeof put, any, (string, Object) => *>,
    selectEditedConfig: any
  ) =>
  (subjectId: string): ThunkAction =>
  (dispatch, getState) => {
    const edited = selectEditedConfig(subjectId)(getState());

    if (!edited) {
      throw new Error(
        `Couldn't save edited data, because no data of '${subjectId} is being edited.`
      );
    }
    dispatch(setIsSaving(subjectId, true));
    return dispatch(put(subjectId, edited)).finally(() => {
      dispatch(setIsSaving(subjectId, false));
      dispatch(setIsEditing(subjectId, false));
    });
  };
