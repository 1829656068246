import { type Permission, PermissionState } from './types';
import _mergeWith from 'lodash/mergeWith';

/**
 * Merges permission objects recursively, keeping the highest permissions.
 * For example: If one object has PermissionState.EDIT permission, and another
 * has PermissionState.VIEW permission, PermissionState.VIEW is overwritten by PermissionState.EDIT, since PermissionState.EDIT is higher.
 * @param {Permission object} object
 * @param  {Permission objects} sources
 */
export const mergePermissions = (...sources: Permission[]): Permission => {
  const merged = _mergeWith(
    {},
    ...sources,
    (objValue, srcValue, key, object, source, stack) => {
      if (objValue === srcValue) {
        return objValue;
      }
      return;
    }
  );
  return merged;
};

export const PERMISSION_URL_BUILDER: Permission = {
  home: { perm: PermissionState.VIEW },
  urlBuilder: { perm: PermissionState.EDIT },
  accountRoles: {
    perm: PermissionState.NONE,
  },
  accounts: {
    perm: PermissionState.NONE,
    account: { perm: PermissionState.NONE },
    ownAccount: { perm: PermissionState.DELETE },
  },
};

export const PERMISSION_MARKET_TAG_MANAGER: Permission = {
  home: { perm: PermissionState.VIEW },
  taggingTool: { perm: PermissionState.EDIT },
  urlBuilder: { perm: PermissionState.EDIT },
  accountRoles: {
    perm: PermissionState.NONE,
  },
  accounts: {
    perm: PermissionState.NONE,
    account: { perm: PermissionState.NONE },
    ownAccount: { perm: PermissionState.DELETE },
  },
};

export const PERMISSION_MARKET_CODE_IMPORT_MANAGER = {
  home: { perm: PermissionState.VIEW },
  urlBuilder: { perm: PermissionState.EDIT },
  codeUploader: { perm: PermissionState.EDIT },
  accountRoles: {
    perm: PermissionState.NONE,
  },
  accounts: {
    perm: PermissionState.NONE,
    account: { perm: PermissionState.NONE },
    ownAccount: { perm: PermissionState.DELETE },
  },
};

export const PERMISSION_FRANCHISEE_MANAGER: Permission = {
  home: { perm: PermissionState.VIEW },
  urlBuilder: { perm: PermissionState.NONE },
  accounts: {
    perm: PermissionState.NONE,
    account: { perm: PermissionState.NONE },
    ownAccount: { perm: PermissionState.DELETE },
  },
  accountRoles: {
    perm: PermissionState.VIEW,
    role: {
      productTeam: { perm: PermissionState.VIEW },
      supportTeam: { perm: PermissionState.VIEW },
      default: { perm: PermissionState.NONE },
      market: {
        perm: PermissionState.VIEW,
        marketManager: { perm: PermissionState.VIEW },
        marketLocationManager: { perm: PermissionState.VIEW },
        marketNutritionManager: { perm: PermissionState.VIEW },
        franchiseeManager: { perm: PermissionState.VIEW },
      },
      ownMarket: {
        perm: PermissionState.VIEW,
        marketManager: { perm: PermissionState.VIEW },
        marketLocationManager: { perm: PermissionState.VIEW },
        marketNutritionManager: { perm: PermissionState.VIEW },
        franchiseeManager: { perm: PermissionState.EDIT },
      },
    },
  },
  restaurants: {
    perm: PermissionState.VIEW,
    serviceAreas: { perm: PermissionState.NONE },
    settings: { perm: PermissionState.NONE },
  },
  localMarketing: {
    perm: PermissionState.DELETE,
    bundle: {
      perm: PermissionState.DELETE,
    },
    settings: {
      perm: PermissionState.NONE,
    },
  },
};

export const PERMISSION_MARKET_LOCATION_MANAGER: Permission = {
  home: { perm: PermissionState.VIEW },
  restaurants: {
    perm: PermissionState.EDIT,
    serviceAreas: { perm: PermissionState.DELETE },
    settings: { perm: PermissionState.NONE },
  },
  urlBuilder: { perm: PermissionState.EDIT },
  accounts: {
    perm: PermissionState.VIEW,
    account: { perm: PermissionState.VIEW },
    ownAccount: { perm: PermissionState.DELETE },
  },
  accountRoles: {
    perm: PermissionState.EDIT,
    role: {
      productTeam: { perm: PermissionState.VIEW },
      supportTeam: { perm: PermissionState.VIEW },
      default: { perm: PermissionState.EDIT },
      market: {
        perm: PermissionState.VIEW,
        marketManager: { perm: PermissionState.VIEW },
        marketLocationManager: { perm: PermissionState.VIEW },
        marketNutritionManager: { perm: PermissionState.VIEW },
        franchiseeManager: { perm: PermissionState.VIEW },
      },
      ownMarket: {
        perm: PermissionState.VIEW,
        marketManager: { perm: PermissionState.VIEW },
        marketLocationManager: { perm: PermissionState.EDIT },
        marketNutritionManager: { perm: PermissionState.VIEW },
        franchiseeManager: { perm: PermissionState.VIEW },
      },
    },
  },
};

export const PERMISSION_MARKET_NUTRITION_MANAGER: Permission = {
  home: { perm: PermissionState.VIEW },
  products: {
    perm: PermissionState.DELETE,
    product: { perm: PermissionState.DELETE },
    settings: { perm: PermissionState.NONE },
    allergens: { perm: PermissionState.DELETE },
    categories: { perm: PermissionState.DELETE },
    nutrients: { perm: PermissionState.DELETE },
  },
  urlBuilder: { perm: PermissionState.EDIT },
  accounts: {
    perm: PermissionState.VIEW,
    account: { perm: PermissionState.VIEW },
    ownAccount: { perm: PermissionState.DELETE },
  },
  accountRoles: {
    perm: PermissionState.EDIT,
    role: {
      productTeam: { perm: PermissionState.VIEW },
      supportTeam: { perm: PermissionState.VIEW },
      default: { perm: PermissionState.EDIT },
      market: {
        perm: PermissionState.VIEW,
        marketManager: { perm: PermissionState.VIEW },
        marketLocationManager: { perm: PermissionState.VIEW },
        marketNutritionManager: { perm: PermissionState.VIEW },
        franchiseeManager: { perm: PermissionState.VIEW },
      },
      ownMarket: {
        perm: PermissionState.VIEW,
        marketManager: { perm: PermissionState.VIEW },
        marketLocationManager: { perm: PermissionState.VIEW },
        marketNutritionManager: { perm: PermissionState.EDIT },
        franchiseeManager: { perm: PermissionState.VIEW },
      },
    },
  },
};

export const PERMISSION_MARKET_MANAGER: Permission = {
  home: { perm: PermissionState.VIEW },
  restaurants: {
    perm: PermissionState.EDIT,
    serviceAreas: { perm: PermissionState.DELETE },
    settings: { perm: PermissionState.NONE },
  },
  products: {
    perm: PermissionState.DELETE,
    product: { perm: PermissionState.DELETE },
    settings: { perm: PermissionState.NONE },
    allergens: { perm: PermissionState.DELETE },
    categories: { perm: PermissionState.DELETE },
    nutrients: { perm: PermissionState.DELETE },
  },
  urlBuilder: { perm: PermissionState.EDIT },
  accountRoles: {
    perm: PermissionState.EDIT,
    role: {
      productTeam: { perm: PermissionState.VIEW },
      supportTeam: { perm: PermissionState.VIEW },
      default: { perm: PermissionState.EDIT },
      market: {
        perm: PermissionState.VIEW,
        marketManager: { perm: PermissionState.VIEW },
        marketLocationManager: { perm: PermissionState.VIEW },
        marketNutritionManager: { perm: PermissionState.VIEW },
        marketTagManager: { perm: PermissionState.VIEW },
        franchiseeManager: { perm: PermissionState.VIEW },
        marketCodeImportManager: { perm: PermissionState.VIEW },
      },
      ownMarket: {
        perm: PermissionState.DELETE,
        marketManager: { perm: PermissionState.DELETE },
        marketLocationManager: { perm: PermissionState.DELETE },
        marketNutritionManager: { perm: PermissionState.DELETE },
        marketTagManager: { perm: PermissionState.DELETE },
        franchiseeManager: { perm: PermissionState.DELETE },
        marketCodeImportManager: { perm: PermissionState.DELETE },
      },
    },
  },
  accounts: {
    perm: PermissionState.VIEW,
    account: { perm: PermissionState.VIEW },
    ownAccount: { perm: PermissionState.DELETE },
  },
  localMarketing: {
    perm: PermissionState.DELETE,
    bundle: {
      perm: PermissionState.DELETE,
    },
    settings: {
      perm: PermissionState.NONE,
    },
  },
  mop: { perm: PermissionState.EDIT },
  codeUploader: { perm: PermissionState.EDIT },
  taggingTool: {
    perm: PermissionState.EDIT,
  },
  remoteConfig: {
    perm: PermissionState.EDIT,
    publish: { perm: PermissionState.NONE },
  },
  whitelist: {
    perm: PermissionState.NONE,
  },
};

export const PERMISSION_SUPPORT_TEAM: Permission = {
  home: { perm: PermissionState.VIEW },
  restaurants: {
    perm: PermissionState.EDIT,
    serviceAreas: { perm: PermissionState.DELETE },
    settings: { perm: PermissionState.NONE },
  },
  products: {
    perm: PermissionState.DELETE,
    product: { perm: PermissionState.DELETE },
    settings: { perm: PermissionState.NONE },
    allergens: { perm: PermissionState.DELETE },
    categories: { perm: PermissionState.DELETE },
    nutrients: { perm: PermissionState.DELETE },
  },
  accounts: {
    perm: PermissionState.EDIT,
    account: {
      perm: PermissionState.EDIT,
      withRole: {
        productTeam: { perm: PermissionState.VIEW },
        supportTeam: { perm: PermissionState.DELETE },
        default: { perm: PermissionState.DELETE },
        market: {
          perm: PermissionState.DELETE,
          marketManager: { perm: PermissionState.DELETE },
          marketLocationManager: { perm: PermissionState.DELETE },
          marketNutritionManager: { perm: PermissionState.DELETE },
          marketTagManager: { perm: PermissionState.DELETE },
          franchiseeManager: { perm: PermissionState.DELETE },
          marketCodeImportManager: { perm: PermissionState.DELETE },
        },
      },
    },
    ownAccount: { perm: PermissionState.DELETE },
  },
  urlBuilder: { perm: PermissionState.EDIT },
  accountRoles: {
    perm: PermissionState.EDIT,
    role: {
      productTeam: { perm: PermissionState.VIEW },
      supportTeam: { perm: PermissionState.EDIT },
      default: { perm: PermissionState.EDIT },
      market: {
        perm: PermissionState.DELETE,
        marketManager: { perm: PermissionState.DELETE },
        marketLocationManager: { perm: PermissionState.DELETE },
        marketNutritionManager: { perm: PermissionState.DELETE },
        marketTagManager: { perm: PermissionState.DELETE },
        franchiseeManager: { perm: PermissionState.DELETE },
        marketCodeImportManager: { perm: PermissionState.DELETE },
      },
      ownMarket: {
        perm: PermissionState.DELETE,
        marketManager: { perm: PermissionState.DELETE },
        marketLocationManager: { perm: PermissionState.DELETE },
        marketNutritionManager: { perm: PermissionState.DELETE },
        marketTagManager: { perm: PermissionState.DELETE },
        franchiseeManager: { perm: PermissionState.DELETE },
        marketCodeImportManager: { perm: PermissionState.DELETE },
      },
    },
  },
  localMarketing: {
    perm: PermissionState.DELETE,
    bundle: {
      perm: PermissionState.DELETE,
    },
    settings: {
      perm: PermissionState.NONE,
    },
  },
  mop: { perm: PermissionState.EDIT },
  codeUploader: { perm: PermissionState.EDIT },
  remoteConfig: {
    perm: PermissionState.VIEW,
  },
  whitelist: {
    perm: PermissionState.NONE,
  },
};

export const PERMISSION_PRODUCT_TEAM: Permission = {
  canDeleteEverything: true,
  canEditEverything: true,
  canViewEverything: true,
  settings: { perm: PermissionState.EDIT },
  ownSettings: { perm: PermissionState.EDIT },
  whitelist: {
    perm: PermissionState.EDIT,
  },
};
