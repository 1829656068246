import { RestaurantFacilityOverride } from '../model/types';

// RESET
export const RESET = 'FACILITYOVERRIDES.RESET';
export type RESET_ACTION = {
  type: 'FACILITYOVERRIDES.RESET',
};

// LIST
export const LIST = 'FACILITYOVERRIDES.LIST';
export type LIST_ACTION = {
  type: 'FACILITYOVERRIDES.LIST',
  payload: Promise<any>,
};

export const LIST_PENDING = 'FACILITYOVERRIDES.LIST_PENDING';
export type LIST_PENDING_ACTION = {
  type: 'FACILITYOVERRIDES.LIST_PENDING',
};

export const LIST_FULFILLED = 'FACILITYOVERRIDES.LIST_FULFILLED';
export type LIST_FULFILLED_ACTION = {
  type: 'FACILITYOVERRIDES.LIST_FULFILLED',
  payload: { data: RestaurantFacilityOverride },
};

export const LIST_REJECTED = 'FACILITYOVERRIDES.LIST_REJECTED';
export type LIST_REJECTED_ACTION = {
  type: 'FACILITYOVERRIDES.LIST_REJECTED',
};

// GET
export const GET = 'FACILITYOVERRIDES.GET';
export type GET_ACTION = {
  type: 'FACILITYOVERRIDES.GET',
  payload: Promise<any>,
};

export const GET_PENDING = 'FACILITYOVERRIDES.GET_PENDING';
export type GET_PENDING_ACTION = {
  type: 'FACILITYOVERRIDES.GET_PENDING',
};

export const GET_FULFILLED = 'FACILITYOVERRIDES.GET_FULFILLED';
export type GET_FULFILLED_ACTION = {
  type: 'FACILITYOVERRIDES.GET_FULFILLED',
  payload: { data: RestaurantFacilityOverride },
};

export const GET_REJECTED = 'FACILITYOVERRIDES.GET_REJECTED';
export type GET_REJECTED_ACTION = {
  type: 'FACILITYOVERRIDES.GET_REJECTED',
};

// POST
export const POST = 'FACILITYOVERRIDES.POST';
export type POST_ACTION = {
  type: 'FACILITYOVERRIDES.POST',
  payload: Promise<any>,
};

export const POST_PENDING = 'FACILITYOVERRIDES.POST_PENDING';
export type POST_PENDING_ACTION = {
  type: 'FACILITYOVERRIDES.POST_PENDING',
};

export const POST_FULFILLED = 'FACILITYOVERRIDES.POST_FULFILLED';
export type POST_FULFILLED_ACTION = {
  type: 'FACILITYOVERRIDES.POST_FULFILLED',
  payload: { data: RestaurantFacilityOverride },
};

export const POST_REJECTED = 'FACILITYOVERRIDES.POST_REJECTED';
export type POST_REJECTED_ACTION = {
  type: 'FACILITYOVERRIDES.POST_REJECTED',
};

// PUT
export const PUT = 'FACILITYOVERRIDES.PUT';
export type PUT_ACTION = {
  type: 'FACILITYOVERRIDES.PUT',
  payload: Promise<any>,
};

export const PUT_PENDING = 'FACILITYOVERRIDES.PUT_PENDING';
export type PUT_PENDING_ACTION = {
  type: 'FACILITYOVERRIDES.PUT_PENDING',
};

export const PUT_FULFILLED = 'FACILITYOVERRIDES.PUT_FULFILLED';
export type PUT_FULFILLED_ACTION = {
  type: 'FACILITYOVERRIDES.PUT_FULFILLED',
  payload: { data: RestaurantFacilityOverride },
};

export const PUT_REJECTED = 'FACILITYOVERRIDES.PUT_REJECTED';
export type PUT_REJECTED_ACTION = {
  type: 'FACILITYOVERRIDES.PUT_REJECTED',
};

// REMOVE

export const REMOVE: 'RESTAURANTS.REMOVE' = 'RESTAURANTS.REMOVE';
export type REMOVE_ACTION = {
  type: typeof REMOVE,
  payload: Promise<{ id: string }>,
};

export const REMOVE_PENDING: 'RESTAURANTS.REMOVE_PENDING' =
  'RESTAURANTS.REMOVE_PENDING';
export type REMOVE_PENDING_ACTION = {
  type: typeof REMOVE_PENDING,
};

export const REMOVE_FULFILLED: 'RESTAURANTS.REMOVE_FULFILLED' =
  'RESTAURANTS.REMOVE_FULFILLED';
export type REMOVE_FULFILLED_ACTION = {
  type: typeof REMOVE_FULFILLED,
  payload: { id: string },
};

export const REMOVE_REJECTED: 'RESTAURANTS.REMOVE_REJECTED' =
  'RESTAURANTS.REMOVE_REJECTED';
export type REMOVE_REJECTED_ACTION = {
  type: typeof REMOVE_REJECTED,
};

export type Action =
  | LIST_ACTION
  | LIST_PENDING_ACTION
  | LIST_FULFILLED_ACTION
  | LIST_REJECTED_ACTION
  | LIST_CANCEL_ALL_ACTION
  | RESET_ACTION
  | GET_ACTION
  | GET_PENDING_ACTION
  | GET_FULFILLED_ACTION
  | GET_REJECTED_ACTION
  | POST_ACTION
  | POST_PENDING_ACTION
  | POST_FULFILLED_ACTION
  | POST_REJECTED_ACTION
  | PUT_ACTION
  | PUT_PENDING_ACTION
  | PUT_FULFILLED_ACTION
  | PUT_REJECTED_ACTION
  | REMOVE_ACTION
  | REMOVE_PENDING_ACTION
  | REMOVE_FULFILLED_ACTION
  | REMOVE_REJECTED_ACTION;
