import {
  getAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  browserLocalPersistence,
  setPersistence,
} from 'firebase/auth';
import type { Creator } from 'state/creators';
import { USER_ACTION_TYPES } from 'state/user/action/types';
import { get as getAccount } from 'state/accounts/action/creators';
import {
  list as listMarkets,
  initSelectedMarket,
} from 'state/markets/action/creators';
import { list as listRoleMembers } from 'state/roleMembers/action/creators';
import { initEnabledFunctionality } from 'state/app/action/creators';
import { userRefreshToken } from 'state/user/action/refreshToken';
import HandledError from 'errors/HandledError';
import {
  ACCOUNTROLES,
  getHighestOrderOfPriorityRole,
} from 'state/roles/helpers';
import { userIsTA } from '../utils/appVerification';

type RoleMembers = {
  member: string;
  role: ACCOUNTROLES;
  id: string;
  market: string | undefined;
};

const IS_PRODUCTION = process.env.NODE_ENV === "production";

const selectedMarketInitializer = initSelectedMarket as Creator<Promise<void>>;

export const userInitializeAppData: Creator<Promise<void>> = () => {
  return async (dispatch, getState) => {
    const userState = getState().user;
    const appReady = userState.appReady;
    const mfaEnrolled = userState.mfaEnrolled;
    const user = userState.firebaseUser;
    const token = userState.firebaseIdToken;

    if (user && token && mfaEnrolled && !appReady) {
      try {
        await Promise.all([
          dispatch(getAccount(user.uid)),
          dispatch(listRoleMembers(user.uid, { fetchAll: true })),
        ]);
        const roles: RoleMembers[] = Object.values(getState().roleMembers.map);
        const userRoles = roles.filter((item) => item.member === user.uid);
        const mostPriorityRole = getHighestOrderOfPriorityRole(userRoles);

        if (mostPriorityRole !== ACCOUNTROLES.DEFAULT) {
          await dispatch(listMarkets({ pageSize: 200 }));
        }
        await dispatch(selectedMarketInitializer());
        await dispatch(initEnabledFunctionality());
        dispatch({
          type: USER_ACTION_TYPES.APP_READY,
          payload: true,
        });
      } catch (err) {
        // already handled?
        if (err instanceof Error && err.name === HandledError.errorName) {
          return;
        }
        // An error message was already shown in the errorMiddleware
        if (
          err instanceof Error &&
          'code' in err &&
          err.code === 'auth/network-request-failed'
        ) {
          return;
        } else {
          console.error(err);
        }
      }
    }
  };
};

export const userInitialize: Creator<void> = () => {
  return (dispatch) => {
    let initialized = false;
    const auth = getAuth();

    onAuthStateChanged(auth, async (firebaseUser) => {
      /**
       * Switch to localStorage firebase session if:
       * - it is local development;
       * - user (for some reason) does not have an email;
       * - user email is NOT in a test automation emails list.
       * Else, use IndexedDB session by default.
       *
       * We need to only set local persistence on non-TA (test automation) users.
       * TA opens new browser window in every test step.
       * This setPersistence sets it to local, which makes user info not move to new browser window
       * This breaks TA as we need the session to persist to new windows
       * Firebase does this with the firebaseLocalStorageDB
       * And the browserLocalPersistence overwrites that.
       * */
      if (!IS_PRODUCTION || !firebaseUser?.email || !userIsTA(firebaseUser.email)) {
        await setPersistence(auth, browserLocalPersistence);
      }

      await dispatch(userRefreshToken());
      dispatch({
        type: USER_ACTION_TYPES.FIREBASE_READY,
        payload: true,
      });
    });

    onIdTokenChanged(auth, async (firebaseUser) => {
      await dispatch(userRefreshToken());

      dispatch({
        type: USER_ACTION_TYPES.FIREBASE_USER,
        payload: firebaseUser,
      });

      if (!initialized) {
        initialized = true;
        window.setInterval(
          async () => {
            await dispatch(userRefreshToken());
          },
          1000 * 60 * 20
        );
        window.document.addEventListener('visibilitychange', async () => {
          if (window.document.visibilityState === 'visible') {
            await dispatch(userRefreshToken());
          }
        });
      }

      await dispatch(userInitializeAppData());
    });
  };
};
