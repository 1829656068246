// Names are taken from Figma: https://www.figma.com/file/OAXlXyxGGTpYskzWY951aTmx/MAP-ME2?node-id=345%3A577

export const TEXT_HEADLINE1 = {
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 'normal',
  fontSize: 24,
};

export const TEXT_HEADLINE2 = {
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 'normal',
  fontSize: 16,
};

export const TEXT_SUB_HEAD = {
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: 'normal',
  fontSize: 14,
};

export const TEXT_BODY = {
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: '20px',
  fontSize: 14,
};

export const TEXT_DESCRIPTION = {
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: '18px',
  fontSize: 12,
};

export const TEXT_BUTTON = {
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: 'normal',
  fontSize: 12,
  textTransform: 'uppercase',
};
