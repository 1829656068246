import type { ContextRouter, Match } from 'react-router-dom';

import { withRouter, NavLink, matchPath } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';

import { translateLocale } from '../../../../../utility/language';
import { searchTree } from '../../../../../utility/utility';
import CONFIG_ROUTES from 'config/routes';
import {
  mapRestaurantsById,
  getRestaurantName,
} from '../../../../../state/restaurants/model/selectors';
import {
  getAccountName,
  mapAccountsById,
} from '../../../../../state/accounts/model/selectors';
import {
  getMarketName,
  mapMarketsById,
} from '../../../../../state/markets/model/selectors';
import {
  selectItemName as selectProductName,
  selectItemsById as selectProductsById,
} from '../../../../../state/nutritionItems/selectors';
import {
  selectItemName as selectProductCategoryName,
  selectItemsById as selectProductCategoriesById,
} from '../../../../../state/nutritionCategories/selectors';
import {
  selectItemName as selectProductAllergenName,
  selectItemsById as selectProductAllergensById,
} from '../../../../../state/nutritionAllergens/selectors';
import {
  selectItemName as selectProductNutrientName,
  selectItemsById as selectProductNutrientsById,
} from '../../../../../state/nutritionNutrients/selectors';
import { selectItemsById as selectLsmBundlesById } from '../../../../../state/lsmBundles/selectors';
import { getCanViewPath } from '../../../../../state/app/selectors';
import useQueryString from '../../../../../hooks/useQueryString/UseQueryString';
import StyledBreadcrumb from '../styledBreadcrumb/StyledBreadcrumb';

type BreadcrumbConfig = {
  crumb: string,
  crumbPath: string,
}[];

type Props = {
  ...ContextRouter,
};

const Breadcrumb = (props: Props) => {
  const { history, location } = props;
  const pathname = location.pathname;

  const [breadcrumbConfig, setBreadcrumbConfig] = useState([]);
  const restaurants = useSelector(mapRestaurantsById);
  const accounts = useSelector(mapAccountsById);
  const markets = useSelector(mapMarketsById);
  const products = useSelector(selectProductsById);
  const productCategories = useSelector(selectProductCategoriesById);
  const productAllergens = useSelector(selectProductAllergensById);
  const productNutrients = useSelector(selectProductNutrientsById);
  const lsmBundles = useSelector(selectLsmBundlesById);
  const canViewPath = useSelector(getCanViewPath);
  const restaurantsDisplayedLocale = useSelector(
    (state) => state.restaurants.displayedLocale
  );
  const { lang: viewedLanguage } = useQueryString();

  useEffect(() => {
    const breadcrumbConfig = getBreadcrumb(CONFIG_ROUTES, pathname);
    setBreadcrumbConfig(breadcrumbConfig);
  }, [pathname]);

  // Workaround to match full path. Source: https://github.com/ReactTraining/react-router/issues/5870#issuecomment-394194338
  const match =
    breadcrumbConfig.length > 0
      ? matchPath(history.location.pathname, {
          path: breadcrumbConfig[breadcrumbConfig.length - 1].crumbPath,
          exact: false,
          strict: false,
        })
      : null;

  const decodeCrumbPath = (breadcrumb: string, match: ?Match) => {
    if (match) {
      const crumbs = breadcrumb.split('/').filter((x) => '' !== x);
      return '/' + crumbs.map((crumb) => decodeCrumb(crumb, match)).join('/');
    } else {
      return breadcrumb;
    }
  };

  const decodeCrumb = (crumb: string, match: ?Match) => {
    let c = crumb;
    if (match && crumb.startsWith(':')) {
      c = match.params[crumb.substring(1)];
      if (c) {
        switch (crumb) {
          case ':viewedRestaurantId':
            {
              const restaurant = restaurants[c];
            if (restaurant) {
              return (
                getRestaurantName(restaurant, restaurantsDisplayedLocale) ||
                `Untitled restaurant`
              );
            }
            return '';
            }
          case ':accountId':
            {
                const account = accounts[c];
              if (account) {
                return getAccountName(account);
              }
              return '';
            }
          case ':viewedMarketId':
            {
              const market = markets[c];
              if (market) {
                return getMarketName(market);
              }
              return '';
            }
          case ':selectedProductId':
            {
              const product = _get(products, `${c}`);
              if (product) {
                return (
                  selectProductName(product, viewedLanguage) || `Untitled product`
                );
              }
              return '';
            }
          case ':selectedProductCategoryId':
            {
              const productCategory = _get(productCategories, `${c}`);
              if (productCategory) {
                return (
                  selectProductCategoryName(productCategory, viewedLanguage) ||
                  `Untitled category`
                );
              }
              return '';
            }
          case ':selectedProductAllergenId':
            {
              const productAllergen = _get(productAllergens, `${c}`);
              if (productAllergen) {
                return (
                  selectProductAllergenName(productAllergen, viewedLanguage) ||
                  `Untitled allergen`
                );
              }
              return '';
            }
          case ':selectedProductNutrientId':
            {
              const productNutrient = _get(productNutrients, `${c}`);
              if (productNutrient) {
                return (
                  selectProductNutrientName(productNutrient, viewedLanguage) ||
                  `Untitled nutrient`
                );
              }
              return '';
            }
          case ':selectedLsmBundleId':
            {
              const lsmBundle = _get(lsmBundles, `${c}`);
              if (lsmBundle) {
                return lsmBundle.name || `Untitled bundle`;
              }
              return '';
            }
          case ':viewedLanguage':
          case ':sourceLocale':
            return translateLocale(c);
          default:
        }
      } else {
        console.warn('Path param not recognized: ' + crumb);
        return crumb;
      }
    }
    return c;
  };

  return breadcrumbConfig ? (
    <StyledBreadcrumb>
      {breadcrumbConfig.map((crumb, idx) => {
        const link = decodeCrumbPath(crumb.crumbPath, match);
        return (
          <React.Fragment key={idx}>
            {idx === breadcrumbConfig.length - 1 ? (
              <span>{decodeCrumb(crumb.crumb, match)}</span>
            ) : (
              <>
                {canViewPath(link) ? (
                  <NavLink to={{ ...history.location, pathname: link }}>
                    {decodeCrumb(crumb.crumb, match)}
                  </NavLink>
                ) : (
                  decodeCrumb(crumb.crumb, match)
                )}
                {' / '}
              </>
            )}
          </React.Fragment>
        );
      })}
    </StyledBreadcrumb>
  ) : null;
};

/** TODO simplify */
export const getBreadcrumb = (
  CONFIG_ROUTES,
  pathname: string
): BreadcrumbConfig => {
  const splitPath = pathname.split('/').filter((x) => '' !== x);
  let crumbPath = '';
  let crumbDepth;
  const breadcrumb = splitPath.reduce((breadcrumb, segment) => {
    crumbPath += `/${segment}`;
    crumbDepth = crumbPath.split('/').filter((x) => x !== '').length;

    let exactMatched = searchTree(
      CONFIG_ROUTES,
      ({ type, path }) => {
        return (type === 'crumbed' || type === 'ignored') && path === crumbPath;
      },
      'routes'
    );
    if (exactMatched) {
      crumbPath = exactMatched.path;
      if (exactMatched.crumb) {
        breadcrumb.push({
          crumb: exactMatched.crumb,
          crumbPath: exactMatched.crumb ? crumbPath : undefined,
        });
      }
    } else {
      let varMatched = searchTree(
        CONFIG_ROUTES,
        ({ type, path }) => {
          const wasLastVarMatchedAContainer = crumbPath.startsWith('/:');
          if (
            type === 'crumbed' ||
            (type === 'container' && !wasLastVarMatchedAContainer)
          ) {
            const pathSegments = path.split('/').filter((x) => x !== '');
            const pathDepth = pathSegments.length;
            if (pathDepth === crumbDepth) {
              let a = path.substring(0, path.lastIndexOf('/'));
              let b = crumbPath.substring(0, crumbPath.lastIndexOf('/'));
              if (a === b) {
                return pathSegments[pathDepth - 1].startsWith(':');
              }
            }
          }
          return false;
        },
        'routes'
      );
      if (varMatched) {
        crumbPath = varMatched.path;
        if (varMatched.crumb) {
          breadcrumb.push({
            crumb: varMatched.crumb,
            crumbPath: varMatched.crumb ? crumbPath : undefined,
          });
        }
      }
    }
    return breadcrumb;
  }, []);

  //TODO fix
  return (breadcrumb: any);
};

export default withRouter(Breadcrumb);
