import type { ThunkAction } from '../../types';
import * as types from './types';
import * as api from '../api';
import type { Asset } from '../model/types';
import listHelper from '../../utility/general/actionCreators/listHelper';

export const reset = () => ({
  type: types.RESET,
});

export const list =
  (
    marketId: string,
    options?: {
      prefix: string,
      getNextPage?: boolean,
      onlyCurrent?: boolean,
      append?: boolean,
      sortDirection?: string,
      sortField?: string,
    }
  ): ThunkAction =>
  (dispatch, getState) => {
    const {
      prefix,
      nextPageToken,
      onlyCurrent,
      limit,
      sortDirection,
      sortField,
      ...restOptions
    } = options || {};

    let cfg = {
      ...restOptions,
      fetch: {
        action: types.LIST,
        bindFetch: (marketId, nextPageToken) =>
          api.list(marketId, {
            prefix,
            limit,
            nextPageToken,
            onlyCurrent,
            sortDirection,
            sortField,
          }),
      },
      subject: marketId,
      statePath: 'assets',
    };

    if (options) {
      if (options.invalidate) {
        cfg = {
          ...cfg,
        };
      }
    }
    return dispatch(listHelper(cfg));
  };

export const get =
  (marketId: string, assetId: number): ThunkAction =>
  (dispatch, getState) => {
    return dispatch({
      type: types.GET,
      // TODO: this will fail on 401, because errorMiddleware will kick in and the `then` func will never run.
      payload: api.get(marketId, assetId).then((response) => response.data),
    });
  };

export const deleteAsset =
  (marketId: string, assetId: number) => (dispatch, getState) => {
    return dispatch({
      type: types.GET,
      payload: api
        .deleteAsset(marketId, assetId)
        .then((response) => response.data),
    });
  };

export const createUpload =
  (marketId: string, assetName: string, assetVersion?: string): ThunkAction =>
  (dispatch, getState) => {
    return dispatch({
      type: types.POST,
      payload: api.createUpload(marketId, assetName, assetVersion),
    });
  };

export const upload =
  (uploadUrl: string, imageFile: any): ThunkAction =>
  (dispatch, getState) => {
    return dispatch({
      type: types.PUT,
      payload: api.uploadAsset(uploadUrl, imageFile).then((result) => result),
    });
  };

export const operationStatus =
  (name: string): ThunkAction =>
  (dispatch, getState) => {
    return dispatch({
      type: types.GET_OPERATION_STATUS,
      payload: api.operationStatus(name),
    }).then((result) => result.value.data.done);
  };

export const appendAsset =
  (asset: Asset): ThunkAction =>
  (dispatch) => {
    return dispatch({
      type: types.APPEND_ASSET,
      payload: asset,
    });
  };
