import type { ReducerFragment } from '../../types';
import type { RolesState } from '../model/types';
import * as types from '../action/types';
import reduceListFulfilled from '../../utility/general/reducers/reduceListFulfilled';

const reduce: ReducerFragment<RolesState> = {
  [types.LIST_PENDING]: (state, action) => ({
    ...state,
    statusList: 'pending',
  }),

  [types.LIST_FULFILLED]: reduceListFulfilled(),

  [types.LIST_REJECTED]: (state, action) => ({
    ...state,
    statusList: 'rejected',
  }),

  [types.SET_MAP_INVALIDATOR_ID]: (state, action) => ({
    ...state,
    mapInvalidatorId: action.payload,
  }),

  [types.UNSET_MAP_INVALIDATOR_ID]: (state, action) => {
    if (state.mapInvalidatorId === action.payload) {
      return {
        ...state,
        mapInvalidatorId: null,
      };
    }
    return state;
  },
};

export default reduce;
