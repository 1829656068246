import React from 'react';
import { SvgIcon, withStyles } from '@material-ui/core';

type Props = { classes: Object };

export default withStyles({
  root: {
    fontSize: '18px',
  },
})(({ classes, ...rest }: Props) => (
  <SvgIcon {...rest} classes={{ root: classes.root }} fontSize="small">
    <path
      d="M17.05 2.486c2.047 0 3.706 8.61 3.706 19.338h2.967C23.723 10.292 20.735 1 17.05 1c-2.094 0-3.966 2.76-5.189 7.128C10.638 3.76 8.77 1.016 6.672 1.016 2.987 1.016 0 10.292 0 21.824h2.966c0-10.727 1.66-19.338 3.706-19.338 2.046 0 3.706 7.806 3.706 17.728h2.966c0-9.922 1.66-17.728 3.706-17.728"
      fill="#FC0"
      fillRule="nonzero"
    />
  </SvgIcon>
));
