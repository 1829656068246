import type { RootState } from '../../';
import type { RoleMember } from './types';
import { getUserAccountId } from '../../user/model/selectors';

export const mapRoleMembersById = (state: RootState) => state.roleMembers.map;

export const isSpecificRole = (state: RootsState, specificRole: string) => {
  const rolesMembers = { ...state?.roleMembers?.map };
  const keysOfRoleMembers = Object.keys(rolesMembers);

  const userAccountId = state?.user?.firebaseUser?.uid;

  if (userAccountId) {
    for (let i = 0; i < keysOfRoleMembers.length; i++) {
      const key = keysOfRoleMembers[i];
      if (
        rolesMembers[key].role === specificRole &&
        rolesMembers[key].member === userAccountId
      ) {
        return true;
      }
    }
  }

  return false;
};

export const getNextPageToken = (state: RootState) =>
  state.roleMembers.nextPageToken;

export const getStatusListRoleMembers = (state: RootState) =>
  state.roleMembers.statusList;

export const listAccountRoleMembers = (
  state: RootState,
  accountId: string
): RoleMember[] => {
  const map = state.roleMembers.map;
  return Object.keys(map)
    .map((memberId) => map[memberId])
    .filter((roleMember: RoleMember) => accountId === roleMember.member);
};

// From map

export const removeRoleMembersOfAccount = (
  map: {| [string]: RoleMember |},
  accountId: string
) => {
  return Object.keys(map)
    .map((memberId) => map[memberId])
    .filter((roleMember: RoleMember) => accountId !== roleMember.member)
    .reduce((map, roleMember) => {
      map[roleMember.id] = roleMember;
      return map;
    }, {});
};

// Misc

export const includesRole = <T: { market?: string, role: string }>(
  roleMembers: T[],
  queryRole: string,
  queryMarket?: string
) => {
  return roleMembers
    ? !!roleMembers.find((roleMember) =>
        queryMarket
          ? roleMember.role === queryRole && roleMember.market === queryMarket
          : roleMember.role === queryRole
      )
    : false;
};

export const getMarketIds = (roleMembers: RoleMember[]) => {
  const marketIdSet = roleMembers.reduce((marketIds, member) => {
    member.market && marketIds.add(member.market);
    return marketIds;
  }, new Set());

  return [...marketIdSet];
};

// User

export const listUserAccountRoleMembers = (state: RootState) => {
  const userId = getUserAccountId(state);
  return userId ? listAccountRoleMembers(state, userId) : [];
};
