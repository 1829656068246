import update from 'immutability-helper';

const reducePutItemPending = (state: any, action: any) => {
  const itemId = action.meta.subjectId;

  return update(state, {
    items: {
      // Create item state if it doesn't exist yet
      [itemId]: (item) =>
        update(item || {}, {
          statusPut: {
            $set: 'pending',
          },
        }),
    },
  });
};

export default reducePutItemPending;
