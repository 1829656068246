// RESET

export const RESET: 'RESTAURANTS_CONFIG.RESET' = 'RESTAURANTS_CONFIG.RESET';
export type RESET_ACTION = {
  type: typeof RESET,
};

// GET

export const GET: 'RESTAURANTS_CONFIG.GET' = 'RESTAURANTS_CONFIG.GET';
export type GET_ACTION = {
  type: typeof GET,
  payload: Promise<any>,
};

export const GET_PENDING: 'RESTAURANTS_CONFIG.GET_PENDING' =
  'RESTAURANTS_CONFIG.GET_PENDING';
export type GET_PENDING_ACTION = {
  type: typeof GET_PENDING,
};

export const GET_FULFILLED: 'RESTAURANTS_CONFIG.GET_FULFILLED' =
  'RESTAURANTS_CONFIG.GET_FULFILLED';
export type GET_FULFILLED_ACTION = {
  type: typeof GET_FULFILLED,
  payload: any,
};

export const GET_REJECTED: 'RESTAURANTS_CONFIG.GET_REJECTED' =
  'RESTAURANTS_CONFIG.GET_REJECTED';
export type GET_REJECTED_ACTION = {
  type: typeof GET_REJECTED,
};

type GET_ACTIONS =
  | GET_ACTION
  | GET_PENDING_ACTION
  | GET_FULFILLED_ACTION
  | GET_REJECTED_ACTION;

// PUT

export const PUT: 'RESTAURANTS_CONFIG.PUT' = 'RESTAURANTS_CONFIG.PUT';
export type PUT_ACTION = {
  type: typeof PUT,
  payload: Promise<any>,
};

export const PUT_PENDING: 'RESTAURANTS_CONFIG.PUT_PENDING' =
  'RESTAURANTS_CONFIG.PUT_PENDING';
export type PUT_PENDING_ACTION = {
  type: typeof PUT_PENDING,
};

export const PUT_FULFILLED: 'RESTAURANTS_CONFIG.PUT_FULFILLED' =
  'RESTAURANTS_CONFIG.PUT_FULFILLED';
export type PUT_FULFILLED_ACTION = {
  type: typeof PUT_FULFILLED,
  payload: any,
};

export const PUT_REJECTED: 'RESTAURANTS_CONFIG.PUT_REJECTED' =
  'RESTAURANTS_CONFIG.PUT_REJECTED';
export type PUT_REJECTED_ACTION = {
  type: typeof PUT_REJECTED,
};

type PUT_ACTIONS =
  | PUT_ACTION
  | PUT_PENDING_ACTION
  | PUT_FULFILLED_ACTION
  | PUT_REJECTED_ACTION;

// SET_EDITED

export const SET_EDITED: 'RESTAURANTS_CONFIG.SET_EDITED' =
  'RESTAURANTS_CONFIG.SET_EDITED';
export type SET_EDITED_ACTION = {
  type: typeof SET_EDITED,
  payload: any,
  meta?: any,
};

// SET_IS_SAVING

export const SET_IS_SAVING: 'RESTAURANTS_CONFIG.SET_IS_SAVING' =
  'RESTAURANTS_CONFIG.SET_IS_SAVING';
export type SET_IS_SAVING_ACTION = {
  type: typeof SET_IS_SAVING,
  payload: any,
  meta?: any,
};

//------------------------------------

export type Action =
  | RESET_ACTION
  | GET_ACTIONS
  | PUT_ACTIONS
  | SET_EDITED_ACTION
  | SET_IS_SAVING_ACTION;
