import type { ThunkAction } from '../../types';
import type { RestaurantsConfig } from '../model/types';
import * as types from './types';
import * as api from '../api/real';
import { selectEdited } from '../model/selectors';

export const get =
  (marketCode: string): ThunkAction =>
  (dispatch, getState) => {
    return dispatch({
      type: types.GET,
      meta: { subjectId: marketCode },
      payload: api.get(marketCode),
    });
  };

export const put =
  (restaurantsConfig: any, marketCode: string): ThunkAction =>
  (dispatch) => {
    return dispatch({
      type: types.PUT,
      meta: { subjectId: marketCode },
      payload: api.put(marketCode, restaurantsConfig),
    });
  };

export const setEdited = (
  marketCode: string,
  restaurantsConfig: ?RestaurantsConfig
) => ({
  type: types.SET_EDITED,
  payload: restaurantsConfig,
  meta: { id: marketCode },
});

export const setIsEditing =
  (marketCode: string, isEditing: boolean): ThunkAction =>
  (dispatch, getState) => {
    const {
      restaurantsConfigs: { map },
    } = getState();

    if (isEditing) {
      dispatch(setEdited(marketCode, map[marketCode]));
    } else {
      dispatch(setEdited(marketCode, undefined));
    }
  };

export const saveEdited =
  (marketCode: string): ThunkAction =>
  (dispatch, getState) => {
    const edited = selectEdited(getState(), marketCode);

    if (!edited) {
      throw new Error(
        `Couldn't save edited restaurant, because no restaurant of market '${marketCode} is being edited.`
      );
    }
    dispatch({
      type: types.SET_IS_SAVING,
      payload: true,
      meta: { id: marketCode },
    });
    return dispatch(put(edited, marketCode)).then(() => {
      dispatch({
        type: types.SET_IS_SAVING,
        payload: false,
        meta: { id: marketCode },
      });
      dispatch(setIsEditing(marketCode, false));
    });
  };
