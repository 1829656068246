import { COLOR_TEXT_DEFAULT } from '../../colors';
import useDarkmode from '../../../hooks/useDarkMode/UseDarkMode';

function IsDark() {
  const { theme } = useDarkmode();
  return theme === 'dark';
}

const List = {
  MuiList: {
    padding: {
      paddingTop: 3,
      paddingBottom: 3,
    },
  },
  MuiListItem: {
    root: { paddingTop: 11, paddingBottom: 11 },
  },
  MuiListItemIcon: {
    root: {
      color: IsDark ? '#FFFFF' : COLOR_TEXT_DEFAULT,
    },
  },
  MuiListItemText: {
    root: {
      padding: '0 10px',
      '&:first-child': {
        paddingLeft: 0,
      },
    },
  },
};

export default List;
