import type { AsyncStatus } from '../types';

export type LSMConfig = {|
  id: string,
  merchantId?: ?string,
  helpText?: ?string,
  templateHelpText?: string,
|};

export type Items = {|
  // [string] = Market code
  [string]: {|
    isSaving?: ?boolean,
    statusGet?: ?AsyncStatus,
    statusPut?: ?AsyncStatus,
    data: LSMConfig,
    editedData?: ?LSMConfig, // A copy of data, modified while in edit mode
  |},
|};

export type State = {|
  items: Items,
|};

// GET

export const GET = 'LSM_CONFIGS.GET';
export type GET_ACTION = {
  type: 'LSM_CONFIGS.GET',
  payload: Promise<any>,
};

export const GET_PENDING = 'LSM_CONFIGS.GET_PENDING';
export type GET_PENDING_ACTION = {
  type: 'LSM_CONFIGS.GET_PENDING',
};

export const GET_FULFILLED = 'LSM_CONFIGS.GET_FULFILLED';
export type GET_FULFILLED_ACTION = {
  type: 'LSM_CONFIGS.GET_FULFILLED',
  payload: any,
};

export const GET_REJECTED = 'LSM_CONFIGS.GET_REJECTED';
export type GET_REJECTED_ACTION = {
  type: 'LSM_CONFIGS.GET_REJECTED',
};

type GET_ACTIONS =
  | GET_ACTION
  | GET_PENDING_ACTION
  | GET_FULFILLED_ACTION
  | GET_REJECTED_ACTION;

// PUT

export const PUT: 'LSM_CONFIGS.PUT' = 'LSM_CONFIGS.PUT';
export type PUT_ACTION = {
  type: typeof PUT,
  payload: Promise<Object>,
};

export const PUT_PENDING: 'LSM_CONFIGS.PUT_PENDING' = 'LSM_CONFIGS.PUT_PENDING';
export type PUT_PENDING_ACTION = {
  type: typeof PUT_PENDING,
};

export const PUT_FULFILLED: 'LSM_CONFIGS.PUT_FULFILLED' =
  'LSM_CONFIGS.PUT_FULFILLED';
export type PUT_FULFILLED_ACTION = {
  type: typeof PUT_FULFILLED,
  payload: Object,
};

export const PUT_REJECTED: 'LSM_CONFIGS.PUT_REJECTED' =
  'LSM_CONFIGS.PUT_REJECTED';
export type PUT_REJECTED_ACTION = {
  type: typeof PUT_REJECTED,
};

type PUT_ACTIONS =
  | PUT_ACTION
  | PUT_PENDING_ACTION
  | PUT_FULFILLED_ACTION
  | PUT_REJECTED_ACTION;

// SET_EDITED

export const SET_EDITED: 'LSM_CONFIGS.SET_EDITED' = 'LSM_CONFIGS.SET_EDITED';
export type SET_EDITED_ACTION = {
  type: typeof SET_EDITED,
  payload: any,
  meta?: any,
};

// SET_IS_SAVING

export const SET_IS_SAVING: 'LSM_CONFIGS.SET_IS_SAVING' =
  'LSM_CONFIGS.SET_IS_SAVING';
export type SET_IS_SAVING_ACTION = {
  type: typeof SET_IS_SAVING,
  payload: any,
  meta?: any,
};

//------------------------------------

export type Action =
  | GET_ACTIONS
  | PUT_ACTIONS
  | SET_EDITED_ACTION
  | SET_IS_SAVING_ACTION;
