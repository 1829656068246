import type { AxiosPromise } from 'axios';
import type {
  Page,
  Restaurant,
  BareRestaurant,
  RemoveResponse,
  ImportResponse,
  RpmConfigData,
} from "./model/types";
import API from '../../utility/API/API';
import getListParams from '../../utility/API/getListParams';
import { APIListParams } from 'utility/API/types';

export const getFirstPage = (marketId: string): AxiosPromise<Page> => {
  const axios = API.axios();
  return axios.get(`/restaurant/v1/${marketId}/restaurants`);
};

export const list = (
  marketId: string,
  options?: APIListParams
): AxiosPromise<Page> => {
  const axios = API.axios();
  return axios.get(`/restaurant/v1/${marketId}/restaurants`, {
    params: getListParams(options),
  });
};

export const get = (
  marketId: string,
  restaurantId: string
): AxiosPromise<Restaurant> => {
  const axios = API.axios();
  return axios.get(`/restaurant/v1/${marketId}/restaurants/${restaurantId}`);
};

export const put = (
  marketId: string,
  restaurant: BareRestaurant & { id: string }
): AxiosPromise<Restaurant> => {
  const axios = API.axios();

  return axios.put(
    `/restaurant/v1/${marketId}/restaurants/${restaurant.id}`,
    restaurant
  );
};

export const post = (
  marketId: string,
  restaurant: BareRestaurant
): AxiosPromise<Restaurant> => {
  const axios = API.axios();
  return axios.post(`/restaurant/v1/${marketId}/restaurants`, restaurant);
};

export const remove = (
  marketId: string,
  restaurantId: string
): AxiosPromise<RemoveResponse> => {
  const axios = API.axios();
  return axios.delete(`/restaurant/v1/${marketId}/restaurants/${restaurantId}`);
};

export const importRestaurants = (
  marketId: string,
  restaurants: Object
): AxiosPromise<ImportResponse> => {
  const axios = API.axios();
  return axios.post(`/restaurant/v1/import/${marketId}`, restaurants);
};

export const patchTimezone = (
  marketId: string,
  restaurantId: string,
  newTimeZone: string
): AxiosPromise<void> => {
  const axios = API.axios();
  return axios.patch(
    `/restaurant/v1/${marketId}/restaurants/${restaurantId}/timezone`,
    {
      timeZone: newTimeZone,
    }
  );
};

export const getRpmConfig = (marketId: string): AxiosPromise<RpmConfigData> => {
  const axios = API.axios();

  return axios.get(`/rpm/v1/config/${marketId}`);
};
