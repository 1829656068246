import type { ReducerFragment } from '../../types';
import type { AssetsState } from '../model/types';
import * as types from '../action/types';

const reduceGet: ReducerFragment<AssetsState> = {
  [types.GET_PENDING]: (state, action) => {
    return { ...state, statusGet: 'pending' };
  },

  [types.GET_FULFILLED]: (state, action) => {
    const asset = action.payload;
    return {
      ...state,
      statusGet: 'fulfilled',
      map: {
        ...state.map,
        [asset.id]: {
          ...state.map[state.map[asset.id]],
          ...asset,
        },
      },
    };
  },

  [types.GET_REJECTED]: (state, action) => {
    return { ...state, statusGet: 'rejected' };
  },
};

export default reduceGet;
