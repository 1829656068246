import type { RestaurantsConfigsState } from '../model/types';
import * as types from '../action/types';
import createReducer from '../../utility/createReducer';
import reduceGet from './get';
import reducePut from './put';
import reduceInfo from './info';

const initialState: RestaurantsConfigsState = {
  statusGet: undefined,
  statusPut: undefined,
  map: {},
  info: {},
};

export default createReducer(initialState, {
  'GLOBAL.RESET': () => initialState,
  [types.RESET]: () => initialState,
  ...reduceGet,
  ...reducePut,
  ...reduceInfo,
});
