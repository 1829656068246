/* eslint-disable react/style-prop-object */
import React from 'react';
import { SvgIcon, withStyles } from '@material-ui/core';

type Props = { classes: Object };

export default withStyles({
  root: {
    fontSize: '24px',
  },
})(({ classes, ...rest }: Props) => (
  <SvgIcon {...rest} classes={{ root: classes.root }} fontSize="small">
    <path
      fill="#868686"
      stroke="#868686"
      strokeLinejoin="round"
      strokeLinecap="butt"
      strokeMiterlimit="4"
      strokeWidth="1.3333"
      d="M5.333 2.667l2.667 1.333 2.667-1.333 2.667 1.333 2.667-1.333 2.667 1.333 2.667-1.333 2.667 1.333 2.667-1.333v26.667h-21.333v-26.667z"
    ></path>
    <path
      fill="#fff"
      d="M26 11.334c-1.761 0-3.189 7.164-3.189 15.999h-2.551c0-8.836-1.426-15.999-3.189-15.999-1.759 0-3.187 7.759-3.187 17.333h-2.551c0-10.31 2.569-18.667 5.738-18.667 1.804 0 3.414 2.512 4.464 6.44 1.053-3.928 2.661-6.44 4.465-6.44v1.334z"
    ></path>
  </SvgIcon>
));
