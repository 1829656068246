import type { MUITheme } from '../../../styles/types';

const styles = (theme: MUITheme) => ({
  text: {
    color: theme.palette.type === 'dark' ? '#8e8e8e' : '#444',
  },
});

export type Classes = $Call<typeof styles, MUITheme>;
export default styles;
