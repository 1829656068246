import type { ThunkAction } from '../../types';
import * as types from './types';
import api from '../api';
import listHelper from '../../utility/general/actionCreators/listHelper';

export const list =
  (options?: {
    getNextPage?: boolean,
    append?: boolean,
    invalidate?: boolean,
    debounce?: {
      key?: string,
      duration: number,
    },
    fetchAll?: boolean,
  }): ThunkAction =>
  (dispatch) => {
    const { invalidate, ...restOptions } = options || {};

    let cfg = {
      ...restOptions,
      fetch: {
        action: types.LIST,
        bindFetch: (subject, nextPageToken) => api.list(nextPageToken),
      },
      statePath: 'roles',
    };

    if (options) {
      if (options.invalidate) {
        cfg = {
          ...cfg,
          invalidate: {
            actionSet: types.SET_MAP_INVALIDATOR_ID,
            actionClear: types.UNSET_MAP_INVALIDATOR_ID,
          },
        };
      }
    }
    return dispatch(listHelper(cfg));
  };
