import mvpConfig from './mvp';
import devConfig from './dev';
import stgConfig from './stg';
import prdConfig from './prd';

export const ENV: 'dev' | 'stg' | 'prd' = (process.env.REACT_APP_ENV: any);

export default {
  mvp: mvpConfig,
  dev: devConfig,
  stg: stgConfig,
  prd: prdConfig,
}[ENV];
