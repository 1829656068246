import type { AxiosPromise } from 'axios';
import type { Asset, Page } from './model/types';
import getListParams, {
  type ListRequestOptions,
} from '../../utility/API/getListParams';
import API from '../../utility/API';

export const list = (
  marketId: string,
  options: ListRequestOptions
): AxiosPromise<Page> => {
  const axios = API.axios();
  return axios.get(`/asset/v1/markets/${marketId}/assets`, {
    params: getListParams(options),
  });
};

export const get = (
  marketId: string,
  assetId: number,
  options: ListRequestOptions
): AxiosPromise<Asset> => {
  const axios = API.axios();
  return axios.get(`/asset/v1/markets/${marketId}/assets/${assetId}`, {
    params: getListParams(options),
  });
};

export const deleteAsset = (
  marketId: string,
  assetId: number
): AxiosPromise<Asset> => {
  const axios = API.axios();
  return axios.delete(`/asset/v1/markets/${marketId}/assets/${assetId}`);
};

export const createUpload = (
  marketId: string,
  assetName: string,
  assetVersion: string,
  force: boolean
): AxiosPromise<Page> => {
  const asset = {
    name: assetName,
    current: assetVersion,
    force: force,
  };
  const axios = API.axios();
  return axios.post(`/asset/v1/markets/${marketId}/uploads`, asset);
};

export const uploadAsset = (
  uploadUrlPath: string,
  imageFile: any
): AxiosPromise<Page> => {
  const axios = API.axios('CUSTOM');
  return axios.put(uploadUrlPath, imageFile, {
    headers: { 'content-type': imageFile.type || 'application/octet-stream' },
  });
};

export const operationStatus = (name: string): AxiosPromise<any> => {
  const axios = API.axios();
  return axios.get(`/operation/v1/operations/${name}`);
};
