import type { MUITheme } from '../../../../../styles/types';
import buttonStyles from '../../../../../styles/button';
import { COLOR_GRAY_DARK } from '../../../../../styles/colors';

const styles = (theme: MUITheme) => ({
  ...buttonStyles(theme),

  icon: {
    color: COLOR_GRAY_DARK,
  },
});

export type Classes = $Call<typeof styles, MUITheme>;
export default styles;
