import React, { type Node } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  pageGutters: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
}));

type Props = {|
  className?: string,
  style?: Object,
  children: Node,
  disableGutters?: boolean,
|};

const GridRootContainer = (props: Props) => {
  const { children, style, className, disableGutters, ...rest } = props;
  const classes = useStyles();

  return (
    <div
      style={style}
      className={clsx(className, {
        [classes.pageGutters]: !disableGutters,
      })}
    >
      <Grid container spacing={3} {...rest}>
        {children}
      </Grid>
    </div>
  );
};

export default GridRootContainer;
