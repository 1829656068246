export const reduceMapToMapOfMarket = <T: { market: string }>(
  itemsById: {| [string]: T |},
  marketId: string
) => {
  const _itemsById = Object.keys(itemsById).reduce((accu, key) => {
    const item = itemsById[key];
    if (item?.market?.toLowerCase() === marketId) {
      accu[key] = item;
    }
    return accu;
  }, {});

  return _itemsById;
};

export const reduceMapToListOfMarket = <T: { market: string }>(
  itemsById: {| [string]: T |},
  marketId: string
) => {
  const items = Object.keys(itemsById).reduce((accu, key) => {
    const item = itemsById[key];
    if (item?.market?.toLowerCase() === marketId) {
      accu.push(item);
    }
    return accu;
  }, []);

  return items;
};
