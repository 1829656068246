import React from 'react';
import { Fade, Slide, Collapse } from '@material-ui/core';

const CollapseFadeSlide = (props: { in: boolean, children: any }) => (
  <Collapse in={props['in']}>
    <div>
      <Fade in={props['in']} timeout={300}>
        <div>
          <Slide in={props['in']} direction="right">
            <div>{props.children}</div>
          </Slide>
        </div>
      </Fade>
    </div>
  </Collapse>
);

export default CollapseFadeSlide;
