import type { Config } from '../model/types';

// GET

export const GET = 'ASSETS_CONFIG.GET';
export type GET_ACTION = {
  type: 'ASSETS_CONFIG.GET',
  payload: Promise<any>,
};

export const GET_PENDING = 'ASSETS_CONFIG.GET_PENDING';
export type GET_PENDING_ACTION = {
  type: 'ASSETS_CONFIG.GET_PENDING',
};

export const GET_FULFILLED = 'ASSETS_CONFIG.GET_FULFILLED';
export type GET_FULFILLED_ACTION = {
  type: 'ASSETS_CONFIG.GET_FULFILLED',
  payload: { data: Config },
};

export const GET_REJECTED = 'ASSETS_CONFIG.GET_REJECTED';
export type GET_REJECTED_ACTION = {
  type: 'ASSETS_CONFIG.GET_REJECTED',
};

export type Action =
  | GET_ACTION
  | GET_PENDING_ACTION
  | GET_FULFILLED_ACTION
  | GET_REJECTED_ACTION;
