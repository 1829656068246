import type { ReducerFragment } from '../../types';
import type { RestaurantsState } from '../model/types';
import * as types from '../action/types';

const reducePut: ReducerFragment<RestaurantsState> = {
  [types.PUT_PENDING]: (state: RestaurantsState) => {
    return { ...state, statusPut: 'pending' };
  },

  [types.PUT_FULFILLED]: (
    state: RestaurantsState,
    action: types.PUT_FULFILLED_ACTION
  ) => {
    return {
      ...state,
      map: { ...state.map, [action.payload.id]: action.payload },
      statusPut: 'fulfilled',
    };
  },

  [types.PUT_REJECTED]: (state: RestaurantsState) => {
    return { ...state, statusPut: 'rejected' };
  },
};

export default reducePut;
