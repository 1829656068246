import type { AssetsConfigState } from '../model/types';
import createReducer from '../../utility/createReducer';
import reduceGet from './get';

export const initialState: AssetsConfigState = {
  statusGet: undefined,
  config: {},
};

export const preloadedState: AssetsConfigState = {
  ...initialState,
};

export default createReducer(initialState, {
  ...reduceGet,
});
