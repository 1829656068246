import * as React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 4,
    textTransform: 'capitalize',
    '& > a': {
      color: theme.palette.typography.disabled,
      fontWeight: 400,
      textDecoration: 'none',
    },
    '& > a:hover': {
      textDecoration: 'underline',
    },
    '& > .highlighted': {
      color: theme.palette.secondary.light,
    },
  },
}));

type Props = {| children: React.Node |};

const StyledBreadcrumb = (props: Props) => {
  const typograpyProps = {
    variant: 'h1',
    classes: useStyles(),
    ...props,
  };
  return <Typography {...typograpyProps} />;
};

export default StyledBreadcrumb;
