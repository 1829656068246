import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import RestaurantsIcon from '@material-ui/icons/Place';
import ProductsIcon from '@material-ui/icons/Fastfood';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import MopIcon from 'components/common/__icons/mopIcon/MopIcon';
import LocalMarketingIcon from '@material-ui/icons/ConfirmationNumber';
import AccountsIcon from '@material-ui/icons/People';
import UrlBuilderIcon from '@material-ui/icons/Link';
import LabelIcon from '@material-ui/icons/Label';
import SecurityIcon from '@material-ui/icons/Security';
import SettingsIcon from '@material-ui/icons/Settings';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import { canView as _canView } from 'state/app/selectors';
import useIsLsmEnabled from 'hooks/useIsLsmEnabled/UseIsLsmEnabled';
import useIsAssetsEnabled from 'hooks/useIsAssetsEnabled/useIsAssetsEnabled';
import SkeletonMenuItem from './SkeletonMenuItem';
import { Divider } from '@material-ui/core';
import NavItem from './navItem/NavItem';
import useStyles from './styles';
import ExpandableNavItem from './navItem/ExpandableNavItem';

const NavMenu = () => {
  const classes = useStyles();
  const { selectedMarketId } = useParams();
  const canView = useSelector(_canView);
  const isLsmEnabled = useIsLsmEnabled(selectedMarketId);
  const isAssetsEnabled = useIsAssetsEnabled(selectedMarketId);

  const enabledFunctionality = useSelector(
    (state) => state.app.enabledFunctionality
  );

  const productSubRoutes = useCallback(
    () => [
      {
        title: 'Categories',
        linkTo: `/${selectedMarketId}/products/categories`,
      },
      {
        title: 'Allergens',
        linkTo: `/${selectedMarketId}/products/allergens`,
      },
      {
        title: 'Nutrients',
        linkTo: `/${selectedMarketId}/products/nutrients`,
      },
    ],
    [selectedMarketId]
  );

  const mopSubRoutes = useCallback(
    () => [
      {
        title: 'Category Images',
        linkTo: `/${selectedMarketId}/mop/category-images`,
      },
      {
        title: 'Product Images',
        linkTo: `/${selectedMarketId}/mop/product-images`,
      },
    ],
    [selectedMarketId]
  );

  const canViewRestaurant = canView('restaurants');
  const canViewProducts = canView('products');
  const canViewLocalMarketing = canView('localMarketing');
  const canViewMOP = canView('mop');
  const canViewUrlBuilder = canView('urlBuilder');
  const canViewTaggingTool = canView('taggingTool');
  const canViewRemoteConfig = canView('remoteConfig');
  const canViewCodeUploader = canView('codeUploader');
  const canViewSecurity = canView('security');
  const canViewAccounts = canView('accounts');
  const canViewSettings = canView('settings');

  const SkeletonMenuItems = useCallback(() => {
    return [...Array(6)].map((item, index) => (
      <SkeletonMenuItem key={`skeleton_menuitem_${index}`} />
    ));
  }, []);

  const MenuItems = useCallback(() => {
    return (
      (
        <ul className={classes.list}>
          <NavItem
            linkTo={`/${selectedMarketId}/home`}
            Icon={HomeIcon}
            title={'home'}
          />
          {canViewRestaurant && (
            <NavItem
              linkTo={`/${selectedMarketId}/restaurants`}
              Icon={RestaurantsIcon}
              title={'restaurants'}
            />
          )}
          {canViewProducts && (
            <ExpandableNavItem
              Icon={ProductsIcon}
              title="products"
              linkTo={`/${selectedMarketId}/products`}
              selectedMarketId={selectedMarketId}
              subroutes={productSubRoutes()}
            />
          )}
          {isAssetsEnabled && canViewMOP && (
            <ExpandableNavItem
              Icon={MopIcon}
              title="MOP"
              linkTo={`/${selectedMarketId}/mop`}
              subroutes={mopSubRoutes()}
            />
          )}
          {isLsmEnabled && canViewLocalMarketing && (
            <NavItem
              linkTo={`/${selectedMarketId}/local-marketing`}
              Icon={LocalMarketingIcon}
              title={'local-marketing'}
            />
          )}
          {canViewUrlBuilder && (
            <NavItem
              linkTo={`/${selectedMarketId}/url-builder`}
              Icon={UrlBuilderIcon}
              title={'url builder'}
            />
          )}
          {canViewTaggingTool && (
            <NavItem
              linkTo={`/${selectedMarketId}/tagging-tool`}
              Icon={LabelIcon}
              title={'tagging tool'}
            />
          )}
          {canViewRemoteConfig && (
            <NavItem
              linkTo={`/${selectedMarketId}/remote-config`}
              Icon={DeviceHubIcon}
              title={'remote config'}
            />
          )}
          {canViewCodeUploader && (
            <NavItem
              linkTo={`/${selectedMarketId}/code-uploader`}
              Icon={LocalOfferIcon}
              title={'code uploader'}
            />
          )}
          <Divider className={classes.divider} />
          {canViewSecurity && (
            <NavItem
              linkTo={`/${selectedMarketId}/security`}
              Icon={SecurityIcon}
              title={'security'}
            />
          )}
          {canViewAccounts && (
            <NavItem
              linkTo={`/${selectedMarketId}/accounts`}
              Icon={AccountsIcon}
              title={'accounts'}
            />
          )}
          {canViewSettings && (
            <NavItem
              linkTo={`/${selectedMarketId}/settings`}
              Icon={SettingsIcon}
              title={'settings'}
            />
          )}
        </ul>
      ) || null
    );
  }, [
    canViewAccounts,
    canViewCodeUploader,
    canViewLocalMarketing,
    canViewMOP,
    canViewProducts,
    canViewRemoteConfig,
    canViewRestaurant,
    canViewSecurity,
    canViewSettings,
    canViewTaggingTool,
    canViewUrlBuilder,
    classes.divider,
    classes.list,
    isAssetsEnabled,
    isLsmEnabled,
    mopSubRoutes,
    productSubRoutes,
    selectedMarketId,
  ]);

  return (
    <>
      {Object.keys(enabledFunctionality).length > 0 ? (
        <MenuItems />
      ) : (
        <SkeletonMenuItems />
      )}
    </>
  );
};

export default NavMenu;
