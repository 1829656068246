import type { AsyncStatus } from '../types';

export type RestaurantsPermission = {|
  id: string,
  restaurantId: string,
  member: {|
    id: string,
    type: string,
  |},
  role: 'Viewer' | 'Editor',
  market: string,
|};

export type Items = {|
  [accountId: string]: RestaurantsPermission,
|};

export type State = {|
  mapInvalidatorId: string,
  statusList: ?AsyncStatus,
  map: Items,
|};

// PUT

export const PUT = 'RESTAURANTS_PERMISSIONS.PUT';
export type PUT_ACTION = {
  type: 'RESTAURANTS_PERMISSIONS.PUT',
  payload: Promise<any>,
};

export const PUT_PENDING = 'RESTAURANTS_PERMISSIONS.PUT_PENDING';
export type PUT_PENDING_ACTION = {
  type: 'RESTAURANTS_PERMISSIONS.PUT_PENDING',
};

export const PUT_FULFILLED = 'RESTAURANTS_PERMISSIONS.PUT_FULFILLED';
export type PUT_FULFILLED_ACTION = {
  type: 'RESTAURANTS_PERMISSIONS.PUT_FULFILLED',
  payload: any,
};

export const PUT_REJECTED = 'RESTAURANTS_PERMISSIONS.PUT_REJECTED';
export type PUT_REJECTED_ACTION = {
  type: 'RESTAURANTS_PERMISSIONS.PUT_REJECTED',
};

type PUT_ACTIONS =
  | PUT_ACTION
  | PUT_PENDING_ACTION
  | PUT_FULFILLED_ACTION
  | PUT_REJECTED_ACTION;

// LIST

export const LIST = 'RESTAURANTS_PERMISSIONS.LIST';
export type LIST_ACTION = {
  type: 'RESTAURANTS_PERMISSIONS.LIST',
  payload: Promise<any>,
};

export const LIST_PENDING = 'RESTAURANTS_PERMISSIONS.LIST_PENDING';
export type LIST_PENDING_ACTION = {
  type: 'RESTAURANTS_PERMISSIONS.LIST_PENDING',
};

export const LIST_FULFILLED = 'RESTAURANTS_PERMISSIONS.LIST_FULFILLED';
export type LIST_FULFILLED_ACTION = {
  type: 'RESTAURANTS_PERMISSIONS.LIST_FULFILLED',
  payload: any,
};

export const LIST_REJECTED = 'RESTAURANTS_PERMISSIONS.LIST_REJECTED';
export type LIST_REJECTED_ACTION = {
  type: 'RESTAURANTS_PERMISSIONS.LIST_REJECTED',
};

// SET_MAP_INVALIDATE_ID

export const SET_MAP_INVALIDATOR_ID =
  'RESTAURANTS_PERMISSIONS.SET_MAP_INVALIDATE_ID';
export type SET_MAP_INVALIDATE_ID_ACTION = {
  type: 'RESTAURANTS_PERMISSIONS.SET_MAP_INVALIDATE_ID',
  payload: string,
};

// UNSET_MAP_INVALIDATE_ID

export const UNSET_MAP_INVALIDATOR_ID =
  'RESTAURANTS_PERMISSIONS.UNSET_MAP_INVALIDATE_ID';
export type UNSET_MAP_INVALIDATE_ID_ACTION = {
  type: 'RESTAURANTS_PERMISSIONS.UNSET_MAP_INVALIDATE_ID',
  payload: string,
};

type LIST_ACTIONS =
  | LIST_ACTION
  | LIST_PENDING_ACTION
  | LIST_FULFILLED_ACTION
  | LIST_REJECTED_ACTION
  | SET_MAP_INVALIDATE_ID_ACTION
  | UNSET_MAP_INVALIDATE_ID_ACTION;

//------------------------------------

export type Action = PUT_ACTIONS | LIST_ACTIONS;
