import type { Reducer } from 'redux';
import type { UserState } from 'state/user/types';
import type { UserAction } from 'state/user/types';
import { USER_INITIAL_STATE } from "state/user/initialState";
import { USER_ACTION_TYPES } from 'state/user/action/types';

export const userReducer: Reducer<UserState, UserAction> = (state = USER_INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GLOBAL.RESET':
      return {  ...USER_INITIAL_STATE, firebaseReady: state.firebaseReady };
    case USER_ACTION_TYPES.APP_READY:
      return { ...state, appReady: action.payload };
    case USER_ACTION_TYPES.FIREBASE_READY:
      return { ...state, firebaseReady: action.payload };
    case USER_ACTION_TYPES.FIREBASE_USER:
      return { ...state, firebaseUser: action.payload };
    case USER_ACTION_TYPES.FIREBASE_TOKEN:
      return { ...state, firebaseIdToken: action.payload };
    case USER_ACTION_TYPES.INVITATION_ACCEPTED:
      return { ...state, i: action.payload };
    case USER_ACTION_TYPES.MFA_ENROLLED:
      return { ...state, mfaEnrolled: action.payload };
    case USER_ACTION_TYPES.EMAIL:
      return { ...state, email: action.payload };
    case USER_ACTION_TYPES.INVITE_CODE:
      return { ...state, inviteCode: action.payload };
    case USER_ACTION_TYPES.PHONE_VERIFICATION_ID:
      return { ...state, phoneVerificationId: action.payload };
    case USER_ACTION_TYPES.MFA_ERROR:
      return { ...state, mfaError: action.payload };
    default:
      return state;
  }
};
