import type { UserState } from 'state/user/types';

export const USER_INITIAL_STATE: UserState = {
  appReady: false,
  firebaseReady: false,
  firebaseUser: null,
  firebaseIdToken: '',
  invitationAccepted: true,
  mfaEnrolled: true,
  email: '',
  inviteCode: '',
  phoneVerificationId: '',
  mfaError: null,
};
