import type { User, MultiFactorInfo } from "firebase/auth";
import { multiFactor, PhoneMultiFactorGenerator } from "firebase/auth";

export function userMfaPhoneGet(firebaseUser?: null | User): string {
  if (firebaseUser) {
    const phoneFactor = multiFactor(firebaseUser)
      .enrolledFactors
      .find((item) => item.factorId === PhoneMultiFactorGenerator.FACTOR_ID);

    if (phoneFactor && 'phoneNumber' in phoneFactor) {
      return (phoneFactor as MultiFactorInfo & { phoneNumber?: string })
        .phoneNumber ?? '';
    }
  }
  return '';
}
