import type { Alert, ConsumerTagWarning, Tabs, Permission } from '../types';

// SET_ALERT

export const SET_ALERT: 'APP.SET_ALERT' = 'APP.SET_ALERT';
export type SET_ALERT_ACTION = {
  type: typeof SET_ALERT,
  payload: ?Alert,
};

export const SET_CONSUMERTAG_WARNING: 'APP.SET_CONSUMERTAG_WARNING' =
  'APP.SET_CONSUMERTAG_WARNING';
export type SET_CONSUMERTAG_WARNING_ACTION = {
  type: typeof SET_ALERT,
  payload: ?ConsumerTagWarning,
};

// SET_TABS

export const SET_TABS: 'APP.SET_TABS' = 'APP.SET_TABS';
export type SET_TABS_ACTION = {
  type: typeof SET_TABS,
  payload: Tabs,
};

// SET_SELECTED_TAB_IDX

export const SET_SELECTED_TAB_IDX: 'APP.SET_SELECTED_TAB_IDX' =
  'APP.SET_SELECTED_TAB_IDX';
export type SET_SELECTED_TAB_IDX_ACTION = {
  type: typeof SET_SELECTED_TAB_IDX,
  payload: number | false,
};

// SET_CURRENT_PAGE

export const SET_CURRENT_PAGE: 'APP.SET_CURRENT_PAGE' = 'APP.SET_CURRENT_PAGE';
export type SET_CURRENT_PAGE_ACTION = {
  type: typeof SET_CURRENT_PAGE,
  payload: number,
};

// RESET_ROUTES

export const RESET_ROUTES: 'APP.RESET_ROUTES' = 'APP.RESET_ROUTES';
export type RESET_ROUTES_ACTION = {
  type: typeof RESET_ROUTES,
};

// SET_ENABLED_FUNCTIONALITY

export const SET_ENABLED_FUNCTIONALITY: 'APP.SET_ENABLED_FUNCTIONALITY' =
  'APP.SET_ENABLED_FUNCTIONALITY';
export type SET_ENABLED_FUNCTIONALITY_ACTION = {
  type: typeof SET_ENABLED_FUNCTIONALITY,
  payload: Permission,
};

//------------------------------------

export type Action =
  | SET_ALERT_ACTION
  | SET_TABS_ACTION
  | SET_SELECTED_TAB_IDX_ACTION
  | RESET_ROUTES_ACTION
  | SET_ENABLED_FUNCTIONALITY_ACTION;
