/**
 * Generates shorthand utility classes for spacing,
 * like those of Bootstrap 4: https://getbootstrap.com/docs/4.1/utilities/spacing/
 * Example of generated classes:
 * .m-0: Forces no margins
 * .mx-1: Forces small margins on left and right sides
 * .pt-4: Forces large margin at top
 */
export default (spacer: number) => {
  let helpers = {};

  [
    { name: 'm', prop: 'margin' },
    { name: 'p', prop: 'padding' },
  ].forEach((type) =>
    [
      { name: '', prop: 'MULTIPLE' },
      { name: 'x', prop: 'MULTIPLE' },
      { name: 'y', prop: 'MULTIPLE' },
      { name: 't', prop: 'top' },
      { name: 'r', prop: 'right' },
      { name: 'b', prop: 'bottom' },
      { name: 'l', prop: 'left' },
    ].forEach((side) =>
      [
        { name: '0', value: 0 },
        { name: '1', value: spacer },
        { name: '2', value: spacer * 2 },
        { name: '3', value: spacer * 3 },
        { name: '4', value: spacer * 4 },
        { name: '5', value: spacer * 5 },
      ].forEach((amount) => {
        if (side.prop === 'MULTIPLE') {
          switch (side.name) {
            case '':
              helpers[`.${type.name}${side.name}-${amount.name}`] = {
                [`${type.prop}`]: `${amount.value}px !important`,
              };
              break;
            case 'x':
              helpers[`.${type.name}${side.name}-${amount.name}`] = {
                [`${type.prop}-left`]: `${amount.value}px !important`,
                [`${type.prop}-right`]: `${amount.value}px !important`,
              };
              break;
            case 'y':
              helpers[`.${type.name}${side.name}-${amount.name}`] = {
                [`${type.prop}-top`]: `${amount.value}px !important`,
                [`${type.prop}-bottom`]: `${amount.value}px !important`,
              };
              break;
            default:
              console.error('Couldnt generate helper for side: ' + side.name);
          }
        } else {
          helpers[`.${type.name}${side.name}-${amount.name}`] = {
            [`${type.prop}-${side.prop}`]: `${amount.value}px !important`,
          };
        }
      })
    )
  );

  return helpers;
};
