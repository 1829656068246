import type { ReducerFragment } from '../../types';
import type { TranslationsState } from '../types';
import update from 'immutability-helper';
import { arrayToObject } from '../../../utility/utility';

const reduce: ReducerFragment<TranslationsState> = {
  'TRANSLATIONS.GET_PENDING': (state, action) => {
    const { meta } = action;

    // Autovivification, since the objects might not exist
    return update(state, {
      [meta.bucket]: (bucket) =>
        update(bucket || {}, {
          [meta.market]: (market) =>
            update(market || {}, {
              [meta.locale]: (locale) =>
                update(locale || { map: undefined }, {
                  getStatus: { $set: 'pending' },
                }),
            }),
        }),
    });
  },

  'TRANSLATIONS.GET_FULFILLED': (state, action) => {
    const { payload, meta } = action;
    const map = arrayToObject(payload.data.items, 'key');
    return update(state, {
      [meta.bucket]: {
        [meta.market]: {
          [meta.locale]: {
            getStatus: { $set: 'fulfilled' },
            map: {
              $set: map,
            },
          },
        },
      },
    });
  },

  'TRANSLATIONS.GET_REJECTED': (state, action) => {
    const { meta } = action;
    return update(state, {
      [meta.bucket]: {
        [meta.market]: {
          [meta.locale]: {
            getStatus: { $set: 'rejected' },
          },
        },
      },
    });
  },
};

export default reduce;
