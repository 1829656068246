import API from 'utility/API';
import apiGetListParams from 'utility/API/getListParams';

/**
 * @typedef {import('axios').AxiosResponse} Response
 * @typedef {import('utility/API/types.d.ts').APIListParams} ListParams
 * @typedef {import('state/accounts/model/types.d.ts').AccountsList} AccountsList
 * @typedef {import('state/accounts/model/types.d.ts').AccountsItem} AccountsItem
 */

/**
 * @param {string} id
 * @return {Promise<Response<AccountsItem, unknown>>}
 * */
export function get(id) {
  const axios = API.axios();

  return axios.get(`/am/v1/users/${id}`);
}

/**
 * @param {AccountsItem} account
 * @return {Promise<Response<AccountsItem>>}
 * */
export function put(account) {
  const axios = API.axios();

  return axios.put(`/am/v1/users/${account.id}`, account);
}

/**
 * @param {string} id
 * @return {Promise<Response<boolean>>}
 * */
export function remove(id) {
  const axios = API.axios();

  return axios.delete(`/am/v1/users/${id}`);
}

/**
 * @param {ListParams} options
 * @return {Promise<Response<AccountsList>>}
 * */
export async function list(options) {
  const axios = API.axios();
  try {
    const response = await axios.get('/am/v1/users', {
      params: apiGetListParams(options),
    });

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err.toJSON());
  }
}
