import type { ReducerFragment } from '../../types';
import type { AssetsState } from '../model/types';
import * as types from '../action/types';

const reduceAppend: ReducerFragment<AssetsState> = {
  [types.APPEND_ASSET]: (state, action) => {
    const asset = action.payload;
    return {
      ...state,
      map: {
        ...state.map,
        [asset.id]: {
          ...state.map[state.map[asset.id]],
          ...asset,
        },
      },
    };
  },
};

export default reduceAppend;
