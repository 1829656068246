import type { MUITheme } from '../../styles/types';

const styles = (theme: MUITheme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },

  pageRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export type Classes = $Call<typeof styles, MUITheme>;
export default styles;
