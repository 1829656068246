import type { State as _State, Action as _Action } from './types';
import update from 'immutability-helper';
import _get from 'lodash/get';
import initialState from './initialState';
import createReducer from '../utility/createReducer';
import * as types from './types';
import { arrayToObject } from '../../utility/utility';

export type State = _State;

export type Action = _Action;

export const reducer = createReducer(initialState, {
  'GLOBAL.RESET': () => initialState,
  [types.LIST_STATUSES_FULFILLED]: (state, action) => {
    const nutritionItemsById = arrayToObject(
      action.payload.data.items || [],
      'id'
    );
    const nextPageToken = action.payload.data.nextPageToken;

    let stateUpdate = { nextPageToken: { $set: nextPageToken } };

    if (_get(action, 'meta.append')) {
      stateUpdate = {
        ...stateUpdate,
        map: { $merge: nutritionItemsById },
      };
    } else {
      stateUpdate = {
        ...stateUpdate,
        map: { $set: nutritionItemsById },
      };
    }

    if ('PRISTINE' === state.mapInvalidatorId) {
      stateUpdate = { ...stateUpdate, mapInvalidatorId: { $set: undefined } };
    }

    return update(state, stateUpdate);
  },
  [types.LIST_STATUSES_PENDING]: (state, action) => {
    return { ...state, statusList: 'pending' };
  },
  [types.LIST_STATUSES_FULFILLED]: (state, action) => {
    return { ...state, statusList: 'fulfilled' };
  },
  [types.LIST_STATUSES_REJECTED]: (state, action) => {
    return { ...state, statusList: 'rejected' };
  },
});
