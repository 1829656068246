import { useSelector } from 'react-redux';
import { isSpecificRole } from 'state/roleMembers/model/selectors';
import useGetLsmConfig from '../useGetLsmConfig/UseGetLsmConfig';
import { getIsLsmEnabled } from '../../utility/lsmBundles';
import { ACCOUNTROLES } from "state/roles/helpers";

/*
  LSM should be visible:
    1. to Market Managers, Franchisee and Support team IF LSM is enabled in config call (has a merchantID)
    2. should always show up to Product Team Members, so that they can access settings
*/

const UseIsLsmEnabled = (marketId: ?string): boolean => {
  const lsmConfig = useGetLsmConfig(marketId);
  const userIsProductTeam = useSelector((state) =>
    isSpecificRole(state, ACCOUNTROLES.PRODUCT_TEAM)
  );
  const userIsAdmin = useSelector((state) => isSpecificRole(state, ACCOUNTROLES.ADMIN));

  // if user is not Product Team / admin, we should return only true if there is an ID and merchantId
  if (userIsProductTeam || userIsAdmin) {
    return true;
  }

  return getIsLsmEnabled(lsmConfig);
};

export default UseIsLsmEnabled;
