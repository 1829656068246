import type { ReducerFragment } from '../../types';
import type { RestaurantsState } from '../model/types';
import * as types from '../action/types';

const reducePost: ReducerFragment<RestaurantsState> = {
  [types.POST_PENDING]: (state: RestaurantsState) => {
    return { ...state, statusPost: 'pending' };
  },

  [types.POST_FULFILLED]: (
    state: RestaurantsState,
    action: types.POST_FULFILLED_ACTION
  ) => {
    return {
      ...state,
      map: { ...state.map, [action.payload.id]: action.payload },
      statusPost: 'fulfilled',
    };
  },

  [types.POST_REJECTED]: (state: RestaurantsState) => {
    return { ...state, statusPost: 'rejected' };
  },
};

export default reducePost;
