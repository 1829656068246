export default {
  id: 'dev',
  firebaseConfig: {
    apiKey: 'AIzaSyAh6TRJNMVt8JWEYXneNDf2jDK8RDiVjZw',
    authDomain: 'dev-marketengine-mcdonalds.firebaseapp.com',
    projectId: 'dev-marketengine-mcdonalds',
    storageBucket: 'dev-marketengine-mcdonalds.appspot.com',
  },
  apiConfig: {
    baseURL: 'https://dev-marketengine-mcdonalds.appspot.com/_ah/api',
    params: {
      key: 'AIzaSyAZlIKdpC9Oi3lBZ6_RM7Gr5_ywVaPOtL0',
    },
  },
  me3Url: 'https://dev-next.marketengine.app',
};
