import { BORDER_TABLE } from '../borders';

export default {
  ' .border-table': {
    border: BORDER_TABLE + ' !important',
  },
  ' .border-bottom-table': {
    borderBottom: BORDER_TABLE + ' !important',
  },
};
