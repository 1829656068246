import type { ReducerFragment } from '../../types';
import type { InvitationsState } from '../model/types';
import * as types from '../action/types';

const reduce: ReducerFragment<InvitationsState> = {
  [types.CREATE_TOKEN_PENDING]: (state, action) => ({
    ...state,
    createTokenStatus: 'pending',
  }),

  [types.CREATE_TOKEN_FULFILLED]: (state, action) => {
    return {
      ...state,
      createTokenStatus: 'fulfilled',
    };
  },

  [types.CREATE_TOKEN_REJECTED]: (state, action) => ({
    ...state,
    createTokenStatus: 'rejected',
  }),
};

export default reduce;
