import type { ReducerFragment } from '../../types';
import type { RestaurantsState } from '../model/types';
import * as types from '../action/types';

const reduceGet: ReducerFragment<RestaurantsState> = {
  [types.GET_PENDING]: (state: RestaurantsState) => {
    return { ...state, statusGet: 'pending' };
  },

  [types.GET_FULFILLED]: (
    state: RestaurantsState,
    action: types.GET_FULFILLED_ACTION
  ) => {
    const restaurant = action.payload;
    return {
      ...state,
      map: { ...state.map, [restaurant.id]: restaurant },
      statusGet: 'fulfilled',
    };
  },

  [types.GET_REJECTED]: (state: RestaurantsState) => {
    return { ...state, statusGet: 'rejected' };
  },
};

export default reduceGet;
