import type { ReducerFragment } from '../../types';
import type { MarketsState } from '../model/types';
import * as types from '../action/types';

const reduceGet: ReducerFragment<MarketsState> = {
  [types.GET_PENDING]: (state, action) => ({
    ...state,
    statusGet: 'pending',
  }),

  [types.GET_FULFILLED]: (state, action) => {
    const market = action.payload;
    return {
      ...state,
      map: {
        ...state.map,
        [market.marketCode]: {
          ...state.map[market.marketCode],
          ...market,
        },
      },
      statusGet: 'fulfilled',
    };
  },

  [types.GET_REJECTED]: (state, action) => ({
    ...state,
    statusGet: 'rejected',
  }),
};

export default reduceGet;
