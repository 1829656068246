import update from 'immutability-helper';

const reduceSetIsSavingItemEditedData = (state: any, action: any) => {
  const isSaving = action.payload;
  const itemId = action.meta.subjectId;
  return update(state, {
    items: {
      // Create item state if it doesn't exist yet
      [itemId]: (item) =>
        update(item || {}, {
          isSaving: { $set: isSaving },
        }),
    },
  });
};

export default reduceSetIsSavingItemEditedData;
