import update from 'immutability-helper';

const reduceSetItemEditedData = (state: any, action: any) => {
  const data = action.payload;
  const itemId = action.meta.subjectId;
  return update(state, {
    items: {
      // Create item state if it doesn't exist yet
      [itemId]: (item) =>
        update(item || {}, {
          editedData: { $set: data },
        }),
    },
  });
};

export default reduceSetItemEditedData;
