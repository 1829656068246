import { List, ListItem } from '@material-ui/core';
import NavMenu from './navMenu/NavMenu';

const AppDrawerContents = (props: Props) => {
  const { onClose } = props;

  return (
    <List component="nav">
      <ListItem />
      <NavMenu onClose={onClose} />
    </List>
  );
};

export default AppDrawerContents;
