const Tooltip = {
  MuiTooltip: {
    popper: {
      opacity: 1,
    },
    tooltip: {
      fontSize: '12px',
      lineHeight: '14px',
      backgroundColor: '#444',
      padding: 7,
    },
    tooltipPlacementBottom: {
      margin: '11px 0',
    },
  },
};

export default Tooltip;
