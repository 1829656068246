import type { RestaurantsState } from '../model/types';
import type { SET_RPM_MODE_ACTION } from "../action/types";
import * as types from '../action/types';
import createReducer from '../../utility/createReducer';
import reduceGet from './get';
import reducePut from './put';
import reducePost from './post';
import reduceRemove from './remove';
import reduceMap from './map';
import { OrderDirection } from 'utility/types';

export const orderDirection = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

export const initialState: RestaurantsState = {
  statusGet: undefined,
  statusPut: undefined,
  statusPost: undefined,
  statusRemove: undefined,
  statusList: undefined,
  nextPageToken: undefined,
  map: {},
  mapInvalidatorId: 'PRISTINE',
  pendingListRequests: [],
  sortBy: undefined,
  sortOrder: orderDirection.ASCENDING as OrderDirection,
  searchQuery: null,
  displayedLocale: undefined,
  rpmMode: {},
};

export const preloadedState: RestaurantsState = {
  ...initialState,
};

type State = typeof initialState;

export default createReducer(initialState, {
  'GLOBAL.RESET': () => initialState,
  [types.RESET]: () => initialState,
  ...reduceGet,
  ...reducePut,
  ...reducePost,
  ...reduceRemove,
  ...reduceMap,
  [types.SET_SORT_BY]: (state: State, action: { payload: string }) => ({
    ...state,
    sortBy: action.payload,
  }),
  [types.SET_SORT_ORDER]: (state: State, action: { payload: string }) => ({
    ...state,
    sortOrder: action.payload,
  }),
  [types.SET_SEARCH_QUERY]: (state: State, action: { payload: string }) => ({
    ...state,
    searchQuery: action.payload,
  }),

  [types.SET_DISPLAYED_LOCALE]: (state: State, action: { payload: string }) => {
    let next = { ...state, displayedLocale: action.payload };

    if (state.displayedLocale !== action.payload) {
      next = { ...next, mapInvalidatorId: 'PRISTINE' };
    }
    return next;
  },
  [types.SET_RPM_MODE]: (state: State, action: SET_RPM_MODE_ACTION) => {
    return {
      ...state,
      rpmMode: {
        ...state.rpmMode,
        [action.payload.market]: action.payload.mode,
      },
    };
  }
});
