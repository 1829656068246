import type { APIListParams, ApiListPayload } from './types.js';

function getListParams(params: APIListParams | undefined): ApiListPayload {
  const {
    nextPageToken,
    orderBy,
    sortOrder,
    searchQuery,
    pageSize,
    prefix,
    onlyCurrent,
    limit,
    sortDirection,
    sortField,
  } = params ?? {};
  const payload = { ...(params?.params ?? {}) };

  if (nextPageToken) {
    if (prefix) {
      payload.pageToken = nextPageToken;
    } else {
      payload.next = nextPageToken;
    }
  }
  if (orderBy) {
    payload.sortField = orderBy;
  }
  if (sortOrder) {
    payload.sortDirection = sortOrder;
  }
  if (searchQuery) {
    payload.query = searchQuery;
  }
  if (pageSize) {
    payload.pageSize = pageSize;
  }
  if (prefix) {
    payload.prefix = prefix;
  }
  if (onlyCurrent) {
    payload.onlyCurrent = true;
  }
  if (limit) {
    payload.limit = limit;
  }
  if (sortDirection) {
    payload.sortDirection = sortDirection;
  }
  if (sortField) {
    payload.sortField = sortField;
  }

  return payload;
}

export default getListParams;
