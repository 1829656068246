import { makeStyles } from '@material-ui/core/styles';
import { TEXT_BUTTON } from 'styles/textStyles';

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.palette.paper.main,
    transition: 'background-color 0.2s',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.paper.hover,
    },
    '&:hover > a': {
      textDecoration: 'none',
    },
  },
  link: {
    display: 'block',
    width: '100%',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  content: {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '11px 16px 11px 24px',
    height: '46px',
  },
  subNavListItem: {
    justifyContent: 'center',

    '& > span': {
      marginLeft: '16px',
    },
  },
  subNavText: {
    marginLeft: '16px',
  },
  icon: {
    minWidth: '24px',
    margin: '0 24px 0 8px',
    color: theme.palette.typography.disabled,
  },
  text: {
    ...TEXT_BUTTON,
    color: theme.palette.typography.disabled,
  },
  isActive: {
    color: `${theme.palette.typography.primary} !important`,
  },
  expandIcon: {
    transform: 'rotate(-90deg)',
    transition: 'transform 0.3s linear',
    color: theme.palette.typography.disabled,
    position: 'absolute',
    top: '25%',
    left: '4px',
  },
  isExpanded: {
    transform: 'rotate(0deg)',
    color: theme.palette.typography.primary,
  },
  list: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
}));

export default useStyles;
