import {
  COLOR_SUCCESS,
  COLOR_GRAY_DARK,
  COLOR_GRAY_BORDER,
} from '../../colors';

const Switch = (theme: string) => ({
  MuiSwitch: {
    switchBase: {
      color: COLOR_GRAY_BORDER,

      '&.MuiSwitch-colorSecondary&$checked + .MuiSwitch-track': {
        backgroundColor: COLOR_SUCCESS,
      },
      '&.MuiSwitch-colorSecondary&$checked .MuiSwitch-thumb': {
        backgroundColor: COLOR_SUCCESS,
      },
      '&.MuiSwitch-colorSecondary + .MuiSwitch-track': {
        backgroundColor: COLOR_GRAY_DARK,
      },
      '&.MuiSwitch-colorSecondary .MuiSwitch-thumb': {
        backgroundColor: COLOR_GRAY_DARK,
      },
      '&.MuiSwitch-colorSecondary.Mui-disabled&$checked + .MuiSwitch-track': {
        backgroundColor: COLOR_GRAY_DARK,
      },
    },
  },
});

export default Switch;
