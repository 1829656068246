const Dialog = {
  MuiDialog: {
    paper: {
      borderRadius: 8,
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.4)',
    },
  },
};

export default Dialog;
