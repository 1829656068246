import type { AssetsState } from '../model/types';
import * as types from '../action/types';
import createReducer from '../../utility/createReducer';
import reduceList from './list';
import reduceGet from './get';
import reducePut from './put';
import reducePost from './post';
import reduceAppend from './append';
import reduceDeleteAsset from './deleteAsset';

export const initialState: AssetsState = {
  statusGet: undefined,
  statusPut: undefined,
  statusPost: undefined,
  statusRemove: undefined,
  statusList: undefined,
  nextPageToken: undefined,
  map: {},
};

export const preloadedState: AssetsState = {
  ...initialState,
};

export default createReducer(initialState, {
  [types.RESET]: () => initialState,
  ...reduceList,
  ...reduceGet,
  ...reducePut,
  ...reducePost,
  ...reduceAppend,
  ...reduceDeleteAsset,
});
