import type { InvitationsState } from '../model/types';
import createReducer from '../../utility/createReducer';
import reduceCreateToken from './createToken';

const initialState: InvitationsState = {
  list: {},
  acceptInvitationStatus: undefined,
  listIsValid: false,
  nextPageToken: undefined,
};

export default createReducer(initialState, {
  'GLOBAL.RESET': () => initialState,
  ...reduceCreateToken,
});
