import { type RootState } from '../';
import { createSelector } from 'reselect';
import { getSelectedMarketId } from '../markets/model/selectors';
import { reduceMapToMapOfMarket } from '../utility/general/selectors/combiners';

export const selectItemsById = (state: RootState) => state.lsmBundles.map;

export const selectItemsByIdOfSelectedMarket = createSelector(
  selectItemsById,
  getSelectedMarketId,
  reduceMapToMapOfMarket
);

export const selectMapInvalidatorId = (state: RootState) =>
  state.lsmBundles.mapInvalidatorId;

export const selectIsListInvalid = createSelector(
  selectMapInvalidatorId,
  (mapInvalidatorId) => !!mapInvalidatorId
);

// Item

export const selectItem = (state: RootState, itemId: string) =>
  state.lsmBundles.map[itemId];
