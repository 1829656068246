import type { ThunkAction } from '../../types';
import type { Alert, ConsumerTagWarning, Tabs } from '../types';
import * as types from './types';
import {
  listUserAccountRoleMembers,
  includesRole,
} from '../../roleMembers/model/selectors';
import {
  PERMISSION_URL_BUILDER,
  PERMISSION_MARKET_TAG_MANAGER,
  PERMISSION_MARKET_CODE_IMPORT_MANAGER,
  PERMISSION_FRANCHISEE_MANAGER,
  PERMISSION_MARKET_LOCATION_MANAGER,
  PERMISSION_MARKET_NUTRITION_MANAGER,
  PERMISSION_MARKET_MANAGER,
  PERMISSION_SUPPORT_TEAM,
  PERMISSION_PRODUCT_TEAM,
  mergePermissions,
} from '../model';
import { ACCOUNTROLES } from "state/roles/helpers";

export const setAlert = (options: Alert) => ({
  type: types.SET_ALERT,
  payload: options,
});

export const unsetAlert = () => ({
  type: types.SET_ALERT,
  payload: undefined,
});

export const setConsumerTagWarning = (options: ConsumerTagWarning) => ({
  type: types.SET_CONSUMERTAG_WARNING,
  payload: options,
});

export const unsetConsumerTagWarning = () => ({
  type: types.SET_CONSUMERTAG_WARNING,
  payload: undefined,
});

export const setTabs = (tabs: Tabs) => ({
  type: types.SET_TABS,
  payload: tabs,
});

export const setSelectedTab =
  (tabName?: string): ThunkAction =>
    (dispatch, getState) => {
      if (tabName) {
        const {
          app: { tabs },
        } = getState();

        let selectedTabIdx;

        tabs.forEach((tab, idx) => {
          if (tab.name.toLowerCase() === tabName.toLowerCase()) {
            selectedTabIdx = idx;
          }
        });

        if (selectedTabIdx !== undefined) {
          dispatch({
            type: types.SET_SELECTED_TAB_IDX,
            payload: selectedTabIdx,
          });
        } else {
          console.error(
            'Failed to select tab: ' +
            tabName +
            ". Reason: Tab with that name doesn't exist."
          );
        }
      } else {
        dispatch({
          type: types.SET_SELECTED_TAB_IDX,
          payload: false,
        });
      }
    };

export const setCurrentPage = (pageNumber: number) => ({
  type: types.SET_CURRENT_PAGE,
  payload: pageNumber,
});

export const initEnabledFunctionality =
  (): ThunkAction => (dispatch, getState) => {
    const roleMembers = listUserAccountRoleMembers(getState());
    let permissions = [];
    if (includesRole(roleMembers, ACCOUNTROLES.DEFAULT)) {
      permissions.push(PERMISSION_URL_BUILDER);
    }

    if (includesRole(roleMembers, ACCOUNTROLES.MARKET_TAG_MANAGER)) {
      permissions.push(PERMISSION_MARKET_TAG_MANAGER);
    }

    if (includesRole(roleMembers, ACCOUNTROLES.MARKET_CODE_IMPORT_MANAGER)) {
      permissions.push(PERMISSION_MARKET_CODE_IMPORT_MANAGER);
    }

    if (
      includesRole(roleMembers, ACCOUNTROLES.ADMIN) ||
      includesRole(roleMembers, ACCOUNTROLES.PRODUCT_TEAM)
    ) {
      permissions.push(PERMISSION_PRODUCT_TEAM);
    }
    if (includesRole(roleMembers, ACCOUNTROLES.SUPPORT_TEAM)) {
      permissions.push(PERMISSION_SUPPORT_TEAM);
    }
    if (includesRole(roleMembers, ACCOUNTROLES.MARKET_MANAGER)) {
      permissions.push(PERMISSION_MARKET_MANAGER);
    }
    if (includesRole(roleMembers, ACCOUNTROLES.MARKET_LOCATION_MANAGER)) {
      permissions.push(PERMISSION_MARKET_LOCATION_MANAGER);
    }
    if (includesRole(roleMembers, ACCOUNTROLES.MARKET_NUTRITION_MANAGER)) {
      permissions.push(PERMISSION_MARKET_NUTRITION_MANAGER);
    }
    if (includesRole(roleMembers, ACCOUNTROLES.MARKET_FRANCHISEE_MANAGER)) {
      permissions.push(PERMISSION_FRANCHISEE_MANAGER);
    }
    const mergedPermission = mergePermissions(...permissions);

    return dispatch({
      type: types.SET_ENABLED_FUNCTIONALITY,
      payload: mergedPermission,
    });
  };
