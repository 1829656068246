/**
 * Creates the classes:
 * d-block, d-inline-block, d-inline, d-grid, d-flex
 */
export default {
  ...['block', 'inline-block', 'inline', 'grid', 'flex'].reduce(
    (classes, attribute) => {
      classes[`.d-${attribute}`] = {
        display: `${attribute} !important`,
      };
      return classes;
    },
    {}
  ),
};
