import { snakeCaseToCamelCase } from './utility';

const convertPathToPermission = (pathname: string) => {
  const segments = pathname
    .split('/')
    .filter((seg) => !!seg)
    .slice(1);
  if (segments && segments[0]) {
    const segment0 = snakeCaseToCamelCase(segments[0]);
    const segment0Follow = segments.slice(1).join('.');
    const path = segment0 + (!!segment0Follow ? '.' + segment0Follow : '');
    return path;
  }
  return '';
};

export default convertPathToPermission;
