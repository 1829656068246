import update from 'immutability-helper';
import * as types from './types';

import initialState from './initialState';
import createReducer from '../utility/createReducer';
import reduceListFulfilled from '../utility/general/reducers/reduceListFulfilled';

export type State = types.State;
export type Action = types.Action;

export const reducer = createReducer(initialState, {
  'GLOBAL.RESET': () => initialState,
  [types.RESET]: () => initialState,
  [types.PUT_FULFILLED]: (state, action) => {
    return update(state, {
      map: { [action.payload.data.id]: { $set: action.payload.data } },
    });
  },
  [types.GET_PENDING]: (state, action) => {
    return update(state, {
      statusGet: { $set: 'pending' },
    });
  },
  [types.GET_FULFILLED]: (state, action) => {
    const item = action.payload.data;
    const itemId = action.meta.subjectId;
    return update(state, {
      statusGet: { $set: 'fulfilled' },
      map: {
        [itemId]: { $set: item },
      },
    });
  },
  [types.LIST_PENDING]: (state, action) => {
    return { ...state, statusList: 'pending' };
  },
  [types.LIST_FULFILLED]: reduceListFulfilled(),
  [types.LIST_REJECTED]: (state, action) => {
    return { ...state, statusList: 'rejected' };
  },
  [types.RESET]: (state: State) => {
    return { ...state, map: {}, mapInvalidatorId: 'PRISTINE' };
  },
});
