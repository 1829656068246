/* eslint import/no-anonymous-default-export: [2, {"allowArray": true}] */

// Source (modified): https://meta.wikimedia.org/wiki/RTL#Wikipedias_using_RTL
export default [
  'ar',
  'syr',
  'arz',
  'azb',
  'ckb',
  'dv',
  'fa',
  'glk',
  'ha',
  'he',
  'lad',
  'mzn',
  'pa',
  'ps',
  'sd',
  'ug',
  'ur',
  'yi',
];
