/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
import update from 'immutability-helper';
import _get from 'lodash/get';

export default <T>(createItem: (string) => T) =>
  (state: any, action: any) => {
    const response = _get(action, 'payload.response');
    const itemId = action.meta.subjectId;
    if (response?.data?.error?.code + '' === '404') {
      let item = createItem(action.meta.subjectId);
      return update(state, {
        items: {
          [itemId]: {
            statusGet: { $set: 'rejected' },
            data: { $set: item },
          },
        },
      });
    } else {
      return update(state, {
        items: {
          [itemId]: {
            statusGet: { $set: 'rejected' },
          },
        },
      });
    }
  };
