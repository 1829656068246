import type { ReducerFragment } from '../../types';
import type { RestaurantsConfigsState } from '../model/types';
import _get from 'lodash/get';
import * as types from '../action/types';
import { createRestaurantsConfig } from '../model/creators';

const fragment: ReducerFragment<RestaurantsConfigsState> = {
  [types.GET_PENDING]: (state, action) => ({
    ...state,
    statusGet: 'pending',
  }),

  [types.GET_FULFILLED]: (state, action) => {
    const restaurantsConfig = action.payload.data;
    const marketCode = action.meta.subjectId;

    return {
      ...state,
      map: {
        ...state.map,
        [marketCode]: restaurantsConfig,
      },
      statusGet: 'fulfilled',
    };
  },

  [types.GET_REJECTED]: (state, action) => {
    const response = _get(action, 'payload.response');
    if (response) {
      const marketCode = action.meta.subjectId;

      let config;
      if (response.data.error.code + '' === '401') {
        return state;
      }
      if (response.data.error.code + '' === '404') {
        config = createRestaurantsConfig(action.meta.subjectId);
      }
      return {
        ...state,
        statusGet: 'rejected',
        map: {
          [marketCode]: config,
        },
      };
    } else {
      return {
        ...state,
        statusGet: 'rejected',
      };
    }
  },
};

export default fragment;
