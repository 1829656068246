import type { LocationDescriptorObject } from 'history';

type To = string | LocationDescriptorObject;

export function redirectsGetToFromSearch(fallback?: To): To {
  const currentSearch = new URLSearchParams(window.location.search);
  const redirect = currentSearch.get('redirect');
  currentSearch.delete('redirect');
  const nextSearch = currentSearch.toString();

  if (redirect) {
    return {
      pathname: redirect,
      search: nextSearch,
    };
  }
  if (typeof fallback === 'string') {
    return {
      pathname: fallback,
      search: nextSearch,
    };
  }
  if (fallback) {
    return {
      ...fallback,
      search: nextSearch,
    };
  }
  return {
    pathname: "/home",
    search: nextSearch,
  };
}

export function redirectsGetSearchFromHref(): string {
  const url = new URL(window.location.href);
  const redirect = url.searchParams.get('redirect');
  if (redirect) {
    url.searchParams.delete('redirect');
  }
  else if (window.location.pathname !== "/") {
    url.searchParams.append('redirect', window.location.pathname);
  }

  return url.searchParams.toString();
}
