import type { ReducerFragment } from '../../types';
import type { AssetsState } from '../model/types';
import * as types from '../action/types';

const reducePost: ReducerFragment<AssetsState> = {
  [types.POST_PENDING]: (state, action) => {
    return { ...state, statusPost: 'pending' };
  },

  [types.POST_FULFILLED]: (state, action) => {
    return { ...state, statusPost: 'fulfilled' };
  },

  [types.POST_REJECTED]: (state, action) => {
    return { ...state, statusPost: 'rejected' };
  },
};

export default reducePost;
