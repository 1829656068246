// RESET
export const RESET = 'RESTAURANTS_ORDERSETTINGS.RESET';
export type RESET_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.RESET',
};

// LIST
export const LIST = 'RESTAURANTS_ORDERSETTINGS.LIST';
export type LIST_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.LIST',
  payload: Promise<any>,
};

export const LIST_PENDING = 'RESTAURANTS_ORDERSETTINGS.LIST_PENDING';
export type LIST_PENDING_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.LIST_PENDING',
};

export const LIST_FULFILLED = 'RESTAURANTS_ORDERSETTINGS.LIST_FULFILLED';
export type LIST_FULFILLED_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.LIST_FULFILLED',
  payload: any,
};

export const LIST_REJECTED = 'RESTAURANTS_ORDERSETTINGS.LIST_REJECTED';
export type LIST_REJECTED_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.LIST_REJECTED',
};

// GET
export const GET = 'RESTAURANTS_ORDERSETTINGS.GET';
export type GET_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.GET',
  payload: Promise<any>,
};

export const GET_PENDING = 'RESTAURANTS_ORDERSETTINGS.GET_PENDING';
export type GET_PENDING_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.GET_PENDING',
};

export const GET_FULFILLED = 'RESTAURANTS_ORDERSETTINGS.GET_FULFILLED';
export type GET_FULFILLED_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.GET_FULFILLED',
};

export const GET_REJECTED = 'RESTAURANTS_ORDERSETTINGS.GET_REJECTED';
export type GET_REJECTED_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.GET_REJECTED',
};

// POST
export const POST = 'RESTAURANTS_ORDERSETTINGS.POST';
export type POST_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.POST',
  payload: Promise<any>,
};

export const POST_PENDING = 'RESTAURANTS_ORDERSETTINGS.POST_PENDING';
export type POST_PENDING_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.POST_PENDING',
};

export const POST_FULFILLED = 'RESTAURANTS_ORDERSETTINGS.POST_FULFILLED';
export type POST_FULFILLED_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.POST_FULFILLED',
};

export const POST_REJECTED = 'RESTAURANTS_ORDERSETTINGS.POST_REJECTED';
export type POST_REJECTED_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.POST_REJECTED',
};

// PUT
export const PUT = 'RESTAURANTS_ORDERSETTINGS.PUT';
export type PUT_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.PUT',
  payload: Promise<any>,
};

export const PUT_PENDING = 'RESTAURANTS_ORDERSETTINGS.PUT_PENDING';
export type PUT_PENDING_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.PUT_PENDING',
};

export const PUT_FULFILLED = 'RESTAURANTS_ORDERSETTINGS.PUT_FULFILLED';
export type PUT_FULFILLED_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.PUT_FULFILLED',
};

export const PUT_REJECTED = 'RESTAURANTS_ORDERSETTINGS.PUT_REJECTED';
export type PUT_REJECTED_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.PUT_REJECTED',
};

// REMOVE
export const REMOVE = 'RESTAURANTS_ORDERSETTINGS.REMOVE';
export type REMOVE_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.REMOVE',
  payload: Promise<any>,
};

export const REMOVE_PENDING = 'RESTAURANTS_ORDERSETTINGS.REMOVE_PENDING';
export type REMOVE_PENDING_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.REMOVE_PENDING',
};

export const REMOVE_FULFILLED = 'RESTAURANTS_ORDERSETTINGS.REMOVE_FULFILLED';
export type REMOVE_FULFILLED_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.REMOVE_FULFILLED',
};

export const REMOVE_REJECTED = 'RESTAURANTS_ORDERSETTINGS.REMOVE_REJECTED';
export type REMOVE_REJECTED_ACTION = {
  type: 'RESTAURANTS_ORDERSETTINGS.REMOVE_REJECTED',
};

export type Action =
  | RESET_ACTION
  | GET_ACTION
  | GET_PENDING_ACTION
  | GET_FULFILLED_ACTION
  | GET_REJECTED_ACTION
  | POST_ACTION
  | POST_PENDING_ACTION
  | POST_FULFILLED_ACTION
  | POST_REJECTED_ACTION
  | PUT_ACTION
  | PUT_PENDING_ACTION
  | PUT_FULFILLED_ACTION
  | PUT_REJECTED_ACTION
  | REMOVE_ACTION
  | REMOVE_PENDING_ACTION
  | REMOVE_FULFILLED_ACTION
  | REMOVE_REJECTED_ACTION;
