import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Switch } from 'react-router-dom';

import RouteWithSubRoutes from '../../../components/common/routeWithSubRoutes/RouteWithSubRoutes';
import * as rolesActions from '../../../state/roles/action/creators';

type Props = {|
  routes: Object[],
|};

const AccountsRoot = (props: Props) => {
  // hooks
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.firebaseIdToken);
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (!isInitialized && authToken) {
      setIsInitialized(true);
      dispatch(rolesActions.list({ fetchAll: true }));
    }
  }, [isInitialized, authToken, dispatch]);

  return (
    <Switch>
      {props.routes.map((route, i) => (
        <RouteWithSubRoutes key={window.location.pathname} {...route} />
      ))}
    </Switch>
  );
};

export default AccountsRoot;
