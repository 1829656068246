export interface RootState {
  app: {
    enabledFunctionality: Permission;
  };
}

export interface Alert {
  id: string;
  title: string;
  body?: string;
  btnClose?: string;
}

export interface ConsumerTagWarning {
  id: string;
  title: string;
  warningText: string;
  body?: string;
}
export interface Tab {
  name: string;
  href: string;
}

export type Tabs = Tab[];

export const PermissionState = {
  NONE: 'NONE',
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
};

type PermissionStateValue = keyof PermissionState;

export interface RolePermission {
  productTeam: { perm: PermissionStateValue };
  supportTeam: { perm: PermissionStateValue };
  default: { perm: PermissionStateValue };
  market: {
    perm: PermissionStateValue;
    marketManager: { perm: PermissionStateValue };
    marketLocationManager: { perm: PermissionStateValue };
    marketNutritionManager: { perm: PermissionStateValue };
    franchiseeManager: { perm: PermissionStateValue };
  };
}
export interface Permission {
  canDeleteEverything: boolean;
  canEditEverything?: boolean;
  canViewEverything?: boolean;
  home?: { perm: PermissionStateValue };
  settings?: { perm: PermissionStateValue };
  ownSettings?: { perm: PermissionStateValue };
  restaurants?: {
    perm: PermissionStateValue;
    settings: { perm: PermissionStateValue };
  };
  products?: {
    perm: PermissionStateValue;
    product: { perm: PermissionStateValue };
    settings: { perm: PermissionStateValue };
    allergens: { perm: PermissionStateValue };
    categories: { perm: PermissionStateValue };
    nutrients: { perm: PermissionStateValue };
  };
  accounts?: {
    perm: PermissionStateValue;
    account: {
      perm: PermissionStateValue;
      withRole?: RolePermission;
    };
    ownAccount: {
      perm: PermissionStateValue;
      delete?: { perm: PermissionStateValue };
    };
  };
  accountRoles?: {
    perm: PermissionStateValue;
    role?: RolePermission & {
      ownMarket?: {
        perm: PermissionStateValue;
        marketManager: { perm: PermissionStateValue };
        marketLocationManager: { perm: PermissionStateValue };
        marketNutritionManager: { perm: PermissionStateValue };
        franchiseeManager: { perm: PermissionStateValue };
      };
    };
  };
  urlBuilder?: { perm: PermissionStateValue };
  localMarketing?: {
    perm: PermissionStateValue;
    bundle: {
      perm: PermissionStateValue;
    };
    settings: {
      perm: PermissionStateValue;
    };
  };
  mop?: { perm: PermissionStateValue };
  codeUploader: { perm: PermissionStateValue };
  remoteConfig: {
    perm: PermissionStateValue;
  };
  whitelist: {
    perm: PermissionStateValue;
  };
}
export interface AppState {
  alert: Alert;
  selectedTabIdx: number | false;
  enabledFunctionality: Permission;
  tabs: Tabs;
  curPage: number;
}
