import { Button, DialogContentText, withStyles , Divider, Typography, Fade} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import styles from './styles';

import * as appActions from '../../../state/app/action/creators';
import { getAppAlert } from '../../../state/app/selectors';

const ANIMATION_DURATION_MS = 200;

type Props = {
  classes: Object,
};

const AppAlertDialog = (props: Props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const alert = useSelector((state) => getAppAlert(state));
  const isAlertEmpty = !!alert;
  const { classes } = props;

  useEffect(() => {
    setIsOpen(isAlertEmpty);
  }, [isAlertEmpty]);

  const handleClose = () => {
    if (alert?.handleCancel) {
      alert.handleCancel();
    }
    setIsOpen(false);
    window.setTimeout(
      () => dispatch(appActions.unsetAlert()),
      ANIMATION_DURATION_MS
    );
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      style={{ zIndex: 1400 }}
    >
      <DialogTitle id="alert-dialog-slide-title" disableTypography>
        <Typography variant="subtitle1" align="center">
          {alert ? alert.title : ''}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent id="AppAlertDialogContent">
        {alert?.body && (
          <DialogContentText
            style={{ whiteSpace: 'pre-wrap' }}
            className={classes.text}
          >
            {alert.body}
          </DialogContentText>
        )}
      </DialogContent>
      <Divider />
      {/* The ID below is used to add additional buttons (using react portals) */}
      <DialogActions id="AppAlertDialogButtons">
        <Button onClick={handleClose}>
          {alert ? (alert.btnClose ? alert.btnClose : 'cancel') : ''}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Transition = React.forwardRef((props, ref) => {
  return <Fade in timeout={ANIMATION_DURATION_MS} {...props} ref={ref} />;
});

export default withStyles(styles)(AppAlertDialog);
