const Accordion = {
  MuiAccordion: {
    root: {
      '&::before': {
        opacity: 0,
      },
      '&:not(:last-of-type)': {
        borderBottom: '1px solid #dedede',
      },
      '&$expanded': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  },
  MuiAccordionSummary: {
    root: {
      minHeight: 56,
    },
    content: {
      marginLeft: '36px !important',
    },
    expandIcon: {
      left: 8,
      right: 'initial',
      position: 'absolute',
    },
  },
};

export default Accordion;
