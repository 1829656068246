import type { State as _State, Action as _Action } from './types';
import update from 'immutability-helper';
import * as types from './types';
import initialState from './initialState';
import createReducer from '../utility/createReducer';

export type State = _State;

export type Action = _Action;

export const reducer = createReducer(initialState, {
  'GLOBAL.RESET': () => initialState,

  [types.PUT_FULFILLED]: (state, { payload }) => {
    return state;
  },
  [types.LIST_PENDING]: (state, action) => {
    return update(state, {
      statusList: { $set: 'pending' },
    });
  },
  [types.LIST_FULFILLED]: (state, action) => {
    let items = action.payload.data.items || [];
    items = items.map((item) => {
      item.market = action.meta.market;
      return item;
    });

    let permissionsByAccountId = items.reduce((accu, item) => {
      accu[item.member.id] = accu[item.member.id] ?? [];
      accu[item.member.id].push(item);
      return accu;
    }, {});

    let nextState = state;
    Object.keys(permissionsByAccountId).forEach((accountId) => {
      nextState = update(state, {
        map: {
          [accountId]: (permissions) => {
            return update(permissions || [], {
              $set:
                permissions?.filter(
                  (permission) => permission.market !== action.meta.market
                ) ?? [],
            });
          },
        },
      });
      nextState = update(nextState, {
        statusList: { $set: 'fulfilled' },
        map: { [accountId]: { $push: permissionsByAccountId[accountId] } },
      });
    });

    return nextState;
  },
  [types.LIST_REJECTED]: (state, action) => {
    return update(state, {
      statusList: { $set: 'rejected' },
    });
  },
  [types.SET_MAP_INVALIDATOR_ID]: (state, action) => {
    return update(state, {
      mapInvalidatorId: { $set: action.payload },
    });
  },
  [types.UNSET_MAP_INVALIDATOR_ID]: (state, action) => {
    if (state.mapInvalidatorId === action.payload) {
      return update(state, {
        mapInvalidatorId: { $set: undefined },
      });
    }
    return state;
  },
});
