/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */

// Language information taken on 2018-12-03
// from: http://www.unicode.org/cldr/charts/latest/supplemental/territory_language_information.html
// It was later modified to remove languages tags not supported by the back end.
// + Norwegian (no) macro language

export type LanguageRegion = {
  name: string;
  code: string;
};

export type Language = {
  name: string;
  code: string;
  regions: LanguageRegion[];
};

type LanguageDataType = {
  [key: string]: Language;
};

export const languageData: LanguageDataType = {
  fa: {
    name: 'Persian',
    code: 'fa',
    regions: [
      { name: 'Afghanistan', code: 'AF' },
      { name: 'Iran', code: 'IR' },
      { name: 'Iraq', code: 'IQ' },
      { name: 'Oman', code: 'OM' },
      { name: 'Pakistan', code: 'PK' },
      { name: 'Qatar', code: 'QA' },
      { name: 'Tajikistan', code: 'TJ' },
      { name: 'United Arab Emirates', code: 'AE' },
    ],
  },
  ps: {
    name: 'Pashto',
    code: 'ps',
    regions: [
      { name: 'Afghanistan', code: 'AF' },
      { name: 'Iran', code: 'IR' },
      { name: 'Pakistan', code: 'PK' },
      { name: 'United Arab Emirates', code: 'AE' },
    ],
  },
  haz: {
    name: 'Hazaragi',
    code: 'haz',
    regions: [{ name: 'Afghanistan', code: 'AF' }],
  },
  'uz-Arab': {
    name: 'Uzbek (Arabic)',
    code: 'uz-Arab',
    regions: [{ name: 'Afghanistan', code: 'AF' }],
  },
  tk: {
    name: 'Turkmen',
    code: 'tk',
    regions: [
      { name: 'Afghanistan', code: 'AF' },
      { name: 'Iran', code: 'IR' },
      { name: 'Turkmenistan', code: 'TM' },
    ],
  },
  prd: {
    name: 'Parsi-Dari',
    code: 'prd',
    regions: [
      { name: 'Afghanistan', code: 'AF' },
      { name: 'Iran', code: 'IR' },
    ],
  },
  bal: {
    name: 'Baluchi',
    code: 'bal',
    regions: [
      { name: 'Afghanistan', code: 'AF' },
      { name: 'Iran', code: 'IR' },
      { name: 'Oman', code: 'OM' },
      { name: 'Pakistan', code: 'PK' },
      { name: 'United Arab Emirates', code: 'AE' },
    ],
  },
  bgn: {
    name: 'Western Balochi',
    code: 'bgn',
    regions: [
      { name: 'Afghanistan', code: 'AF' },
      { name: 'Iran', code: 'IR' },
      { name: 'Pakistan', code: 'PK' },
    ],
  },
  ug: {
    name: 'Uyghur',
    code: 'ug',
    regions: [
      { name: 'Afghanistan', code: 'AF' },
      { name: 'China', code: 'CN' },
    ],
  },
  'kk-Arab': {
    name: 'Kazakh (Arabic)',
    code: 'kk-Arab',
    regions: [
      { name: 'Afghanistan', code: 'AF' },
      { name: 'China', code: 'CN' },
      { name: 'Iran', code: 'IR' },
      { name: 'Mongolia', code: 'MN' },
    ],
  },
  sv: {
    name: 'Swedish',
    code: 'sv',
    regions: [
      { name: 'Åland Islands', code: 'AX' },
      { name: 'Denmark', code: 'DK' },
      { name: 'Finland', code: 'FI' },
      { name: 'Sweden', code: 'SE' },
    ],
  },
  sq: {
    name: 'Albanian',
    code: 'sq',
    regions: [
      { name: 'Albania', code: 'AL' },
      { name: 'Greece', code: 'GR' },
      { name: 'Kosovo', code: 'XK' },
      { name: 'Macedonia', code: 'MK' },
      { name: 'Montenegro', code: 'ME' },
      { name: 'Serbia', code: 'RS' },
      { name: 'Turkey', code: 'TR' },
    ],
  },
  el: {
    name: 'Greek',
    code: 'el',
    regions: [
      { name: 'Albania', code: 'AL' },
      { name: 'Cyprus', code: 'CY' },
      { name: 'Egypt', code: 'EG' },
      { name: 'Germany', code: 'DE' },
      { name: 'Greece', code: 'GR' },
      { name: 'Italy', code: 'IT' },
      { name: 'Romania', code: 'RO' },
      { name: 'Turkey', code: 'TR' },
      { name: 'Ukraine', code: 'UA' },
      { name: 'United Kingdom', code: 'GB' },
    ],
  },
  mk: {
    name: 'Macedonian',
    code: 'mk',
    regions: [
      { name: 'Albania', code: 'AL' },
      { name: 'Greece', code: 'GR' },
      { name: 'Macedonia', code: 'MK' },
    ],
  },
  arq: {
    name: 'Algerian Arabic',
    code: 'arq',
    regions: [{ name: 'Algeria', code: 'DZ' }],
  },
  ar: {
    name: 'Arabic',
    code: 'ar',
    regions: [
      { name: 'Algeria', code: 'DZ' },
      { name: 'Bahrain', code: 'BH' },
      { name: 'Cameroon', code: 'CM' },
      { name: 'Chad', code: 'TD' },
      { name: 'Comoros', code: 'KM' },
      { name: 'Cyprus', code: 'CY' },
      { name: 'Djibouti', code: 'DJ' },
      { name: 'Egypt', code: 'EG' },
      { name: 'Eritrea', code: 'ER' },
      { name: 'Iran', code: 'IR' },
      { name: 'Iraq', code: 'IQ' },
      { name: 'Israel', code: 'IL' },
      { name: 'Jordan', code: 'JO' },
      { name: 'Kenya', code: 'KE' },
      { name: 'Kuwait', code: 'KW' },
      { name: 'Lebanon', code: 'LB' },
      { name: 'Libya', code: 'LY' },
      { name: 'Mali', code: 'ML' },
      { name: 'Mauritania', code: 'MR' },
      { name: 'Morocco', code: 'MA' },
      { name: 'Niger', code: 'NE' },
      { name: 'Nigeria', code: 'NG' },
      { name: 'Oman', code: 'OM' },
      { name: 'Palestinian Territories', code: 'PS' },
      { name: 'Qatar', code: 'QA' },
      { name: 'Saudi Arabia', code: 'SA' },
      { name: 'Somalia', code: 'SO' },
      { name: 'South Sudan', code: 'SS' },
      { name: 'Sudan', code: 'SD' },
      { name: 'Syria', code: 'SY' },
      { name: 'Tajikistan', code: 'TJ' },
      { name: 'Tunisia', code: 'TN' },
      { name: 'Turkey', code: 'TR' },
      { name: 'United Arab Emirates', code: 'AE' },
      { name: 'Western Sahara', code: 'EH' },
      { name: 'Yemen', code: 'YE' },
    ],
  },
  fr: {
    name: 'French',
    code: 'fr',
    regions: [
      { name: 'Algeria', code: 'DZ' },
      { name: 'Andorra', code: 'AD' },
      { name: 'Austria', code: 'AT' },
      { name: 'Belgium', code: 'BE' },
      { name: 'Benin', code: 'BJ' },
      { name: 'Burkina Faso', code: 'BF' },
      { name: 'Burundi', code: 'BI' },
      { name: 'Cameroon', code: 'CM' },
      { name: 'Canada', code: 'CA' },
      { name: 'Central African Republic', code: 'CF' },
      { name: 'Chad', code: 'TD' },
      { name: 'Comoros', code: 'KM' },
      { name: 'Congo - Brazzaville', code: 'CG' },
      { name: 'Congo - Kinshasa', code: 'CD' },
      { name: 'Côte d’Ivoire', code: 'CI' },
      { name: 'Cyprus', code: 'CY' },
      { name: 'Djibouti', code: 'DJ' },
      { name: 'Equatorial Guinea', code: 'GQ' },
      { name: 'France', code: 'FR' },
      { name: 'French Guiana', code: 'GF' },
      { name: 'French Polynesia', code: 'PF' },
      { name: 'French Southern Territories', code: 'TF' },
      { name: 'Gabon', code: 'GA' },
      { name: 'Germany', code: 'DE' },
      { name: 'Greece', code: 'GR' },
      { name: 'Guadeloupe', code: 'GP' },
      { name: 'Guinea', code: 'GN' },
      { name: 'Haiti', code: 'HT' },
      { name: 'Hungary', code: 'HU' },
      { name: 'Ireland', code: 'IE' },
      { name: 'Italy', code: 'IT' },
      { name: 'Lebanon', code: 'LB' },
      { name: 'Luxembourg', code: 'LU' },
      { name: 'Madagascar', code: 'MG' },
      { name: 'Mali', code: 'ML' },
      { name: 'Malta', code: 'MT' },
      { name: 'Martinique', code: 'MQ' },
      { name: 'Mauritania', code: 'MR' },
      { name: 'Mauritius', code: 'MU' },
      { name: 'Mayotte', code: 'YT' },
      { name: 'Monaco', code: 'MC' },
      { name: 'Morocco', code: 'MA' },
      { name: 'Netherlands', code: 'NL' },
      { name: 'New Caledonia', code: 'NC' },
      { name: 'Niger', code: 'NE' },
      { name: 'Portugal', code: 'PT' },
      { name: 'Réunion', code: 'RE' },
      { name: 'Romania', code: 'RO' },
      { name: 'Rwanda', code: 'RW' },
      { name: 'Senegal', code: 'SN' },
      { name: 'Seychelles', code: 'SC' },
      { name: 'St. Barthélemy', code: 'BL' },
      { name: 'St. Martin', code: 'MF' },
      { name: 'St. Pierre & Miquelon', code: 'PM' },
      { name: 'Switzerland', code: 'CH' },
      { name: 'Syria', code: 'SY' },
      { name: 'Togo', code: 'TG' },
      { name: 'Tunisia', code: 'TN' },
      { name: 'United Kingdom', code: 'GB' },
      { name: 'United States', code: 'US' },
      { name: 'Vanuatu', code: 'VU' },
      { name: 'Wallis & Futuna', code: 'WF' },
    ],
  },
  kab: {
    name: 'Kabyle',
    code: 'kab',
    regions: [{ name: 'Algeria', code: 'DZ' }],
  },
  en: {
    name: 'English',
    code: 'en',
    regions: [
      { name: 'Algeria', code: 'DZ' },
      { name: 'American Samoa', code: 'AS' },
      { name: 'Anguilla', code: 'AI' },
      { name: 'Antigua & Barbuda', code: 'AG' },
      { name: 'Argentina', code: 'AR' },
      { name: 'Aruba', code: 'AW' },
      { name: 'Ascension Island', code: 'AC' },
      { name: 'Australia', code: 'AU' },
      { name: 'Austria', code: 'AT' },
      { name: 'Bahamas', code: 'BS' },
      { name: 'Bangladesh', code: 'BD' },
      { name: 'Barbados', code: 'BB' },
      { name: 'Belgium', code: 'BE' },
      { name: 'Belize', code: 'BZ' },
      { name: 'Bermuda', code: 'BM' },
      { name: 'Bhutan', code: 'BT' },
      { name: 'Bosnia & Herzegovina', code: 'BA' },
      { name: 'Botswana', code: 'BW' },
      { name: 'Brazil', code: 'BR' },
      { name: 'British Indian Ocean Territory', code: 'IO' },
      { name: 'British Virgin Islands', code: 'VG' },
      { name: 'Brunei', code: 'BN' },
      { name: 'Bulgaria', code: 'BG' },
      { name: 'Burundi', code: 'BI' },
      { name: 'Cameroon', code: 'CM' },
      { name: 'Canada', code: 'CA' },
      { name: 'Cayman Islands', code: 'KY' },
      { name: 'Chile', code: 'CL' },
      { name: 'China', code: 'CN' },
      { name: 'Christmas Island', code: 'CX' },
      { name: 'Cocos (Keeling) Islands', code: 'CC' },
      { name: 'Cook Islands', code: 'CK' },
      { name: 'Croatia', code: 'HR' },
      { name: 'Cyprus', code: 'CY' },
      { name: 'Czechia', code: 'CZ' },
      { name: 'Denmark', code: 'DK' },
      { name: 'Diego Garcia', code: 'DG' },
      { name: 'Dominica', code: 'DM' },
      { name: 'Dominican Republic', code: 'DO' },
      { name: 'Egypt', code: 'EG' },
      { name: 'Eritrea', code: 'ER' },
      { name: 'Estonia', code: 'EE' },
      { name: 'Ethiopia', code: 'ET' },
      { name: 'Falkland Islands', code: 'FK' },
      { name: 'Fiji', code: 'FJ' },
      { name: 'Finland', code: 'FI' },
      { name: 'France', code: 'FR' },
      { name: 'Gambia', code: 'GM' },
      { name: 'Germany', code: 'DE' },
      { name: 'Ghana', code: 'GH' },
      { name: 'Gibraltar', code: 'GI' },
      { name: 'Greece', code: 'GR' },
      { name: 'Grenada', code: 'GD' },
      { name: 'Guam', code: 'GU' },
      { name: 'Guernsey', code: 'GG' },
      { name: 'Guyana', code: 'GY' },
      { name: 'Honduras', code: 'HN' },
      { name: 'Hong Kong SAR China', code: 'HK' },
      { name: 'Hungary', code: 'HU' },
      { name: 'India', code: 'IN' },
      { name: 'Iraq', code: 'IQ' },
      { name: 'Ireland', code: 'IE' },
      { name: 'Isle of Man', code: 'IM' },
      { name: 'Israel', code: 'IL' },
      { name: 'Italy', code: 'IT' },
      { name: 'Jamaica', code: 'JM' },
      { name: 'Jersey', code: 'JE' },
      { name: 'Jordan', code: 'JO' },
      { name: 'Kazakhstan', code: 'KZ' },
      { name: 'Kenya', code: 'KE' },
      { name: 'Kiribati', code: 'KI' },
      { name: 'Latvia', code: 'LV' },
      { name: 'Lebanon', code: 'LB' },
      { name: 'Lesotho', code: 'LS' },
      { name: 'Liberia', code: 'LR' },
      { name: 'Lithuania', code: 'LT' },
      { name: 'Luxembourg', code: 'LU' },
      { name: 'Macau SAR China', code: 'MO' },
      { name: 'Madagascar', code: 'MG' },
      { name: 'Malawi', code: 'MW' },
      { name: 'Malaysia', code: 'MY' },
      { name: 'Malta', code: 'MT' },
      { name: 'Marshall Islands', code: 'MH' },
      { name: 'Mauritius', code: 'MU' },
      { name: 'Mexico', code: 'MX' },
      { name: 'Micronesia', code: 'FM' },
      { name: 'Montserrat', code: 'MS' },
      { name: 'Morocco', code: 'MA' },
      { name: 'Namibia', code: 'NA' },
      { name: 'Nauru', code: 'NR' },
      { name: 'Nepal', code: 'NP' },
      { name: 'Netherlands', code: 'NL' },
      { name: 'New Zealand', code: 'NZ' },
      { name: 'Nigeria', code: 'NG' },
      { name: 'Niue', code: 'NU' },
      { name: 'Norfolk Island', code: 'NF' },
      { name: 'Northern Mariana Islands', code: 'MP' },
      { name: 'Pakistan', code: 'PK' },
      { name: 'Palau', code: 'PW' },
      { name: 'Panama', code: 'PA' },
      { name: 'Papua New Guinea', code: 'PG' },
      { name: 'Philippines', code: 'PH' },
      { name: 'Pitcairn Islands', code: 'PN' },
      { name: 'Poland', code: 'PL' },
      { name: 'Portugal', code: 'PT' },
      { name: 'Puerto Rico', code: 'PR' },
      { name: 'Romania', code: 'RO' },
      { name: 'Rwanda', code: 'RW' },
      { name: 'Samoa', code: 'WS' },
      { name: 'Seychelles', code: 'SC' },
      { name: 'Sierra Leone', code: 'SL' },
      { name: 'Singapore', code: 'SG' },
      { name: 'Sint Maarten', code: 'SX' },
      { name: 'Slovakia', code: 'SK' },
      { name: 'Slovenia', code: 'SI' },
      { name: 'Solomon Islands', code: 'SB' },
      { name: 'South Africa', code: 'ZA' },
      { name: 'South Sudan', code: 'SS' },
      { name: 'Spain', code: 'ES' },
      { name: 'Sri Lanka', code: 'LK' },
      { name: 'St. Helena', code: 'SH' },
      { name: 'St. Kitts & Nevis', code: 'KN' },
      { name: 'St. Lucia', code: 'LC' },
      { name: 'St. Pierre & Miquelon', code: 'PM' },
      { name: 'St. Vincent & Grenadines', code: 'VC' },
      { name: 'Sudan', code: 'SD' },
      { name: 'Swaziland', code: 'SZ' },
      { name: 'Sweden', code: 'SE' },
      { name: 'Switzerland', code: 'CH' },
      { name: 'Tanzania', code: 'TZ' },
      { name: 'Thailand', code: 'TH' },
      { name: 'Taiwan', code: 'TW' },
      { name: 'Tokelau', code: 'TK' },
      { name: 'Tonga', code: 'TO' },
      { name: 'Trinidad & Tobago', code: 'TT' },
      { name: 'Tristan da Cunha', code: 'TA' },
      { name: 'Turkey', code: 'TR' },
      { name: 'Turks & Caicos Islands', code: 'TC' },
      { name: 'Tuvalu', code: 'TV' },
      { name: 'U.S. Outlying Islands', code: 'UM' },
      { name: 'U.S. Virgin Islands', code: 'VI' },
      { name: 'Uganda', code: 'UG' },
      { name: 'United Kingdom', code: 'GB' },
      { name: 'United States', code: 'US' },
      { name: 'Vanuatu', code: 'VU' },
      { name: 'Yemen', code: 'YE' },
      { name: 'Zambia', code: 'ZM' },
      { name: 'Zimbabwe', code: 'ZW' },
    ],
  },
  sm: {
    name: 'Samoan',
    code: 'sm',
    regions: [
      { name: 'American Samoa', code: 'AS' },
      { name: 'Samoa', code: 'WS' },
    ],
  },
  ca: {
    name: 'Catalan',
    code: 'ca',
    regions: [
      { name: 'Andorra', code: 'AD' },
      { name: 'France', code: 'FR' },
      { name: 'Italy', code: 'IT' },
      { name: 'Spain', code: 'ES' },
    ],
  },
  es: {
    name: 'Spanish',
    code: 'es',
    regions: [
      { name: 'Andorra', code: 'AD' },
      { name: 'Argentina', code: 'AR' },
      { name: 'Belize', code: 'BZ' },
      { name: 'Bolivia', code: 'BO' },
      { name: 'Brazil', code: 'BR' },
      { name: 'Canary Islands', code: 'IC' },
      { name: 'Ceuta & Melilla', code: 'EA' },
      { name: 'Chile', code: 'CL' },
      { name: 'Colombia', code: 'CO' },
      { name: 'Costa Rica', code: 'CR' },
      { name: 'Cuba', code: 'CU' },
      { name: 'Curaçao', code: 'CW' },
      { name: 'Dominican Republic', code: 'DO' },
      { name: 'Ecuador', code: 'EC' },
      { name: 'El Salvador', code: 'SV' },
      { name: 'Equatorial Guinea', code: 'GQ' },
      { name: 'France', code: 'FR' },
      { name: 'Germany', code: 'DE' },
      { name: 'Gibraltar', code: 'GI' },
      { name: 'Guatemala', code: 'GT' },
      { name: 'Honduras', code: 'HN' },
      { name: 'Mexico', code: 'MX' },
      { name: 'Morocco', code: 'MA' },
      { name: 'Nicaragua', code: 'NI' },
      { name: 'Panama', code: 'PA' },
      { name: 'Paraguay', code: 'PY' },
      { name: 'Peru', code: 'PE' },
      { name: 'Philippines', code: 'PH' },
      { name: 'Portugal', code: 'PT' },
      { name: 'Puerto Rico', code: 'PR' },
      { name: 'Romania', code: 'RO' },
      { name: 'Sint Maarten', code: 'SX' },
      { name: 'Spain', code: 'ES' },
      { name: 'Trinidad & Tobago', code: 'TT' },
      { name: 'United States', code: 'US' },
      { name: 'Uruguay', code: 'UY' },
      { name: 'Venezuela', code: 'VE' },
    ],
  },
  pt: {
    name: 'Portuguese',
    code: 'pt',
    regions: [
      { name: 'Angola', code: 'AO' },
      { name: 'Antigua & Barbuda', code: 'AG' },
      { name: 'Brazil', code: 'BR' },
      { name: 'Cape Verde', code: 'CV' },
      { name: 'Equatorial Guinea', code: 'GQ' },
      { name: 'France', code: 'FR' },
      { name: 'Guinea-Bissau', code: 'GW' },
      { name: 'Luxembourg', code: 'LU' },
      { name: 'Macau SAR China', code: 'MO' },
      { name: 'Mozambique', code: 'MZ' },
      { name: 'Portugal', code: 'PT' },
      { name: 'São Tomé & Príncipe', code: 'ST' },
      { name: 'Switzerland', code: 'CH' },
      { name: 'Timor-Leste', code: 'TL' },
    ],
  },
  umb: {
    name: 'Umbundu',
    code: 'umb',
    regions: [{ name: 'Angola', code: 'AO' }],
  },
  kmb: {
    name: 'Kimbundu',
    code: 'kmb',
    regions: [{ name: 'Angola', code: 'AO' }],
  },
  ln: {
    name: 'Lingala',
    code: 'ln',
    regions: [
      { name: 'Angola', code: 'AO' },
      { name: 'Central African Republic', code: 'CF' },
      { name: 'Congo - Brazzaville', code: 'CG' },
      { name: 'Congo - Kinshasa', code: 'CD' },
    ],
  },
  cy: {
    name: 'Welsh',
    code: 'cy',
    regions: [
      { name: 'Argentina', code: 'AR' },
      { name: 'United Kingdom', code: 'GB' },
    ],
  },
  gn: {
    name: 'Guarani',
    code: 'gn',
    regions: [
      { name: 'Argentina', code: 'AR' },
      { name: 'Bolivia', code: 'BO' },
      { name: 'Paraguay', code: 'PY' },
    ],
  },
  hy: {
    name: 'Armenian',
    code: 'hy',
    regions: [
      { name: 'Armenia', code: 'AM' },
      { name: 'Cyprus', code: 'CY' },
      { name: 'Georgia', code: 'GE' },
      { name: 'Iran', code: 'IR' },
      { name: 'Lebanon', code: 'LB' },
      { name: 'Russia', code: 'RU' },
      { name: 'Syria', code: 'SY' },
      { name: 'Turkey', code: 'TR' },
    ],
  },
  ku: {
    name: 'Kurdish',
    code: 'ku',
    regions: [
      { name: 'Armenia', code: 'AM' },
      { name: 'Azerbaijan', code: 'AZ' },
      { name: 'Georgia', code: 'GE' },
      { name: 'Germany', code: 'DE' },
      { name: 'Syria', code: 'SY' },
      { name: 'Turkey', code: 'TR' },
      { name: 'Turkmenistan', code: 'TM' },
    ],
  },
  az: {
    name: 'Azerbaijani',
    code: 'az',
    regions: [
      { name: 'Armenia', code: 'AM' },
      { name: 'Azerbaijan', code: 'AZ' },
      { name: 'Turkey', code: 'TR' },
    ],
  },
  nl: {
    name: 'Dutch',
    code: 'nl',
    regions: [
      { name: 'Aruba', code: 'AW' },
      { name: 'Belgium', code: 'BE' },
      { name: 'Caribbean Netherlands', code: 'BQ' },
      { name: 'Curaçao', code: 'CW' },
      { name: 'France', code: 'FR' },
      { name: 'Germany', code: 'DE' },
      { name: 'Netherlands', code: 'NL' },
      { name: 'Sint Maarten', code: 'SX' },
      { name: 'Suriname', code: 'SR' },
    ],
  },
  pap: {
    name: 'Papiamento',
    code: 'pap',
    regions: [
      { name: 'Aruba', code: 'AW' },
      { name: 'Caribbean Netherlands', code: 'BQ' },
      { name: 'Curaçao', code: 'CW' },
    ],
  },
  'zh-Hant': {
    name: 'Traditional Chinese',
    code: 'zh-Hant',
    regions: [
      { name: 'Australia', code: 'AU' },
      { name: 'Brunei', code: 'BN' },
      { name: 'French Guiana', code: 'GF' },
      { name: 'French Polynesia', code: 'PF' },
      { name: 'Hong Kong SAR China', code: 'HK' },
      { name: 'Indonesia', code: 'ID' },
      { name: 'Macau SAR China', code: 'MO' },
      { name: 'Malaysia', code: 'MY' },
      { name: 'Panama', code: 'PA' },
      { name: 'Philippines', code: 'PH' },
      { name: 'Suriname', code: 'SR' },
      { name: 'Taiwan', code: 'TW' },
      { name: 'Thailand', code: 'TH' },
      { name: 'United Kingdom', code: 'GB' },
      { name: 'United States', code: 'US' },
      { name: 'Vietnam', code: 'VN' },
    ],
  },
  'zh-Hans': {
    name: 'Simplified Chinese',
    code: 'zh-Hans',
    regions: [{ name: 'Hong Kong SAR China', code: 'HK' }],
  },
  'zh-HK': {
    name: 'Hong Kong Chinese',
    code: 'zh-HK',
    regions: [{ name: 'Hong Kong SAR China', code: 'HK' }],
  },
  it: {
    name: 'Italian',
    code: 'it',
    regions: [
      { name: 'Australia', code: 'AU' },
      { name: 'Austria', code: 'AT' },
      { name: 'Brazil', code: 'BR' },
      { name: 'Canada', code: 'CA' },
      { name: 'Croatia', code: 'HR' },
      { name: 'France', code: 'FR' },
      { name: 'Germany', code: 'DE' },
      { name: 'Italy', code: 'IT' },
      { name: 'Malta', code: 'MT' },
      { name: 'San Marino', code: 'SM' },
      { name: 'Slovenia', code: 'SI' },
      { name: 'Switzerland', code: 'CH' },
      { name: 'United Kingdom', code: 'GB' },
      { name: 'United States', code: 'US' },
      { name: 'Vatican City', code: 'VA' },
    ],
  },
  wbp: {
    name: 'Warlpiri',
    code: 'wbp',
    regions: [{ name: 'Australia', code: 'AU' }],
  },
  de: {
    name: 'German',
    code: 'de',
    regions: [
      { name: 'Austria', code: 'AT' },
      { name: 'Belgium', code: 'BE' },
      { name: 'Brazil', code: 'BR' },
      { name: 'Bulgaria', code: 'BG' },
      { name: 'Canada', code: 'CA' },
      { name: 'Czechia', code: 'CZ' },
      { name: 'Denmark', code: 'DK' },
      { name: 'Finland', code: 'FI' },
      { name: 'France', code: 'FR' },
      { name: 'Germany', code: 'DE' },
      { name: 'Greece', code: 'GR' },
      { name: 'Hungary', code: 'HU' },
      { name: 'Italy', code: 'IT' },
      { name: 'Kazakhstan', code: 'KZ' },
      { name: 'Liechtenstein', code: 'LI' },
      { name: 'Lithuania', code: 'LT' },
      { name: 'Luxembourg', code: 'LU' },
      { name: 'Namibia', code: 'NA' },
      { name: 'Netherlands', code: 'NL' },
      { name: 'Paraguay', code: 'PY' },
      { name: 'Poland', code: 'PL' },
      { name: 'Romania', code: 'RO' },
      { name: 'Slovakia', code: 'SK' },
      { name: 'Slovenia', code: 'SI' },
      { name: 'Switzerland', code: 'CH' },
      { name: 'United Kingdom', code: 'GB' },
      { name: 'United States', code: 'US' },
    ],
  },
  bar: {
    name: 'Bavarian',
    code: 'bar',
    regions: [
      { name: 'Austria', code: 'AT' },
      { name: 'Germany', code: 'DE' },
    ],
  },
  hr: {
    name: 'Croatian',
    code: 'hr',
    regions: [
      { name: 'Austria', code: 'AT' },
      { name: 'Bosnia & Herzegovina', code: 'BA' },
      { name: 'Croatia', code: 'HR' },
      { name: 'Germany', code: 'DE' },
      { name: 'Hungary', code: 'HU' },
      { name: 'Italy', code: 'IT' },
      { name: 'Serbia', code: 'RS' },
      { name: 'Slovenia', code: 'SI' },
    ],
  },
  sl: {
    name: 'Slovenian',
    code: 'sl',
    regions: [
      { name: 'Austria', code: 'AT' },
      { name: 'Hungary', code: 'HU' },
      { name: 'Italy', code: 'IT' },
      { name: 'Slovenia', code: 'SI' },
    ],
  },
  hu: {
    name: 'Hungarian',
    code: 'hu',
    regions: [
      { name: 'Austria', code: 'AT' },
      { name: 'Hungary', code: 'HU' },
      { name: 'Israel', code: 'IL' },
      { name: 'Romania', code: 'RO' },
      { name: 'Serbia', code: 'RS' },
      { name: 'Slovakia', code: 'SK' },
      { name: 'Slovenia', code: 'SI' },
      { name: 'Ukraine', code: 'UA' },
    ],
  },
  'az-Cyrl': {
    name: 'Azerbaijani (Cyrillic)',
    code: 'az-Cyrl',
    regions: [
      { name: 'Azerbaijan', code: 'AZ' },
      { name: 'Russia', code: 'RU' },
    ],
  },
  tly: {
    name: 'Talysh',
    code: 'tly',
    regions: [{ name: 'Azerbaijan', code: 'AZ' }],
  },
  ttt: {
    name: 'Muslim Tat',
    code: 'ttt',
    regions: [{ name: 'Azerbaijan', code: 'AZ' }],
  },
  tkr: {
    name: 'Tsakhur',
    code: 'tkr',
    regions: [{ name: 'Azerbaijan', code: 'AZ' }],
  },
  ml: {
    name: 'Malayalam',
    code: 'ml',
    regions: [
      { name: 'Bahrain', code: 'BH' },
      { name: 'India', code: 'IN' },
      { name: 'Israel', code: 'IL' },
      { name: 'Malaysia', code: 'MY' },
      { name: 'Qatar', code: 'QA' },
      { name: 'Singapore', code: 'SG' },
      { name: 'United Arab Emirates', code: 'AE' },
      { name: 'United Kingdom', code: 'GB' },
    ],
  },
  bn: {
    name: 'Bangla',
    code: 'bn',
    regions: [
      { name: 'Bangladesh', code: 'BD' },
      { name: 'India', code: 'IN' },
      { name: 'Nepal', code: 'NP' },
      { name: 'United Kingdom', code: 'GB' },
    ],
  },
  rkt: {
    name: 'Rangpuri',
    code: 'rkt',
    regions: [
      { name: 'Bangladesh', code: 'BD' },
      { name: 'India', code: 'IN' },
    ],
  },
  syl: {
    name: 'Sylheti',
    code: 'syl',
    regions: [
      { name: 'Bangladesh', code: 'BD' },
      { name: 'United Kingdom', code: 'GB' },
    ],
  },
  ccp: {
    name: 'Chakma',
    code: 'ccp',
    regions: [
      { name: 'Bangladesh', code: 'BD' },
      { name: 'India', code: 'IN' },
    ],
  },
  my: {
    name: 'Burmese',
    code: 'my',
    regions: [
      { name: 'Bangladesh', code: 'BD' },
      { name: 'Myanmar (Burma)', code: 'MM' },
    ],
  },
  grt: {
    name: 'Garo',
    code: 'grt',
    regions: [
      { name: 'Bangladesh', code: 'BD' },
      { name: 'India', code: 'IN' },
    ],
  },
  mro: {
    name: 'Mru',
    code: 'mro',
    regions: [{ name: 'Bangladesh', code: 'BD' }],
  },
  mni: {
    name: 'Manipuri',
    code: 'mni',
    regions: [
      { name: 'Bangladesh', code: 'BD' },
      { name: 'India', code: 'IN' },
    ],
  },
  be: {
    name: 'Belarusian',
    code: 'be',
    regions: [
      { name: 'Belarus', code: 'BY' },
      { name: 'Poland', code: 'PL' },
      { name: 'Ukraine', code: 'UA' },
    ],
  },
  ru: {
    name: 'Russian',
    code: 'ru',
    regions: [
      { name: 'Belarus', code: 'BY' },
      { name: 'Bulgaria', code: 'BG' },
      { name: 'China', code: 'CN' },
      { name: 'Estonia', code: 'EE' },
      { name: 'Finland', code: 'FI' },
      { name: 'Georgia', code: 'GE' },
      { name: 'Germany', code: 'DE' },
      { name: 'Israel', code: 'IL' },
      { name: 'Kazakhstan', code: 'KZ' },
      { name: 'Kyrgyzstan', code: 'KG' },
      { name: 'Latvia', code: 'LV' },
      { name: 'Lithuania', code: 'LT' },
      { name: 'Moldova', code: 'MD' },
      { name: 'Mongolia', code: 'MN' },
      { name: 'Poland', code: 'PL' },
      { name: 'Russia', code: 'RU' },
      { name: 'Svalbard & Jan Mayen', code: 'SJ' },
      { name: 'Tajikistan', code: 'TJ' },
      { name: 'Turkmenistan', code: 'TM' },
      { name: 'Ukraine', code: 'UA' },
      { name: 'United States', code: 'US' },
      { name: 'Uzbekistan', code: 'UZ' },
    ],
  },
  vls: {
    name: 'West Flemish',
    code: 'vls',
    regions: [{ name: 'Belgium', code: 'BE' }],
  },
  wa: {
    name: 'Walloon',
    code: 'wa',
    regions: [{ name: 'Belgium', code: 'BE' }],
  },
  fon: {
    name: 'Fon',
    code: 'fon',
    regions: [{ name: 'Benin', code: 'BJ' }],
  },
  yo: {
    name: 'Yoruba',
    code: 'yo',
    regions: [
      { name: 'Benin', code: 'BJ' },
      { name: 'Nigeria', code: 'NG' },
    ],
  },
  dz: {
    name: 'Dzongkha',
    code: 'dz',
    regions: [
      { name: 'Bhutan', code: 'BT' },
      { name: 'India', code: 'IN' },
    ],
  },
  ne: {
    name: 'Nepali',
    code: 'ne',
    regions: [
      { name: 'Bhutan', code: 'BT' },
      { name: 'India', code: 'IN' },
      { name: 'Nepal', code: 'NP' },
    ],
  },
  tsj: {
    name: 'Tshangla',
    code: 'tsj',
    regions: [{ name: 'Bhutan', code: 'BT' }],
  },
  lep: {
    name: 'Lepcha',
    code: 'lep',
    regions: [
      { name: 'Bhutan', code: 'BT' },
      { name: 'India', code: 'IN' },
      { name: 'Nepal', code: 'NP' },
    ],
  },
  qu: {
    name: 'Quechua',
    code: 'qu',
    regions: [
      { name: 'Bolivia', code: 'BO' },
      { name: 'Ecuador', code: 'EC' },
      { name: 'Peru', code: 'PE' },
    ],
  },
  ay: {
    name: 'Aymara',
    code: 'ay',
    regions: [
      { name: 'Bolivia', code: 'BO' },
      { name: 'Peru', code: 'PE' },
    ],
  },
  aro: {
    name: 'Araona',
    code: 'aro',
    regions: [{ name: 'Bolivia', code: 'BO' }],
  },
  bs: {
    name: 'Bosnian',
    code: 'bs',
    regions: [{ name: 'Bosnia & Herzegovina', code: 'BA' }],
  },
  'bs-Cyrl': {
    name: 'Bosnian (Cyrillic)',
    code: 'bs-Cyrl',
    regions: [{ name: 'Bosnia & Herzegovina', code: 'BA' }],
  },
  sr: {
    name: 'Serbian',
    code: 'sr',
    regions: [
      { name: 'Bosnia & Herzegovina', code: 'BA' },
      { name: 'Kosovo', code: 'XK' },
      { name: 'Montenegro', code: 'ME' },
      { name: 'Serbia', code: 'RS' },
    ],
  },
  'sr-Latn': {
    name: 'Serbian (Latin)',
    code: 'sr-Latn',
    regions: [
      { name: 'Bosnia & Herzegovina', code: 'BA' },
      { name: 'Kosovo', code: 'XK' },
      { name: 'Montenegro', code: 'ME' },
      { name: 'Romania', code: 'RO' },
      { name: 'Russia', code: 'RU' },
      { name: 'Serbia', code: 'RS' },
      { name: 'Turkey', code: 'TR' },
    ],
  },
  tn: {
    name: 'Tswana',
    code: 'tn',
    regions: [
      { name: 'Botswana', code: 'BW' },
      { name: 'Namibia', code: 'NA' },
      { name: 'South Africa', code: 'ZA' },
      { name: 'Zimbabwe', code: 'ZW' },
    ],
  },
  af: {
    name: 'Afrikaans',
    code: 'af',
    regions: [
      { name: 'Botswana', code: 'BW' },
      { name: 'Namibia', code: 'NA' },
      { name: 'South Africa', code: 'ZA' },
    ],
  },
  ja: {
    name: 'Japanese',
    code: 'ja',
    regions: [
      { name: 'Brazil', code: 'BR' },
      { name: 'Japan', code: 'JP' },
    ],
  },
  ko: {
    name: 'Korean',
    code: 'ko',
    regions: [
      { name: 'Brazil', code: 'BR' },
      { name: 'China', code: 'CN' },
      { name: 'Japan', code: 'JP' },
      { name: 'North Korea', code: 'KP' },
      { name: 'South Korea', code: 'KR' },
      { name: 'United States', code: 'US' },
    ],
  },
  kgp: {
    name: 'Kaingang',
    code: 'kgp',
    regions: [{ name: 'Brazil', code: 'BR' }],
  },
  gub: {
    name: 'Guajajára',
    code: 'gub',
    regions: [{ name: 'Brazil', code: 'BR' }],
  },
  yrl: {
    name: 'Nheengatu',
    code: 'yrl',
    regions: [{ name: 'Brazil', code: 'BR' }],
  },
  xav: {
    name: 'Xavánte',
    code: 'xav',
    regions: [{ name: 'Brazil', code: 'BR' }],
  },
  ms: {
    name: 'Malay',
    code: 'ms',
    regions: [
      { name: 'Brunei', code: 'BN' },
      { name: 'Malaysia', code: 'MY' },
      { name: 'Singapore', code: 'SG' },
    ],
  },
  'ms-Arab': {
    name: 'Malay (Arabic)',
    code: 'ms-Arab',
    regions: [
      { name: 'Brunei', code: 'BN' },
      { name: 'Cocos (Keeling) Islands', code: 'CC' },
      { name: 'Indonesia', code: 'ID' },
    ],
  },
  bg: {
    name: 'Bulgarian',
    code: 'bg',
    regions: [
      { name: 'Bulgaria', code: 'BG' },
      { name: 'Greece', code: 'GR' },
      { name: 'Moldova', code: 'MD' },
      { name: 'Romania', code: 'RO' },
      { name: 'Turkey', code: 'TR' },
      { name: 'Ukraine', code: 'UA' },
    ],
  },
  tr: {
    name: 'Turkish',
    code: 'tr',
    regions: [
      { name: 'Bulgaria', code: 'BG' },
      { name: 'Cyprus', code: 'CY' },
      { name: 'Germany', code: 'DE' },
      { name: 'Greece', code: 'GR' },
      { name: 'Macedonia', code: 'MK' },
      { name: 'Netherlands', code: 'NL' },
      { name: 'Romania', code: 'RO' },
      { name: 'Turkey', code: 'TR' },
      { name: 'Ukraine', code: 'UA' },
      { name: 'Uzbekistan', code: 'UZ' },
    ],
  },
  mos: {
    name: 'Mossi',
    code: 'mos',
    regions: [{ name: 'Burkina Faso', code: 'BF' }],
  },
  dyu: {
    name: 'Dyula',
    code: 'dyu',
    regions: [{ name: 'Burkina Faso', code: 'BF' }],
  },
  ff: {
    name: 'Fulah',
    code: 'ff',
    regions: [
      { name: 'Burkina Faso', code: 'BF' },
      { name: 'Cameroon', code: 'CM' },
      { name: 'Gambia', code: 'GM' },
      { name: 'Ghana', code: 'GH' },
      { name: 'Guinea', code: 'GN' },
      { name: 'Guinea-Bissau', code: 'GW' },
      { name: 'Liberia', code: 'LR' },
      { name: 'Mauritania', code: 'MR' },
      { name: 'Niger', code: 'NE' },
      { name: 'Nigeria', code: 'NG' },
      { name: 'Senegal', code: 'SN' },
      { name: 'Sierra Leone', code: 'SL' },
    ],
  },
  'ff-Adlm': {
    name: 'Fulah (Adlam)',
    code: 'ff-Adlm',
    regions: [
      { name: 'Burkina Faso', code: 'BF' },
      { name: 'Cameroon', code: 'CM' },
      { name: 'Gambia', code: 'GM' },
      { name: 'Ghana', code: 'GH' },
      { name: 'Guinea', code: 'GN' },
      { name: 'Guinea-Bissau', code: 'GW' },
      { name: 'Liberia', code: 'LR' },
      { name: 'Mauritania', code: 'MR' },
      { name: 'Niger', code: 'NE' },
      { name: 'Nigeria', code: 'NG' },
      { name: 'Senegal', code: 'SN' },
      { name: 'Sierra Leone', code: 'SL' },
    ],
  },
  rn: {
    name: 'Rundi',
    code: 'rn',
    regions: [{ name: 'Burundi', code: 'BI' }],
  },
  sw: {
    name: 'Swahili',
    code: 'sw',
    regions: [
      { name: 'Burundi', code: 'BI' },
      { name: 'Congo - Kinshasa', code: 'CD' },
      { name: 'Kenya', code: 'KE' },
      { name: 'Mayotte', code: 'YT' },
      { name: 'Mozambique', code: 'MZ' },
      { name: 'Somalia', code: 'SO' },
      { name: 'South Africa', code: 'ZA' },
      { name: 'Tanzania', code: 'TZ' },
      { name: 'Uganda', code: 'UG' },
    ],
  },
  km: {
    name: 'Khmer',
    code: 'km',
    regions: [{ name: 'Cambodia', code: 'KH' }],
  },
  cja: {
    name: 'Western Cham',
    code: 'cja',
    regions: [{ name: 'Cambodia', code: 'KH' }],
  },
  kdt: {
    name: 'Kuy',
    code: 'kdt',
    regions: [
      { name: 'Cambodia', code: 'KH' },
      { name: 'Laos', code: 'LA' },
      { name: 'Thailand', code: 'TH' },
    ],
  },
  bum: {
    name: 'Bulu',
    code: 'bum',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  ewo: {
    name: 'Ewondo',
    code: 'ewo',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  ybb: {
    name: 'Yemba',
    code: 'ybb',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  bbj: {
    name: 'Ghomala',
    code: 'bbj',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  nnh: {
    name: 'Ngiemboon',
    code: 'nnh',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  bkm: {
    name: 'Kom',
    code: 'bkm',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  bas: {
    name: 'Basaa',
    code: 'bas',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  bax: {
    name: 'Bamun',
    code: 'bax',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  byv: {
    name: 'Medumba',
    code: 'byv',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  mua: {
    name: 'Mundang',
    code: 'mua',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  maf: {
    name: 'Mafa',
    code: 'maf',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  bfd: {
    name: 'Bafut',
    code: 'bfd',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  bss: {
    name: 'Akoose',
    code: 'bss',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  kkj: {
    name: 'Kako',
    code: 'kkj',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  dua: {
    name: 'Duala',
    code: 'dua',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  mgo: {
    name: 'Metaʼ',
    code: 'mgo',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  jgo: {
    name: 'Ngomba',
    code: 'jgo',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  ksf: {
    name: 'Bafia',
    code: 'ksf',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  agq: {
    name: 'Aghem',
    code: 'agq',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  'ha-Arab': {
    name: 'Hausa (Arabic)',
    code: 'ha-Arab',
    regions: [
      { name: 'Cameroon', code: 'CM' },
      { name: 'Nigeria', code: 'NG' },
      { name: 'Sudan', code: 'SD' },
    ],
  },
  nmg: {
    name: 'Kwasio',
    code: 'nmg',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  yav: {
    name: 'Yangben',
    code: 'yav',
    regions: [{ name: 'Cameroon', code: 'CM' }],
  },
  pdt: {
    name: 'Plautdietsch',
    code: 'pdt',
    regions: [{ name: 'Canada', code: 'CA' }],
  },
  cr: {
    name: 'Cree',
    code: 'cr',
    regions: [{ name: 'Canada', code: 'CA' }],
  },
  crk: {
    name: 'Plains Cree',
    code: 'crk',
    regions: [{ name: 'Canada', code: 'CA' }],
  },
  yi: {
    name: 'Yiddish',
    code: 'yi',
    regions: [
      { name: 'Canada', code: 'CA' },
      { name: 'Israel', code: 'IL' },
      { name: 'Sweden', code: 'SE' },
      { name: 'Ukraine', code: 'UA' },
      { name: 'United Kingdom', code: 'GB' },
      { name: 'United States', code: 'US' },
    ],
  },
  iu: {
    name: 'Inuktitut',
    code: 'iu',
    regions: [{ name: 'Canada', code: 'CA' }],
  },
  'iu-Latn': {
    name: 'Inuktitut (Latin)',
    code: 'iu-Latn',
    regions: [{ name: 'Canada', code: 'CA' }],
  },
  moe: {
    name: 'Montagnais',
    code: 'moe',
    regions: [{ name: 'Canada', code: 'CA' }],
  },
  crj: {
    name: 'Southern East Cree',
    code: 'crj',
    regions: [{ name: 'Canada', code: 'CA' }],
  },
  atj: {
    name: 'Atikamekw',
    code: 'atj',
    regions: [{ name: 'Canada', code: 'CA' }],
  },
  crl: {
    name: 'Northern East Cree',
    code: 'crl',
    regions: [{ name: 'Canada', code: 'CA' }],
  },
  csw: {
    name: 'Swampy Cree',
    code: 'csw',
    regions: [{ name: 'Canada', code: 'CA' }],
  },
  crm: {
    name: 'Moose Cree',
    code: 'crm',
    regions: [{ name: 'Canada', code: 'CA' }],
  },
  ikt: {
    name: 'Inuinnaqtun',
    code: 'ikt',
    regions: [{ name: 'Canada', code: 'CA' }],
  },
  moh: {
    name: 'Mohawk',
    code: 'moh',
    regions: [{ name: 'Canada', code: 'CA' }],
  },
  dgr: {
    name: 'Dogrib',
    code: 'dgr',
    regions: [{ name: 'Canada', code: 'CA' }],
  },
  den: {
    name: 'Slave',
    code: 'den',
    regions: [{ name: 'Canada', code: 'CA' }],
  },
  scs: {
    name: 'North Slavey',
    code: 'scs',
    regions: [{ name: 'Canada', code: 'CA' }],
  },
  nsk: {
    name: 'Naskapi',
    code: 'nsk',
    regions: [{ name: 'Canada', code: 'CA' }],
  },
  chp: {
    name: 'Chipewyan',
    code: 'chp',
    regions: [{ name: 'Canada', code: 'CA' }],
  },
  gwi: {
    name: 'Gwichʼin',
    code: 'gwi',
    regions: [{ name: 'Canada', code: 'CA' }],
  },
  kea: {
    name: 'Kabuverdianu',
    code: 'kea',
    regions: [{ name: 'Cape Verde', code: 'CV' }],
  },
  sg: {
    name: 'Sango',
    code: 'sg',
    regions: [{ name: 'Central African Republic', code: 'CF' }],
  },
  arn: {
    name: 'Mapuche',
    code: 'arn',
    regions: [{ name: 'Chile', code: 'CL' }],
  },
  zh: {
    name: 'Chinese',
    code: 'zh',
    regions: [
      { name: 'China', code: 'CN' },
      { name: 'Hong Kong SAR China', code: 'HK' },
      { name: 'Macau SAR China', code: 'MO' },
      { name: 'Malaysia', code: 'MY' },
      { name: 'Mongolia', code: 'MN' },
      { name: 'Singapore', code: 'SG' },
      { name: 'Taiwan', code: 'TW' },
    ],
  },
  wuu: {
    name: 'Wu Chinese',
    code: 'wuu',
    regions: [{ name: 'China', code: 'CN' }],
  },
  'yue-Hans': {
    name: 'Cantonese (Simplified)',
    code: 'yue-Hans',
    regions: [{ name: 'China', code: 'CN' }],
  },
  hsn: {
    name: 'Xiang Chinese',
    code: 'hsn',
    regions: [{ name: 'China', code: 'CN' }],
  },
  hak: {
    name: 'Hakka Chinese',
    code: 'hak',
    regions: [{ name: 'China', code: 'CN' }],
  },
  nan: {
    name: 'Min Nan Chinese',
    code: 'nan',
    regions: [{ name: 'China', code: 'CN' }],
  },
  gan: {
    name: 'Gan Chinese',
    code: 'gan',
    regions: [{ name: 'China', code: 'CN' }],
  },
  ii: {
    name: 'Sichuan Yi',
    code: 'ii',
    regions: [{ name: 'China', code: 'CN' }],
  },
  za: {
    name: 'Zhuang',
    code: 'za',
    regions: [{ name: 'China', code: 'CN' }],
  },
  'mn-Mong': {
    name: 'Mongolian (Mongolian)',
    code: 'mn-Mong',
    regions: [{ name: 'China', code: 'CN' }],
  },
  bo: {
    name: 'Tibetan',
    code: 'bo',
    regions: [
      { name: 'China', code: 'CN' },
      { name: 'India', code: 'IN' },
      { name: 'Nepal', code: 'NP' },
    ],
  },
  lis: {
    name: 'Lisu',
    code: 'lis',
    regions: [{ name: 'China', code: 'CN' }],
  },
  'ky-Arab': {
    name: 'Kyrgyz (Arabic)',
    code: 'ky-Arab',
    regions: [{ name: 'China', code: 'CN' }],
  },
  nxq: {
    name: 'Naxi',
    code: 'nxq',
    regions: [{ name: 'China', code: 'CN' }],
  },
  khb: {
    name: 'Lü',
    code: 'khb',
    regions: [{ name: 'China', code: 'CN' }],
  },
  tdd: {
    name: 'Tai Nüa',
    code: 'tdd',
    regions: [{ name: 'China', code: 'CN' }],
  },
  lcp: {
    name: 'Western Lawa',
    code: 'lcp',
    regions: [
      { name: 'China', code: 'CN' },
      { name: 'Thailand', code: 'TH' },
    ],
  },
  vi: {
    name: 'Vietnamese',
    code: 'vi',
    regions: [
      { name: 'China', code: 'CN' },
      { name: 'United States', code: 'US' },
      { name: 'Vietnam', code: 'VN' },
    ],
  },
  'uz-Cyrl': {
    name: 'Uzbek (Cyrillic)',
    code: 'uz-Cyrl',
    regions: [
      { name: 'China', code: 'CN' },
      { name: 'Uzbekistan', code: 'UZ' },
    ],
  },
  lzh: {
    name: 'Literary Chinese',
    code: 'lzh',
    regions: [{ name: 'China', code: 'CN' }],
  },
  guc: {
    name: 'Wayuu',
    code: 'guc',
    regions: [{ name: 'Colombia', code: 'CO' }],
  },
  zdj: {
    name: 'Ngazidja Comorian',
    code: 'zdj',
    regions: [{ name: 'Comoros', code: 'KM' }],
  },
  wni: {
    name: 'Ndzwani Comorian',
    code: 'wni',
    regions: [{ name: 'Comoros', code: 'KM' }],
  },
  lua: {
    name: 'Luba-Lulua',
    code: 'lua',
    regions: [{ name: 'Congo - Kinshasa', code: 'CD' }],
  },
  lu: {
    name: 'Luba-Katanga',
    code: 'lu',
    regions: [{ name: 'Congo - Kinshasa', code: 'CD' }],
  },
  kg: {
    name: 'Kongo',
    code: 'kg',
    regions: [{ name: 'Congo - Kinshasa', code: 'CD' }],
  },
  lol: {
    name: 'Mongo',
    code: 'lol',
    regions: [{ name: 'Congo - Kinshasa', code: 'CD' }],
  },
  rw: {
    name: 'Kinyarwanda',
    code: 'rw',
    regions: [
      { name: 'Congo - Kinshasa', code: 'CD' },
      { name: 'Rwanda', code: 'RW' },
      { name: 'Uganda', code: 'UG' },
    ],
  },
  bci: {
    name: 'Baoulé',
    code: 'bci',
    regions: [{ name: 'Côte d’Ivoire', code: 'CI' }],
  },
  sef: {
    name: 'Cebaara Senoufo',
    code: 'sef',
    regions: [{ name: 'Côte d’Ivoire', code: 'CI' }],
  },
  dnj: {
    name: 'Dan',
    code: 'dnj',
    regions: [{ name: 'Côte d’Ivoire', code: 'CI' }],
  },
  kfo: {
    name: 'Koro',
    code: 'kfo',
    regions: [{ name: 'Côte d’Ivoire', code: 'CI' }],
  },
  bqv: {
    name: 'Koro Wachi',
    code: 'bqv',
    regions: [{ name: 'Côte d’Ivoire', code: 'CI' }],
  },
  cs: {
    name: 'Czech',
    code: 'cs',
    regions: [
      { name: 'Czechia', code: 'CZ' },
      { name: 'Slovakia', code: 'SK' },
    ],
  },
  sk: {
    name: 'Slovak',
    code: 'sk',
    regions: [
      { name: 'Czechia', code: 'CZ' },
      { name: 'Hungary', code: 'HU' },
      { name: 'Serbia', code: 'RS' },
      { name: 'Slovakia', code: 'SK' },
    ],
  },
  pl: {
    name: 'Polish',
    code: 'pl',
    regions: [
      { name: 'Czechia', code: 'CZ' },
      { name: 'Germany', code: 'DE' },
      { name: 'Israel', code: 'IL' },
      { name: 'Poland', code: 'PL' },
      { name: 'Romania', code: 'RO' },
      { name: 'Slovakia', code: 'SK' },
      { name: 'Ukraine', code: 'UA' },
    ],
  },
  da: {
    name: 'Danish',
    code: 'da',
    regions: [
      { name: 'Denmark', code: 'DK' },
      { name: 'Germany', code: 'DE' },
      { name: 'Greenland', code: 'GL' },
      { name: 'Iceland', code: 'IS' },
    ],
  },
  fo: {
    name: 'Faroese',
    code: 'fo',
    regions: [
      { name: 'Denmark', code: 'DK' },
      { name: 'Faroe Islands', code: 'FO' },
    ],
  },
  kl: {
    name: 'Kalaallisut',
    code: 'kl',
    regions: [
      { name: 'Denmark', code: 'DK' },
      { name: 'Greenland', code: 'GL' },
    ],
  },
  jut: {
    name: 'Jutish',
    code: 'jut',
    regions: [{ name: 'Denmark', code: 'DK' }],
  },
  aa: {
    name: 'Afar',
    code: 'aa',
    regions: [
      { name: 'Djibouti', code: 'DJ' },
      { name: 'Eritrea', code: 'ER' },
      { name: 'Ethiopia', code: 'ET' },
    ],
  },
  so: {
    name: 'Somali',
    code: 'so',
    regions: [
      { name: 'Djibouti', code: 'DJ' },
      { name: 'Ethiopia', code: 'ET' },
      { name: 'Kenya', code: 'KE' },
      { name: 'Somalia', code: 'SO' },
    ],
  },
  qug: {
    name: 'Chimborazo Highland Quichua',
    code: 'qug',
    regions: [{ name: 'Ecuador', code: 'EC' }],
  },
  arz: {
    name: 'Egyptian Arabic',
    code: 'arz',
    regions: [{ name: 'Egypt', code: 'EG' }],
  },
  fan: {
    name: 'Fang',
    code: 'fan',
    regions: [{ name: 'Equatorial Guinea', code: 'GQ' }],
  },
  bvb: {
    name: 'Bube',
    code: 'bvb',
    regions: [{ name: 'Equatorial Guinea', code: 'GQ' }],
  },
  ti: {
    name: 'Tigrinya',
    code: 'ti',
    regions: [
      { name: 'Eritrea', code: 'ER' },
      { name: 'Ethiopia', code: 'ET' },
      { name: 'Israel', code: 'IL' },
    ],
  },
  tig: {
    name: 'Tigre',
    code: 'tig',
    regions: [{ name: 'Eritrea', code: 'ER' }],
  },
  ssy: {
    name: 'Saho',
    code: 'ssy',
    regions: [{ name: 'Eritrea', code: 'ER' }],
  },
  byn: {
    name: 'Blin',
    code: 'byn',
    regions: [{ name: 'Eritrea', code: 'ER' }],
  },
  et: {
    name: 'Estonian',
    code: 'et',
    regions: [
      { name: 'Estonia', code: 'EE' },
      { name: 'Finland', code: 'FI' },
    ],
  },
  fi: {
    name: 'Finnish',
    code: 'fi',
    regions: [
      { name: 'Estonia', code: 'EE' },
      { name: 'Finland', code: 'FI' },
      { name: 'Russia', code: 'RU' },
      { name: 'Sweden', code: 'SE' },
    ],
  },
  vro: {
    name: 'Võro',
    code: 'vro',
    regions: [{ name: 'Estonia', code: 'EE' }],
  },
  am: {
    name: 'Amharic',
    code: 'am',
    regions: [
      { name: 'Ethiopia', code: 'ET' },
      { name: 'Israel', code: 'IL' },
    ],
  },
  om: {
    name: 'Oromo',
    code: 'om',
    regions: [
      { name: 'Ethiopia', code: 'ET' },
      { name: 'Kenya', code: 'KE' },
      { name: 'Somalia', code: 'SO' },
    ],
  },
  sid: {
    name: 'Sidamo',
    code: 'sid',
    regions: [{ name: 'Ethiopia', code: 'ET' }],
  },
  wal: {
    name: 'Wolaytta',
    code: 'wal',
    regions: [{ name: 'Ethiopia', code: 'ET' }],
  },
  hi: {
    name: 'Hindi',
    code: 'hi',
    regions: [
      { name: 'Fiji', code: 'FJ' },
      { name: 'India', code: 'IN' },
      { name: 'Nepal', code: 'NP' },
      { name: 'South Africa', code: 'ZA' },
      { name: 'Uganda', code: 'UG' },
    ],
  },
  hif: {
    name: 'Fiji Hindi',
    code: 'hif',
    regions: [{ name: 'Fiji', code: 'FJ' }],
  },
  fj: {
    name: 'Fijian',
    code: 'fj',
    regions: [{ name: 'Fiji', code: 'FJ' }],
  },
  rtm: {
    name: 'Rotuman',
    code: 'rtm',
    regions: [{ name: 'Fiji', code: 'FJ' }],
  },
  rmf: {
    name: 'Kalo Finnish Romani',
    code: 'rmf',
    regions: [{ name: 'Finland', code: 'FI' }],
  },
  se: {
    name: 'Northern Sami',
    code: 'se',
    regions: [
      { name: 'Finland', code: 'FI' },
      { name: 'Norway', code: 'NO' },
      { name: 'Sweden', code: 'SE' },
    ],
  },
  smn: {
    name: 'Inari Sami',
    code: 'smn',
    regions: [{ name: 'Finland', code: 'FI' }],
  },
  sms: {
    name: 'Skolt Sami',
    code: 'sms',
    regions: [{ name: 'Finland', code: 'FI' }],
  },
  oc: {
    name: 'Occitan',
    code: 'oc',
    regions: [{ name: 'France', code: 'FR' }],
  },
  pcd: {
    name: 'Picard',
    code: 'pcd',
    regions: [{ name: 'France', code: 'FR' }],
  },
  gsw: {
    name: 'Swiss German',
    code: 'gsw',
    regions: [
      { name: 'France', code: 'FR' },
      { name: 'Germany', code: 'DE' },
      { name: 'Liechtenstein', code: 'LI' },
      { name: 'Switzerland', code: 'CH' },
    ],
  },
  br: {
    name: 'Breton',
    code: 'br',
    regions: [{ name: 'France', code: 'FR' }],
  },
  co: {
    name: 'Corsican',
    code: 'co',
    regions: [{ name: 'France', code: 'FR' }],
  },
  eu: {
    name: 'Basque',
    code: 'eu',
    regions: [
      { name: 'France', code: 'FR' },
      { name: 'Spain', code: 'ES' },
    ],
  },
  frp: {
    name: 'Arpitan',
    code: 'frp',
    regions: [{ name: 'France', code: 'FR' }],
  },
  ia: {
    name: 'Interlingua',
    code: 'ia',
    regions: [
      { name: 'France', code: 'FR' },
      { name: 'Sweden', code: 'SE' },
    ],
  },
  gcr: {
    name: 'Guianese Creole French',
    code: 'gcr',
    regions: [{ name: 'French Guiana', code: 'GF' }],
  },
  ty: {
    name: 'Tahitian',
    code: 'ty',
    regions: [{ name: 'French Polynesia', code: 'PF' }],
  },
  puu: {
    name: 'Punu',
    code: 'puu',
    regions: [{ name: 'Gabon', code: 'GA' }],
  },
  man: {
    name: 'Mandingo',
    code: 'man',
    regions: [{ name: 'Gambia', code: 'GM' }],
  },
  ka: {
    name: 'Georgian',
    code: 'ka',
    regions: [
      { name: 'Georgia', code: 'GE' },
      { name: 'Iran', code: 'IR' },
      { name: 'Turkey', code: 'TR' },
    ],
  },
  xmf: {
    name: 'Mingrelian',
    code: 'xmf',
    regions: [{ name: 'Georgia', code: 'GE' }],
  },
  ab: {
    name: 'Abkhazian',
    code: 'ab',
    regions: [
      { name: 'Georgia', code: 'GE' },
      { name: 'Turkey', code: 'TR' },
    ],
  },
  os: {
    name: 'Ossetic',
    code: 'os',
    regions: [
      { name: 'Georgia', code: 'GE' },
      { name: 'Russia', code: 'RU' },
    ],
  },
  nds: {
    name: 'Low German',
    code: 'nds',
    regions: [
      { name: 'Germany', code: 'DE' },
      { name: 'Netherlands', code: 'NL' },
    ],
  },
  vmf: {
    name: 'Main-Franconian',
    code: 'vmf',
    regions: [{ name: 'Germany', code: 'DE' }],
  },
  swg: {
    name: 'Swabian',
    code: 'swg',
    regions: [{ name: 'Germany', code: 'DE' }],
  },
  ksh: {
    name: 'Colognian',
    code: 'ksh',
    regions: [{ name: 'Germany', code: 'DE' }],
  },
  hsb: {
    name: 'Upper Sorbian',
    code: 'hsb',
    regions: [{ name: 'Germany', code: 'DE' }],
  },
  frr: {
    name: 'Northern Frisian',
    code: 'frr',
    regions: [{ name: 'Germany', code: 'DE' }],
  },
  dsb: {
    name: 'Lower Sorbian',
    code: 'dsb',
    regions: [{ name: 'Germany', code: 'DE' }],
  },
  frs: {
    name: 'Eastern Frisian',
    code: 'frs',
    regions: [{ name: 'Germany', code: 'DE' }],
  },
  stq: {
    name: 'Saterland Frisian',
    code: 'stq',
    regions: [{ name: 'Germany', code: 'DE' }],
  },
  pfl: {
    name: 'Palatine German',
    code: 'pfl',
    regions: [{ name: 'Germany', code: 'DE' }],
  },
  ak: {
    name: 'Akan',
    code: 'ak',
    regions: [{ name: 'Ghana', code: 'GH' }],
  },
  ee: {
    name: 'Ewe',
    code: 'ee',
    regions: [
      { name: 'Ghana', code: 'GH' },
      { name: 'Togo', code: 'TG' },
    ],
  },
  abr: {
    name: 'Abron',
    code: 'abr',
    regions: [{ name: 'Ghana', code: 'GH' }],
  },
  gur: {
    name: 'Frafra',
    code: 'gur',
    regions: [{ name: 'Ghana', code: 'GH' }],
  },
  ada: {
    name: 'Adangme',
    code: 'ada',
    regions: [{ name: 'Ghana', code: 'GH' }],
  },
  gaa: {
    name: 'Ga',
    code: 'gaa',
    regions: [{ name: 'Ghana', code: 'GH' }],
  },
  nzi: {
    name: 'Nzima',
    code: 'nzi',
    regions: [{ name: 'Ghana', code: 'GH' }],
  },
  ha: {
    name: 'Hausa',
    code: 'ha',
    regions: [
      { name: 'Ghana', code: 'GH' },
      { name: 'Niger', code: 'NE' },
      { name: 'Nigeria', code: 'NG' },
    ],
  },
  saf: {
    name: 'Safaliba',
    code: 'saf',
    regions: [{ name: 'Ghana', code: 'GH' }],
  },
  pnt: {
    name: 'Pontic',
    code: 'pnt',
    regions: [{ name: 'Greece', code: 'GR' }],
  },
  tsd: {
    name: 'Tsakonian',
    code: 'tsd',
    regions: [{ name: 'Greece', code: 'GR' }],
  },
  ch: {
    name: 'Chamorro',
    code: 'ch',
    regions: [
      { name: 'Guam', code: 'GU' },
      { name: 'Northern Mariana Islands', code: 'MP' },
    ],
  },
  quc: {
    name: 'Kʼicheʼ',
    code: 'quc',
    regions: [{ name: 'Guatemala', code: 'GT' }],
  },
  'man-Nkoo': {
    name: 'Mandingo (N’Ko)',
    code: 'man-Nkoo',
    regions: [{ name: 'Guinea', code: 'GN' }],
  },
  sus: {
    name: 'Susu',
    code: 'sus',
    regions: [{ name: 'Guinea', code: 'GN' }],
  },
  nqo: {
    name: 'N’Ko',
    code: 'nqo',
    regions: [{ name: 'Guinea', code: 'GN' }],
  },
  kpe: {
    name: 'Kpelle',
    code: 'kpe',
    regions: [
      { name: 'Guinea', code: 'GN' },
      { name: 'Liberia', code: 'LR' },
    ],
  },
  knf: {
    name: 'Mankanya',
    code: 'knf',
    regions: [
      { name: 'Guinea-Bissau', code: 'GW' },
      { name: 'Senegal', code: 'SN' },
    ],
  },
  ht: {
    name: 'Haitian Creole',
    code: 'ht',
    regions: [{ name: 'Haiti', code: 'HT' }],
  },
  yue: {
    name: 'Cantonese',
    code: 'yue',
    regions: [{ name: 'Hong Kong SAR China', code: 'HK' }],
  },
  ro: {
    name: 'Romanian',
    code: 'ro',
    regions: [
      { name: 'Hungary', code: 'HU' },
      { name: 'Israel', code: 'IL' },
      { name: 'Moldova', code: 'MD' },
      { name: 'Romania', code: 'RO' },
      { name: 'Serbia', code: 'RS' },
      { name: 'Ukraine', code: 'UA' },
    ],
  },
  is: {
    name: 'Icelandic',
    code: 'is',
    regions: [{ name: 'Iceland', code: 'IS' }],
  },
  te: {
    name: 'Telugu',
    code: 'te',
    regions: [{ name: 'India', code: 'IN' }],
  },
  mr: {
    name: 'Marathi',
    code: 'mr',
    regions: [{ name: 'India', code: 'IN' }],
  },
  ta: {
    name: 'Tamil',
    code: 'ta',
    regions: [
      { name: 'India', code: 'IN' },
      { name: 'Malaysia', code: 'MY' },
      { name: 'Mauritius', code: 'MU' },
      { name: 'Réunion', code: 'RE' },
      { name: 'Singapore', code: 'SG' },
      { name: 'Sri Lanka', code: 'LK' },
    ],
  },
  ur: {
    name: 'Urdu',
    code: 'ur',
    regions: [
      { name: 'India', code: 'IN' },
      { name: 'Mauritius', code: 'MU' },
      { name: 'Pakistan', code: 'PK' },
    ],
  },
  gu: {
    name: 'Gujarati',
    code: 'gu',
    regions: [
      { name: 'India', code: 'IN' },
      { name: 'Kenya', code: 'KE' },
    ],
  },
  kn: {
    name: 'Kannada',
    code: 'kn',
    regions: [{ name: 'India', code: 'IN' }],
  },
  or: {
    name: 'Odia',
    code: 'or',
    regions: [{ name: 'India', code: 'IN' }],
  },
  pa: {
    name: 'Punjabi',
    code: 'pa',
    regions: [
      { name: 'India', code: 'IN' },
      { name: 'Kenya', code: 'KE' },
      { name: 'Singapore', code: 'SG' },
      { name: 'United Kingdom', code: 'GB' },
    ],
  },
  bho: {
    name: 'Bhojpuri',
    code: 'bho',
    regions: [
      { name: 'India', code: 'IN' },
      { name: 'Mauritius', code: 'MU' },
      { name: 'Nepal', code: 'NP' },
    ],
  },
  awa: {
    name: 'Awadhi',
    code: 'awa',
    regions: [
      { name: 'India', code: 'IN' },
      { name: 'Nepal', code: 'NP' },
    ],
  },
  as: {
    name: 'Assamese',
    code: 'as',
    regions: [{ name: 'India', code: 'IN' }],
  },
  bgc: {
    name: 'Haryanvi',
    code: 'bgc',
    regions: [{ name: 'India', code: 'IN' }],
  },
  mag: {
    name: 'Magahi',
    code: 'mag',
    regions: [{ name: 'India', code: 'IN' }],
  },
  mai: {
    name: 'Maithili',
    code: 'mai',
    regions: [
      { name: 'India', code: 'IN' },
      { name: 'Nepal', code: 'NP' },
    ],
  },
  mwr: {
    name: 'Marwari',
    code: 'mwr',
    regions: [{ name: 'India', code: 'IN' }],
  },
  hne: {
    name: 'Chhattisgarhi',
    code: 'hne',
    regions: [{ name: 'India', code: 'IN' }],
  },
  dcc: {
    name: 'Deccan',
    code: 'dcc',
    regions: [{ name: 'India', code: 'IN' }],
  },
  bjj: {
    name: 'Kanauji',
    code: 'bjj',
    regions: [{ name: 'India', code: 'IN' }],
  },
  sat: {
    name: 'Santali',
    code: 'sat',
    regions: [{ name: 'India', code: 'IN' }],
  },
  wtm: {
    name: 'Mewati',
    code: 'wtm',
    regions: [{ name: 'India', code: 'IN' }],
  },
  ks: {
    name: 'Kashmiri',
    code: 'ks',
    regions: [
      { name: 'India', code: 'IN' },
      { name: 'Pakistan', code: 'PK' },
      { name: 'United Kingdom', code: 'GB' },
    ],
  },
  kok: {
    name: 'Konkani',
    code: 'kok',
    regions: [{ name: 'India', code: 'IN' }],
  },
  gom: {
    name: 'Goan Konkani',
    code: 'gom',
    regions: [{ name: 'India', code: 'IN' }],
  },
  swv: {
    name: 'Shekhawati',
    code: 'swv',
    regions: [{ name: 'India', code: 'IN' }],
  },
  gbm: {
    name: 'Garhwali',
    code: 'gbm',
    regions: [{ name: 'India', code: 'IN' }],
  },
  lmn: {
    name: 'Lambadi',
    code: 'lmn',
    regions: [{ name: 'India', code: 'IN' }],
  },
  sd: {
    name: 'Sindhi',
    code: 'sd',
    regions: [
      { name: 'India', code: 'IN' },
      { name: 'Pakistan', code: 'PK' },
    ],
  },
  gon: {
    name: 'Gondi',
    code: 'gon',
    regions: [{ name: 'India', code: 'IN' }],
  },
  kfy: {
    name: 'Kumaoni',
    code: 'kfy',
    regions: [{ name: 'India', code: 'IN' }],
  },
  doi: {
    name: 'Dogri',
    code: 'doi',
    regions: [{ name: 'India', code: 'IN' }],
  },
  kru: {
    name: 'Kurukh',
    code: 'kru',
    regions: [{ name: 'India', code: 'IN' }],
  },
  sck: {
    name: 'Sadri',
    code: 'sck',
    regions: [{ name: 'India', code: 'IN' }],
  },
  wbq: {
    name: 'Waddar',
    code: 'wbq',
    regions: [{ name: 'India', code: 'IN' }],
  },
  xnr: {
    name: 'Kangri',
    code: 'xnr',
    regions: [{ name: 'India', code: 'IN' }],
  },
  khn: {
    name: 'Khandesi',
    code: 'khn',
    regions: [{ name: 'India', code: 'IN' }],
  },
  tcy: {
    name: 'Tulu',
    code: 'tcy',
    regions: [{ name: 'India', code: 'IN' }],
  },
  wbr: {
    name: 'Wagdi',
    code: 'wbr',
    regions: [{ name: 'India', code: 'IN' }],
  },
  brx: {
    name: 'Bodo',
    code: 'brx',
    regions: [{ name: 'India', code: 'IN' }],
  },
  noe: {
    name: 'Nimadi',
    code: 'noe',
    regions: [{ name: 'India', code: 'IN' }],
  },
  bhb: {
    name: 'Bhili',
    code: 'bhb',
    regions: [{ name: 'India', code: 'IN' }],
  },
  hoc: {
    name: 'Ho',
    code: 'hoc',
    regions: [{ name: 'India', code: 'IN' }],
  },
  raj: {
    name: 'Rajasthani',
    code: 'raj',
    regions: [{ name: 'India', code: 'IN' }],
  },
  mtr: {
    name: 'Mewari',
    code: 'mtr',
    regions: [{ name: 'India', code: 'IN' }],
  },
  unr: {
    name: 'Mundari',
    code: 'unr',
    regions: [{ name: 'India', code: 'IN' }],
  },
  bhi: {
    name: 'Bhilali',
    code: 'bhi',
    regions: [{ name: 'India', code: 'IN' }],
  },
  hoj: {
    name: 'Hadothi',
    code: 'hoj',
    regions: [{ name: 'India', code: 'IN' }],
  },
  kha: {
    name: 'Khasi',
    code: 'kha',
    regions: [{ name: 'India', code: 'IN' }],
  },
  kfr: {
    name: 'Kachhi',
    code: 'kfr',
    regions: [{ name: 'India', code: 'IN' }],
  },
  unx: {
    name: 'Munda',
    code: 'unx',
    regions: [{ name: 'India', code: 'IN' }],
  },
  bfy: {
    name: 'Bagheli',
    code: 'bfy',
    regions: [
      { name: 'India', code: 'IN' },
      { name: 'Nepal', code: 'NP' },
    ],
  },
  srx: {
    name: 'Sirmauri',
    code: 'srx',
    regions: [{ name: 'India', code: 'IN' }],
  },
  saz: {
    name: 'Saurashtra',
    code: 'saz',
    regions: [{ name: 'India', code: 'IN' }],
  },
  'sd-Deva': {
    name: 'Sindhi (Devanagari)',
    code: 'sd-Deva',
    regions: [{ name: 'India', code: 'IN' }],
  },
  bfq: {
    name: 'Badaga',
    code: 'bfq',
    regions: [{ name: 'India', code: 'IN' }],
  },
  njo: {
    name: 'Ao Naga',
    code: 'njo',
    regions: [{ name: 'India', code: 'IN' }],
  },
  ria: {
    name: 'Riang [India]',
    code: 'ria',
    regions: [{ name: 'India', code: 'IN' }],
  },
  bpy: {
    name: 'Bishnupriya',
    code: 'bpy',
    regions: [{ name: 'India', code: 'IN' }],
  },
  bft: {
    name: 'Balti',
    code: 'bft',
    regions: [
      { name: 'India', code: 'IN' },
      { name: 'Pakistan', code: 'PK' },
    ],
  },
  bra: {
    name: 'Braj',
    code: 'bra',
    regions: [{ name: 'India', code: 'IN' }],
  },
  btv: {
    name: 'Bateri',
    code: 'btv',
    regions: [
      { name: 'India', code: 'IN' },
      { name: 'Pakistan', code: 'PK' },
    ],
  },
  lif: {
    name: 'Limbu',
    code: 'lif',
    regions: [
      { name: 'India', code: 'IN' },
      { name: 'Nepal', code: 'NP' },
    ],
  },
  lah: {
    name: 'Lahnda',
    code: 'lah',
    regions: [
      { name: 'India', code: 'IN' },
      { name: 'Pakistan', code: 'PK' },
    ],
  },
  sa: {
    name: 'Sanskrit',
    code: 'sa',
    regions: [{ name: 'India', code: 'IN' }],
  },
  kht: {
    name: 'Khamti',
    code: 'kht',
    regions: [
      { name: 'India', code: 'IN' },
      { name: 'Myanmar (Burma)', code: 'MM' },
    ],
  },
  dv: {
    name: 'Divehi',
    code: 'dv',
    regions: [
      { name: 'India', code: 'IN' },
      { name: 'Maldives', code: 'MV' },
    ],
  },
  id: {
    name: 'Indonesian',
    code: 'id',
    regions: [
      { name: 'Indonesia', code: 'ID' },
      { name: 'Netherlands', code: 'NL' },
    ],
  },
  jv: {
    name: 'Javanese',
    code: 'jv',
    regions: [
      { name: 'Indonesia', code: 'ID' },
      { name: 'Malaysia', code: 'MY' },
    ],
  },
  su: {
    name: 'Sundanese',
    code: 'su',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  mad: {
    name: 'Madurese',
    code: 'mad',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  min: {
    name: 'Minangkabau',
    code: 'min',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  bew: {
    name: 'Betawi',
    code: 'bew',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  ban: {
    name: 'Balinese',
    code: 'ban',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  bug: {
    name: 'Buginese',
    code: 'bug',
    regions: [
      { name: 'Indonesia', code: 'ID' },
      { name: 'Malaysia', code: 'MY' },
    ],
  },
  bjn: {
    name: 'Banjar',
    code: 'bjn',
    regions: [
      { name: 'Indonesia', code: 'ID' },
      { name: 'Malaysia', code: 'MY' },
    ],
  },
  ace: {
    name: 'Achinese',
    code: 'ace',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  sas: {
    name: 'Sasak',
    code: 'sas',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  bbc: {
    name: 'Batak Toba',
    code: 'bbc',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  mak: {
    name: 'Makasar',
    code: 'mak',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  ljp: {
    name: 'Lampung Api',
    code: 'ljp',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  rej: {
    name: 'Rejang',
    code: 'rej',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  gor: {
    name: 'Gorontalo',
    code: 'gor',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  nij: {
    name: 'Ngaju',
    code: 'nij',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  kge: {
    name: 'Komering',
    code: 'kge',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  aoz: {
    name: 'Uab Meto',
    code: 'aoz',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  kvr: {
    name: 'Kerinci',
    code: 'kvr',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  lbw: {
    name: 'Tolaki',
    code: 'lbw',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  gay: {
    name: 'Gayo',
    code: 'gay',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  rob: {
    name: "Tae'",
    code: 'rob',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  mdr: {
    name: 'Mandar',
    code: 'mdr',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  sxn: {
    name: 'Sangir',
    code: 'sxn',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  sly: {
    name: 'Selayar',
    code: 'sly',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  mwv: {
    name: 'Mentawai',
    code: 'mwv',
    regions: [{ name: 'Indonesia', code: 'ID' }],
  },
  'az-Arab': {
    name: 'Azerbaijani (Arabic)',
    code: 'az-Arab',
    regions: [
      { name: 'Iran', code: 'IR' },
      { name: 'Iraq', code: 'IQ' },
      { name: 'Turkey', code: 'TR' },
    ],
  },
  mzn: {
    name: 'Mazanderani',
    code: 'mzn',
    regions: [{ name: 'Iran', code: 'IR' }],
  },
  glk: {
    name: 'Gilaki',
    code: 'glk',
    regions: [{ name: 'Iran', code: 'IR' }],
  },
  ckb: {
    name: 'Central Kurdish',
    code: 'ckb',
    regions: [
      { name: 'Iran', code: 'IR' },
      { name: 'Iraq', code: 'IQ' },
    ],
  },
  sdh: {
    name: 'Southern Kurdish',
    code: 'sdh',
    regions: [{ name: 'Iran', code: 'IR' }],
  },
  lrc: {
    name: 'Northern Luri',
    code: 'lrc',
    regions: [
      { name: 'Iran', code: 'IR' },
      { name: 'Iraq', code: 'IQ' },
    ],
  },
  rmt: {
    name: 'Domari',
    code: 'rmt',
    regions: [{ name: 'Iran', code: 'IR' }],
  },
  bqi: {
    name: 'Bakhtiari',
    code: 'bqi',
    regions: [{ name: 'Iran', code: 'IR' }],
  },
  luz: {
    name: 'Southern Luri',
    code: 'luz',
    regions: [{ name: 'Iran', code: 'IR' }],
  },
  lki: {
    name: 'Laki',
    code: 'lki',
    regions: [{ name: 'Iran', code: 'IR' }],
  },
  gbz: {
    name: 'Zoroastrian Dari',
    code: 'gbz',
    regions: [{ name: 'Iran', code: 'IR' }],
  },
  syr: {
    name: 'Syriac',
    code: 'syr',
    regions: [
      { name: 'Iraq', code: 'IQ' },
      { name: 'Syria', code: 'SY' },
    ],
  },
  ga: {
    name: 'Irish',
    code: 'ga',
    regions: [
      { name: 'Ireland', code: 'IE' },
      { name: 'United Kingdom', code: 'GB' },
    ],
  },
  gv: {
    name: 'Manx',
    code: 'gv',
    regions: [{ name: 'Isle of Man', code: 'IM' }],
  },
  he: {
    name: 'Hebrew',
    code: 'he',
    regions: [{ name: 'Israel', code: 'IL' }],
  },
  lad: {
    name: 'Ladino',
    code: 'lad',
    regions: [{ name: 'Israel', code: 'IL' }],
  },
  sc: {
    name: 'Sardinian',
    code: 'sc',
    regions: [{ name: 'Italy', code: 'IT' }],
  },
  vec: {
    name: 'Venetian',
    code: 'vec',
    regions: [{ name: 'Italy', code: 'IT' }],
  },
  nap: {
    name: 'Neapolitan',
    code: 'nap',
    regions: [{ name: 'Italy', code: 'IT' }],
  },
  lij: {
    name: 'Ligurian',
    code: 'lij',
    regions: [{ name: 'Italy', code: 'IT' }],
  },
  scn: {
    name: 'Sicilian',
    code: 'scn',
    regions: [{ name: 'Italy', code: 'IT' }],
  },
  sdc: {
    name: 'Sassarese Sardinian',
    code: 'sdc',
    regions: [{ name: 'Italy', code: 'IT' }],
  },
  fur: {
    name: 'Friulian',
    code: 'fur',
    regions: [{ name: 'Italy', code: 'IT' }],
  },
  egl: {
    name: 'Emilian',
    code: 'egl',
    regions: [{ name: 'Italy', code: 'IT' }],
  },
  lmo: {
    name: 'Lombard',
    code: 'lmo',
    regions: [
      { name: 'Italy', code: 'IT' },
      { name: 'Switzerland', code: 'CH' },
    ],
  },
  pms: {
    name: 'Piedmontese',
    code: 'pms',
    regions: [{ name: 'Italy', code: 'IT' }],
  },
  rgn: {
    name: 'Romagnol',
    code: 'rgn',
    regions: [{ name: 'Italy', code: 'IT' }],
  },
  jam: {
    name: 'Jamaican Creole English',
    code: 'jam',
    regions: [{ name: 'Jamaica', code: 'JM' }],
  },
  ryu: {
    name: 'Central Okinawan',
    code: 'ryu',
    regions: [{ name: 'Japan', code: 'JP' }],
  },
  kk: {
    name: 'Kazakh',
    code: 'kk',
    regions: [
      { name: 'Kazakhstan', code: 'KZ' },
      { name: 'Turkey', code: 'TR' },
    ],
  },
  'ug-Cyrl': {
    name: 'Uyghur (Cyrillic)',
    code: 'ug-Cyrl',
    regions: [
      { name: 'Kazakhstan', code: 'KZ' },
      { name: 'Mongolia', code: 'MN' },
    ],
  },
  ki: {
    name: 'Kikuyu',
    code: 'ki',
    regions: [{ name: 'Kenya', code: 'KE' }],
  },
  luy: {
    name: 'Luyia',
    code: 'luy',
    regions: [{ name: 'Kenya', code: 'KE' }],
  },
  luo: {
    name: 'Luo',
    code: 'luo',
    regions: [{ name: 'Kenya', code: 'KE' }],
  },
  kam: {
    name: 'Kamba',
    code: 'kam',
    regions: [{ name: 'Kenya', code: 'KE' }],
  },
  kln: {
    name: 'Kalenjin',
    code: 'kln',
    regions: [{ name: 'Kenya', code: 'KE' }],
  },
  guz: {
    name: 'Gusii',
    code: 'guz',
    regions: [{ name: 'Kenya', code: 'KE' }],
  },
  mer: {
    name: 'Meru',
    code: 'mer',
    regions: [{ name: 'Kenya', code: 'KE' }],
  },
  mas: {
    name: 'Masai',
    code: 'mas',
    regions: [
      { name: 'Kenya', code: 'KE' },
      { name: 'Tanzania', code: 'TZ' },
    ],
  },
  ebu: {
    name: 'Embu',
    code: 'ebu',
    regions: [{ name: 'Kenya', code: 'KE' }],
  },
  dav: {
    name: 'Taita',
    code: 'dav',
    regions: [{ name: 'Kenya', code: 'KE' }],
  },
  teo: {
    name: 'Teso',
    code: 'teo',
    regions: [
      { name: 'Kenya', code: 'KE' },
      { name: 'Uganda', code: 'UG' },
    ],
  },
  pko: {
    name: 'Pökoot',
    code: 'pko',
    regions: [{ name: 'Kenya', code: 'KE' }],
  },
  saq: {
    name: 'Samburu',
    code: 'saq',
    regions: [{ name: 'Kenya', code: 'KE' }],
  },
  gil: {
    name: 'Gilbertese',
    code: 'gil',
    regions: [{ name: 'Kiribati', code: 'KI' }],
  },
  aln: {
    name: 'Gheg Albanian',
    code: 'aln',
    regions: [{ name: 'Kosovo', code: 'XK' }],
  },
  ky: {
    name: 'Kyrgyz',
    code: 'ky',
    regions: [{ name: 'Kyrgyzstan', code: 'KG' }],
  },
  lo: { name: 'Lao', code: 'lo', regions: [{ name: 'Laos', code: 'LA' }] },
  kjg: {
    name: 'Khmu',
    code: 'kjg',
    regions: [{ name: 'Laos', code: 'LA' }],
  },
  lv: {
    name: 'Latvian',
    code: 'lv',
    regions: [{ name: 'Latvia', code: 'LV' }],
  },
  ltg: {
    name: 'Latgalian',
    code: 'ltg',
    regions: [{ name: 'Latvia', code: 'LV' }],
  },
  'ku-Arab': {
    name: 'Kurdish (Arabic)',
    code: 'ku-Arab',
    regions: [{ name: 'Lebanon', code: 'LB' }],
  },
  st: {
    name: 'Southern Sotho',
    code: 'st',
    regions: [
      { name: 'Lesotho', code: 'LS' },
      { name: 'South Africa', code: 'ZA' },
    ],
  },
  zu: {
    name: 'Zulu',
    code: 'zu',
    regions: [
      { name: 'Lesotho', code: 'LS' },
      { name: 'Malawi', code: 'MW' },
      { name: 'Mozambique', code: 'MZ' },
      { name: 'South Africa', code: 'ZA' },
      { name: 'Swaziland', code: 'SZ' },
    ],
  },
  ss: {
    name: 'Swati',
    code: 'ss',
    regions: [
      { name: 'Lesotho', code: 'LS' },
      { name: 'South Africa', code: 'ZA' },
      { name: 'Swaziland', code: 'SZ' },
    ],
  },
  xh: {
    name: 'Xhosa',
    code: 'xh',
    regions: [
      { name: 'Lesotho', code: 'LS' },
      { name: 'South Africa', code: 'ZA' },
    ],
  },
  vai: {
    name: 'Vai',
    code: 'vai',
    regions: [{ name: 'Liberia', code: 'LR' }],
  },
  men: {
    name: 'Mende',
    code: 'men',
    regions: [
      { name: 'Liberia', code: 'LR' },
      { name: 'Sierra Leone', code: 'SL' },
    ],
  },
  'vai-Latn': {
    name: 'Vai (Latin)',
    code: 'vai-Latn',
    regions: [{ name: 'Liberia', code: 'LR' }],
  },
  wae: {
    name: 'Walser',
    code: 'wae',
    regions: [
      { name: 'Liechtenstein', code: 'LI' },
      { name: 'Switzerland', code: 'CH' },
    ],
  },
  lt: {
    name: 'Lithuanian',
    code: 'lt',
    regions: [
      { name: 'Lithuania', code: 'LT' },
      { name: 'Poland', code: 'PL' },
    ],
  },
  sgs: {
    name: 'Samogitian',
    code: 'sgs',
    regions: [{ name: 'Lithuania', code: 'LT' }],
  },
  lb: {
    name: 'Luxembourgish',
    code: 'lb',
    regions: [{ name: 'Luxembourg', code: 'LU' }],
  },
  mg: {
    name: 'Malagasy',
    code: 'mg',
    regions: [{ name: 'Madagascar', code: 'MG' }],
  },
  ny: {
    name: 'Nyanja',
    code: 'ny',
    regions: [
      { name: 'Malawi', code: 'MW' },
      { name: 'Mozambique', code: 'MZ' },
      { name: 'Zambia', code: 'ZM' },
      { name: 'Zimbabwe', code: 'ZW' },
    ],
  },
  tum: {
    name: 'Tumbuka',
    code: 'tum',
    regions: [{ name: 'Malawi', code: 'MW' }],
  },
  tog: {
    name: 'Nyasa Tonga',
    code: 'tog',
    regions: [{ name: 'Malawi', code: 'MW' }],
  },
  iba: {
    name: 'Iban',
    code: 'iba',
    regions: [{ name: 'Malaysia', code: 'MY' }],
  },
  zmi: {
    name: 'Negeri Sembilan Malay',
    code: 'zmi',
    regions: [{ name: 'Malaysia', code: 'MY' }],
  },
  dtp: {
    name: 'Central Dusun',
    code: 'dtp',
    regions: [{ name: 'Malaysia', code: 'MY' }],
  },
  bm: {
    name: 'Bambara',
    code: 'bm',
    regions: [{ name: 'Mali', code: 'ML' }],
  },
  ffm: {
    name: 'Maasina Fulfulde',
    code: 'ffm',
    regions: [{ name: 'Mali', code: 'ML' }],
  },
  snk: {
    name: 'Soninke',
    code: 'snk',
    regions: [{ name: 'Mali', code: 'ML' }],
  },
  mwk: {
    name: 'Kita Maninkakan',
    code: 'mwk',
    regions: [{ name: 'Mali', code: 'ML' }],
  },
  ses: {
    name: 'Koyraboro Senni',
    code: 'ses',
    regions: [{ name: 'Mali', code: 'ML' }],
  },
  tmh: {
    name: 'Tamashek',
    code: 'tmh',
    regions: [
      { name: 'Mali', code: 'ML' },
      { name: 'Niger', code: 'NE' },
    ],
  },
  'bm-Nkoo': {
    name: 'Bambara (N’Ko)',
    code: 'bm-Nkoo',
    regions: [{ name: 'Mali', code: 'ML' }],
  },
  khq: {
    name: 'Koyra Chiini',
    code: 'khq',
    regions: [{ name: 'Mali', code: 'ML' }],
  },
  dtm: {
    name: 'Tomo Kan Dogon',
    code: 'dtm',
    regions: [{ name: 'Mali', code: 'ML' }],
  },
  kao: {
    name: 'Xaasongaxango',
    code: 'kao',
    regions: [{ name: 'Mali', code: 'ML' }],
  },
  bmq: {
    name: 'Bomu',
    code: 'bmq',
    regions: [{ name: 'Mali', code: 'ML' }],
  },
  bze: {
    name: 'Jenaama Bozo',
    code: 'bze',
    regions: [{ name: 'Mali', code: 'ML' }],
  },
  mt: {
    name: 'Maltese',
    code: 'mt',
    regions: [{ name: 'Malta', code: 'MT' }],
  },
  mh: {
    name: 'Marshallese',
    code: 'mh',
    regions: [{ name: 'Marshall Islands', code: 'MH' }],
  },
  wo: {
    name: 'Wolof',
    code: 'wo',
    regions: [
      { name: 'Mauritania', code: 'MR' },
      { name: 'Senegal', code: 'SN' },
    ],
  },
  mfe: {
    name: 'Morisyen',
    code: 'mfe',
    regions: [{ name: 'Mauritius', code: 'MU' }],
  },
  swb: {
    name: 'Comorian',
    code: 'swb',
    regions: [{ name: 'Mayotte', code: 'YT' }],
  },
  buc: {
    name: 'Bushi',
    code: 'buc',
    regions: [{ name: 'Mayotte', code: 'YT' }],
  },
  yua: {
    name: 'Yucateco',
    code: 'yua',
    regions: [{ name: 'Mexico', code: 'MX' }],
  },
  nhe: {
    name: 'Eastern Huasteca Nahuatl',
    code: 'nhe',
    regions: [{ name: 'Mexico', code: 'MX' }],
  },
  nhw: {
    name: 'Western Huasteca Nahuatl',
    code: 'nhw',
    regions: [{ name: 'Mexico', code: 'MX' }],
  },
  maz: {
    name: 'Central Mazahua',
    code: 'maz',
    regions: [{ name: 'Mexico', code: 'MX' }],
  },
  nch: {
    name: 'Central Huasteca Nahuatl',
    code: 'nch',
    regions: [{ name: 'Mexico', code: 'MX' }],
  },
  sei: {
    name: 'Seri',
    code: 'sei',
    regions: [{ name: 'Mexico', code: 'MX' }],
  },
  chk: {
    name: 'Chuukese',
    code: 'chk',
    regions: [{ name: 'Micronesia', code: 'FM' }],
  },
  pon: {
    name: 'Pohnpeian',
    code: 'pon',
    regions: [{ name: 'Micronesia', code: 'FM' }],
  },
  kos: {
    name: 'Kosraean',
    code: 'kos',
    regions: [{ name: 'Micronesia', code: 'FM' }],
  },
  yap: {
    name: 'Yapese',
    code: 'yap',
    regions: [{ name: 'Micronesia', code: 'FM' }],
  },
  uli: {
    name: 'Ulithian',
    code: 'uli',
    regions: [{ name: 'Micronesia', code: 'FM' }],
  },
  uk: {
    name: 'Ukrainian',
    code: 'uk',
    regions: [
      { name: 'Moldova', code: 'MD' },
      { name: 'Poland', code: 'PL' },
      { name: 'Serbia', code: 'RS' },
      { name: 'Slovakia', code: 'SK' },
      { name: 'Ukraine', code: 'UA' },
    ],
  },
  gag: {
    name: 'Gagauz',
    code: 'gag',
    regions: [{ name: 'Moldova', code: 'MD' }],
  },
  mn: {
    name: 'Mongolian',
    code: 'mn',
    regions: [
      { name: 'Mongolia', code: 'MN' },
      { name: 'Russia', code: 'RU' },
    ],
  },
  ary: {
    name: 'Moroccan Arabic',
    code: 'ary',
    regions: [{ name: 'Morocco', code: 'MA' }],
  },
  zgh: {
    name: 'Standard Moroccan Tamazight',
    code: 'zgh',
    regions: [{ name: 'Morocco', code: 'MA' }],
  },
  tzm: {
    name: 'Central Atlas Tamazight',
    code: 'tzm',
    regions: [{ name: 'Morocco', code: 'MA' }],
  },
  shi: {
    name: 'Tachelhit',
    code: 'shi',
    regions: [{ name: 'Morocco', code: 'MA' }],
  },
  'shi-Latn': {
    name: 'Tachelhit (Latin)',
    code: 'shi-Latn',
    regions: [{ name: 'Morocco', code: 'MA' }],
  },
  rif: {
    name: 'Riffian',
    code: 'rif',
    regions: [{ name: 'Morocco', code: 'MA' }],
  },
  'rif-Latn': {
    name: 'Riffian (Latin)',
    code: 'rif-Latn',
    regions: [
      { name: 'Morocco', code: 'MA' },
      { name: 'Netherlands', code: 'NL' },
    ],
  },
  vmw: {
    name: 'Makhuwa',
    code: 'vmw',
    regions: [{ name: 'Mozambique', code: 'MZ' }],
  },
  ndc: {
    name: 'Ndau',
    code: 'ndc',
    regions: [
      { name: 'Mozambique', code: 'MZ' },
      { name: 'Zimbabwe', code: 'ZW' },
    ],
  },
  ts: {
    name: 'Tsonga',
    code: 'ts',
    regions: [
      { name: 'Mozambique', code: 'MZ' },
      { name: 'South Africa', code: 'ZA' },
      { name: 'Swaziland', code: 'SZ' },
    ],
  },
  ngl: {
    name: 'Lomwe',
    code: 'ngl',
    regions: [{ name: 'Mozambique', code: 'MZ' }],
  },
  seh: {
    name: 'Sena',
    code: 'seh',
    regions: [{ name: 'Mozambique', code: 'MZ' }],
  },
  mgh: {
    name: 'Makhuwa-Meetto',
    code: 'mgh',
    regions: [{ name: 'Mozambique', code: 'MZ' }],
  },
  rng: {
    name: 'Ronga',
    code: 'rng',
    regions: [{ name: 'Mozambique', code: 'MZ' }],
  },
  yao: {
    name: 'Yao',
    code: 'yao',
    regions: [{ name: 'Mozambique', code: 'MZ' }],
  },
  shn: {
    name: 'Shan',
    code: 'shn',
    regions: [
      { name: 'Myanmar (Burma)', code: 'MM' },
      { name: 'Thailand', code: 'TH' },
    ],
  },
  kac: {
    name: 'Kachin',
    code: 'kac',
    regions: [{ name: 'Myanmar (Burma)', code: 'MM' }],
  },
  mnw: {
    name: 'Mon',
    code: 'mnw',
    regions: [
      { name: 'Myanmar (Burma)', code: 'MM' },
      { name: 'Thailand', code: 'TH' },
    ],
  },
  kj: {
    name: 'Kuanyama',
    code: 'kj',
    regions: [{ name: 'Namibia', code: 'NA' }],
  },
  ng: {
    name: 'Ndonga',
    code: 'ng',
    regions: [{ name: 'Namibia', code: 'NA' }],
  },
  naq: {
    name: 'Nama',
    code: 'naq',
    regions: [{ name: 'Namibia', code: 'NA' }],
  },
  hz: {
    name: 'Herero',
    code: 'hz',
    regions: [{ name: 'Namibia', code: 'NA' }],
  },
  na: {
    name: 'Nauru',
    code: 'na',
    regions: [{ name: 'Nauru', code: 'NR' }],
  },
  new: {
    name: 'Newari',
    code: 'new',
    regions: [{ name: 'Nepal', code: 'NP' }],
  },
  jml: {
    name: 'Jumli',
    code: 'jml',
    regions: [{ name: 'Nepal', code: 'NP' }],
  },
  taj: {
    name: 'Eastern Tamang',
    code: 'taj',
    regions: [{ name: 'Nepal', code: 'NP' }],
  },
  dty: {
    name: 'Dotyali',
    code: 'dty',
    regions: [{ name: 'Nepal', code: 'NP' }],
  },
  thl: {
    name: 'Dangaura Tharu',
    code: 'thl',
    regions: [{ name: 'Nepal', code: 'NP' }],
  },
  bap: {
    name: 'Bantawa',
    code: 'bap',
    regions: [{ name: 'Nepal', code: 'NP' }],
  },
  tdg: {
    name: 'Western Tamang',
    code: 'tdg',
    regions: [{ name: 'Nepal', code: 'NP' }],
  },
  thr: {
    name: 'Rana Tharu',
    code: 'thr',
    regions: [{ name: 'Nepal', code: 'NP' }],
  },
  mgp: {
    name: 'Eastern Magar',
    code: 'mgp',
    regions: [{ name: 'Nepal', code: 'NP' }],
  },
  thq: {
    name: 'Kochila Tharu',
    code: 'thq',
    regions: [{ name: 'Nepal', code: 'NP' }],
  },
  mrd: {
    name: 'Western Magar',
    code: 'mrd',
    regions: [{ name: 'Nepal', code: 'NP' }],
  },
  xsr: {
    name: 'Sherpa',
    code: 'xsr',
    regions: [{ name: 'Nepal', code: 'NP' }],
  },
  rjs: {
    name: 'Rajbanshi',
    code: 'rjs',
    regions: [{ name: 'Nepal', code: 'NP' }],
  },
  tsf: {
    name: 'Southwestern Tamang',
    code: 'tsf',
    regions: [{ name: 'Nepal', code: 'NP' }],
  },
  ggn: {
    name: 'Eastern Gurung',
    code: 'ggn',
    regions: [{ name: 'Nepal', code: 'NP' }],
  },
  gvr: {
    name: 'Gurung',
    code: 'gvr',
    regions: [{ name: 'Nepal', code: 'NP' }],
  },
  tkt: {
    name: 'Kathoriya Tharu',
    code: 'tkt',
    regions: [{ name: 'Nepal', code: 'NP' }],
  },
  tdh: {
    name: 'Thulung',
    code: 'tdh',
    regions: [{ name: 'Nepal', code: 'NP' }],
  },
  'unr-Deva': {
    name: 'Mundari (Devanagari)',
    code: 'unr-Deva',
    regions: [{ name: 'Nepal', code: 'NP' }],
  },
  li: {
    name: 'Limburgish',
    code: 'li',
    regions: [{ name: 'Netherlands', code: 'NL' }],
  },
  fy: {
    name: 'Western Frisian',
    code: 'fy',
    regions: [{ name: 'Netherlands', code: 'NL' }],
  },
  gos: {
    name: 'Gronings',
    code: 'gos',
    regions: [{ name: 'Netherlands', code: 'NL' }],
  },
  zea: {
    name: 'Zeelandic',
    code: 'zea',
    regions: [{ name: 'Netherlands', code: 'NL' }],
  },
  mi: {
    name: 'Maori',
    code: 'mi',
    regions: [{ name: 'New Zealand', code: 'NZ' }],
  },
  dje: {
    name: 'Zarma',
    code: 'dje',
    regions: [{ name: 'Niger', code: 'NE' }],
  },
  fuq: {
    name: 'Central-Eastern Niger Fulfulde',
    code: 'fuq',
    regions: [{ name: 'Niger', code: 'NE' }],
  },
  twq: {
    name: 'Tasawaq',
    code: 'twq',
    regions: [{ name: 'Niger', code: 'NE' }],
  },
  pcm: {
    name: 'Nigerian Pidgin',
    code: 'pcm',
    regions: [{ name: 'Nigeria', code: 'NG' }],
  },
  ig: {
    name: 'Igbo',
    code: 'ig',
    regions: [{ name: 'Nigeria', code: 'NG' }],
  },
  fuv: {
    name: 'Nigerian Fulfulde',
    code: 'fuv',
    regions: [{ name: 'Nigeria', code: 'NG' }],
  },
  tiv: {
    name: 'Tiv',
    code: 'tiv',
    regions: [{ name: 'Nigeria', code: 'NG' }],
  },
  efi: {
    name: 'Efik',
    code: 'efi',
    regions: [{ name: 'Nigeria', code: 'NG' }],
  },
  ibb: {
    name: 'Ibibio',
    code: 'ibb',
    regions: [{ name: 'Nigeria', code: 'NG' }],
  },
  bin: {
    name: 'Bini',
    code: 'bin',
    regions: [{ name: 'Nigeria', code: 'NG' }],
  },
  kaj: {
    name: 'Jju',
    code: 'kaj',
    regions: [{ name: 'Nigeria', code: 'NG' }],
  },
  kcg: {
    name: 'Tyap',
    code: 'kcg',
    regions: [{ name: 'Nigeria', code: 'NG' }],
  },
  cch: {
    name: 'Atsam',
    code: 'cch',
    regions: [{ name: 'Nigeria', code: 'NG' }],
  },
  amo: {
    name: 'Amo',
    code: 'amo',
    regions: [{ name: 'Nigeria', code: 'NG' }],
  },
  niu: {
    name: 'Niuean',
    code: 'niu',
    regions: [{ name: 'Niue', code: 'NU' }],
  },
  nb: {
    name: 'Norwegian Bokmål',
    code: 'nb',
    regions: [
      { name: 'Norway', code: 'NO' },
      { name: 'Svalbard & Jan Mayen', code: 'SJ' },
    ],
  },
  nn: {
    name: 'Norwegian Nynorsk',
    code: 'nn',
    regions: [{ name: 'Norway', code: 'NO' }],
  },
  no: {
    name: 'Norwegian',
    code: 'no',
    regions: [{ name: 'Norway', code: 'NO' }],
  },
  'pa-Arab': {
    name: 'Punjabi (Arabic)',
    code: 'pa-Arab',
    regions: [{ name: 'Pakistan', code: 'PK' }],
  },
  skr: {
    name: 'Saraiki',
    code: 'skr',
    regions: [{ name: 'Pakistan', code: 'PK' }],
  },
  brh: {
    name: 'Brahui',
    code: 'brh',
    regions: [{ name: 'Pakistan', code: 'PK' }],
  },
  hno: {
    name: 'Northern Hindko',
    code: 'hno',
    regions: [{ name: 'Pakistan', code: 'PK' }],
  },
  hnd: {
    name: 'Southern Hindko',
    code: 'hnd',
    regions: [{ name: 'Pakistan', code: 'PK' }],
  },
  'tg-Arab': {
    name: 'Tajik (Arabic)',
    code: 'tg-Arab',
    regions: [{ name: 'Pakistan', code: 'PK' }],
  },
  gju: {
    name: 'Gujari',
    code: 'gju',
    regions: [{ name: 'Pakistan', code: 'PK' }],
  },
  kvx: {
    name: 'Parkari Koli',
    code: 'kvx',
    regions: [{ name: 'Pakistan', code: 'PK' }],
  },
  khw: {
    name: 'Khowar',
    code: 'khw',
    regions: [{ name: 'Pakistan', code: 'PK' }],
  },
  mvy: {
    name: 'Indus Kohistani',
    code: 'mvy',
    regions: [{ name: 'Pakistan', code: 'PK' }],
  },
  kxp: {
    name: 'Wadiyara Koli',
    code: 'kxp',
    regions: [{ name: 'Pakistan', code: 'PK' }],
  },
  gjk: {
    name: 'Kachi Koli',
    code: 'gjk',
    regions: [{ name: 'Pakistan', code: 'PK' }],
  },
  pau: {
    name: 'Palauan',
    code: 'pau',
    regions: [{ name: 'Palau', code: 'PW' }],
  },
  tpi: {
    name: 'Tok Pisin',
    code: 'tpi',
    regions: [{ name: 'Papua New Guinea', code: 'PG' }],
  },
  ho: {
    name: 'Hiri Motu',
    code: 'ho',
    regions: [{ name: 'Papua New Guinea', code: 'PG' }],
  },
  fil: {
    name: 'Filipino',
    code: 'fil',
    regions: [
      { name: 'Philippines', code: 'PH' },
      { name: 'United States', code: 'US' },
    ],
  },
  ceb: {
    name: 'Cebuano',
    code: 'ceb',
    regions: [{ name: 'Philippines', code: 'PH' }],
  },
  ilo: {
    name: 'Iloko',
    code: 'ilo',
    regions: [{ name: 'Philippines', code: 'PH' }],
  },
  hil: {
    name: 'Hiligaynon',
    code: 'hil',
    regions: [{ name: 'Philippines', code: 'PH' }],
  },
  bik: {
    name: 'Bikol',
    code: 'bik',
    regions: [{ name: 'Philippines', code: 'PH' }],
  },
  war: {
    name: 'Waray',
    code: 'war',
    regions: [{ name: 'Philippines', code: 'PH' }],
  },
  bhk: {
    name: 'Albay Bicolano',
    code: 'bhk',
    regions: [{ name: 'Philippines', code: 'PH' }],
  },
  pam: {
    name: 'Pampanga',
    code: 'pam',
    regions: [{ name: 'Philippines', code: 'PH' }],
  },
  pag: {
    name: 'Pangasinan',
    code: 'pag',
    regions: [{ name: 'Philippines', code: 'PH' }],
  },
  mdh: {
    name: 'Maguindanaon',
    code: 'mdh',
    regions: [{ name: 'Philippines', code: 'PH' }],
  },
  tsg: {
    name: 'Tausug',
    code: 'tsg',
    regions: [{ name: 'Philippines', code: 'PH' }],
  },
  cps: {
    name: 'Capiznon',
    code: 'cps',
    regions: [{ name: 'Philippines', code: 'PH' }],
  },
  krj: {
    name: 'Kinaray-a',
    code: 'krj',
    regions: [{ name: 'Philippines', code: 'PH' }],
  },
  bto: {
    name: 'Rinconada Bikol',
    code: 'bto',
    regions: [{ name: 'Philippines', code: 'PH' }],
  },
  hnn: {
    name: 'Hanunoo',
    code: 'hnn',
    regions: [{ name: 'Philippines', code: 'PH' }],
  },
  tbw: {
    name: 'Tagbanwa',
    code: 'tbw',
    regions: [{ name: 'Philippines', code: 'PH' }],
  },
  bku: {
    name: 'Buhid',
    code: 'bku',
    regions: [{ name: 'Philippines', code: 'PH' }],
  },
  szl: {
    name: 'Silesian',
    code: 'szl',
    regions: [{ name: 'Poland', code: 'PL' }],
  },
  csb: {
    name: 'Kashubian',
    code: 'csb',
    regions: [{ name: 'Poland', code: 'PL' }],
  },
  sli: {
    name: 'Lower Silesian',
    code: 'sli',
    regions: [{ name: 'Poland', code: 'PL' }],
  },
  gl: {
    name: 'Galician',
    code: 'gl',
    regions: [
      { name: 'Portugal', code: 'PT' },
      { name: 'Spain', code: 'ES' },
    ],
  },
  rcf: {
    name: 'Réunion Creole French',
    code: 'rcf',
    regions: [{ name: 'Réunion', code: 'RE' }],
  },
  tt: {
    name: 'Tatar',
    code: 'tt',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  ba: {
    name: 'Bashkir',
    code: 'ba',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  cv: {
    name: 'Chuvash',
    code: 'cv',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  ce: {
    name: 'Chechen',
    code: 'ce',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  av: {
    name: 'Avaric',
    code: 'av',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  udm: {
    name: 'Udmurt',
    code: 'udm',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  chm: {
    name: 'Mari',
    code: 'chm',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  sah: {
    name: 'Sakha',
    code: 'sah',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  kbd: {
    name: 'Kabardian',
    code: 'kbd',
    regions: [
      { name: 'Russia', code: 'RU' },
      { name: 'Turkey', code: 'TR' },
    ],
  },
  myv: {
    name: 'Erzya',
    code: 'myv',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  dar: {
    name: 'Dargwa',
    code: 'dar',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  bua: {
    name: 'Buriat',
    code: 'bua',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  mdf: {
    name: 'Moksha',
    code: 'mdf',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  kum: {
    name: 'Kumyk',
    code: 'kum',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  kv: {
    name: 'Komi',
    code: 'kv',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  lez: {
    name: 'Lezghian',
    code: 'lez',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  krc: {
    name: 'Karachay-Balkar',
    code: 'krc',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  inh: {
    name: 'Ingush',
    code: 'inh',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  tyv: {
    name: 'Tuvinian',
    code: 'tyv',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  ady: {
    name: 'Adyghe',
    code: 'ady',
    regions: [
      { name: 'Russia', code: 'RU' },
      { name: 'Turkey', code: 'TR' },
    ],
  },
  krl: {
    name: 'Karelian',
    code: 'krl',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  lbe: {
    name: 'Lak',
    code: 'lbe',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  koi: {
    name: 'Komi-Permyak',
    code: 'koi',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  mrj: {
    name: 'Western Mari',
    code: 'mrj',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  alt: {
    name: 'Southern Altai',
    code: 'alt',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  vep: {
    name: 'Veps',
    code: 'vep',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  izh: {
    name: 'Ingrian',
    code: 'izh',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  cu: {
    name: 'Church Slavic',
    code: 'cu',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  vot: {
    name: 'Votic',
    code: 'vot',
    regions: [{ name: 'Russia', code: 'RU' }],
  },
  eo: {
    name: 'Esperanto',
    code: 'eo',
    regions: [{ name: 'San Marino', code: 'SM' }],
  },
  srr: {
    name: 'Serer',
    code: 'srr',
    regions: [{ name: 'Senegal', code: 'SN' }],
  },
  dyo: {
    name: 'Jola-Fonyi',
    code: 'dyo',
    regions: [{ name: 'Senegal', code: 'SN' }],
  },
  sav: {
    name: 'Saafi-Saafi',
    code: 'sav',
    regions: [{ name: 'Senegal', code: 'SN' }],
  },
  mfv: {
    name: 'Mandjak',
    code: 'mfv',
    regions: [{ name: 'Senegal', code: 'SN' }],
  },
  bjt: {
    name: 'Balanta-Ganja',
    code: 'bjt',
    regions: [{ name: 'Senegal', code: 'SN' }],
  },
  snf: {
    name: 'Noon',
    code: 'snf',
    regions: [{ name: 'Senegal', code: 'SN' }],
  },
  bsc: {
    name: 'Bassari',
    code: 'bsc',
    regions: [{ name: 'Senegal', code: 'SN' }],
  },
  mey: {
    name: 'Hassaniyya',
    code: 'mey',
    regions: [{ name: 'Senegal', code: 'SN' }],
  },
  tnr: {
    name: 'Ménik',
    code: 'tnr',
    regions: [{ name: 'Senegal', code: 'SN' }],
  },
  crs: {
    name: 'Seselwa Creole French',
    code: 'crs',
    regions: [{ name: 'Seychelles', code: 'SC' }],
  },
  kri: {
    name: 'Krio',
    code: 'kri',
    regions: [{ name: 'Sierra Leone', code: 'SL' }],
  },
  tem: {
    name: 'Timne',
    code: 'tem',
    regions: [{ name: 'Sierra Leone', code: 'SL' }],
  },
  vic: {
    name: 'Virgin Islands Creole English',
    code: 'vic',
    regions: [{ name: 'Sint Maarten', code: 'SX' }],
  },
  rug: {
    name: 'Roviana',
    code: 'rug',
    regions: [{ name: 'Solomon Islands', code: 'SB' }],
  },
  nso: {
    name: 'Northern Sotho',
    code: 'nso',
    regions: [{ name: 'South Africa', code: 'ZA' }],
  },
  ve: {
    name: 'Venda',
    code: 've',
    regions: [
      { name: 'South Africa', code: 'ZA' },
      { name: 'Zimbabwe', code: 'ZW' },
    ],
  },
  nr: {
    name: 'South Ndebele',
    code: 'nr',
    regions: [{ name: 'South Africa', code: 'ZA' }],
  },
  nus: {
    name: 'Nuer',
    code: 'nus',
    regions: [{ name: 'South Sudan', code: 'SS' }],
  },
  ast: {
    name: 'Asturian',
    code: 'ast',
    regions: [{ name: 'Spain', code: 'ES' }],
  },
  ext: {
    name: 'Extremaduran',
    code: 'ext',
    regions: [{ name: 'Spain', code: 'ES' }],
  },
  si: {
    name: 'Sinhala',
    code: 'si',
    regions: [{ name: 'Sri Lanka', code: 'LK' }],
  },
  bej: {
    name: 'Beja',
    code: 'bej',
    regions: [{ name: 'Sudan', code: 'SD' }],
  },
  fvr: {
    name: 'Fur',
    code: 'fvr',
    regions: [{ name: 'Sudan', code: 'SD' }],
  },
  mls: {
    name: 'Masalit',
    code: 'mls',
    regions: [{ name: 'Sudan', code: 'SD' }],
  },
  fia: {
    name: 'Nobiin',
    code: 'fia',
    regions: [{ name: 'Sudan', code: 'SD' }],
  },
  zag: {
    name: 'Zaghawa',
    code: 'zag',
    regions: [{ name: 'Sudan', code: 'SD' }],
  },
  srn: {
    name: 'Sranan Tongo',
    code: 'srn',
    regions: [{ name: 'Suriname', code: 'SR' }],
  },
  fit: {
    name: 'Tornedalen Finnish',
    code: 'fit',
    regions: [{ name: 'Sweden', code: 'SE' }],
  },
  rmu: {
    name: 'Tavringer Romani',
    code: 'rmu',
    regions: [{ name: 'Sweden', code: 'SE' }],
  },
  smj: {
    name: 'Lule Sami',
    code: 'smj',
    regions: [{ name: 'Sweden', code: 'SE' }],
  },
  sma: {
    name: 'Southern Sami',
    code: 'sma',
    regions: [{ name: 'Sweden', code: 'SE' }],
  },
  rm: {
    name: 'Romansh',
    code: 'rm',
    regions: [{ name: 'Switzerland', code: 'CH' }],
  },
  rmo: {
    name: 'Sinte Romani',
    code: 'rmo',
    regions: [{ name: 'Switzerland', code: 'CH' }],
  },
  trv: {
    name: 'Taroko',
    code: 'trv',
    regions: [{ name: 'Taiwan', code: 'TW' }],
  },
  tg: {
    name: 'Tajik',
    code: 'tg',
    regions: [{ name: 'Tajikistan', code: 'TJ' }],
  },
  suk: {
    name: 'Sukuma',
    code: 'suk',
    regions: [{ name: 'Tanzania', code: 'TZ' }],
  },
  nym: {
    name: 'Nyamwezi',
    code: 'nym',
    regions: [{ name: 'Tanzania', code: 'TZ' }],
  },
  kde: {
    name: 'Makonde',
    code: 'kde',
    regions: [{ name: 'Tanzania', code: 'TZ' }],
  },
  bez: {
    name: 'Bena',
    code: 'bez',
    regions: [{ name: 'Tanzania', code: 'TZ' }],
  },
  ksb: {
    name: 'Shambala',
    code: 'ksb',
    regions: [{ name: 'Tanzania', code: 'TZ' }],
  },
  mgy: {
    name: 'Mbunga',
    code: 'mgy',
    regions: [{ name: 'Tanzania', code: 'TZ' }],
  },
  asa: {
    name: 'Asu',
    code: 'asa',
    regions: [{ name: 'Tanzania', code: 'TZ' }],
  },
  lag: {
    name: 'Langi',
    code: 'lag',
    regions: [{ name: 'Tanzania', code: 'TZ' }],
  },
  jmc: {
    name: 'Machame',
    code: 'jmc',
    regions: [{ name: 'Tanzania', code: 'TZ' }],
  },
  rof: {
    name: 'Rombo',
    code: 'rof',
    regions: [{ name: 'Tanzania', code: 'TZ' }],
  },
  vun: {
    name: 'Vunjo',
    code: 'vun',
    regions: [{ name: 'Tanzania', code: 'TZ' }],
  },
  rwk: {
    name: 'Rwa',
    code: 'rwk',
    regions: [{ name: 'Tanzania', code: 'TZ' }],
  },
  sbp: {
    name: 'Sangu',
    code: 'sbp',
    regions: [{ name: 'Tanzania', code: 'TZ' }],
  },
  th: {
    name: 'Thai',
    code: 'th',
    regions: [{ name: 'Thailand', code: 'TH' }],
  },
  tts: {
    name: 'Northeastern Thai',
    code: 'tts',
    regions: [{ name: 'Thailand', code: 'TH' }],
  },
  nod: {
    name: 'Northern Thai',
    code: 'nod',
    regions: [{ name: 'Thailand', code: 'TH' }],
  },
  sou: {
    name: 'Southern Thai',
    code: 'sou',
    regions: [{ name: 'Thailand', code: 'TH' }],
  },
  mfa: {
    name: 'Pattani Malay',
    code: 'mfa',
    regions: [{ name: 'Thailand', code: 'TH' }],
  },
  kxm: {
    name: 'Northern Khmer',
    code: 'kxm',
    regions: [{ name: 'Thailand', code: 'TH' }],
  },
  lwl: {
    name: 'Eastern Lawa',
    code: 'lwl',
    regions: [{ name: 'Thailand', code: 'TH' }],
  },
  tet: {
    name: 'Tetum',
    code: 'tet',
    regions: [{ name: 'Timor-Leste', code: 'TL' }],
  },
  ife: {
    name: 'Ifè',
    code: 'ife',
    regions: [{ name: 'Togo', code: 'TG' }],
  },
  tkl: {
    name: 'Tokelau',
    code: 'tkl',
    regions: [{ name: 'Tokelau', code: 'TK' }],
  },
  to: {
    name: 'Tongan',
    code: 'to',
    regions: [{ name: 'Tonga', code: 'TO' }],
  },
  aeb: {
    name: 'Tunisian Arabic',
    code: 'aeb',
    regions: [{ name: 'Tunisia', code: 'TN' }],
  },
  zza: {
    name: 'Zaza',
    code: 'zza',
    regions: [{ name: 'Turkey', code: 'TR' }],
  },
  bgx: {
    name: 'Balkan Gagauz Turkish',
    code: 'bgx',
    regions: [{ name: 'Turkey', code: 'TR' }],
  },
  kiu: {
    name: 'Kirmanjki',
    code: 'kiu',
    regions: [{ name: 'Turkey', code: 'TR' }],
  },
  lzz: {
    name: 'Laz',
    code: 'lzz',
    regions: [{ name: 'Turkey', code: 'TR' }],
  },
  tru: {
    name: 'Turoyo',
    code: 'tru',
    regions: [{ name: 'Turkey', code: 'TR' }],
  },
  uz: {
    name: 'Uzbek',
    code: 'uz',
    regions: [
      { name: 'Turkey', code: 'TR' },
      { name: 'Turkmenistan', code: 'TM' },
      { name: 'Uzbekistan', code: 'UZ' },
    ],
  },
  'ky-Latn': {
    name: 'Kyrgyz (Latin)',
    code: 'ky-Latn',
    regions: [{ name: 'Turkey', code: 'TR' }],
  },
  tvl: {
    name: 'Tuvalu',
    code: 'tvl',
    regions: [{ name: 'Tuvalu', code: 'TV' }],
  },
  lg: {
    name: 'Ganda',
    code: 'lg',
    regions: [{ name: 'Uganda', code: 'UG' }],
  },
  nyn: {
    name: 'Nyankole',
    code: 'nyn',
    regions: [{ name: 'Uganda', code: 'UG' }],
  },
  cgg: {
    name: 'Chiga',
    code: 'cgg',
    regions: [{ name: 'Uganda', code: 'UG' }],
  },
  xog: {
    name: 'Soga',
    code: 'xog',
    regions: [{ name: 'Uganda', code: 'UG' }],
  },
  laj: {
    name: 'Lango [Uganda]',
    code: 'laj',
    regions: [{ name: 'Uganda', code: 'UG' }],
  },
  ach: {
    name: 'Acoli',
    code: 'ach',
    regions: [{ name: 'Uganda', code: 'UG' }],
  },
  myx: {
    name: 'Masaaba',
    code: 'myx',
    regions: [{ name: 'Uganda', code: 'UG' }],
  },
  ttj: {
    name: 'Tooro',
    code: 'ttj',
    regions: [{ name: 'Uganda', code: 'UG' }],
  },
  rue: {
    name: 'Rusyn',
    code: 'rue',
    regions: [{ name: 'Ukraine', code: 'UA' }],
  },
  crh: {
    name: 'Crimean Turkish',
    code: 'crh',
    regions: [{ name: 'Ukraine', code: 'UA' }],
  },
  sco: {
    name: 'Scots',
    code: 'sco',
    regions: [{ name: 'United Kingdom', code: 'GB' }],
  },
  gd: {
    name: 'Scottish Gaelic',
    code: 'gd',
    regions: [{ name: 'United Kingdom', code: 'GB' }],
  },
  kw: {
    name: 'Cornish',
    code: 'kw',
    regions: [{ name: 'United Kingdom', code: 'GB' }],
  },
  nv: {
    name: 'Navajo',
    code: 'nv',
    regions: [{ name: 'United States', code: 'US' }],
  },
  pdc: {
    name: 'Pennsylvania German',
    code: 'pdc',
    regions: [{ name: 'United States', code: 'US' }],
  },
  haw: {
    name: 'Hawaiian',
    code: 'haw',
    regions: [{ name: 'United States', code: 'US' }],
  },
  frc: {
    name: 'Cajun French',
    code: 'frc',
    regions: [{ name: 'United States', code: 'US' }],
  },
  chr: {
    name: 'Cherokee',
    code: 'chr',
    regions: [{ name: 'United States', code: 'US' }],
  },
  esu: {
    name: 'Central Yupik',
    code: 'esu',
    regions: [{ name: 'United States', code: 'US' }],
  },
  dak: {
    name: 'Dakota',
    code: 'dak',
    regions: [{ name: 'United States', code: 'US' }],
  },
  cho: {
    name: 'Choctaw',
    code: 'cho',
    regions: [{ name: 'United States', code: 'US' }],
  },
  lkt: {
    name: 'Lakota',
    code: 'lkt',
    regions: [{ name: 'United States', code: 'US' }],
  },
  ik: {
    name: 'Inupiaq',
    code: 'ik',
    regions: [{ name: 'United States', code: 'US' }],
  },
  mus: {
    name: 'Creek',
    code: 'mus',
    regions: [{ name: 'United States', code: 'US' }],
  },
  kaa: {
    name: 'Kara-Kalpak',
    code: 'kaa',
    regions: [{ name: 'Uzbekistan', code: 'UZ' }],
  },
  bi: {
    name: 'Bislama',
    code: 'bi',
    regions: [{ name: 'Vanuatu', code: 'VU' }],
  },
  la: {
    name: 'Latin',
    code: 'la',
    regions: [{ name: 'Vatican City', code: 'VA' }],
  },
  cjm: {
    name: 'Eastern Cham',
    code: 'cjm',
    regions: [{ name: 'Vietnam', code: 'VN' }],
  },
  wls: {
    name: 'Wallisian',
    code: 'wls',
    regions: [{ name: 'Wallis & Futuna', code: 'WF' }],
  },
  fud: {
    name: 'East Futuna',
    code: 'fud',
    regions: [{ name: 'Wallis & Futuna', code: 'WF' }],
  },
  bem: {
    name: 'Bemba',
    code: 'bem',
    regions: [{ name: 'Zambia', code: 'ZM' }],
  },
  loz: {
    name: 'Lozi',
    code: 'loz',
    regions: [{ name: 'Zambia', code: 'ZM' }],
  },
  sn: {
    name: 'Shona',
    code: 'sn',
    regions: [{ name: 'Zimbabwe', code: 'ZW' }],
  },
  nd: {
    name: 'North Ndebele',
    code: 'nd',
    regions: [{ name: 'Zimbabwe', code: 'ZW' }],
  },
  mxc: {
    name: 'Manyika',
    code: 'mxc',
    regions: [{ name: 'Zimbabwe', code: 'ZW' }],
  },
  kck: {
    name: 'Kalanga',
    code: 'kck',
    regions: [{ name: 'Zimbabwe', code: 'ZW' }],
  },
};
