import type { MUITheme } from '../../../../styles/types';
import * as React from 'react';
import clsx from 'clsx';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import { COLOR_DRAWER_BG } from '../../../../styles/colors';

const styles = (theme: MUITheme) => ({
  pos_relative: {
    position: 'relative',
    flexGrow: 1,
    width: '100%',
  },
  backdrop__progress: {
    marginLeft: 0,
    marginTop: 0,
    background: theme.palette.background.default,
    transition: 'opacity 1s linear',
    opacity: 0.8,
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    flexGrow: 1,
    width: 'calc(100%)',
    height: 'calc(100%)',
    borderRadius: 5,
    pointerEvents: 'none',
  },
  'backdrop__progress--gray': {
    background: COLOR_DRAWER_BG,
  },
  'backdrop__progress--white': {
    background: 'white',
  },
  'backdrop__progress--transparent': {
    background: theme.palette.paper.transparent,
  },
  'backdrop__progress--expand': {
    marginLeft: -5,
    marginTop: -5,
    width: 'calc(100% + 10px)',
    height: 'calc(100% + 10px)',
  },
  'backdrop__progress--isLoading': {
    pointerEvents: 'initial',
  },
  wrapper__progress: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  'wrapper__progress--indented': {
    top: 8,
  },
  'wrapper__progress--start': {
    position: 'absolute',
    left: '0',
    marginLeft: '0',
    transform: 'translate(0, 0)',
  },
  'wrapper__progress--centered': {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

type Props = {|
  className: string,
  children: ?React.Node,
  classes: Object,
  variants?: Array<
    | 'start'
    | 'indented'
    | 'regular'
    | 'centered'
    | 'white'
    | 'gray'
    | 'expand'
    | 'transparent'
  >,
  isLoading?: boolean,
  showSpinner?: boolean,
|};

const LoadingOverlay = ({
  className,
  children,
  classes,
  variants,
  isLoading,
  showSpinner,
  ...rest
}: Props) => {
  variants = variants || [];
  return (
    <div className={clsx(classes.pos_relative, className)} {...rest}>
      <Fade in={isLoading} timeout={{ enter: 0, exit: 200 }}>
        <div
          className={clsx(classes.backdrop__progress, {
            [classes['backdrop__progress--isLoading']]: isLoading,
            [classes['backdrop__progress--white']]: variants.includes('white'),
            [classes['backdrop__progress--gray']]: variants.includes('gray'),
            [classes['backdrop__progress--expand']]:
              variants.includes('expand'),
            [classes['backdrop__progress--transparent']]:
              variants.includes('transparent'),
          })}
        >
          {showSpinner && (
            <div
              className={clsx(classes.wrapper__progress, {
                [classes['wrapper__progress--indented']]:
                  variants.includes('indented'),
                [classes['wrapper__progress--start']]:
                  variants.includes('start'),
                [classes['wrapper__progress--centered']]:
                  variants.includes('centered'),
              })}
            >
              <CircularProgress className={classes.progress} />
            </div>
          )}
        </div>
      </Fade>
      {children}
    </div>
  );
};

LoadingOverlay.defaultProps = {
  isLoading: true,
  showSpinner: true,
};

export default withStyles(styles)(LoadingOverlay);
