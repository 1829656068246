import type { ReducerFragment } from '../../types';
import type { AccountsState } from '../model/types';
import * as types from '../action/types';

const reduce: ReducerFragment<AccountsState> = {
  [types.PUT_PENDING]: (state, action) => ({
    ...state,
    putStatus: 'pending',
  }),

  [types.PUT_FULFILLED]: (state, action) => {
    return {
      ...state,
      map: { ...state.map, [action.payload.id]: action.payload },
      putStatus: 'fulfilled',
    };
  },

  [types.PUT_REJECTED]: (state, action) => ({
    ...state,
    putStatus: 'rejected',
  }),
};

export default reduce;
