import type { AsyncStatus } from '../types';

export type NutritionSourceName =
  | 'human'
  | 'poland'
  | 'adattivo'
  | 'mpromo'
  | 'dna'
  | 'dnadirect'
  | 'spain'
  | 'poland';

export type NutritionSourceCzskType = 'Cz' | 'Sk';

export type NutritionSourceDnaIdentifierType =
  | 'StateSiteID'
  | 'LocalRefNum'
  | 'RFMID';

export type NutritionSource =
  | Object
  | {|
      name: 'human',
    |}
  | {|
      name: 'adattivo',
      country: string,
      language: string,
    |}
  | {|
      name: 'mpromo',
      country: string,
      language: string,
      username: string,
      password: string,
    |}
  | {|
      name: 'dna',
      country: string,
      language: string,
      apiKey: string,
      categoryJsonUrl: ?string,
    |}
  | {|
      name: 'dnadirect',
      country: string,
      language: string,
      categoryJsonUrl: ?string,
    |}
  | {|
      name: 'spain',
      country: string,
      language: string,
    |}
  | {|
      name: 'poland',
      country: string,
      language: string,
    |};

export type NutritionConfig = {|
  id: string,
  primarySource: ?string,
  sources: {|
    [string: string]: NutritionSource,
  |},
  heroImage?: string,
  heroImageId?: string,
  ingredientsEnabled?: boolean,
  nutrientsEnabled?: boolean,
  nutrientsOfRDIEnabled?: boolean,
  allergensEnabled?: boolean,
  portionsEnabled?: boolean,
  nutrientsPer100gEnabled?: boolean,
  nutrientsPerUnitEnabled?: boolean,
|};

export type Items = {|
  // [string] = Market code
  [string]: {|
    isSaving: boolean,
    statusGet: ?AsyncStatus,
    statusPut: ?AsyncStatus,
    data: NutritionConfig,
    editedData: ?NutritionConfig, // A copy of data, modified while in edit mode
  |},
|};

export type State = {|
  +items: Items,
|};

// Actions

// RESET

export const RESET: 'PRODUCTS_CONFIG.RESET' = 'PRODUCTS_CONFIG.RESET';
export type RESET_ACTION = {
  type: typeof RESET,
};

// GET

export const GET: 'PRODUCTS_CONFIG.GET' = 'PRODUCTS_CONFIG.GET';
export type GET_ACTION = {
  type: typeof GET,
  payload: Promise<{ data: Object }>,
};

export const GET_PENDING: 'PRODUCTS_CONFIG.GET_PENDING' =
  'PRODUCTS_CONFIG.GET_PENDING';
export type GET_PENDING_ACTION = {
  type: typeof GET_PENDING,
};

export const GET_FULFILLED: 'PRODUCTS_CONFIG.GET_FULFILLED' =
  'PRODUCTS_CONFIG.GET_FULFILLED';
export type GET_FULFILLED_ACTION = {
  type: typeof GET_FULFILLED,
  payload: { data: Object },
};

export const GET_REJECTED: 'PRODUCTS_CONFIG.GET_REJECTED' =
  'PRODUCTS_CONFIG.GET_REJECTED';
export type GET_REJECTED_ACTION = {
  type: typeof GET_REJECTED,
};

type GET_ACTIONS =
  | GET_ACTION
  | GET_PENDING_ACTION
  | GET_FULFILLED_ACTION
  | GET_REJECTED_ACTION;

// PUT

export const PUT: 'PRODUCTS_CONFIG.PUT' = 'PRODUCTS_CONFIG.PUT';
export type PUT_ACTION = {
  type: typeof PUT,
  payload: Promise<Object>,
};

export const PUT_PENDING: 'PRODUCTS_CONFIG.PUT_PENDING' =
  'PRODUCTS_CONFIG.PUT_PENDING';
export type PUT_PENDING_ACTION = {
  type: typeof PUT_PENDING,
};

export const PUT_FULFILLED: 'PRODUCTS_CONFIG.PUT_FULFILLED' =
  'PRODUCTS_CONFIG.PUT_FULFILLED';
export type PUT_FULFILLED_ACTION = {
  type: typeof PUT_FULFILLED,
  payload: Object,
};

export const PUT_REJECTED: 'PRODUCTS_CONFIG.PUT_REJECTED' =
  'PRODUCTS_CONFIG.PUT_REJECTED';
export type PUT_REJECTED_ACTION = {
  type: typeof PUT_REJECTED,
};

type PUT_ACTIONS =
  | PUT_ACTION
  | PUT_PENDING_ACTION
  | PUT_FULFILLED_ACTION
  | PUT_REJECTED_ACTION;

// SET_EDITED

export const SET_EDITED: 'PRODUCTS_CONFIG.SET_EDITED' =
  'PRODUCTS_CONFIG.SET_EDITED';
export type SET_EDITED_ACTION = {
  type: typeof SET_EDITED,
  payload: any,
  meta?: any,
};

// SET_IS_SAVING

export const SET_IS_SAVING: 'PRODUCTS_CONFIG.SET_IS_SAVING' =
  'PRODUCTS_CONFIG.SET_IS_SAVING';
export type SET_IS_SAVING_ACTION = {
  type: typeof SET_IS_SAVING,
  payload: any,
  meta?: any,
};

// CREATE_SIGNED_URL

export const CREATE_SIGNED_URL = 'NUTRITION_CATEGORIES.CREATE_SIGNED_URL';
export type CREATE_SIGNED_URL_ACTION = {
  type: 'NUTRITION_CATEGORIES.CREATE_SIGNED_URL',
  payload: any,
};

// UPLOAD_IMAGE

export const UPLOAD_IMAGE = 'NUTRITION_CATEGORIES.UPLOAD_IMAGE';
export type UPLOAD_IMAGE_ACTION = {
  type: 'NUTRITION_CATEGORIES.UPLOAD_IMAGE',
  payload: any,
};

//------------------------------------

export type Action =
  | RESET_ACTION
  | GET_ACTIONS
  | PUT_ACTIONS
  | SET_EDITED_ACTION
  | SET_IS_SAVING_ACTION
  | CREATE_SIGNED_URL_ACTION
  | UPLOAD_IMAGE_ACTION;
