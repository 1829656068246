import type { AsyncStatus } from '../types';

export type NutritionAllergen = {|
  externalId: string,
  market: string,
  status: 'enabled' | 'disabled',
  order: number,
  languages: {|
    [locale: string]: {|
      name: string,
      status: 'enabled' | 'disabled',
    |},
  |},
|};

export type Items = {|
  [itemId: string]: NutritionAllergen,
|};

export type State = {|
  mapInvalidatorId: string,
  statusList: ?AsyncStatus,
  searchQuery?: ?string,
  map: Items,
|};

// SET_ORDER

export const SET_ORDER = 'NUTRITION_ALLERGENS.SET_ORDER';
export type SET_ORDER_ACTION = {
  type: 'NUTRITION_ALLERGENS.SET_ORDER',
  payload: Promise<any>,
};

export const SET_ORDER_PENDING = 'NUTRITION_ALLERGENS.SET_ORDER_PENDING';
export type SET_ORDER_PENDING_ACTION = {
  type: 'NUTRITION_ALLERGENS.SET_ORDER_PENDING',
};

export const SET_ORDER_FULFILLED = 'NUTRITION_ALLERGENS.SET_ORDER_FULFILLED';
export type SET_ORDER_FULFILLED_ACTION = {
  type: 'NUTRITION_ALLERGENS.SET_ORDER_FULFILLED',
  payload: any,
};

export const SET_ORDER_REJECTED = 'NUTRITION_ALLERGENS.SET_ORDER_REJECTED';
export type SET_ORDER_REJECTED_ACTION = {
  type: 'NUTRITION_ALLERGENS.SET_ORDER_REJECTED',
};

// POST

export const POST = 'NUTRITION_ALLERGENS.POST';
export type POST_ACTION = {
  type: 'NUTRITION_ALLERGENS.POST',
  payload: Promise<any>,
};

export const POST_PENDING = 'NUTRITION_ALLERGENS.POST_PENDING';
export type POST_PENDING_ACTION = {
  type: 'NUTRITION_ALLERGENS.POST_PENDING',
};

export const POST_FULFILLED = 'NUTRITION_ALLERGENS.POST_FULFILLED';
export type POST_FULFILLED_ACTION = {
  type: 'NUTRITION_ALLERGENS.POST_FULFILLED',
  payload: any,
};

export const POST_REJECTED = 'NUTRITION_ALLERGENS.POST_REJECTED';
export type POST_REJECTED_ACTION = {
  type: 'NUTRITION_ALLERGENS.POST_REJECTED',
};

type POST_ACTIONS =
  | POST_ACTION
  | POST_PENDING_ACTION
  | POST_FULFILLED_ACTION
  | POST_REJECTED_ACTION;

// PUT

export const PUT = 'NUTRITION_ALLERGENS.PUT';
export type PUT_ACTION = {
  type: 'NUTRITION_ALLERGENS.PUT',
  payload: Promise<any>,
};

export const PUT_PENDING = 'NUTRITION_ALLERGENS.PUT_PENDING';
export type PUT_PENDING_ACTION = {
  type: 'NUTRITION_ALLERGENS.PUT_PENDING',
};

export const PUT_FULFILLED = 'NUTRITION_ALLERGENS.PUT_FULFILLED';
export type PUT_FULFILLED_ACTION = {
  type: 'NUTRITION_ALLERGENS.PUT_FULFILLED',
  payload: any,
};

export const PUT_REJECTED = 'NUTRITION_ALLERGENS.PUT_REJECTED';
export type PUT_REJECTED_ACTION = {
  type: 'NUTRITION_ALLERGENS.PUT_REJECTED',
};

type PUT_ACTIONS =
  | PUT_ACTION
  | PUT_PENDING_ACTION
  | PUT_FULFILLED_ACTION
  | PUT_REJECTED_ACTION;

// REMOVE

export const REMOVE = 'NUTRITION_ALLERGENS.REMOVE';
export type REMOVE_ACTION = {
  type: 'NUTRITION_ALLERGENS.REMOVE',
  payload: Promise<any>,
};

export const REMOVE_PENDING = 'NUTRITION_ALLERGENS.REMOVE_PENDING';
export type REMOVE_PENDING_ACTION = {
  type: 'NUTRITION_ALLERGENS.REMOVE_PENDING',
};

export const REMOVE_FULFILLED = 'NUTRITION_ALLERGENS.REMOVE_FULFILLED';
export type REMOVE_FULFILLED_ACTION = {
  type: 'NUTRITION_ALLERGENS.REMOVE_FULFILLED',
  payload: any,
};

export const REMOVE_REJECTED = 'NUTRITION_ALLERGENS.REMOVE_REJECTED';
export type REMOVE_REJECTED_ACTION = {
  type: 'NUTRITION_ALLERGENS.REMOVE_REJECTED',
};

type REMOVE_ACTIONS =
  | REMOVE_ACTION
  | REMOVE_PENDING_ACTION
  | REMOVE_FULFILLED_ACTION
  | REMOVE_REJECTED_ACTION;

// GET

export const GET = 'NUTRITION_ALLERGENS.GET';
export type GET_ACTION = {
  type: 'NUTRITION_ALLERGENS.GET',
  payload: Promise<any>,
};

export const GET_PENDING = 'NUTRITION_ALLERGENS.GET_PENDING';
export type GET_PENDING_ACTION = {
  type: 'NUTRITION_ALLERGENS.GET_PENDING',
};

export const GET_FULFILLED = 'NUTRITION_ALLERGENS.GET_FULFILLED';
export type GET_FULFILLED_ACTION = {
  type: 'NUTRITION_ALLERGENS.GET_FULFILLED',
  payload: any,
};

export const GET_REJECTED = 'NUTRITION_ALLERGENS.GET_REJECTED';
export type GET_REJECTED_ACTION = {
  type: 'NUTRITION_ALLERGENS.GET_REJECTED',
};

type GET_ACTIONS =
  | GET_ACTION
  | GET_PENDING_ACTION
  | GET_FULFILLED_ACTION
  | GET_REJECTED_ACTION;

// LIST

export const LIST = 'NUTRITION_ALLERGENS.LIST';
export type LIST_ACTION = {
  type: 'NUTRITION_ALLERGENS.LIST',
  payload: Promise<any>,
};

export const LIST_PENDING = 'NUTRITION_ALLERGENS.LIST_PENDING';
export type LIST_PENDING_ACTION = {
  type: 'NUTRITION_ALLERGENS.LIST_PENDING',
};

export const LIST_FULFILLED = 'NUTRITION_ALLERGENS.LIST_FULFILLED';
export type LIST_FULFILLED_ACTION = {
  type: 'NUTRITION_ALLERGENS.LIST_FULFILLED',
  payload: any,
};

export const LIST_REJECTED = 'NUTRITION_ALLERGENS.LIST_REJECTED';
export type LIST_REJECTED_ACTION = {
  type: 'NUTRITION_ALLERGENS.LIST_REJECTED',
};

// SET_MAP_INVALIDATE_ID

export const SET_MAP_INVALIDATOR_ID =
  'NUTRITION_ALLERGENS.SET_MAP_INVALIDATE_ID';
export type SET_MAP_INVALIDATE_ID_ACTION = {
  type: 'NUTRITION_ALLERGENS.SET_MAP_INVALIDATE_ID',
  payload: string,
};

// UNSET_MAP_INVALIDATE_ID

export const UNSET_MAP_INVALIDATOR_ID =
  'NUTRITION_ALLERGENS.UNSET_MAP_INVALIDATE_ID';
export type UNSET_MAP_INVALIDATE_ID_ACTION = {
  type: 'NUTRITION_ALLERGENS.UNSET_MAP_INVALIDATE_ID',
  payload: string,
};

// SET_SEARCH_QUERY

export const SET_SEARCH_QUERY = 'NUTRITION_CATEGORIES.SET_SEARCH_QUERY';
export type SET_SEARCH_QUERY_ACTION = {
  type: 'NUTRITION_CATEGORIES.SET_SEARCH_QUERY',
  payload: ?string,
};

type LIST_ACTIONS =
  | LIST_ACTION
  | LIST_PENDING_ACTION
  | LIST_FULFILLED_ACTION
  | LIST_REJECTED_ACTION
  | SET_MAP_INVALIDATE_ID_ACTION
  | UNSET_MAP_INVALIDATE_ID_ACTION
  | SET_SEARCH_QUERY_ACTION;

//------------------------------------

export type Action =
  | SET_ORDER_ACTION
  | SET_ORDER_PENDING_ACTION
  | SET_ORDER_FULFILLED_ACTION
  | SET_ORDER_REJECTED_ACTION
  | POST_ACTIONS
  | PUT_ACTIONS
  | REMOVE_ACTIONS
  | GET_ACTIONS
  | LIST_ACTIONS;
