import type { RestaurantFacilityOverrideState } from '../model/types';
import * as types from '../actions/types';
import createReducer from '../../utility/createReducer';
import reduceGet from './get';
import reduceList from './list';
import reducePost from './post';
import reducePut from './put';
import reduceRemove from './remove';

export const initialState: RestaurantFacilityOverrideState = {
  statusGet: undefined,
  statusPut: undefined,
  statusPost: undefined,
  statusRemove: undefined,
  statusList: undefined,
  nextPageToken: undefined,
  map: {},
  selectedFacility: {},
  mapInvalidatorId: 'PRISTINE',
  pendingListRequests: [],
  displayedLocale: undefined,
};

export const preloadedState: RestaurantFacilityOverrideState = {
  ...initialState,
};

export default createReducer(initialState, {
  'GLOBAL.RESET': () => initialState,
  [types.RESET]: () => initialState,
  ...reduceGet,
  ...reduceList,
  ...reducePost,
  ...reducePut,
  ...reduceRemove,
});
