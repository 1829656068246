import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { withStyles, Menu, MenuItem, Fade } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { userSignOut } from "state/user/action/signOut";
import { getUserAccountName } from 'state/accounts/model/selectors';
import { getUser, getUserAccountId } from 'state/user/model/selectors';
import MenuButton from 'components/common/__button/menuButton/MenuButton';
import styles from './styles';

const AccountMenu = (props) => {
  const { classes, history } = props;

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector(getUser);
  const userId = useSelector(getUserAccountId);
  const userDisplayName = useSelector(getUserAccountName);
  const selectedMarketId = useSelector(
    (state) => state.markets.selectedMarketId
  );

  const handleOpenProfile = () => {
    if (userId) {
      history.push(`/${selectedMarketId}/accounts/account/${userId}`);
    } else {
      history.push(`/`);
    }

    setAnchorEl(null);
  };

  return user ? (
    <>
      <Fade in={!!userDisplayName}>
        <MenuButton
          data-e2e="app-bar--account-menu--toggle"
          ariaOwns={anchorEl ? 'account-menu' : null}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          variant="account"
        >
          <AccountCircleIcon
            className={clsx(classes.buttonIconStart, classes.icon)}
          />
          {userDisplayName}
        </MenuButton>
      </Fade>
      <Menu
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handleOpenProfile}>My account</MenuItem>
        <MenuItem onClick={() => dispatch(userSignOut())}>
          Sign out
        </MenuItem>
      </Menu>
    </>
  ) : null;
};

export default withRouter(withStyles(styles)(AccountMenu));
