export enum ACCOUNTROLES {
  ADMIN = 'admin',
  PRODUCT_TEAM = 'productTeam',
  SUPPORT_TEAM = 'supportTeam',
  MARKET_MANAGER = 'marketManager',
  MARKET_TAG_MANAGER = 'marketTagManager',
  MARKET_CODE_IMPORT_MANAGER = 'marketCodeImportManager',
  MARKET_FRANCHISEE_MANAGER = 'franchiseeManager',
  MARKET_LOCATION_MANAGER = 'marketLocationManager',
  MARKET_NUTRITION_MANAGER = 'marketNutritionManager',
  DEFAULT = 'default',
}

const ROLES_IN_PRIORITY: { [key: string]: number } = {
  [ACCOUNTROLES.ADMIN]: 5,
  [ACCOUNTROLES.PRODUCT_TEAM]: 4,
  [ACCOUNTROLES.SUPPORT_TEAM]: 3,
  [ACCOUNTROLES.MARKET_MANAGER]: 2,
  [ACCOUNTROLES.MARKET_TAG_MANAGER]: 1,
  [ACCOUNTROLES.MARKET_CODE_IMPORT_MANAGER]: 1,
  [ACCOUNTROLES.MARKET_FRANCHISEE_MANAGER]: 1,
  [ACCOUNTROLES.MARKET_LOCATION_MANAGER]: 1,
  [ACCOUNTROLES.MARKET_NUTRITION_MANAGER]: 1,
  [ACCOUNTROLES.DEFAULT]: 0,
};

type RoleMember = {
  id: string;
  role: ACCOUNTROLES;
  member: string;
  market: string | undefined;
};

export const getHighestOrderOfPriorityRole = (
  roles: RoleMember[]
): ACCOUNTROLES => {
  let priority = 0;
  let priorityRole = ACCOUNTROLES.DEFAULT;

  for (const item of roles) {
    const role: ACCOUNTROLES | undefined = item?.role;
    if (role) {
      const currentPriority = ROLES_IN_PRIORITY[role];
      if (currentPriority && currentPriority > priority) {
        priority = currentPriority;
        priorityRole = role;
      }
    }
  }
  return priorityRole;
};
