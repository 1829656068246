import type { State as _State, Action as _Action } from './types';
import update from 'immutability-helper';
import * as types from './types';
import initialState from './initialState';
import createReducer from '../utility/createReducer';
import reduceListFulfilled from '../utility/general/reducers/reduceListFulfilled';

export type State = _State;

export type Action = _Action;

export const reducer = createReducer(initialState, {
  'GLOBAL.RESET': () => initialState,
  [types.GET_PENDING]: (state, action) => {
    return update(state, {
      statusGet: { $set: 'pending' },
    });
  },
  [types.GET_FULFILLED]: (state, action) => {
    const nutritionConfig = action.payload.data;
    const categoryId = action.meta.subjectId;
    return update(state, {
      statusGet: { $set: 'fulfilled' },
      map: {
        [categoryId]: { $set: nutritionConfig },
      },
    });
  },
  [types.PUT_FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      map: { ...state.map, [payload.id]: payload },
    };
  },
  [types.LIST_PENDING]: (state, action) => {
    return update(state, {
      statusList: { $set: 'pending' },
    });
  },
  [types.LIST_FULFILLED]: reduceListFulfilled(),
  [types.LIST_REJECTED]: (state, action) => {
    return update(state, {
      statusList: { $set: 'rejected' },
    });
  },
  [types.SET_MAP_INVALIDATOR_ID]: (state, action) => {
    return update(state, {
      mapInvalidatorId: { $set: action.payload },
    });
  },
  [types.UNSET_MAP_INVALIDATOR_ID]: (state, action) => {
    if (state.mapInvalidatorId === action.payload) {
      return update(state, {
        mapInvalidatorId: { $set: undefined },
      });
    }
    return state;
  },
  [types.SET_SEARCH_QUERY]: (state, action) => {
    return update(state, {
      searchQuery: { $set: action.payload },
    });
  },
});
