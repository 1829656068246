import type { ReducerFragment } from '../../types';
import type { RestaurantsImportsState } from './types';
import update from 'immutability-helper';
import _get from 'lodash/get';

const reduce: ReducerFragment<RestaurantsImportsState> = {
  'RESTAURANTS_IMPORTS.STATUSES.LIST_PENDING': (state, action) => {
    return {
      ...state,
      statusList: 'pending',
    };
  },
  'RESTAURANTS_IMPORTS.STATUSES.LIST_REJECTED': (state, action) => {
    return {
      ...state,
      statusList: 'rejected',
    };
  },
  'RESTAURANTS_IMPORTS.STATUSES.LIST_FULFILLED': (state, action) => {
    const { payload, meta } = action;

    const updateNextPageToken = {
      nextPageToken: { $set: payload.data.nextPageToken },
    };
    const updateInvalidatorId =
      'PRISTINE' === _get(state, `markets.${meta.subject}.invalidatorId`)
        ? { invalidatorId: { $set: undefined } }
        : {};

    const updateStatuses = {
      statuses: meta.append
        ? {
            $push: payload.data.items || [],
          }
        : {
            $set: payload.data.items || [],
          },
    };

    return update(state, {
      markets: {
        [meta.subject]: {
          ...updateNextPageToken,
          ...updateStatuses,
          ...updateInvalidatorId,
        },
      },
      statusList: { $set: 'fulfilled' },
    });
  },
  'RESTAURANTS_IMPORTS.STATUSES.SET_INVALIDATOR_ID': (state, action) => {
    const { payload, meta } = action;
    return update(state, {
      markets: {
        [meta.subject]: {
          invalidatorId: { $set: payload },
        },
      },
    });
  },
  'RESTAURANTS_IMPORTS.STATUSES.UNSET_INVALIDATOR_ID': (state, action) => {
    const { payload, meta } = action;
    const currentId = _get(state, `markets.${meta.subject}.invalidatorId`);
    if (currentId === payload) {
      return update(state, {
        markets: {
          [meta.subject]: {
            invalidatorId: { $set: undefined },
          },
        },
      });
    }
    return state;
  },
};

export default reduce;
