import type { AxiosPromise } from 'axios';
import type { Config } from './model/types';
import API from '../../utility/API';

export const get = (marketId: string): AxiosPromise<Config> => {
  const axios = API.axios();
  const config = {
    validateStatus: (status) => [200, 404].includes(status),
  };
  return axios.get(`/asset/v1/markets/${marketId}/config`, config);
};
