import { Switch } from 'react-router-dom';
import React from 'react';

import RouteWithSubRoutes from '../../../components/common/routeWithSubRoutes/RouteWithSubRoutes';

type Props = {
  routes: Object[],
};

const DeviceKeys = (props: Props) => {
  return (
    <Switch>
      {props.routes.map((route, i) => (
        <RouteWithSubRoutes key={`${route.path}_${i}`} {...route} />
      ))}
    </Switch>
  );
};

export default DeviceKeys;
