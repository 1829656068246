import type { RootState } from '../../';
import { type RestaurantsConfig, type RestaurantsSource } from './types';
import { createSelector } from 'reselect';
import {
  getSelectedMarketId,
  getSelectedMarketLanguages,
} from '../../markets/model/selectors';

// From root

export const mapRestaurantsConfigsByMarketId = (state: RootState) =>
  state.restaurantsConfigs.map;

export const selectConfigOfSelectedMarket = createSelector(
  mapRestaurantsConfigsByMarketId,
  getSelectedMarketId,
  (map, selectedMarketId) => {
    return selectedMarketId ? map[selectedMarketId] : undefined;
  }
);

export const selectPrimarySourceOfSelectedMarket = createSelector(
  selectConfigOfSelectedMarket,
  (config: ?RestaurantsConfig) => {
    return config?.primarySource
      ? config.sources[config.primarySource]
      : undefined;
  }
);

export const selectHasSelectedMarketExternalSource = createSelector(
  selectPrimarySourceOfSelectedMarket,
  (primarySource: ?RestaurantsSource) => {
    return primarySource ? primarySource.name !== 'human' : undefined;
  }
);

export const getSelectedMarketRestaurantLocales = createSelector(
  selectConfigOfSelectedMarket,
  (config: ?RestaurantsConfig) => {
    return config?.sources ? Object.keys(config.sources) : [];
  }
);

export const getSelectedMarketUnusedRestaurantLanguages = (
  state: RootState
): string[] => {
  const marketLangObjs = getSelectedMarketLanguages(state);
  const marketLangs = marketLangObjs.map((obj) => obj.locale);
  const restoConfLangs = getSelectedMarketRestaurantLocales(state);
  return marketLangs.filter((lang) => !restoConfLangs.includes(lang));
};

export const getSelectedMarketPrimaryRestaurantLocale = (
  state: RootState
): ?string | void => {
  const selectedMarketId = getSelectedMarketId(state);
  const restaurantsConfig = selectedMarketId
    ? mapRestaurantsConfigsByMarketId(state)[selectedMarketId]
    : undefined;
  if (!restaurantsConfig) {
    return undefined;
  }
  return restaurantsConfig.primarySource;
};

export const getStatusGet = (state: RootState) =>
  state.restaurantsConfigs.statusGet;

export const selectEdited = (state: RootState, marketCode: string) => {
  const info = state.restaurantsConfigs.info[marketCode];
  return info ? info.edited : undefined;
};

export const selectIsSaving = (state: RootState, marketCode: string) => {
  const info = state.restaurantsConfigs.info[marketCode];
  return info ? info.isSaving : undefined;
};

export const getRestaurantTimeZoneDeviation = (state: RootState, selectedMarketId: string, viewedRestaurantId: string) => {
  return state.restaurantsConfigs?.map[selectedMarketId]?.timeZoneDeviations?.[viewedRestaurantId];
}
