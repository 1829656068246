import { type LSMOfferTemplate } from '../../state/lsmOfferTemplates/types';
import { type LSMConfig } from '../../state/lsmConfigs/types';
import {
  format,
  parseISO,
  subDays,
  isBefore,
  isAfter,
  isWithinInterval,
  formatDistanceToNow,
  addWeeks,
  startOfToday,
  addMinutes,
} from 'date-fns';

import { TIME_FORMAT, TIME_FORMAT_STORAGE_KEY } from '../time';

export const getDefaultLCLang = (offer: ?LSMOfferTemplate) => {
  if (!offer?.localisedContent) {
    return undefined;
  }

  let localisedContentFiltered = offer.localisedContent.filter(
    (c) => c.title || c.description || c.promoImagePath
  );

  if (
    localisedContentFiltered.find((content) => content.languageCode === 'en')
  ) {
    return 'en';
  }

  return localisedContentFiltered[0].languageCode;
};

export const getIsLsmEnabled = (config: ?LSMConfig) => {
  return Boolean(config?.id && config?.merchantId);
};

export const formatEditableUntil = (publishAt: string) => {
  let publishAtDate = parseISO(publishAt);
  if (
    isAfter(new Date(), subDays(publishAtDate, 1)) &&
    isBefore(new Date(), publishAtDate)
  ) {
    return formatDistanceToNow(publishAtDate);
  }
  return format(
    publishAtDate,
    localStorage.getItem(TIME_FORMAT_STORAGE_KEY) || 'MMM d, yyyy'
  );
};

export const isOfferAvailableInBundle = (
  bundleStartUTC: ?string,
  bundleEndUTC: ?string,
  offerStartUTC: ?string,
  offerEndUTC: ?string
) => {
  const offerStart = offerStartUTC ? parseISO(offerStartUTC) : undefined;
  const offerEnd = offerEndUTC ? parseISO(offerEndUTC) : undefined;
  const bundleStart = bundleStartUTC ? parseISO(bundleStartUTC) : undefined;
  const bundleEnd = bundleEndUTC ? parseISO(bundleEndUTC) : undefined;
  if (offerStart && offerEnd && bundleStart && bundleEnd) {
    const offerInterval = {
      start: offerStart,
      end: offerEnd,
    };
    return (
      isWithinInterval(bundleStart, offerInterval) &&
      isWithinInterval(bundleEnd, offerInterval)
    );
  }
  return true;
};

export const getBundleMaxEndDate = (bundleStartUTC: ?string): ?Date => {
  return bundleStartUTC ? addWeeks(parseISO(bundleStartUTC), 6) : undefined;
};

/**
 * Generates a string like "Mon, Wed, Fri" or "Tue - Fri"
 */
export const getOfferDaysRequirementString = (dayNumbers: number[]) => {
  if (!dayNumbers.length) {
    return '';
  }
  let dayStr = [
    { name: 'Sun', include: true },
    { name: 'Mon', include: true },
    { name: 'Tue', include: true },
    { name: 'Wed', include: true },
    { name: 'Thu', include: true },
    { name: 'Fri', include: true },
    { name: 'Sat', include: true },
  ];

  if (dayNumbers.length === 1) {
    return dayStr[dayNumbers[0]].name;
  }

  const sortedDayNumbers = dayNumbers.sort();

  let continous = true;
  sortedDayNumbers.forEach((dayNum, idx) => {
    if (
      sortedDayNumbers.length > idx + 1 &&
      sortedDayNumbers[idx + 1] !== dayNum + 1
    ) {
      continous = false;
    }
  });

  let str = '';
  if (continous) {
    const firstDayName = dayStr[sortedDayNumbers[0]].name;
    const lastDayName =
      dayStr[sortedDayNumbers[sortedDayNumbers.length - 1]].name;
    str = `${firstDayName} - ${lastDayName}`;
  } else {
    sortedDayNumbers.forEach((dayNum, idx) => {
      const day = dayStr[dayNum];
      if (idx !== 0) {
        str += ', ';
      }
      str += day.name;
    });
  }
  return str;
};

export const getOfferDailyTimesRequirementString = (
  dailyStartTimeMinutes: number,
  dailyEndTimeMinutes: number
) => {
  let dStart = addMinutes(startOfToday(), dailyStartTimeMinutes);
  let dEnd = addMinutes(startOfToday(), dailyEndTimeMinutes);

  return `${format(dStart, TIME_FORMAT)} - ${format(dEnd, TIME_FORMAT)}`;
};
