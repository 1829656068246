import update from 'immutability-helper';

const reducePutItemRejected = (state: any, action: any) => {
  const subjectId = action.meta.subjectId;

  return update(state, {
    items: {
      [subjectId]: {
        statusPut: { $set: 'rejected' },
      },
    },
  });
};

export default reducePutItemRejected;
