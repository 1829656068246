import _get from 'lodash/get';
import update from 'immutability-helper';
import { arrayToObject } from '../../../../utility/utility';

type Options = ?{|
  idAttribute?: string,
  subjectAttribute?: string,
  addOrderAttribute: ?boolean,
  itemDecorator?: (any) => any,
|};

const reduceListFulfilled = (options: Options) => (state: any, action: any) => {
  if (
    !state.pendingListRequests ||
    state.pendingListRequests.includes(action.meta.requestId)
  ) {
    const { idAttribute, subjectAttribute, addOrderAttribute, itemDecorator } =
      {
        idAttribute: 'id',
        addOrderAttribute: false,
        subjectAttribute: undefined,
        itemDecorator: undefined,
        append: true,
        ...options,
      };

    let items = action.payload.data.items || [];
    if (addOrderAttribute) {
      let orderStart = 0;

      if (action?.meta?.append) {
        orderStart = getAppendedOrder(state, action, subjectAttribute);
      }

      items = items.map((item, idx) => ({ order: orderStart + idx, ...item }));
    }

    const itemsById = arrayToObject(items, idAttribute, itemDecorator);
    const nextPageToken = action.payload.data.nextPageToken;

    let stateUpdate = {
      nextPageToken: { $set: nextPageToken },
      statusList: { $set: 'fulfilled' },
    };

    if (_get(action, 'meta.append')) {
      stateUpdate = {
        ...stateUpdate,
        map: { $merge: itemsById },
      };
    } else {
      stateUpdate = {
        ...stateUpdate,
        map: { $set: itemsById },
      };
    }

    if ('PRISTINE' === state.mapInvalidatorId) {
      stateUpdate = { ...stateUpdate, mapInvalidatorId: { $set: undefined } };
    }

    return update(state, stateUpdate);
  }
  return update(state, { statusList: { $set: 'fulfilled' } });
};

function getAppendedOrder(state: any, action: any, subjectAttribute: ?string) {
  const subjectId = action?.meta?.subject;
  if (subjectAttribute && !subjectId) {
    throw new Error(
      'Could not add order attribute - unable to calculate start order.' +
        'Please add the subject ID as meta.subject in your list action.'
    );
  }
  if (subjectId && !subjectAttribute) {
    throw new Error(
      'Could not add order attribute - unable to calculate start order.' +
        'Please provide a subject attribute to this function.'
    );
  }
  const map = _get(state, 'map') || {};
  const subjectItems = subjectAttribute
    ? Object.keys(map)
        .map((id) => map[id])
        .filter((item) => item[subjectAttribute] === subjectId)
    : Object.keys(map).map((id) => map[id]);

  return subjectItems.length;
}

export default reduceListFulfilled;
