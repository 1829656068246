// Inspiration: https://reacttraining.com/react-router/web/example/route-config, 11 mar 2019
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import ErrorBoundary from '../errorBoundary/ErrorBoundary';

const RouteWithSubRoutes = ({
  type,
  path,
  routes,
  location,
  from,
  to,
  exact,
  component: RouteComponent,
  preload,
}: any) => {
  switch (type) {
    case 'redirect':
      return (
        <Route
          exact={exact}
          path={from}
          render={({ match: { params }, location }) => (
            <Redirect
              from={from}
              to={{
                ...location,
                pathname: Object
                  .entries(params)
                  .reduce((acc, [name, value]) => acc.replace(`:${name}`, value), to),
              }}
              exact={exact}
            />
          )}
        />
      );
    case 'container':
    case 'uncrumbed':
    case 'crumbed':
    case undefined:
      return (
        <Route
          exact={exact}
          path={path}
          render={(routeProps) => {
            preload && preload.forEach((c) => c.preload());
            // pass the sub-routes down to keep nesting
            return (
              <ErrorBoundary>
                <RouteComponent {...routeProps} routes={routes} />
              </ErrorBoundary>
            );
          }}
        />
      );
    case 'ignored':
      return null;
    default: {
      throw new Error(`Unrecognized type: ${type}`);
    }
  }
};
export default RouteWithSubRoutes;
