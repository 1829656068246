import type { ReducerFragment } from '../../types';
import type { RoleMembersState } from '../model/types';
import * as types from '../action/types';

const reduce: ReducerFragment<RoleMembersState> = {
  [types.APPLY_PENDING]: (state, action) => ({
    ...state,
    applyStatus: 'pending',
  }),

  [types.APPLY_FULFILLED]: (state, action) => {
    return {
      ...state,
      applyStatus: 'fulfilled',
    };
  },

  [types.APPLY_REJECTED]: (state, action) => ({
    ...state,
    applyStatus: 'rejected',
  }),
};

export default reduce;
