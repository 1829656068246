import type { ReducerFragment } from '../../types';
import type { RestaurantOrderSettingsState } from '../model/types';
import * as types from '../action/types';

const reduceRemove: ReducerFragment<RestaurantOrderSettingsState> = {
  [types.REMOVE_PENDING]: (state, action) => {
    return { ...state, statusRemove: 'pending' };
  },

  [types.REMOVE_FULFILLED]: (state, action) => {
    return { ...state, statusRemove: 'fulfilled' };
  },

  [types.REMOVE_REJECTED]: (state, action) => {
    return { ...state, statusRemove: 'rejected' };
  },
};

export default reduceRemove;
