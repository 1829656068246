import type { RootState } from '../';
import _get from 'lodash/get';
import { createSelector } from 'reselect';
import { getSelectedMarketId } from '../markets/model/selectors';
import {
  reduceMapToMapOfMarket,
  reduceMapToListOfMarket,
} from '../utility/general/selectors/combiners';

export const selectItemsById = (state: RootState): Object => {
  return state.nutritionAllergens.map;
};

export const selectItemsByIdOfSelectedMarket = createSelector(
  selectItemsById,
  getSelectedMarketId,
  reduceMapToMapOfMarket
);

export const selectItemsOfSelectedMarket = createSelector(
  selectItemsById,
  getSelectedMarketId,
  reduceMapToListOfMarket
);

export const selectIsListInvalid =
  () =>
  (state: RootState): boolean => {
    return !!state.nutritionAllergens.mapInvalidatorId;
  };

export const selectSearchQuery =
  () =>
  (state: RootState): ?string => {
    return state.nutritionNutrients.searchQuery;
  };

export const selectStatusList =
  () =>
  (state: RootState): ?string => {
    return state.nutritionNutrients.statusList;
  };

// From Item

export const selectItemName = (item: any, language: string) => {
  return _get(item, `languages.${language}.name`) || '';
};
