import update from 'immutability-helper';

const reduceGetItemPending = (state: any, action: any) => {
  const itemId = action.meta.subjectId;

  return update(state, {
    items: {
      // Create item state if it doesn't exist yet
      [itemId]: (item) =>
        update(item || {}, {
          statusGet: { $set: 'pending' },
        }),
    },
  });
};

export default reduceGetItemPending;
