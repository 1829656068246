export default {
  '.flex': {
    display: 'flex !important',
  },
  ' .justify-content-start': {
    display: 'flex !important',
    justifyContent: 'flex-start !important',
  },
  '.justify-content-end': {
    display: 'flex !important',
    justifyContent: 'flex-end !important',
  },
  '.justify-content-center': {
    display: 'flex !important',
    justifyContent: 'center !important',
  },
  '.justify-content-between': {
    display: 'flex !important',
    justifyContent: 'space-between !important',
  },
  '.justify-content-around': {
    display: 'flex !important',
    justifyContent: 'space-around !important',
  },
  '.align-items-center': {
    alignItems: 'center !important',
  },
  '.flex-order-negative-1': {
    order: '-1 !important',
  },
  '.flex-order-negative-2': {
    order: '-2 !important',
  },
  '.flex-order-1': {
    order: '1 !important',
  },
  '.flex-order-2': {
    order: '2 !important',
  },
  '.flex-order-3': {
    order: '3 !important',
  },
  '.flex-grow-1': {
    flexGrow: '1 !important',
  },
  '.flex-grow-2': {
    flexGrow: '2 !important',
  },
  '.flex-grow-3': {
    flexGrow: '3 !important',
  },
};
