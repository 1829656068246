import type { RootState } from '../../../state';
import _get from 'lodash/get';

export const selectItemData =
  (stateNode: string) =>
  (marketId: string) =>
  (state: RootState): any =>
    _get(state, `${stateNode}.items.${marketId}.data`);

export const selectItemEditedData =
  (stateNode: string) => (marketId: string) => (state: RootState) =>
    _get(state, `${stateNode}.items.${marketId}.editedData`);

export const selectItemIsSaving =
  (stateNode: string) => (marketId: string) => (state: RootState) =>
    _get(state, `${stateNode}.items.${marketId}.isSaving`);

export const selectItemStatusGet =
  (stateNode: string) => (marketId: string) => (state: RootState) =>
    _get(state, `${stateNode}.items.${marketId}.statusGet`);
