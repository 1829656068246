import type { Language } from './constants/languageData';
import { languageData } from './constants/languageData';
import rtlLanguages from './constants/rtlLanguages';

export const getLanguageName = (language: Language): string => language.name;

export const getLanguageRegion = (language: Language, regionCode: string) =>
  language.regions.find((region) => region.code === regionCode);

export const splitLocale = (locale: string) => {
  const separatorIdx = locale.lastIndexOf('-');
  const languageCode = locale.slice(0, separatorIdx);
  const regionCode = locale.slice(separatorIdx + 1);
  return [languageCode, regionCode];
};

export const findLanguageWithLocale = (locale: string) => {
  const separatorIdx = locale.lastIndexOf('-');
  const languageCode = locale.slice(0, separatorIdx);
  let language = languageData[languageCode];
  return language;
};

export const translateLocale = (locale: string) => {
  const separatorIdx = locale.lastIndexOf('-');
  const languageCode = locale.slice(0, separatorIdx);
  const regionCode = locale.slice(separatorIdx + 1);

  let language = languageData[languageCode];

  return language ? `${language.name} (${regionCode.toLowerCase()})` : '';
};

export const isRtl = (locale: string) => {
  const separatorIdx = locale.lastIndexOf('-');
  const languageCode = locale.slice(0, separatorIdx);

  return rtlLanguages.includes(languageCode);
};
