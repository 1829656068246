export default {
  id: 'prd',
  firebaseConfig: {
    apiKey: 'AIzaSyDkayGT2XJBY0HE3UO6EtOIwlhukqp0mBs',
    authDomain: 'prd-marketengine-mcdonalds.firebaseapp.com',
    projectId: 'prd-marketengine-mcdonalds',
    storageBucket: 'prd-marketengine-mcdonalds.appspot.com',
  },
  apiConfig: {
    baseURL: 'https://prd-marketengine-mcdonalds.appspot.com/_ah/api',
    params: {
      key: 'AIzaSyBMi0G-TAXw0WtAOZsdjer7JZMz2t_-21I',
    },
  },
  me3Url: 'https://next.marketengine.app',
};
