import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import {
  getStatusGet as getStatusGetRestaurantsConfig,
  getSelectedMarketPrimaryRestaurantLocale,
} from '../state/restaurantsConfigs/model/selectors';
import { setDisplayedLocale as setDisplayedRestaurantsLocale } from '../state/restaurants/action/creators';
import { get as getRestaurantsConfig } from '../state/restaurantsConfigs/action/creators';

const mapStateToProps = (state) => ({
  statusGetRestaurantsConfig: getStatusGetRestaurantsConfig(state),
  authToken: state.user.firebaseIdToken,
  primaryRestaurantLocale: getSelectedMarketPrimaryRestaurantLocale(state),
  selectedMarketId: state.markets.selectedMarketId,
});

const actionCreators = {
  getRestaurantsConfig,
  setDisplayedRestaurantsLocale,
};

//TODO make an actual hook once react-redux reaches 7.1
const WithUserMarketRestaurantsConfig = ({
  children,
  authToken,
  getRestaurantsConfig,
  setDisplayedRestaurantsLocale,
  primaryRestaurantLocale,
  selectedMarketId,
  ...rest
}) => {
  useEffect(() => {
    if (authToken && selectedMarketId && selectedMarketId !== 'pending') {
      getRestaurantsConfig(selectedMarketId).then((resp) => {
        setDisplayedRestaurantsLocale(_get(resp, 'value.data.primarySource'));
        return resp;
      });
    }
  }, [
    authToken,
    selectedMarketId,
    getRestaurantsConfig,
    setDisplayedRestaurantsLocale,
  ]);

  return children || null;
};

export default withRouter(
  connect(mapStateToProps, actionCreators)(WithUserMarketRestaurantsConfig)
);
