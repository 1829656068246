import type { RootState } from '../../';
import type { Account } from './types';

// From root

export const mapAccountsById = (state: RootState) => state.accounts.map;

export const getAccountsNextPageToken = (state: RootState) =>
  state.accounts.nextPageToken;

export const getStatusListAccounts = (state: RootState) =>
  state.accounts.listStatus;

export const getStatusGetAccount = (state: RootState) =>
  state.accounts.getStatus;

export const isAccountsListValid = (state: RootState) =>
  !state.accounts.mapInvalidatorId;

export const getAccountsSearchQuery = (state: RootState) =>
  state.accounts.searchQuery;

// From Account

export const getAccountName = (account: Account) => account.displayName;

export const getAccountEmail = (account: Account) => account.email;

// User

export const getUserAccountId = (state: RootState) => {
  const userAccount = state.accounts.userAccount;

  if (!userAccount) return undefined;

  return userAccount.id;
};

export const getUserAccountName = (state: RootState) => {
  const userAccount = state.accounts.userAccount;

  if (!userAccount) return undefined;

  return getAccountName(userAccount);
};
