import type { AxiosPromise } from 'axios';
import type { Invitation } from '../model/types';

// POST_INVITATION

export const POST_INVITATION: 'INVITATIONS.POST_INVITATION' =
  'INVITATIONS.POST_INVITATION';
export type POST_INVITATION_ACTION = {
  type: typeof POST_INVITATION,
  payload: Promise<Invitation>,
  meta: {
    deactivateErrorMiddlewareMsg: true,
  },
};

export const POST_INVITATION_PENDING: 'INVITATIONS.POST_INVITATION_PENDING' =
  'INVITATIONS.POST_INVITATION_PENDING';
export type POST_INVITATION_PENDING_ACTION = {
  type: typeof POST_INVITATION_PENDING,
};

export const POST_INVITATION_FULFILLED: 'INVITATIONS.POST_INVITATION_FULFILLED' =
  'INVITATIONS.POST_INVITATION_FULFILLED';
export type POST_INVITATION_FULFILLED_ACTION = {
  type: typeof POST_INVITATION_FULFILLED,
  payload: Invitation,
};

export const POST_INVITATION_REJECTED: 'INVITATIONS.POST_INVITATION_REJECTED' =
  'INVITATIONS.POST_INVITATION_REJECTED';
export type POST_INVITATION_REJECTED_ACTION = {
  type: typeof POST_INVITATION_REJECTED,
};

type POST_INVITATION_ACTIONS =
  | POST_INVITATION_ACTION
  | POST_INVITATION_PENDING_ACTION
  | POST_INVITATION_FULFILLED_ACTION
  | POST_INVITATION_REJECTED_ACTION;

// RESEND_INVITATION

export const RESEND_INVITATION: 'INVITATIONS.RESEND_INVITATION' =
  'INVITATIONS.RESEND_INVITATION';
export type RESEND_INVITATION_ACTION = {
  type: typeof RESEND_INVITATION,
  payload: AxiosPromise<void>,
};

export const RESEND_INVITATION_PENDING: 'INVITATIONS.RESEND_INVITATION_PENDING' =
  'INVITATIONS.RESEND_INVITATION_PENDING';
export type RESEND_INVITATION_PENDING_ACTION = {
  type: typeof RESEND_INVITATION_PENDING,
};

export const RESEND_INVITATION_FULFILLED: 'INVITATIONS.RESEND_INVITATION_FULFILLED' =
  'INVITATIONS.RESEND_INVITATION_FULFILLED';
export type RESEND_INVITATION_FULFILLED_ACTION = {
  type: typeof RESEND_INVITATION_FULFILLED,
  payload: void,
};

export const RESEND_INVITATION_REJECTED: 'INVITATIONS.RESEND_INVITATION_REJECTED' =
  'INVITATIONS.RESEND_INVITATION_REJECTED';
export type RESEND_INVITATION_REJECTED_ACTION = {
  type: typeof RESEND_INVITATION_REJECTED,
};

type RESEND_INVITATION_ACTIONS =
  | RESEND_INVITATION_ACTION
  | RESEND_INVITATION_PENDING_ACTION
  | RESEND_INVITATION_FULFILLED_ACTION
  | RESEND_INVITATION_REJECTED_ACTION;

// CREATE_TOKEN

export const CREATE_TOKEN: 'INVITATIONS.CREATE_TOKEN' =
  'INVITATIONS.CREATE_TOKEN';
export type CREATE_TOKEN_ACTION = {
  type: typeof CREATE_TOKEN,
  payload: Promise<Invitation>,
};

export const CREATE_TOKEN_PENDING: 'INVITATIONS.CREATE_TOKEN_PENDING' =
  'INVITATIONS.CREATE_TOKEN_PENDING';
export type CREATE_TOKEN_PENDING_ACTION = {
  type: typeof CREATE_TOKEN_PENDING,
};

export const CREATE_TOKEN_FULFILLED: 'INVITATIONS.CREATE_TOKEN_FULFILLED' =
  'INVITATIONS.CREATE_TOKEN_FULFILLED';
export type CREATE_TOKEN_FULFILLED_ACTION = {
  type: typeof CREATE_TOKEN_FULFILLED,
  payload: Invitation,
};

export const CREATE_TOKEN_REJECTED: 'INVITATIONS.CREATE_TOKEN_REJECTED' =
  'INVITATIONS.CREATE_TOKEN_REJECTED';
export type CREATE_TOKEN_REJECTED_ACTION = {
  type: typeof CREATE_TOKEN_REJECTED,
};

type CREATE_TOKEN_ACTIONS =
  | CREATE_TOKEN_ACTION
  | CREATE_TOKEN_PENDING_ACTION
  | CREATE_TOKEN_FULFILLED_ACTION
  | CREATE_TOKEN_REJECTED_ACTION;

//------------------------------------

export type Action =
  | POST_INVITATION_ACTIONS
  | RESEND_INVITATION_ACTIONS
  | CREATE_TOKEN_ACTIONS;
