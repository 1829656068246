import type { Creator } from "state/creators";
import { setAlert } from "state/app/action/creators";
import { userSignOut } from "state/user/action/signOut";

export const userKickOut: Creator<Promise<void>> = () => (dispatch) => {
  console.warn('The user does not have the required role(s) to use this website.');
  return dispatch(userSignOut())
    .then(() => {
      dispatch(setAlert({
        id: 'missing-permissions',
        title: 'Missing permissions',
        body: 'Sorry, you don\'t have the required permissions to use this service. \nPlease, contact someone who does if you think this is an error.',
        btnClose: 'ok',
      }));
    })
    .catch((err) => {
      console.error(err);
    });
};
