import { initializeApp } from 'firebase/app';
import { Switch } from 'react-router-dom';
import env from 'config/env';
import CONFIG_ROUTES from 'config/routes';
import API from 'utility/API/API';
import store from 'state/index';
import { userInitialize } from "state/user/action/initialize";
import ScrollToTop from 'components/app/scrollToTop/ScrollToTop';
import RouteGuard from "components/common/routeGuard/RouteGuard";
import RouteWithSubRoutes from 'components/common/routeWithSubRoutes/RouteWithSubRoutes';

initializeApp(env.firebaseConfig);

API.initialize(env.apiConfig);

store.dispatch(userInitialize());

export default function Root(): JSX.Element {
  return (
    <ScrollToTop>
      <RouteGuard>
        <Switch>
          {CONFIG_ROUTES.map(({ key, ...restProps }) => (
            <RouteWithSubRoutes key={key} {...restProps} />
          ))}
        </Switch>
      </RouteGuard>
    </ScrollToTop>
  );
}
