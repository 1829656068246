import React from 'react';
import { Switch } from 'react-router-dom';
import RouteWithSubRoutes from '../../../components/common/routeWithSubRoutes/RouteWithSubRoutes';
import WithUserMarketRestaurantsConfig from '../../../hooks/WithUserMarketRestaurantsConfig';

type Props = {
  routes: Object[],
};

const RestaurantsRoot = ({ routes, ...rest }: Props) => {
  return (
    <WithUserMarketRestaurantsConfig>
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={`${route.path}_${i}`} {...route} />
        ))}
      </Switch>
    </WithUserMarketRestaurantsConfig>
  );
};

export default RestaurantsRoot;
