import * as React from 'react';
import type { Classes } from './styles';
import { withStyles, Button, Icon } from '@material-ui/core';
import styles from './styles';

type Props = {|
  classes: Classes,
  ariaOwns: ?string,
  children: React.Node,
  variant: 'account',
|};

const MenuButton = ({
  ariaOwns,
  children,
  classes,
  variant,
  ...rest
}: Props) => {
  let _classesButton = {};
  let _classesIcon = {};
  if ('account' === variant) {
    _classesButton = { label: classes.variantAccount_buttonLabel };
    _classesIcon = { root: classes.variantAccount_iconRoot };
  }

  return (
    <Button
      aria-owns={ariaOwns}
      aria-haspopup="true"
      color="inherit"
      classes={_classesButton}
      {...rest}
    >
      {children}
      <Icon classes={_classesIcon}>arrow_drop_down</Icon>
    </Button>
  );
};

export default withStyles(styles)(MenuButton);
