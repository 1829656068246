import { TEXT_BUTTON } from '../../textStyles';

const Button = {
  MuiButton: {
    root: {
      ...TEXT_BUTTON,
      padding: '11px 8px',
      minWidth: 96,
    },
    text: {
      padding: '11px 8px',
    },
    contained: {
      boxShadow: 'none',
    },
  },
};

export default Button;
