export const USER_ACTION_TYPES = {
  APP_READY: 'USER.APP_READY',
  FIREBASE_READY: 'USER.FIREBASE_READY',
  FIREBASE_USER: 'USER.FIREBASE_USER',
  FIREBASE_TOKEN: 'USER.FIREBASE_TOKEN',
  INVITATION_ACCEPTED: 'USER.INVITATION_ACCEPTED',
  MFA_ENROLLED: 'USER.MFA_ENROLLED',
  EMAIL: 'USER.EMAIL',
  INVITE_CODE: 'USER.INVITE_CODE',
  PHONE_VERIFICATION_ID: 'USER.PHONE_VERIFICATION_ID',
  MFA_ERROR: 'USER.MFA_ERROR',
} as const;

