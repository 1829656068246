import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '46px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: theme.palette.skeleton.main,
    height: '24px',
    margin: '0 24px',
    width: '100%',
  },
  divider: {
    margin: '30px 0 !important',
  },
  list: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
}));

export default useStyles;
