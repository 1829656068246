import type { RootState } from 'state/index';
import type { UserState } from "state/user/types";
import store from "state/index";

const KEY = '1k:state:user:v2';

type Value = Pick<
  UserState,
  'invitationAccepted' | 'mfaEnrolled' | 'email' | 'inviteCode' | 'phoneVerificationId'
>;

export function userStorageValueGet(): Value {
  const valueString = window.localStorage.getItem(KEY);

  if (valueString) {
    try {
      return JSON.parse(valueString) as Value;
    }
    catch (err) {
      // continue
    }
  }
  return Object.freeze({
    invitationAccepted: true,
    mfaEnrolled: true,
    email: '',
    inviteCode: '',
    phoneVerificationId: '',
  });
}

export function userStorageValueSet(state: RootState): void {
  const nextValue: Value = {
    invitationAccepted: state.user.invitationAccepted,
    mfaEnrolled: state.user.mfaEnrolled,
    email: state.user.email,
    inviteCode: state.user.inviteCode,
    phoneVerificationId: state.user.phoneVerificationId,
  };
  window.localStorage.setItem(KEY, JSON.stringify(nextValue));
}

export function userStorageSave(): void {
  userStorageValueSet(store.getState());
}
