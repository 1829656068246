import type { User } from "firebase/auth";
import type { RootState } from 'state/index';
import type { UserState } from "state/user/types";
import { userMfaPhoneGet } from "state/user/utils/mfaPhone";

export const getUserState = (state: RootState): UserState => {
  return state.user;
};

export const getUser = (state: RootState): null | User => {
  return getUserState(state).firebaseUser;
};

export const getUserAccountId = (state: RootState): string => {
  const user = getUser(state);

  return user ? user.uid : '';
};

export const getUserMfaPhoneNumber = (state: RootState): string => {
  return userMfaPhoneGet(getUser(state));
};

export const selectIsAuthenticated = (state: RootState): boolean => {
  return Boolean(getUserState(state).firebaseIdToken);
};
