import API from '../../../utility/API';

export const listForAccount = (
  accountId: string,
  nextPageToken?: ?string
): Promise<any> => {
  const axios = API.axios();
  return axios.get(`/am/v1/users/${accountId}/roles`, {
    params: {
      ...(nextPageToken ? { next: nextPageToken } : {}),
    },
  });
};

export const apply = (changeRecord: Object): Promise<any> => {
  const axios = API.axios();
  return axios.post(`/am/v1/roles`, changeRecord);
};
