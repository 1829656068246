import type { ReducerFragment } from '../../types';
import type { MarketsState } from '../model/types';
import reduceListFulfilled from '../../utility/general/reducers/reduceListFulfilled';
import * as types from '../action/types';

const reduce: ReducerFragment<MarketsState> = {
  [types.LIST_PENDING]: (state, action) => ({
    ...state,
    statusList: 'pending',
  }),

  [types.LIST_FULFILLED]: reduceListFulfilled({
    idAttribute: 'marketCode',
    addOrderAttribute: true,
  }),

  [types.LIST_REJECTED]: (state, action) => ({
    ...state,
    statusList: 'rejected',
  }),

  [types.SET_MAP_INVALIDATOR_ID]: (state, action) => {
    return {
      ...state,
      mapInvalidatorId: action.payload,
    };
  },

  [types.UNSET_MAP_INVALIDATOR_ID]: (state, action) => {
    if (state.mapInvalidatorId === action.payload) {
      return {
        ...state,
        mapInvalidatorId: null,
      };
    }
    return state;
  },
};

export default reduce;
