import { withStyles, AppBar, Toolbar, Collapse } from '@material-ui/core';
import { useSelector } from 'react-redux';
import React from 'react';

import ElevateOnScroll from '../../../../common/elevateOnScroll/ElevateOnScroll';
import AppBottomAppBarTabs from '../appBottomAppBarTabs/AppBottomAppBarTabs';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import styles from './styles';

const AppBottomAppBar = (props: Props) => {
  const app = useSelector((state) => state.app);

  return (
    <BottomAppBar
      className={props.classes.root}
      classes={props.classes}
      color={'inherit'}
      position={'fixed'}
    >
      {app.tabs.length > 0 && (
        <Collapse timeout={100} in={app.tabs.length > 0}>
          <AppBottomAppBarTabs />
        </Collapse>
      )}
    </BottomAppBar>
  );
};

const BottomAppBar = ({
  className,
  classes,
  elevation,
  color,
  position,
  children,
}) => {
  return (
    <ElevateOnScroll>
      <AppBar className={className} color={color} position={position}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarStart}>
            <Breadcrumb />
            <div id="AppBarStartContextControls" className="ml-2">
              {/* Portal target */}
            </div>
          </div>
          <div
            id="AppBarEndContextControls"
            className={classes.contextControlsEnd}
          >
            {/* Portal target */}
          </div>
        </Toolbar>
        {children}
      </AppBar>
    </ElevateOnScroll>
  );
};

export default withStyles(styles)(AppBottomAppBar);
