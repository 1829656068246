import type { MarketsState } from '../model/types';
import * as types from '../action/types';
import { getPersistedState } from '../persistance';
import createReducer from '../../utility/createReducer';
import reduceGet from './get';
import reduceSetSelected from './setSelected';
import reduceMap from './map';
import reducePost from './post';
import reducePut from './put';
import reduceRemove from './remove';
import { orderDirection } from '../../../utility/utility';

const initialState: MarketsState = {
  statusGet: undefined,
  statusPut: undefined,
  statusPost: undefined,
  statusRemove: undefined,
  statusList: undefined,
  map: {},
  selectedMarketId: undefined,
  selectedMarketIdDefault: undefined,
  selectedMarketLanguages: [],
  nextPageToken: undefined,
  mapInvalidatorId: 'PRISTINE', // make invalid before anything have been fetched
  sortBy: 'name',
  sortOrder: orderDirection.ASCENDING,
  searchQuery: '',
};

export const preloadedState: MarketsState = {
  ...initialState,
  ...getPersistedState(),
};

export default createReducer(initialState, {
  'GLOBAL.RESET': () => initialState,
  [types.RESET]: () => initialState,
  ...reduceGet,
  ...reducePost,
  ...reducePut,
  ...reduceRemove,
  ...reduceSetSelected,
  ...reduceMap,
});
