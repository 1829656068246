import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { type LSMConfig } from '../../state/lsmConfigs/types';
import { selectItemsById } from '../../state/lsmConfigs/selectors';

const UseGetLsmConfig = (marketId: ?string): ?LSMConfig => {
  const lsmConfigs = useSelector(selectItemsById);

  const [lsmConfig, setLsmConfig] = useState();

  useEffect(() => {
    if (lsmConfigs && marketId) {
      setLsmConfig(lsmConfigs[marketId]?.data);
    } else {
      setLsmConfig(undefined);
    }
  }, [marketId, lsmConfigs]);

  return lsmConfig;
};

export default UseGetLsmConfig;
