import type { RootState } from '../../';
import type { Market } from './types';
import _get from 'lodash/get';

// From root

export const mapMarketsById = (state: RootState) => state.markets.map;

export const listMarkets = (state: RootState, options?: { sort?: boolean }) => {
  const list = Object.keys(state.markets.map).map(
    (key) => state.markets.map[key]
  );
  if (_get(options, 'sort')) {
    const orderedList: Market[] = list.sort(
      (item1: Market, item2: Market): number => {
        return item1.order - item2.order;
      }
    );
    return orderedList;
  }
  return list;
};

export const getStatusGet = (state: RootState) => state.markets.statusGet;

export const getStatusListMarkets = (state: RootState) =>
  state.markets.statusList;

export const isMarketsInvalid = (state: RootState) =>
  !!state.markets.mapInvalidatorId;

export const getSelectedMarketId = (state: RootState) => {
  return state.markets.selectedMarketId;
};

export const getSelectedMarketLanguages = (state: RootState) => {
  const marketId = getSelectedMarketId(state);
  const market = marketId ? state.markets.map[marketId] : undefined;
  return market ? getMarketLanguages(market) : [];
};

// From market

export const getMarketName = (market: Market) => market.name;

export const getMarketId = (market: Market) => market.marketCode;

export const getMarketCountryCode = (market: Market) => market.countryCode;

export const getMarketLanguages = (market: Market) => market.languages;
