import type { MiddlewareAPI } from 'redux';
import type { Action, Dispatch } from './types';
import type { RootState } from './';
import isPromise from 'is-promise';
import _get from 'lodash/get';
import { setAlert } from './app/action/creators';
import * as marketsAction from './markets/action/types';

/**
 * Dispatches global side effects of errors in promises.
 * Based of an example in the 'redux-promise-middleware' docs.
 * Here: https://github.com/pburtchaell/redux-promise-middleware/blob/master/examples/catching-errors-with-middleware/middleware.js
 */
const errorMiddleware =
  (store: MiddlewareAPI<RootState, Action, Dispatch>) =>
  (next: any) =>
  (action: any) => {
    // All actions pass through here, error or not
    if (!isPromise(action.payload)) {
      return next(action);
    }

    return next(action).catch((error) => {
      if (error?.response?.status?.toString() === 403 && action.type === marketsAction.LIST) {
        // The user doesn't have access to markets, this is normal if they only have
        // the default permission, therefore it is silently ignored.
        return;
      }
      if ('auth/network-request-failed' === error.code) {
        next(
          setAlert({
            id: 'auth/network-request-failed',
            title: "How's your internet connection?",
            body: error.message,
            btnClose: 'Ok',
          })
        );
      } else if (!_get(action, 'meta.deactivateErrorMiddlewareMsg')) {
        let message = '';
        const mResponseErrorMessage = _get(
          error,
          'response.data.error.message'
        );
        const mResponseUrl = _get(error, 'request.responseURL');
        const sAction = `Action:\n${action.type}`;
        const sErrorMessage = error.message ? `Error:\n${error.message}` : '';
        const sResponseErrorMessage = mResponseErrorMessage
          ? `Api error:\n${mResponseErrorMessage}`
          : '';
        const sResponseUrl = mResponseUrl ? `URL:\n${mResponseUrl}` : '';
        const sErrorCode = error.code ? `Error code:\n${error.code}` : '';

        next(
          setAlert({
            id: message,
            title: 'Error details, see console for more',
            body:
              [
                sAction,
                sErrorMessage,
                sResponseErrorMessage,
                sResponseUrl,
              ].join('\n\n') + sErrorCode,
            btnClose: 'Ok',
          })
        );
      }
      console.error('Error object', { ...error, toString: undefined });
      return Promise.reject(error);
    });
  };

export default errorMiddleware;
