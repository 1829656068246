import * as types from './types';
import update from 'immutability-helper';
import initialState from './initialState';
import createReducer from '../utility/createReducer';
import reduceListFulfilled from '../utility/general/reducers/reduceListFulfilled';
import { getDefaultLCLang } from '../../utility/lsmBundles';

export type State = types.State;
export type Action = types.Action;

export const reducer = createReducer(initialState, {
  'GLOBAL.RESET': () => initialState,
  [types.LIST_FULFILLED]: reduceListFulfilled({
    addOrderAttribute: false,
    itemDecorator: (offerTemplate: types.LSMOfferTemplate) => {
      return {
        ...offerTemplate,
        _meta: {
          localisedContentLang: getDefaultLCLang(offerTemplate),
        },
      };
    },
  }),
  [types.SET_OFFER_DISPLAYED_LC_LANG]: (
    state,
    { payload: { offerId, displayedLCLang } }
  ) => {
    return update(state, {
      map: {
        [offerId]: {
          _meta: {
            localisedContentLang: { $set: displayedLCLang },
          },
        },
      },
    });
  },
});
