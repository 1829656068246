import type { ReducerFragment } from '../../types';
import type { RestaurantFacilityOverrideState } from '../model/types';
import * as types from '../actions/types';

const reduceList: ReducerFragment<RestaurantFacilityOverrideState> = {
  [types.LIST_PENDING]: (state, action) => {
    return { ...state, statusList: 'pending' };
  },

  [types.LIST_FULFILLED]: (state, action) => {
    const facilityOverrides = action.payload;
    return {
      ...state,
      map: { ...state.map, facilityOverrides },
      statusList: 'fulfilled',
    };
  },

  [types.LIST_REJECTED]: (state, action) => {
    return { ...state, statusList: 'rejected' };
  },
};

export default reduceList;
