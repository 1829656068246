import type { Creator } from "state/creators";
import { getAuth } from "firebase/auth";
import { USER_ACTION_TYPES } from "state/user/action/types";

export const userRefreshToken: Creator<Promise<void>> = (forceRefresh?: boolean) => {
  return async (dispatch) => {
    const user = getAuth().currentUser;

    if (user) {
      try {
        const token = await user.getIdToken(forceRefresh);
        dispatch({
          type: USER_ACTION_TYPES.FIREBASE_TOKEN,
          payload: token,
        });
      }
      catch (err) {
        console.error(err);
        dispatch({
          type: USER_ACTION_TYPES.FIREBASE_TOKEN,
          payload: '',
        });
      }
    }
  };
};
