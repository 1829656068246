export const COLOR_TEXT_DEFAULT = '#444444';
export const COLOR_TEXT_DISABLED = '#868686';
export const COLOR_DRAWER_BG = '#FCFCFC';
export const COLOR_GRAY_DARK = '#868686';
export const COLOR_GRAY_BORDER = '#DEDEDE';
export const COLOR_DANGER = '#F24141';
export const COLOR_WARNING = '#ffa726';
export const COLOR_BG_DEFAULT = '#F5F5F5';
export const COLOR_SUCCESS = '#3C9E1A';
export const COLOR_BUTTON_TEXT = '#fff';

export default {
  textDefault: '#444444',
  drawerBg: '#FCFCFC',
  grayDark: '#868686',
  divider: '#DEDEDE',
  danger: '#F24141',
};
