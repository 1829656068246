import type { ReducerFragment } from '../../types';
import type { MarketsState } from '../model/types';
import * as types from '../action/types';

const reduceSetSelected: ReducerFragment<MarketsState> = {
  [types.SET_SELECTED_MARKET_ID]: (state, action) => ({
    ...state,
    selectedMarketId: action.payload,
  }),
};

export default reduceSetSelected;
