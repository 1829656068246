import type { AsyncStatus } from '../types';

export type NutritionCategoryLanguage = {|
  name: string,
|};

export type NutritionCategory = {|
  id: string,
  externalId: string,
  market: string,
  status: 'enabled' | 'disabled',
  order: number,
  imageId?: string,
  image?: string,
  imagePreview?: string,
  languages: {|
    [locale: string]: NutritionCategoryLanguage,
  |},
|};

export type Items = {|
  [itemId: string]: NutritionCategory,
|};

export type State = {|
  mapInvalidatorId: string,
  statusList: ?AsyncStatus,
  searchQuery?: ?string,
  map: Items,
|};

// SET_ORDER

export const SET_ORDER = 'NUTRITION_CATEGORIES.SET_ORDER';
export type SET_ORDER_ACTION = {
  type: 'NUTRITION_CATEGORIES.SET_ORDER',
  payload: Promise<any>,
};

export const SET_ORDER_PENDING = 'NUTRITION_CATEGORIES.SET_ORDER_PENDING';
export type SET_ORDER_PENDING_ACTION = {
  type: 'NUTRITION_CATEGORIES.SET_ORDER_PENDING',
};

export const SET_ORDER_FULFILLED = 'NUTRITION_CATEGORIES.SET_ORDER_FULFILLED';
export type SET_ORDER_FULFILLED_ACTION = {
  type: 'NUTRITION_CATEGORIES.SET_ORDER_FULFILLED',
  payload: any,
};

export const SET_ORDER_REJECTED = 'NUTRITION_CATEGORIES.SET_ORDER_REJECTED';
export type SET_ORDER_REJECTED_ACTION = {
  type: 'NUTRITION_CATEGORIES.SET_ORDER_REJECTED',
};

// POST

export const POST = 'NUTRITION_CATEGORIES.POST';
export type POST_ACTION = {
  type: 'NUTRITION_CATEGORIES.POST',
  payload: Promise<any>,
};

export const POST_PENDING = 'NUTRITION_CATEGORIES.POST_PENDING';
export type POST_PENDING_ACTION = {
  type: 'NUTRITION_CATEGORIES.POST_PENDING',
};

export const POST_FULFILLED = 'NUTRITION_CATEGORIES.POST_FULFILLED';
export type POST_FULFILLED_ACTION = {
  type: 'NUTRITION_CATEGORIES.POST_FULFILLED',
  payload: any,
};

export const POST_REJECTED = 'NUTRITION_CATEGORIES.POST_REJECTED';
export type POST_REJECTED_ACTION = {
  type: 'NUTRITION_CATEGORIES.POST_REJECTED',
};

type POST_ACTIONS =
  | POST_ACTION
  | POST_PENDING_ACTION
  | POST_FULFILLED_ACTION
  | POST_REJECTED_ACTION;

// PUT

export const PUT = 'NUTRITION_CATEGORIES.PUT';
export type PUT_ACTION = {
  type: 'NUTRITION_CATEGORIES.PUT',
  payload: Promise<any>,
};

export const PUT_PENDING = 'NUTRITION_CATEGORIES.PUT_PENDING';
export type PUT_PENDING_ACTION = {
  type: 'NUTRITION_CATEGORIES.PUT_PENDING',
};

export const PUT_FULFILLED = 'NUTRITION_CATEGORIES.PUT_FULFILLED';
export type PUT_FULFILLED_ACTION = {
  type: 'NUTRITION_CATEGORIES.PUT_FULFILLED',
  payload: any,
};

export const PUT_REJECTED = 'NUTRITION_CATEGORIES.PUT_REJECTED';
export type PUT_REJECTED_ACTION = {
  type: 'NUTRITION_CATEGORIES.PUT_REJECTED',
};

type PUT_ACTIONS =
  | PUT_ACTION
  | PUT_PENDING_ACTION
  | PUT_FULFILLED_ACTION
  | PUT_REJECTED_ACTION;

// REMOVE

export const REMOVE = 'NUTRITION_CATEGORIES.REMOVE';
export type REMOVE_ACTION = {
  type: 'NUTRITION_CATEGORIES.REMOVE',
  payload: Promise<any>,
};

export const REMOVE_PENDING = 'NUTRITION_CATEGORIES.REMOVE_PENDING';
export type REMOVE_PENDING_ACTION = {
  type: 'NUTRITION_CATEGORIES.REMOVE_PENDING',
};

export const REMOVE_FULFILLED = 'NUTRITION_CATEGORIES.REMOVE_FULFILLED';
export type REMOVE_FULFILLED_ACTION = {
  type: 'NUTRITION_CATEGORIES.REMOVE_FULFILLED',
  payload: any,
};

export const REMOVE_REJECTED = 'NUTRITION_CATEGORIES.REMOVE_REJECTED';
export type REMOVE_REJECTED_ACTION = {
  type: 'NUTRITION_CATEGORIES.REMOVE_REJECTED',
};

type REMOVE_ACTIONS =
  | REMOVE_ACTION
  | REMOVE_PENDING_ACTION
  | REMOVE_FULFILLED_ACTION
  | REMOVE_REJECTED_ACTION;

// GET

export const GET = 'NUTRITION_CATEGORIES.GET';
export type GET_ACTION = {
  type: 'NUTRITION_CATEGORIES.GET',
  payload: Promise<any>,
};

export const GET_PENDING = 'NUTRITION_CATEGORIES.GET_PENDING';
export type GET_PENDING_ACTION = {
  type: 'NUTRITION_CATEGORIES.GET_PENDING',
};

export const GET_FULFILLED = 'NUTRITION_CATEGORIES.GET_FULFILLED';
export type GET_FULFILLED_ACTION = {
  type: 'NUTRITION_CATEGORIES.GET_FULFILLED',
  payload: any,
};

export const GET_REJECTED = 'NUTRITION_CATEGORIES.GET_REJECTED';
export type GET_REJECTED_ACTION = {
  type: 'NUTRITION_CATEGORIES.GET_REJECTED',
};

type GET_ACTIONS =
  | GET_ACTION
  | GET_PENDING_ACTION
  | GET_FULFILLED_ACTION
  | GET_REJECTED_ACTION;

// LIST

export const LIST = 'NUTRITION_CATEGORIES.LIST';
export type LIST_ACTION = {
  type: 'NUTRITION_CATEGORIES.LIST',
  payload: Promise<any>,
};

export const LIST_PENDING = 'NUTRITION_CATEGORIES.LIST_PENDING';
export type LIST_PENDING_ACTION = {
  type: 'NUTRITION_CATEGORIES.LIST_PENDING',
};

export const LIST_FULFILLED = 'NUTRITION_CATEGORIES.LIST_FULFILLED';
export type LIST_FULFILLED_ACTION = {
  type: 'NUTRITION_CATEGORIES.LIST_FULFILLED',
  payload: any,
};

export const LIST_REJECTED = 'NUTRITION_CATEGORIES.LIST_REJECTED';
export type LIST_REJECTED_ACTION = {
  type: 'NUTRITION_CATEGORIES.LIST_REJECTED',
};

// SET_MAP_INVALIDATE_ID

export const SET_MAP_INVALIDATOR_ID =
  'NUTRITION_CATEGORIES.SET_MAP_INVALIDATE_ID';
export type SET_MAP_INVALIDATE_ID_ACTION = {
  type: 'NUTRITION_CATEGORIES.SET_MAP_INVALIDATE_ID',
  payload: string,
};

// UNSET_MAP_INVALIDATE_ID

export const UNSET_MAP_INVALIDATOR_ID =
  'NUTRITION_CATEGORIES.UNSET_MAP_INVALIDATE_ID';
export type UNSET_MAP_INVALIDATE_ID_ACTION = {
  type: 'NUTRITION_CATEGORIES.UNSET_MAP_INVALIDATE_ID',
  payload: string,
};

// SET_SEARCH_QUERY

export const SET_SEARCH_QUERY = 'NUTRITION_CATEGORIES.SET_SEARCH_QUERY';
export type SET_SEARCH_QUERY_ACTION = {
  type: 'NUTRITION_CATEGORIES.SET_SEARCH_QUERY',
  payload: ?string,
};

type LIST_ACTIONS =
  | LIST_ACTION
  | LIST_PENDING_ACTION
  | LIST_FULFILLED_ACTION
  | LIST_REJECTED_ACTION
  | SET_MAP_INVALIDATE_ID_ACTION
  | UNSET_MAP_INVALIDATE_ID_ACTION
  | SET_SEARCH_QUERY_ACTION;

// CREATE_SIGNED_URL

export const CREATE_SIGNED_URL = 'NUTRITION_CATEGORIES.CREATE_SIGNED_URL';
export type CREATE_SIGNED_URL_ACTION = {
  type: 'NUTRITION_CATEGORIES.CREATE_SIGNED_URL',
  payload: any,
};

// UPLOAD_IMAGE

export const UPLOAD_IMAGE = 'NUTRITION_CATEGORIES.UPLOAD_IMAGE';
export type UPLOAD_IMAGE_ACTION = {
  type: 'NUTRITION_CATEGORIES.UPLOAD_IMAGE',
  payload: any,
};

//------------------------------------

export type Action =
  | SET_ORDER_ACTION
  | SET_ORDER_PENDING_ACTION
  | SET_ORDER_FULFILLED_ACTION
  | SET_ORDER_REJECTED_ACTION
  | POST_ACTIONS
  | PUT_ACTIONS
  | REMOVE_ACTIONS
  | GET_ACTIONS
  | LIST_ACTIONS
  | CREATE_SIGNED_URL_ACTION
  | UPLOAD_IMAGE_ACTION;
