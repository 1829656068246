import type { RoleMembersState } from '../model/types';
import { getPersistedState } from '../persistance';
import createReducer from '../../utility/createReducer';
import reduceMap from './map';
import reduceApply from './apply';

const initialState: RoleMembersState = {
  statusList: undefined,
  applyStatus: undefined,
  map: {},
  mapIsValid: false,
  nextPageToken: undefined,
};

export const preloadedState: RoleMembersState = {
  ...initialState,
  ...getPersistedState(),
};

export default createReducer(initialState, {
  'GLOBAL.RESET': () => initialState,
  ...reduceMap,
  ...reduceApply,
});
