import type { ReducerFragment } from '../../types';
import type { MarketsState } from '../model/types';
import * as types from '../action/types';

const reducePost: ReducerFragment<MarketsState> = {
  [types.POST_PENDING]: (state, action) => {
    return { ...state, statusPost: 'pending' };
  },

  [types.POST_FULFILLED]: (state, action) => {
    const market = action.payload;
    return {
      ...state,
      map: { ...state.map, [market.marketCode]: market },
      statusPost: 'fulfilled',
    };
  },

  [types.POST_REJECTED]: (state, action) => {
    return { ...state, statusPost: 'rejected' };
  },
};

export default reducePost;
