import type { MUITheme } from '../../../styles/types';

const styles = (theme: MUITheme) => {
  return {
    root: {
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(15),
      },
    },
    center: {
      width: '100%',
      height: '100vh',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 374,
        height: '100%',
      },
    },
  };
};

export type Classes = $Call<typeof styles, MUITheme>;
export default styles;
