const Checkbox = (theme: string) => ({
  MuiCheckbox: {
    colorSecondary: {
      '&$checked': {
        color: theme === 'dark' ? '#3C9E1A' : '#444', //Because, for some reason, checkboxes have color secondary be default...
      },
    },
  },
});

export default Checkbox;
