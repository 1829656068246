/**
 * An error that has already been handled.
 * So no further actions needs to be taken.
 */
class HandledError extends Error {
  static errorName = 'HandledError';

  constructor(message: string) {
    super(message);
    this.name = HandledError.name;
  }
}

export default HandledError;
