import type { ReducerFragment } from '../../types';
import type { AccountsState } from '../model/types';
import * as types from '../action/types';

const reduce: ReducerFragment<AccountsState> = {
  [types.GET_PENDING]: (state, action) => ({
    ...state,
    getStatus: 'pending',
  }),

  [types.GET_FULFILLED]: (state, action) => {
    const { payload, meta } = action;

    let nextState = {
      ...state,
      map: { ...state.map, [payload.data.id]: payload.data },
      getStatus: 'fulfilled',
    };

    if (meta.isAuthenticatedUser) {
      nextState = { ...nextState, userAccount: payload.data };
    }

    return nextState;
  },

  [types.GET_REJECTED]: (state, action) => ({
    ...state,
    getStatus: 'rejected',
  }),
};

export default reduce;
