import update from 'immutability-helper';

const reduceGetItemFulfilled = (state: any, action: any) => {
  const data = action.payload.data;
  const itemId = action.meta.subjectId;

  return update(state, {
    items: {
      [itemId]: {
        statusGet: { $set: 'fulfilled' },
        data: { $set: data },
      },
    },
  });
};

export default reduceGetItemFulfilled;
