import useStyles from './styles';

const SkeletonHomeCard = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <span className="anim-shimmer"></span>
      </div>
    </div>
  );
};

export default SkeletonHomeCard;
