export type State = {|
  searchQuery?: string,
  mapInvalidatorId: string,
  map: Object,
|};

// IMPORT

export const IMPORT = 'NUTRITION_IMPORTS.IMPORT';
export type IMPORT_ACTION = {
  type: 'NUTRITION_IMPORTS.IMPORT',
  payload: Promise<any>,
};

export const IMPORT_PENDING = 'NUTRITION_IMPORTS.IMPORT_PENDING';
export type IMPORT_PENDING_ACTION = {
  type: 'NUTRITION_IMPORTS.IMPORT_PENDING',
};

export const IMPORT_FULFILLED = 'NUTRITION_IMPORTS.IMPORT_FULFILLED';
export type IMPORT_FULFILLED_ACTION = {
  type: 'NUTRITION_IMPORTS.IMPORT_FULFILLED',
  payload: any,
};

export const IMPORT_REJECTED = 'NUTRITION_IMPORTS.IMPORT_REJECTED';
export type IMPORT_REJECTED_ACTION = {
  type: 'NUTRITION_IMPORTS.IMPORT_REJECTED',
};

type IMPORT_ACTIONS =
  | IMPORT_ACTION
  | IMPORT_PENDING_ACTION
  | IMPORT_FULFILLED_ACTION
  | IMPORT_REJECTED_ACTION;

// LIST_STATUSES

export const LIST_STATUSES = 'NUTRITION_IMPORTS.LIST_STATUSES';
export type LIST_STATUSES_ACTION = {
  type: 'NUTRITION_IMPORTS.LIST_STATUSES',
  payload: Promise<any>,
};

export const LIST_STATUSES_PENDING = 'NUTRITION_IMPORTS.LIST_STATUSES_PENDING';
export type LIST_STATUSES_PENDING_ACTION = {
  type: 'NUTRITION_IMPORTS.LIST_STATUSES_PENDING',
};

export const LIST_STATUSES_FULFILLED =
  'NUTRITION_IMPORTS.LIST_STATUSES_FULFILLED';
export type LIST_STATUSES_FULFILLED_ACTION = {
  type: 'NUTRITION_IMPORTS.LIST_STATUSES_FULFILLED',
  payload: any,
};

export const LIST_STATUSES_REJECTED =
  'NUTRITION_IMPORTS.LIST_STATUSES_REJECTED';
export type LIST_STATUSES_REJECTED_ACTION = {
  type: 'NUTRITION_IMPORTS.LIST_STATUSES_REJECTED',
};

// SET_MAP_INVALIDATOR_ID

export const SET_MAP_INVALIDATOR_ID =
  'NUTRITION_CATEGORIES.SET_MAP_INVALIDATOR_ID';
export type SET_MAP_INVALIDATOR_ID_ACTION = {
  type: 'NUTRITION_CATEGORIES.SET_MAP_INVALIDATOR_ID',
  payload: string,
};

// UNSET_MAP_INVALIDATOR_ID

export const UNSET_MAP_INVALIDATOR_ID =
  'NUTRITION_CATEGORIES.UNSET_MAP_INVALIDATOR_ID';
export type UNSET_MAP_INVALIDATOR_ID_ACTION = {
  type: 'NUTRITION_CATEGORIES.UNSET_MAP_INVALIDATOR_ID',
  payload: string,
};

type LIST_STATUSES_ACTIONS =
  | LIST_STATUSES_ACTION
  | LIST_STATUSES_PENDING_ACTION
  | LIST_STATUSES_FULFILLED_ACTION
  | LIST_STATUSES_REJECTED_ACTION
  | SET_MAP_INVALIDATOR_ID_ACTION
  | UNSET_MAP_INVALIDATOR_ID_ACTION;

//------------------------------------

export type Action = IMPORT_ACTIONS | LIST_STATUSES_ACTIONS;
