import type { RestaurantsImportsState, Ofmarket } from './types';
import createReducer from '../../utility/createReducer';
import reduceStatuses from './statuses';

const initialState: RestaurantsImportsState = {
  markets: {},
  statusList: undefined,
};

export const getInitialMarket = (): Ofmarket => ({
  statuses: [],
  sortOrder: 'desc',
  invalidatorId: 'PRISTINE',
});

export default createReducer(initialState, {
  'GLOBAL.RESET': () => initialState,
  'RESTAURANTS_IMPORTS.SCAFFOLD_MARKET': (state, action) => {
    if (state.markets[action.payload]) return state;

    return {
      ...state,
      markets: { ...state.markets, [action.payload]: getInitialMarket() },
    };
  },
  ...reduceStatuses,
});
