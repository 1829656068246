import type { ReducerFragment } from '../../types';
import type { AssetsState } from '../model/types';
import * as types from '../action/types';

const reducePut: ReducerFragment<AssetsState> = {
  [types.PUT_PENDING]: (state, action) => {
    return { ...state, statusPut: 'pending' };
  },

  [types.PUT_FULFILLED]: (state, action) => {
    return { ...state, statusPut: 'fulfilled' };
  },

  [types.PUT_REJECTED]: (state, action) => {
    return { ...state, statusPut: 'rejected' };
  },
};

export default reducePut;
