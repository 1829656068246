import type { MUITheme } from '../../../../styles/types';

const styles = (theme: MUITheme) => {
  const paddingY = theme.spacing(4);

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      '&.expandHeight': {
        marginTop: paddingY,
        marginBottom: paddingY,
      },
      '&.fillHeight': {
        height: '100%',
        flexGrow: 1,
      },
    },
  };
};

export type Classes = $Call<typeof styles, MUITheme>;
export default styles;
