import type { ReducerFragment } from '../../types';
import type { RestaurantsState } from '../model/types';
import * as types from '../action/types';
import update from 'immutability-helper';

const reduceRemove: ReducerFragment<RestaurantsState> = {
  [types.REMOVE_PENDING]: (state: RestaurantsState) => ({
    ...state,
    statusRemove: 'pending',
  }),

  [types.REMOVE_FULFILLED]: (
    state: RestaurantsState,
    action: types.REMOVE_FULFILLED_ACTION
  ) => ({
    ...state,
    map: update(state.map, {
      $unset: [action.payload.id],
    }),
    statusRemove: 'fulfilled',
  }),

  [types.REMOVE_REJECTED]: (state: RestaurantsState) => ({
    ...state,
    statusRemove: 'rejected',
  }),
};

export default reduceRemove;
