import * as types from './types';

import initialState from './initialState';
import createReducer from '../utility/createReducer';
import reduceListFulfilled from '../utility/general/reducers/reduceListFulfilled';

export type State = types.State;

export type Action = types.Action;

export const reducer = createReducer(initialState, {
  'GLOBAL.RESET': () => initialState,

  [types.RESET_MAP]: (state, action) => {
    return { ...state, map: {}, mapInvalidatorId: 'PRISTINE' };
  },

  [types.LIST_PENDING]: (state, action) => {
    return {
      ...state,
      statusList: 'pending',
    };
  },

  [types.LIST_FULFILLED]: reduceListFulfilled(),

  [types.LIST_REJECTED]: (state, action) => {
    return {
      ...state,
      statusList: 'rejected',
    };
  },

  [types.SET_MAP_INVALIDATE_ID]: (state, action) => ({
    ...state,
    mapInvalidatorId: action.payload,
  }),

  [types.UNSET_MAP_INVALIDATE_ID]: (state, action) => {
    if (state.mapInvalidatorId === action.payload) {
      return { ...state, mapInvalidatorId: null };
    }
    return state;
  },

  [types.SET_SEARCH_QUERY]: (state, action) => {
    return {
      ...state,
      searchQuery: action.payload,
    };
  },

  [types.GET_FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      map: { ...state.map, [payload.id]: payload },
    };
  },

  [types.PUT_FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      map: { ...state.map, [payload.id]: payload },
    };
  },
});
