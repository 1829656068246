import type { MultiFactorError, AuthError } from 'firebase/auth';
import { FirebaseError } from '@firebase/util';
import type { AxiosError } from 'axios';
import axios from 'axios';

export function userErrorsIsFirebase(err: unknown): err is FirebaseError {
  return err instanceof Error && err.name === 'FirebaseError';
}

export function userErrorsIsFirebaseMfa(err: unknown): err is MultiFactorError {
  if (userErrorsIsFirebase(err)) {
    if (err.code === 'auth/multi-factor-auth-required') {
      return true;
    }
  }
  return false;
}

export function userErrorsIsFirebaseCode(err: unknown): err is AuthError {
  if (userErrorsIsFirebase(err)) {
    if (err.code === 'auth/invalid-verification-code') {
      return true;
    }
  }
  return false;
}

export function userErrorsGetMfaError(err: unknown): null | MultiFactorError {
  if (userErrorsIsFirebaseMfa(err)) {
    return err;
  }
  return null;
}

export function userErrorsIsAxios(err: unknown): err is AxiosError {
  return axios.isAxiosError(err);
}

export function userErrorsGetAxiosMessage(err: unknown): string {
  if (userErrorsIsAxios(err)) {
    return err.response?.data?.error?.message?.toLowerCase() ?? '';
  }
  return '';
}
