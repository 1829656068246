import type { Role } from '../model/types';

// LIST

export const LIST: 'ROLES.LIST' = 'ROLES.LIST';
export type LIST_ACTION = {
  type: typeof LIST,
  payload: Promise<{| [string]: Role |}>,
};

export const LIST_PENDING: 'ROLES.LIST_PENDING' = 'ROLES.LIST_PENDING';
export type LIST_PENDING_ACTION = {
  type: typeof LIST_PENDING,
};

export const LIST_FULFILLED: 'ROLES.LIST_FULFILLED' = 'ROLES.LIST_FULFILLED';
export type LIST_FULFILLED_ACTION = {
  type: typeof LIST_FULFILLED,
  payload: {| [string]: Role |},
};

export const LIST_REJECTED: 'ROLES.LIST_REJECTED' = 'ROLES.LIST_REJECTED';
export type LIST_REJECTED_ACTION = {
  type: typeof LIST_REJECTED,
};

export const SET_MAP_INVALIDATOR_ID: 'ROLES.SET_MAP_INVALIDATOR_ID' =
  'ROLES.SET_MAP_INVALIDATOR_ID';
export type SET_MAP_INVALIDATOR_ID_ACTION = {
  type: typeof SET_MAP_INVALIDATOR_ID,
  payload: string,
};

export const UNSET_MAP_INVALIDATOR_ID: 'ROLES.UNSET_MAP_INVALIDATOR_ID' =
  'ROLES.UNSET_MAP_INVALIDATOR_ID';
export type UNSET_MAP_INVALIDATOR_ID_ACTION = {
  type: typeof UNSET_MAP_INVALIDATOR_ID,
  payload: string,
};

type LIST_ACTIONS =
  | LIST_ACTION
  | LIST_PENDING_ACTION
  | LIST_FULFILLED_ACTION
  | LIST_REJECTED_ACTION
  | SET_MAP_INVALIDATOR_ID_ACTION
  | UNSET_MAP_INVALIDATOR_ID_ACTION;

//------------------------------------

export type Action = LIST_ACTIONS;
