import type { ReducerFragment } from '../../types';
import type { RestaurantFacilityOverrideState } from '../model/types';
import * as types from '../actions/types';

const reduceRemove: ReducerFragment<RestaurantFacilityOverrideState> = {
  [types.REMOVE_PENDING]: (state) => ({
    ...state,
    statusRemove: 'pending',
  }),

  [types.REMOVE_FULFILLED]: (state, action) => ({
    ...state,
    statusRemove: 'fulfilled',
  }),

  [types.REMOVE_REJECTED]: (state) => ({
    ...state,
    statusRemove: 'rejected',
  }),
};

export default reduceRemove;
