type Reducer = (state: *, action: *) => *;

//TODO --> find a way to get the action type in the handler
const createReducer = (
  initialState: *,
  handlers: {
    [*]: Reducer,
  }
) => {
  return function reducer(state: * = initialState, action: *) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
};

export default createReducer;
