import type { Action } from '../types';
import type { Reducer } from 'redux';
import type { AppState } from './types';

const initialState: AppState = {
  alert: undefined,
  enabledFunctionality: {},
  selectedTabIdx: false,
  tabs: [],
  curPage: undefined,
};

export const preloadedState: AppState = {
  ...initialState,
};

const reducer: Reducer<AppState, Action> = (
  state: AppState = initialState,
  action
): AppState => {
  switch (action.type) {
    case 'GLOBAL.RESET':
      return initialState;
    case 'APP.SET_ALERT':
      return { ...state, alert: action.payload };
    case 'APP.SET_CONSUMERTAG_WARNING':
      return { ...state, consumerTagWarning: action.payload };
    case 'APP.SET_TABS':
      return { ...state, tabs: action.payload };
    case 'APP.SET_SELECTED_TAB_IDX':
      return { ...state, selectedTabIdx: action.payload };
    case 'APP.SET_CURRENT_PAGE':
      return { ...state, curPage: action.payload };
    case 'APP.SET_ENABLED_FUNCTIONALITY':
      return { ...state, enabledFunctionality: action.payload };
    default:
      return state;
  }
};

export default reducer;
