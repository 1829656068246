import API from '../../../utility/API';

export const list = (nextPageToken?: ?string): Promise<any> => {
  const axios = API.axios();
  return axios.get(`/am/v1/roles`, {
    params: {
      ...(nextPageToken ? { next: nextPageToken } : {}),
    },
  });
};
