import type { RootState } from '../';
import type { AsyncStatus } from '../types';
import type { Market } from './model/types';

const KEY = '1k:state:markets';

type PersistedMarketsState =
  | {|
      selectedMarketId: ?string,
      map: {| [string]: Market |},
      statusList: ?AsyncStatus,
    |}
  | {||};

export const getPersistedState = (): PersistedMarketsState => {
  const persisted = localStorage.getItem(KEY);
  if (persisted) {
    return JSON.parse(persisted);
  }
  return Object.freeze({});
};

export const persistState = (state: RootState): void => {
  const toPersist: PersistedMarketsState = {
    selectedMarketId: state.markets.selectedMarketId,
    map: state.markets.map,
    statusList: state.markets.statusList,
  };
  if (toPersist) {
    localStorage.setItem(KEY, JSON.stringify(toPersist));
  }
};
