import type { Asset } from '../model/types';

// RESET

export const RESET = 'RESTAURANTS.RESET';
export type RESET_ACTION = {
  type: 'RESTAURANTS.RESET',
};

// LIST

export const LIST = 'ASSETS.LIST';
export type LIST_ACTION = {
  type: 'ASSETS.LIST',
  payload: Promise<any>,
};

export const LIST_PENDING = 'ASSETS.LIST_PENDING';
export type LIST_PENDING_ACTION = {
  type: 'ASSETS.LIST_PENDING',
};

export const LIST_FULFILLED = 'ASSETS.LIST_FULFILLED';
export type LIST_FULFILLED_ACTION = {
  type: 'ASSETS.LIST_FULFILLED',
  payload: { data: Asset },
};

export const LIST_REJECTED = 'ASSETS.LIST_REJECTED';
export type LIST_REJECTED_ACTION = {
  type: 'ASSETS.LIST_REJECTED',
};

// GET

export const GET = 'ASSETS.GET';
export type GET_ACTION = {
  type: 'ASSETS.GET',
  payload: Promise<any>,
};

export const GET_PENDING = 'ASSETS.GET_PENDING';
export type GET_PENDING_ACTION = {
  type: 'ASSETS.GET_PENDING',
};

export const GET_FULFILLED = 'ASSETS.GET_FULFILLED';
export type GET_FULFILLED_ACTION = {
  type: 'ASSETS.GET_FULFILLED',
  payload: { data: Asset },
};

export const GET_REJECTED = 'ASSETS.GET_REJECTED';
export type GET_REJECTED_ACTION = {
  type: 'ASSETS.GET_REJECTED',
};

// PUT

export const PUT = 'ASSETS.PUT';
export type PUT_ACTION = {
  type: 'ASSETS.PUT',
  payload: Promise<any>,
};

export const PUT_PENDING = 'ASSETS.PUT_PENDING';
export type PUT_PENDING_ACTION = {
  type: 'ASSETS.PUT_PENDING',
};

export const PUT_FULFILLED = 'ASSETS.PUT_FULFILLED';
export type PUT_FULFILLED_ACTION = {
  type: 'ASSETS.PUT_FULFILLED',
};

export const PUT_REJECTED = 'ASSETS.PUT_REJECTED';
export type PUT_REJECTED_ACTION = {
  type: 'ASSETS.PUT_REJECTED',
};

// POST

export const POST = 'ASSETS.POST';
export type POST_ACTION = {
  type: 'ASSETS.POST',
  payload: Promise<any>,
};

export const POST_PENDING = 'ASSETS.POST_PENDING';
export type POST_PENDING_ACTION = {
  type: 'ASSETS.POST_PENDING',
};

export const POST_FULFILLED = 'ASSETS.POST_FULFILLED';
export type POST_FULFILLED_ACTION = {
  type: 'ASSETS.POST_FULFILLED',
};

export const POST_REJECTED = 'ASSETS.POST_REJECTED';
export type POST_REJECTED_ACTION = {
  type: 'ASSETS.POST_REJECTED',
};

// DELETE
export const DELETE = 'ASSETS.DELETE';
export type DELETE_ACTION = {
  type: 'ASSETS.DELETE',
  payload: Promise<any>,
};

export const DELETE_PENDING = 'ASSETS.DELETE_PENDING';
export type DELETE_PENDING_ACTION = {
  type: 'ASSETS.DELETE_PENDING',
};

export const DELETE_FULFILLED = 'ASSETS.DELETE_FULFILLED';
export type DELETE_FULFILLED_ACTION = {
  type: 'ASSETS.DELETE_FULFILLED',
};

export const DELETE_REJECTED = 'ASSETS.DELETE_REJECTED';
export type DELETE_REJECTED_ACTION = {
  type: 'ASSETS.DELETE_REJECTED',
};

// GET OPERATION STATUS

export const GET_OPERATION_STATUS = 'ASSETS.GET_OPERATION_STATUS';
export type GET_OPERATION_STATUS_ACTION = {
  type: 'ASSETS.GET_OPERATION_STATUS',
};

// APPEND ASSET

export const APPEND_ASSET = 'ASSETS.APPEND_ASSET';
export type APPEND_ASSET_ACTION = {
  type: 'ASSETS.APPEND_ASSET',
};

export type Action =
  | RESET_ACTION
  | LIST_ACTION
  | LIST_PENDING_ACTION
  | LIST_FULFILLED_ACTION
  | LIST_REJECTED_ACTION
  | GET_ACTION
  | GET_PENDING_ACTION
  | GET_FULFILLED_ACTION
  | GET_REJECTED_ACTION
  | PUT_ACTION
  | PUT_PENDING_ACTION
  | PUT_FULFILLED_ACTION
  | PUT_REJECTED_ACTION
  | POST_ACTION
  | POST_PENDING_ACTION
  | POST_FULFILLED_ACTION
  | POST_REJECTED_ACTION
  | GET_OPERATION_STATUS_ACTION
  | APPEND_ASSET_ACTION;
