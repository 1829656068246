import {
  set,
  getHours,
  getMilliseconds,
  getMinutes,
  getSeconds,
  parseISO,
  format,
} from 'date-fns';

export const DATE_TIME_FORMAT = 'yy.MM.dd hh:mm aa';

export const TIME_FORMAT = 'HH:mm';

export const DATE_FORMATS_LIST = {
  DEFAULT: 'MMM d, yyyy',
  ISO: 'yyyy-MM-dd hh:mm',
};

export const TIME_FORMAT_STORAGE_KEY = 'dateTimeFormat';

/** Set hours, minutes, seconds, milliseconds from dateFrom on dateTo.  */
export const setTime = (dateTo: Date, dateFrom: Date) => {
  return set(dateTo, {
    hours: getHours(dateFrom),
    minutes: getMinutes(dateFrom),
    seconds: getSeconds(dateFrom),
    milliseconds: getMilliseconds(dateFrom),
  });
};

export const customDateFormat = (date: string) => {
  if (date) {
    return format(
      parseISO(date),
      localStorage.getItem('dateTimeFormat') || DATE_FORMATS_LIST.DEFAULT
    );
  }
  return '';
};
