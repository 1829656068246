import { Hidden, Drawer, withStyles } from '@material-ui/core';
import * as React from 'react';

import AppDrawerContent from './appDrawerContent/AppDrawerContent';

const styles = (theme) => ({
  nav: {
    position: 'relative',
    width: theme.dimensions.drawer.width,
  },
  drawerPaper: {
    width: theme.dimensions.drawer.width,
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
      paddingTop: theme.dimensions.appBar.height,
      zIndex: theme.zIndex.appBar,
    },
    display: 'flex',
    justifyContent: 'space-between',
  },
});

type Props = {
  classes: Object,
  mobileIsOpen: boolean,
  onClose: () => void,
};

const AppDrawer = (props: Props) => {
  const { classes, mobileIsOpen, onClose } = props;

  return (
    <div>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          open={mobileIsOpen}
          onClose={onClose}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <AppDrawerContent onClose={onClose} />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <nav className={classes.nav}>
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <AppDrawerContent onClose={onClose} />
          </Drawer>
        </nav>
      </Hidden>
    </div>
  );
};

export default withStyles(styles)(AppDrawer);
