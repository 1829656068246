import type { MUITheme } from '../../../styles/types';

const styles = (theme: MUITheme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor:
      theme.palette.type === 'dark' ? '#1b1b1b' : theme.palette.primary.main,
  },
  toolbarStart: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 2,
  },
});

export type Classes = $Call<typeof styles, MUITheme>;
export default styles;
