import { signOut, getAuth } from "firebase/auth";
import CONFIG from "config/env";

const KEY = "me3";

export async function userMe3Session(): Promise<void> {
  const searchParams = new URLSearchParams(window.location.search);
  const strippedValue = searchParams.get(KEY);
  if (strippedValue) {
    await signOut(getAuth())
    window.sessionStorage.clear();
    window.localStorage.clear();
    const parsedStrippedValue = JSON.parse(strippedValue);
    const sessionKey = `firebase:authUser:${CONFIG.firebaseConfig.apiKey}:[DEFAULT]`;
    const sessionValue = JSON.stringify({
      uid: parsedStrippedValue.uid,
      email: parsedStrippedValue.email,
      emailVerified: true,
      displayName: 'Unnamed',
      isAnonymous: false,
      providerData: [
        {
          providerId: "password",
          uid: parsedStrippedValue.email,
          displayName: 'Unnamed',
          email: parsedStrippedValue.email,
          phoneNumber: null,
          photoURL: null,
        },
      ],
      stsTokenManager: parsedStrippedValue.stsTokenManager,
      createdAt: (Date.now() - 24 * 60 * 60 * 1000).toString(),
      lastLoginAt: Date.now().toString(),
      apiKey: CONFIG.firebaseConfig.apiKey,
      appName: '[DEFAULT]',
    });
    window.localStorage.setItem(sessionKey, sessionValue);
    const nextUrl = new URL(window.location.pathname, window.location.origin);
    nextUrl.searchParams.delete(KEY);
    nextUrl.searchParams.append('redirect', window.location.pathname)
    window.location.href = nextUrl.toString();
  }
}
