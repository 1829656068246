import * as React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

const styles = {
  root: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
};

type Props = {|
  className: string,
  classes: typeof styles,
  children: React.Node,
|};

const HorizontalGroup = withStyles(styles)(
  ({ className, classes, children, ...rest }: Props) => (
    <div className={clsx(className, classes.root)} {...rest}>
      {children}
    </div>
  )
);

export default HorizontalGroup;
