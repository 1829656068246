import { getAuth, signOut } from "firebase/auth";
import type { Creator } from "state/creators";

export const userSignOut: Creator<Promise<void>> = () => {
  return (dispatch) => {
    return signOut(getAuth())
      .then(() => {
        window.sessionStorage.clear();
        dispatch({ type: 'GLOBAL.RESET' });
      })
      .catch((err) => {
        console.error(err);
      });
  };
};
