import type { ThunkAction, ListActionCreatorOptions } from '../../types';
import type { Account } from '../model/types';
import * as types from './types';
import * as api from '../api';
import { userKickOut } from "state/user/action/kickOut";
import { getUserAccountId } from '../../user/model/selectors';
import HandledError from '../../../errors/HandledError';
import listHelper from '../../utility/general/actionCreators/listHelper';

export const resetMap = () => ({
  type: types.RESET_MAP,
});

export const get =
  (id: string): ThunkAction =>
  (dispatch, getState) => {
    const userAccountId = getUserAccountId(getState());
    const isAuthenticatedUser = id === userAccountId;
    return dispatch({
      type: types.GET,
      payload: api.get(id),
      meta: {
        isAuthenticatedUser,
      },
    }).catch((error) => {
      if (
        error &&
        error.response &&
        error.response.status === 403 &&
        (!userAccountId || isAuthenticatedUser)
      ) {
        dispatch(userKickOut());
        return Promise.reject(
          new HandledError('Unauthorized: User kicked out')
        );
      }
      return Promise.reject(error);
    });
  };

export const put =
  (model: Account): ThunkAction =>
  (dispatch) => {
    return dispatch({
      type: types.PUT,
      payload: api.put(model).then((response) => response.data),
    });
  };

export const remove =
  (accountId: string): ThunkAction =>
  (dispatch) => {
    return dispatch({
      type: types.DELETE,
      payload: api.remove(accountId),
    });
  };

export const listSortedBy =
  (sortBy: string): ThunkAction =>
  (dispatch, getState) => {
    const sortByEncodedForBackend = sortBy.replace('-', '_');
    dispatch({
      type: types.SET_SORT_BY,
      payload: sortByEncodedForBackend,
    });

    return dispatch(
      list({ append: false, invalidate: true, debounce: { duration: 400 } })
    );
  };

export const setSearchQuery =
  (searchQuery: ?string): ThunkAction =>
  (dispatch) => {
    return dispatch({
      type: types.SET_SEARCH_QUERY,
      payload: searchQuery,
    });
  };

export const list =
  (options?: ListActionCreatorOptions): ThunkAction =>
  (dispatch, getState) => {
    const { invalidate, ...restOptions } = options || {};
    const { accounts } = getState();
    const { sortBy: orderBy, sortOrder, searchQuery } = accounts;

    let cfg = {
      ...restOptions,
      statePath: 'accounts',
      fetch: {
        action: types.LIST,
        bindFetch: (market, nextPageToken) =>
          api.list({ nextPageToken, orderBy, sortOrder, searchQuery }),
      },
    };

    if (options) {
      if (options.invalidate) {
        cfg = {
          ...cfg,
          invalidate: {
            actionSet: types.SET_MAP_INVALIDATOR_ID,
            actionClear: types.UNSET_MAP_INVALIDATOR_ID,
          },
        };
      }
    }

    return dispatch(listHelper(cfg));
  };
