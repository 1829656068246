import type { TranslationsState } from '../types';
import createReducer from '../../utility/createReducer';
import reduceGet from './get';

const initialState: TranslationsState = {
  ...null,
};

export default createReducer(initialState, {
  'GLOBAL.RESET': () => initialState,
  ...reduceGet,
});
