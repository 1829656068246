// RESET

export const RESET: 'ROLE_MEMBERS.RESET' = 'ROLE_MEMBERS.RESET';
export type RESET_ACTION = {
  type: typeof RESET,
};

// LIST_OF_ACCOUNT

export const LIST_OF_ACCOUNT: 'ROLE_MEMBERS.LIST_OF_ACCOUNT' =
  'ROLE_MEMBERS.LIST_OF_ACCOUNT';
export type LIST_OF_ACCOUNT_ACTION = {
  type: typeof LIST_OF_ACCOUNT,
  payload: Promise<any>,
};

export const LIST_OF_ACCOUNT_PENDING: 'ROLE_MEMBERS.LIST_OF_ACCOUNT_PENDING' =
  'ROLE_MEMBERS.LIST_OF_ACCOUNT_PENDING';
export type LIST_OF_ACCOUNT_PENDING_ACTION = {
  type: typeof LIST_OF_ACCOUNT_PENDING,
};

export const LIST_OF_ACCOUNT_FULFILLED: 'ROLE_MEMBERS.LIST_OF_ACCOUNT_FULFILLED' =
  'ROLE_MEMBERS.LIST_OF_ACCOUNT_FULFILLED';
export type LIST_OF_ACCOUNT_FULFILLED_ACTION = {
  type: typeof LIST_OF_ACCOUNT_FULFILLED,
  payload: any,
};

export const LIST_OF_ACCOUNT_REJECTED: 'ROLE_MEMBERS.LIST_OF_ACCOUNT_REJECTED' =
  'ROLE_MEMBERS.LIST_OF_ACCOUNT_REJECTED';
export type LIST_OF_ACCOUNT_REJECTED_ACTION = {
  type: typeof LIST_OF_ACCOUNT_REJECTED,
};

export const SET_IS_MAP_VALID: 'ROLE_MEMBERS.SET_IS_MAP_VALID' =
  'ROLE_MEMBERS.SET_IS_MAP_VALID';
export type SET_IS_MAP_VALID_ACTION = {
  type: typeof SET_IS_MAP_VALID,
  payload: boolean,
};

type LIST_OF_ACCOUNT_ACTIONS =
  | LIST_OF_ACCOUNT_ACTION
  | LIST_OF_ACCOUNT_PENDING_ACTION
  | LIST_OF_ACCOUNT_FULFILLED_ACTION
  | LIST_OF_ACCOUNT_REJECTED_ACTION
  | SET_IS_MAP_VALID_ACTION;

// APPLY

export const APPLY: 'ROLE_MEMBERS.APPLY' = 'ROLE_MEMBERS.APPLY';
export type APPLY_ACTION = {
  type: typeof APPLY,
  payload: Promise<any>,
};

export const APPLY_PENDING: 'ROLE_MEMBERS.APPLY_PENDING' =
  'ROLE_MEMBERS.APPLY_PENDING';
export type APPLY_PENDING_ACTION = {
  type: typeof APPLY_PENDING,
};

export const APPLY_FULFILLED: 'ROLE_MEMBERS.APPLY_FULFILLED' =
  'ROLE_MEMBERS.APPLY_FULFILLED';
export type APPLY_FULFILLED_ACTION = {
  type: typeof APPLY_FULFILLED,
  payload: any,
};

export const APPLY_REJECTED: 'ROLE_MEMBERS.APPLY_REJECTED' =
  'ROLE_MEMBERS.APPLY_REJECTED';
export type APPLY_REJECTED_ACTION = {
  type: typeof APPLY_REJECTED,
};

type APPLY_ACTIONS =
  | APPLY_ACTION
  | APPLY_PENDING_ACTION
  | APPLY_FULFILLED_ACTION
  | APPLY_REJECTED_ACTION;

//------------------------------------

export type Action = RESET_ACTION | LIST_OF_ACCOUNT_ACTIONS | APPLY_ACTIONS;
