import { LINK_STYLES } from './Link';

const CssBaseline = {
  MuiCssBaseline: {
    '@global': {
      a: LINK_STYLES,
    },
  },
};

export default CssBaseline;
