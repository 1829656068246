import type { LocationDescriptorObject } from 'history';
import type { ReactNode } from 'react';
import { useMemo } from "react";
import { useLocation, Redirect } from "react-router";
import { redirectsGetToFromSearch, redirectsGetSearchFromHref } from "utility/redirects";
import { canView as appCanView, getCanViewPath } from 'state/app/selectors';
import { useSelector } from "hooks/selector";
import LoadingOverlay from "components/common/__loaders/loadingOverlay/LoadingOverlay";

type To = string | LocationDescriptorObject;

interface Props {
  children: ReactNode;
}

export default function RouteGuard(props: Props): JSX.Element {
  const { children } = props;
  const { pathname } = useLocation();
  const marketId = useSelector<string>((state) => state.markets.selectedMarketId);
  const canViewSubject = useSelector<(subject: string) => boolean>(appCanView);
  const canViewPath = useSelector<(path: string) => boolean>(getCanViewPath);
  const appReady = useSelector((state) => state.user.appReady);
  const firebaseReady = useSelector((state) => state.user.firebaseReady);
  const userHasToken = useSelector((state) => Boolean(state.user.firebaseIdToken));
  const userAcceptedInvite = useSelector((state) => state.user.invitationAccepted);
  const userInviteCode = useSelector((state) => state.user.inviteCode);
  const userEnrolledMfa = useSelector((state) => state.user.mfaEnrolled);
  const userCanViewPathname = canViewPath(pathname);
  const userDefaultPathname = getDefaultPathname(canViewSubject);
  const routeIsPublic = pathname.startsWith('/auth/');
  const routeIsAuthActivate = pathname.startsWith('/auth/activate/');
  const routeIsRoot = pathname === '/';
  const pending = !firebaseReady || (userHasToken && userEnrolledMfa && !appReady);

  const to = useMemo<null | To>(() => {
    if (firebaseReady) {
      if (userHasToken && userEnrolledMfa && (routeIsRoot || routeIsPublic || (appReady && !userCanViewPathname))) {
        const to = redirectsGetToFromSearch(userDefaultPathname);
        const nextPathname = typeof to === "string"
          ? to
          : to.pathname ?? '';
        const nextSearch = typeof to === "string"
          ? ''
          : to.search ?? '';

        return {
          pathname: getToWithMarketId(nextPathname, marketId),
          search: nextSearch,
        };
      }
      if ((!userHasToken || !userEnrolledMfa)) {
        if (!userAcceptedInvite && !routeIsAuthActivate) {
          if (userInviteCode) {
            return {
              pathname: '/auth/activate/set-password',
              search: redirectsGetSearchFromHref(),
            };
          }
          return {
            pathname: '/auth/activate/start',
            search: redirectsGetSearchFromHref(),
          };
        }
        if (routeIsRoot || !routeIsPublic) {
          return {
            pathname: '/auth/sign-in',
            search: redirectsGetSearchFromHref(),
          };
        }
      }
    }
    return null;
  }, [
    routeIsRoot,
    routeIsPublic,
    routeIsAuthActivate,
    firebaseReady,
    appReady,
    marketId,
    userHasToken,
    userAcceptedInvite,
    userInviteCode,
    userEnrolledMfa,
    userCanViewPathname,
    userDefaultPathname,
  ]);

  if (pending) {
    return (
      <LoadingOverlay
        variants={['centered', 'expand']}
        style={{ minHeight: '80vh' }}
      />
    );
  }
  if (to) {
    return (
      <Redirect to={to} />
    );
  }
  return (
    <>{children}</>
  );
}

function getToWithMarketId(pathname: string, marketId: string): string {
  return `/${marketId || '_'}/${pathname.split('/').slice(2).join('/')}`;
}

/** The page that the user will land on if they have access, in order of priority */
const DEFAULT_PAGES = [['home', '/home'], ['urlBuilder', '/url-builder']] as const;

function getDefaultPathname(canView: (subject: string) => boolean): string {
  const [, pathname = '/home'] = DEFAULT_PAGES.find(([page]) => canView(page)) || [];

  return pathname;
}
